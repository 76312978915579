import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { format, parseISO } from "date-fns";
import { useAgainstReferences } from "../../../service";
import { DialogMedium, Loading } from "../../../helper";
import { Box, TablePagination } from "@mui/material";

import { againstReferencePDF, openURL } from "../../../pdfModels";
import useNotifier from "../../../hooks/useNotifier";
import TableFilter from "../../../components/Table/TableFilter";
import AgainstReferenceForm from "./AgainstReferenceForm";

export default function MyAgainstReferences() {
  const { getAgainstReferences, suspendReference } = useAgainstReferences();
  const queryClient = useQueryClient();
  const [limit, setLimit] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [unicReference, setUnicReference] = React.useState();
  const [openView, setOpenView] = React.useState(false);
  const [action, setAction] = React.useState();
  const notify = useNotifier();

  const suspendReferenceMutation = useMutation(suspendReference);

  const againstReferenceQuery = useQuery(
    ["myAgainstReferences", [page, limit]],
    () => getAgainstReferences({ page, limit, created_by_me: true }),
    {
      initialData: { totalItems: 0, items: [] },
    }
  );

  const tableColumns = [
    {
      name: "Data Solicitada",
      field: "createdAt",
      use: (date) => format(parseISO(date), "dd/MM/yyyy"),
    },
    { name: "Paciente", field: "attendance?.patient?.name" },
    { name: "Especialidade Requisitada", field: "requested_specialty.describe" },
    {
      name: "Situação",
      field: "suspendedAt",
      use: (suspendedAt, data) => {
        if (suspendedAt === null && data.response === null) {
          return "Aberto";
        } else if (suspendedAt) {
          return "Suspenso";
        } else if (data.response) {
          return "Fechado";
        }
      },
    },
  ];

  async function handleSuspend(data) {
    if (!data.suspendedAt && !data.response) {
      suspendReferenceMutation.mutate(data.id, {
        onSuccess: (response) => {
          notify(response.message, "success");
          queryClient.invalidateQueries("myAgainstReferences");
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      });
    } else if (data.suspendedAt && !data.response) {
      notify("Esta Referência / Contrarreferência Já Foi Suspensa", "error");
    } else if (!data.suspendedAt && data.response) {
      notify("Não é possível Suspender uma Referência / Contrarreferência já Respondida", "error");
    }
  }

  function handleView() {
    setOpenView(true);
    setAction("view");
  }

  function printAgainstReference(index) {
    const againstReference = againstReferenceQuery.data?.items[index];

    if (againstReference.signed_document.result) {
      return openURL(againstReference.signed_document.result);
    }

    const pdf = againstReferencePDF(
      againstReference,
      againstReference.attendance.patient,
      againstReference.cids
    );

    pdf.open();
  }

  function handleClose() {
    setOpenView(false);
  }

  if (againstReferenceQuery.isLoading) return <Loading />;

  return (
    <>
      <DialogMedium
        title="Visualizar Referência / Contrarreferência"
        open={openView}
        type={action}
        handleClose={handleClose}
      >
        <AgainstReferenceForm type="view" reference={unicReference} />
      </DialogMedium>
      <TableFilter
        columns={tableColumns}
        data={againstReferenceQuery.data.items}
        loading={againstReferenceQuery.isFetching && againstReferenceQuery.isPreviousData}
        actions
        actionsTypes={["view", "suspend", "print"]}
        rowStatus={(row) => (row.suspendedAt ? "disabled" : "")}
        disableActions={(row, action) => {
          return row.suspendedAt && ["suspend", "print"].includes(action);
        }}
        actionHandleView={(_, data) => {
          setUnicReference(data);
          handleView();
        }}
        actionHandleSuspend={(_, data) => {
          handleSuspend(data);
        }}
        actionHandlePrint={printAgainstReference}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          count={againstReferenceQuery.data.totalItems}
          component="div"
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={(event) => {
            setLimit(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </Box>
    </>
  );
}
