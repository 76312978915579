import { Card, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { accessObjectByString } from "../../utils";

function DynamicFeedback({ data, shape }) {
  const formatter = (type, data, shape) => {
    let value;

    if (type === "label") {
      value = shape.label
        ? shape.label
        : accessObjectByString(data, shape.labelKey);
    } else {
      value = accessObjectByString(data, shape.valueKey);
    }

    if (shape.formatter instanceof Function) {
      return shape.formatter(value, data);
    }

    return value;
  };

  const elements = {
    title: (data, shape) => (
      <Typography variant="p" color="secondary" fontWeight="500" fontSize={15}>
        {formatter("label", data, shape)}
      </Typography>
    ),
    header: (data, shape) => (
      <Typography
        variant="h3"
        color="gray"
        fontWeight="700"
        fontSize={13}
        sx={{ my: 1.4 }}
      >
        {formatter("label", data, shape)}
      </Typography>
    ),
    description: (data, shape) => (
      <Typography
        variant="h5"
        color="primary"
        fontWeight="500"
        fontSize={13}
        my={0.2}
      >
        <Typography
          fontSize={13}
          color="secondary"
          fontWeight="500"
          component="span"
        >
          {shape.label
            ? shape.label
            : accessObjectByString(data, shape.labelKey)}{" "}
          :
        </Typography>{" "}
        {formatter("value", data, shape)}
      </Typography>
    ),
  };

  return (
    <Card
      sx={{
        border: "3px solid transparent",
        borderColor: "white",
        borderWidth: "3px",
        boxShadow: "0 0 0 1.5px #FFFFFF",
      }}
    >
      {shape.map((item, index) => {
        if (item.hidden instanceof Function) {
          const value = accessObjectByString(
            data,
            item.valueKey ?? item.labelKey
          );

          if (item.hidden(value, data)) return null;
        }

        return (
          <Fragment
            key={`${item.type}-${
              item.label ?? item.labelKey ?? item.valueKey
            }-${index}`}
          >
            {elements[item.type](data, item)}
          </Fragment>
        );
      })}
    </Card>
  );
}

export default DynamicFeedback;
