import {
  Box,
  Button,
  Grid,
  IconButton,
  TablePagination,
  Typography,
  useTheme,
} from "@mui/material";
import { BoxContainer, ColorInfoLabels } from "../../../components";
import {
  AutocompleteField,
  DateField,
  PaginatedAutocompleteField,
} from "../../../components/FormFields";
import { FormProvider, useForm } from "react-hook-form";
import ThriftHistory from "./History";
import TableFilter from "../../../components/Table/TableFilter";
import {
  AssignmentReturned,
  AttachMoney,
  DownloadForOffline,
  UploadFile,
} from "@mui/icons-material";
import PercentBar from "../../../components/PercentBar";
import useThrift from "../../../service/useThrift";
import { useCompany } from "../../../service/useCompany";
import { useMutation, useQuery } from "react-query";
import { useLocalStorage, useNotifier } from "../../../hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { validation } from "./validations";
import { format, parseISO } from "date-fns";
import { DialogMedium, Loading } from "../../../helper";
import ThriftErrorFeedback from "./ThriftErrorFeedback";
import { useState } from "react";
import { openURL } from "../../../pdfModels";
import { v4 as uuidv4 } from "uuid";
import calcPercent from "../../../utils/calcPercent";
import formatDateString from "../../../utils/formatDateString";
import BillingForm from "../Billing/Form";
import SendUpdateFile from "./SendUpdateFile";
import { ptBR } from "date-fns/locale";

export default function Thrift() {
  const [userData] = useLocalStorage("user-data");
  const theme = useTheme();
  const form = useForm({
    defaultValues: {
      company: userData.company,
      region: userData.company?.sts ? { value: userData.company?.sts } : null,
      esus_record: null,
      start_date: null,
      end_date: null,
    },
    resolver: yupResolver(validation),
  });
  const [companyField, regionField] = form.watch(["company", "region"]);
  const [initialDateField] = form.watch(["start_date"]);
  const [params, setParams] = useState({
    page: 0,
    limit: 10,
  });
  const [analysisData, setAnalysisData] = useState([]);
  const [analysisInfo, setAnalysisInfo] = useState({
    success: 0,
    failed: 0,
    total: 0,
    analyzed: true,
  });
  const [errorFeedback, setErrorFeedback] = useState(false);
  const [rpaToBilling, setRpaToBilling] = useState(null);
  const [fileForm, setFileForm] = useState(false);
  const notify = useNotifier();
  const [enableAnalysisQuery, setEnableAnalysisQuery] = useState(false);

  const {
    getESUSRecord,
    getThriftAnalysis,
    getThriftAnalysisByHistoryID,
    getThriftAnalysisFile,
    sendThrift,
    getLastCnesUpdateDate,
  } = useThrift();

  const importMutation = useMutation({
    mutationFn: getThriftAnalysisFile,
    mutationKey: "getThriftAnalysisFile",
  });
  const sendMutation = useMutation({
    mutationFn: sendThrift,
    mutationKey: "send-thrift",
  });

  const lastCnesQuery = useQuery(
    ["get-last-cnes-update-date"],
    getLastCnesUpdateDate,
    {
      refetchOnMount: false,
    }
  );

  const getAnalysisQuery = useQuery(
    ["analysis-by-history-id", params, enableAnalysisQuery],
    () => {
      if (!!params.esus_record_history) {
        const { esus_record_history, ...filters } = params;
        return getThriftAnalysisByHistoryID(esus_record_history, filters);
      }

      return getThriftAnalysis(params);
    },
    {
      enabled: enableAnalysisQuery,
      onSuccess({ analysisDetails, ...data }) {
        if (!analysisDetails) {
          setAnalysisData(undefined);
          setAnalysisInfo({
            success: 0,
            failed: 0,
            total: 0,
            analyzed: true,
          });
          notify(data.message, "info");
          return;
        }

        const info = {
          success:
            analysisDetails?.qty_consistencies + analysisDetails?.duplicates,
          failed: analysisDetails?.qty_inconsistencies,
          total: analysisDetails.qty_overall,
          analyzed: analysisDetails.analyzed,
        };

        if (analysisDetails.urlXml) {
          info["urlXml"] = analysisDetails.urlXml;
        }

        if (data.items?.length) {
          setEnableAnalysisQuery(false);
          setParams((params) => ({
            ...params,
            esus_record_history: data.items[0].esus_record_history_id,
          }));
        }

        setAnalysisInfo(({ urlXml }) => ({ urlXml, ...info }));
        setAnalysisData(data);
        refetchHistory();
      },
      onError(data) {
        setAnalysisData(undefined);
        setAnalysisInfo({
          success: 0,
          failed: 0,
          total: 0,
          analyzed: true,
        });
        setEnableAnalysisQuery(false);
        notify(data.message, "error");
      },
    }
  );

  const { getCompanies } = useCompany();

  const { getThriftAnalysisHistory } = useThrift();
  const [historyPage, setHistoryPage] = useState(0);
  const [history, setHistory] = useState([]);

  const historyQuery = useQuery(
    ["getThriftAnalysisHistory", historyPage],
    () => getThriftAnalysisHistory({ page: historyPage }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: false,
      onSuccess(data) {
        if (historyPage === 0) {
          setHistory(data.items);
          return;
        }
        setHistory((curr) => [...curr, ...data.items]);
      },
      onError() {
        setHistory([]);
      },
      onSettled() {
        setEnableAnalysisQuery(false);
      },
    }
  );

  function refetchHistory() {
    if (params.esus_record_history) return;

    if (historyPage === 0) {
      historyQuery.refetch();
    } else {
      setHistoryPage(0);
    }
  }
  const statusColors = [
    {
      text: "Validações de Inconsistências de dados",
      color: "rgba(255, 246, 168, 0.8)",
    },
    {
      text: "Validações de Campos Obrigatórios",
      color: "rgba(255, 223, 186, 0.8)",
    },
    {
      text: "Ambas as ocorrências",
      color: "rgba(216, 0, 0, 0.3)",
    },
  ];

  const errorTypes = {
    businessRules: "Inconsistências de dados",
    requiredRules: "Campos Obrigatórios",
  };

  const regionOptions = [
    {
      value: "ITAIM",
    },
    {
      value: "SAO MIGUEL",
    },
    {
      value: "TIRADENTES",
    },
    {
      value: "ITAQUERA",
    },
    {
      value: "GUAIANASES",
    },
  ];
  const tableColumns = [
    {
      name: "CNS do Paciente",
      field: "patient_sus_card",
    },
    {
      name: "Data de Atd.",
      field: "attendance_date",
      use: (data) => formatDateString(data),
    },
    {
      name: "CNS do Profissional",
      field: "employee_sus_card",
    },
    {
      name: "Unidade",
      field: "company?.name",
    },
    {
      name: "Tipo da Ficha",
      field: "esus_record_history?.esus_record?.name",
    },
    {
      name: "Inconsistências",
      use: (_, item) =>
        Object.entries(item.inconsistencies).reduce((prev, curr, index) => {
          if (!curr[1]?.length) return prev;
          if (index === 0 || !prev) {
            return errorTypes[curr[0]];
          }
          return `${prev} / ${errorTypes[curr[0]]}`;
        }, ""),
    },
  ];

  function importData() {
    importMutation.mutate(params.esus_record_history, {
      onSuccess(data) {
        const fileUrl = URL.createObjectURL(
          new Blob([data.data], { type: "text/xlsx" })
        );
        const Content_Disposition = data.headers?.["content-disposition"];
        const match = Content_Disposition?.match(/filename\s*?="(.+)"/);

        let filename = `thrift_${uuidv4()}`; // Default filename

        if (match && match[1]) {
          filename = match[1];
        }
        openURL(fileUrl, filename);
      },
    });
  }

  function handleViewHistory(historyID) {
    setParams(({ limit }) => ({
      limit,
      page: 0,
      esus_record_history: historyID,
    }));
    setAnalysisInfo((infos) => ({ ...infos, urlXml: undefined }));
    setEnableAnalysisQuery(true);
    form.reset();
  }

  const handleSubmit = form.handleSubmit(
    ({ company, esus_record, end_date, start_date, region }) => {
      if (!esus_record) {
        return form.setError("esus_record", {
          message: "Escolha a ficha para a analise.",
        });
      }

      setParams(({ limit }) => ({
        company_id: company?.id,
        esus_record_id: esus_record?.id,
        end_date: end_date ? format(end_date, "yyyy-MM-dd") : undefined,
        start_date: start_date ? format(start_date, "yyyy-MM-dd") : undefined,
        page: 0,
        region: region?.value,
        limit,
      }));
      setEnableAnalysisQuery(true);
    }
  );

  const handleSend = form.handleSubmit(({ end_date, start_date }) => {
    sendMutation.mutate(
      {
        end_date: end_date ? format(end_date, "yyyy-MM-dd") : undefined,
        start_date: start_date ? format(start_date, "yyyy-MM-dd") : undefined,
      },
      {
        onSuccess() {
          notify("Envio feito com sucesso.", "success");
          refetchHistory();
        },
      }
    );
  });

  const loadingMessage = sendMutation.isLoading
    ? "Enviando fichas..."
    : `${
        params.esus_record_history ? "Solicitando" : "Realizando"
      } análise ... `;

  return (
    <>
      <DialogMedium
        open={!!errorFeedback}
        handleClose={() => setErrorFeedback(false)}
      >
        <ThriftErrorFeedback error={errorFeedback} errorTypes={errorTypes} />
      </DialogMedium>
      <DialogMedium
        open={fileForm}
        fullWidth
        maxWidth={"sm"}
        handleClose={() => setFileForm(false)}
      >
        <SendUpdateFile handleClose={() => setFileForm(false)} />
      </DialogMedium>
      <DialogMedium
        open={!!rpaToBilling}
        handleClose={() => setRpaToBilling(null)}
        fullWidth
        maxWidth={"xl"}
      >
        <BillingForm
          closeForm={() => setRpaToBilling(null)}
          rpaId={rpaToBilling}
          isClassification={false}
        />
      </DialogMedium>
      <BoxContainer title={"e-SUS Thrift"}>
        {getAnalysisQuery.isFetching || sendMutation.isLoading ? (
          <Loading
            maxWidth={"100%"}
            backgroundColor={"rgba(255,255,255,0.7)"}
            message={loadingMessage}
          />
        ) : null}
        <Grid container columnSpacing={2} rowSpacing={0.5}>
          <Grid
            item
            xs={8}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
          >
            <FormProvider {...form}>
              <form onSubmit={handleSubmit}>
                <Grid item xs={11} container spacing={2}>
                  <Grid item xs={6}>
                    <PaginatedAutocompleteField
                      name={"company"}
                      label={"Unidade"}
                      service={(params) =>
                        getCompanies({
                          ...params,
                          thrift: true,
                          region: regionField?.value,
                        })
                      }
                      queryKey={"getCompanies"}
                      refetchService={[regionField?.value]}
                      optionLabelKey="name"
                      filterKey={"name"}
                      customOnChange={(value) => {
                        form.setValue(
                          "region",
                          value?.sts ? { value: value?.sts } : null
                        );
                      }}
                      disabled={!userData?.company?.is_master}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <AutocompleteField
                      control={form.control}
                      name={"region"}
                      label={"Região"}
                      disabled={!userData?.company?.is_master || !!companyField}
                      options={regionOptions}
                      optionLabelKey="value"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <PaginatedAutocompleteField
                      name={"esus_record"}
                      service={getESUSRecord}
                      queryKey={"getESUSRecord"}
                      filterKey={"name"}
                      optionLabelKey="name"
                      label={"Tipo de Ficha"}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DateField
                      name={"start_date"}
                      fullWidth
                      label={"Data Inicio"}
                      maxDate={new Date()}
                      customOnChange={() => {
                        form.resetField("end_date");
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DateField
                      name={"end_date"}
                      fullWidth
                      label={"Data Fim"}
                      maxDate={new Date()}
                      minDate={initialDateField}
                      disabled={!initialDateField}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Box display={"flex"} columnGap={"5px"}>
                      <Button variant="contained" type="submit">
                        INICIAR ANÁLISE
                      </Button>
                      <Button variant="outlined" onClick={() => handleSend()}>
                        ENVIAR FICHAS
                      </Button>
                      <Button
                        onClick={() => setFileForm(true)}
                        title="Atualizar arquivo XML obtido no Portal do CNES."
                        startIcon={<UploadFile />}
                        variant={"outlined"}
                      >
                        CNES
                      </Button>
                      {analysisInfo.failed ? (
                        <IconButton
                          title="Importar análise"
                          onClick={() => importData()}
                        >
                          <AssignmentReturned color={"primary"} />
                        </IconButton>
                      ) : null}
                      {analysisInfo.urlXml ? (
                        <IconButton
                          title="Download XML"
                          onClick={() => openURL(analysisInfo.urlXml)}
                        >
                          <DownloadForOffline color={"primary"} />
                        </IconButton>
                      ) : null}
                    </Box>
                  </Grid>
                  {analysisInfo.total ? (
                    <>
                      <Grid item xs={7}>
                        <PercentBar
                          percent={calcPercent(
                            analysisInfo.success,
                            analysisInfo.total
                          )}
                          colors={
                            !analysisInfo.analyzed
                              ? [theme.palette.primary.main]
                              : []
                          }
                          labels={
                            !analysisInfo.analyzed
                              ? [
                                  `${analysisInfo.total} Fichas geradas para download`,
                                ]
                              : [
                                  `${analysisInfo.success} Válidos`,
                                  `${analysisInfo.failed} Inválidos`,
                                ]
                          }
                        />
                      </Grid>
                    </>
                  ) : null}
                  {lastCnesQuery.data?.updatedDate && (
                    <Grid item xs={12}>
                      <Typography color={"primary.main"}>
                        Informamos que o CNES para e-SUS foi atualizado pela
                        última vez em{" "}
                        {format(
                          parseISO(lastCnesQuery.data.updatedDate),
                          "dd 'de' MMMM 'de' yyyy 'às' HH:mm",
                          {
                            locale: ptBR,
                          }
                        )}
                        .
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </form>
            </FormProvider>
            <ColorInfoLabels labels={statusColors} />
          </Grid>
          <Grid item xs={4}>
            <ThriftHistory
              handleViewHistory={handleViewHistory}
              data={history}
              totalPage={historyQuery.data?.total}
              page={historyPage}
              handlePage={setHistoryPage}
            />
          </Grid>
          <Grid item xs={12}>
            <TableFilter
              data={analysisData?.items || []}
              columns={tableColumns}
              error={getAnalysisQuery.error?.message}
              rowStatus={(row) => {
                if (
                  row.inconsistencies?.businessRules?.length &&
                  row.inconsistencies?.requiredRules?.length
                ) {
                  return "error-light";
                }
                if (row.inconsistencies?.businessRules?.length) {
                  return "attention";
                }
                if (row.inconsistencies?.requiredRules?.length) {
                  return "warning";
                }
              }}
              customizeActions={{
                response: {
                  title: "Registro de faturamento",
                  icon: <AttachMoney />,
                },
              }}
              actionHandleView={(id, row) => {
                setErrorFeedback(row.inconsistencies);
              }}
              actionHandleResponse={(_, row) => {
                console.log(row);
                setRpaToBilling(row.confirmed_by_siga_id);
              }}
              noHeaderOptions
              actions
              actionsTypes={["view", "response"]}
            />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <TablePagination
                count={analysisData?.totalItems || 0}
                component="div"
                page={params.page}
                onPageChange={(_, newPage) => {
                  setParams((params) => ({ ...params, page: newPage }));
                  setEnableAnalysisQuery(true);
                }}
                rowsPerPage={params.limit}
                rowsPerPageOptions={[5, 10, 20, 75]}
                onRowsPerPageChange={({ target }) => {
                  setParams((params) => ({
                    ...params,
                    page: 0,
                    limit: parseInt(target.value, 10),
                  }));
                  setEnableAnalysisQuery(true);
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </BoxContainer>
    </>
  );
}
