import { format, parseISO } from "date-fns";
import { headerHorizontalStyle, footer, title, patientInformations } from "./pdfComponents";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { v4 as uuidv4 } from "uuid";

function MaternityLeavePDF(data, patient) {
  const pdfName = uuidv4();
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const description = {
    alignment: "center",
    margin: [0, 30, 0, 0],
    text: [
      { text: `Atesto que a Srª. ${patient.social_name ? patient.social_name : patient.name} `, },
      { text: data.ctps ? `Portadora da carteira profissional nº ${data.ctps} `: "", },
      { text: data.ctps_series ? `série ${data.ctps_series} `: "", },
      { text: `Encontra-se no ${data.weeks_of_pregnancy}  mês de gestação e deve afastar-se do trabalho `, },
      { text: `por um período de ${data.maternity_leave} `, },
      { text: `dias a partir de ${data.maternity_license_date && format(parseISO(data.maternity_license_date), "dd/MM/yyyy")} de conformidade com o disposto na consideração leis do trabalho e retificação pelo artigo 2º \n\n`,},
      { text: `(lei Federal Nº 11770/ 09 de Setembro de 2008) e pela\n `, },
      { text: `(lei Municipal Nº 14872/ 03 de Dezembro de 2008) `, },
      { text: "." },
      { text: `\n\nObservação: ${data.observations}` },
    ],
  };


  const urlType = pdfName + "/types/declaration";

  let employee;

  if (data.soap?.employee_specialty) {
    employee = {
      ...data.soap.employee_specialty.employee,
      specialty: data.soap.employee_specialty.specialty,
    };
  }

  const definitions = {
    info: { title: pdfName },
    pageMargins: [40, 105, 40, 120],
    defaultStyle: {
      fontSize: 10,
    },
    ...headerHorizontalStyle(data?.attendance?.company),
    footer: footer(
      data.createdAt,
      true,
      employee,
      data?.attendance?.company,
      urlType
    ),
    content: [title("ATESTADO MÉDICO - LICENÇA MATERNIDADE"), patientInformations(patient), description],
  };

  const documentPDF = pdfMake.createPdf(definitions);
  documentPDF.name = `${pdfName}.pdf`;
  documentPDF.base64 = async () => {
    const pathBase64 = await new Promise((resolve) => {
      documentPDF.getBase64((base64) => {
        resolve(base64);
      });
    });

    return pathBase64;
  };

  return documentPDF;
}

export default MaternityLeavePDF;