import { useQuery } from "react-query";
import { BoxContainer } from "../../components";
import TableFilter from "../../components/Table/TableFilter";
import { useCockpit } from "../../service";
import { Box, TablePagination } from "@mui/material";
import { useState } from "react";
import { useNotifier } from "../../hooks";
import { Button } from "../../components/FormFields";
import { format, parseISO } from "date-fns";

export default function CEM() {
  const notify = useNotifier();
  const { getCEM } = useCockpit();
  const [params, setParams] = useState({
    page: 0,
    limit: 10,
  });

  const cemQuery = useQuery(["get-CEM", params], () => getCEM(params), {
    initialData: {
      items: [],
      totalItems: 0,
    },
    onError(data) {
      notify(data.message, "error");
    },
  });

  const columns = [
    {
      name: "DT. Agendada",
      field: "scheduling_date",
      use: (date) => (date ? format(parseISO(date), "dd/MM/yyyy") : "Não agendado"),
    },
    {
      name: "HR. Agendada",
      field: "scheduling_hour",
      use: (date) => date ?? "Não agendado",
    },
    {
      name: "HR. Recepção",
      field: "reception_hours",
      use: (date) => date ?? "Não informado",
    },
    {
      name: "Paciente",
      field: "name",
    },
    {
      name: "Atendimento",
      field: "number",
    },
    {
      name: "Data Nascimento",
      field: "birth_date",
      use: (birth_date) => format(parseISO(birth_date), "dd/MM/yyyy"),
    },
    {
      name: "Exame",
      field: "procedure_name",
      filter: "procedure_name",
      type: "text",
    },
    {
      name: "Inicio e Termino",
      field: "entry_and_exit_time",
    },
  ];

  const additionalFilters = [
    {
      name: "Data Inicial",
      type: "date",
      filter: "initial_date",
    },
    {
      name: "Data final",
      type: "date",
      filter: "final_date",
    },
    {
      name: "Tipo de atendimento",
      type: "select",
      filter: "attendance_type",
      options: [
        { label: "Agendado", value: "scheduled" },
        { label: "Não agendado", value: "unscheduled" },
      ],
    },
  ];

  return (
    <BoxContainer>
      <Button
        sx={{
          my: "12px",
        }}
        onClick={() => cemQuery.refetch()}
      >
        Atualizar
      </Button>
      <TableFilter
        data={cemQuery.data.items}
        columns={columns}
        loading={cemQuery.isFetching}
        othersFilter={additionalFilters}
        handleFilter={(filters) => setParams(({ limit }) => ({ page: 0, limit, ...filters }))}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          count={cemQuery.data.totalItems}
          component="div"
          page={params.page}
          onPageChange={(_, newPage) => {
            setParams((params) => ({ ...params, page: newPage }));
          }}
          rowsPerPage={params.limit}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={({ target }) => {
            setParams((params) => ({
              ...params,
              page: 0,
              limit: parseInt(target.value, 10),
            }));
          }}
        />
      </Box>
    </BoxContainer>
  );
}
