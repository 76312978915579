import React from "react";
import { DialogMedium } from "../../../helper";
import { useAgainstReferences } from "../../../service";
import { format, parseISO } from "date-fns";
import { useQuery } from "react-query";
import { Box, TablePagination } from "@mui/material";
import TableFilter from "../../../components/Table/TableFilter";
import AgainstReferenceForm from "../../Attendance/AgainstReferences/AgainstReferenceForm";

function ListReference({ attendance }) {
  const [limit, setLimit] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [openReference, setOpenReference] = React.useState(false);
  const [reference, setReference] = React.useState();
  const { getAgainstReferences } = useAgainstReferences();

  const againstReferenceQuery = useQuery(
    ["againstReferences", page, limit],
    () => getAgainstReferences({ page, limit, patient_id: attendance.patient.id }),
    {
      initialData: { totalItems: 0, items: [] },
    }
  );

  const tableColumns = [
    {
      name: "Data Solicitada",
      field: "createdAt",
      use: (date) => format(parseISO(date), "dd/MM/yyyy"),
    },
    { name: "Paciente", field: "attendance?.patient?.name" },
    { name: "Especialidade Requisitada", field: "requested_specialty.describe" },
    {
      name: "Situação",
      field: "suspendedAt",
      use: (suspendedAt, data) => {
        if (suspendedAt === null && data.response === null) {
          return "Aberto";
        } else if (suspendedAt) {
          return "Suspenso";
        } else if (data.response) {
          return "Fechado";
        }
      },
    },
  ];

  const toggleDialog = () => {
    if (openReference) {
      setOpenReference(false);
    } else {
      setOpenReference(true);
    }
  };

  const handleView = () => {
    setOpenReference(true);
  };

  return (
    <>
      <DialogMedium
        title="Referência / Contrarreferência"
        maxWidth="sm"
        fullWidth={true}
        open={openReference}
        handleClose={toggleDialog}
      >
        <AgainstReferenceForm type="view" reference={reference} />
      </DialogMedium>
      <TableFilter
        data={againstReferenceQuery.data.items}
        columns={tableColumns}
        loading={againstReferenceQuery.isFetching}
        actions
        actionsTypes={["view"]}
        actionHandleView={(_, data) => {
          setReference(data);
          handleView();
        }}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          count={againstReferenceQuery.data.totalItems}
          component="div"
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={({ target }) => {
            setLimit(parseInt(target.value, 10));
            setPage(0);
          }}
        />
      </Box>
    </>
  );
}

export default ListReference;
