import { Typography } from "@mui/material";
import { theme } from "../../../../config/theme";

export default function FootRegion({ values, title }) {
    if (!values?.problems && !values?.notes) return null;
    return (
      <Typography color={theme.palette.primary.light} component={"span"}>
        <Typography color={theme.palette.primary.main}>
          {title && (
            <Typography
              variant="span"
              color={theme.palette.primary.light}
              fontWeight="500"
              sx={{ marginTop: "20px" }}
            >
              {`> ${title}: `}
            </Typography>
          )}
          {values.problems?.map((problem) => (
            <Typography color={theme.palette.primary.main} ml={4}>
              {problem.describe}
            </Typography>
          ))}
          {values.notes && (
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px", ml: 3 }}
              >
                {`Notas: `}
              </Typography>
              {values.notes}
            </Typography>
          )}
        </Typography>
      </Typography>
    );
  };