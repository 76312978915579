import { Grid, Typography } from "@mui/material";
import React from "react";
import Yup from "../../../config/yup";
import { usePatient } from "../../../service";
import { useMutation } from "react-query";
import useNotifier from "../../../hooks/useNotifier";
import { DialogMedium } from "../../../helper";
import { AutocompleteField, Button, TextField } from "../../../components/FormFields";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { race } from "../../../config/standardSelects";
import RegexOf from "../../../config/regex";
import { cleanUpMask } from "../../../config/mask";

export default function PatientDataConfirm({
  patientId,
  type,
  open,
  handleClose,
  onSuccess,
  onError,
}) {
  const notify = useNotifier();

  const { updatePatient } = usePatient();
  const updatePatientMutation = useMutation(updatePatient);

  const types = {
    race: {
      initialValues: {
        race: null,
      },
      validations: Yup.object().shape({
        race: Yup.object().required("É requerido").nullable(),
      }),
      title: "Confirmação de dados do paciente",
      message:
        "Observamos que no cadastro do paciente não foram fornecidas informações sobre raça e cor. Portanto, esses dados não serão incluídos no PDF gerado. Para garantir a precisão das informações, por favor, preencha o campo abaixo para atualizar o cadastro do paciente.",
      handler: (values) => ({ race: values.race.describe }),
    },
    phone: {
      initialValues: {
        cell_phone: null,
      },
      validations: Yup.object().shape({
        cell_phone: Yup.string().required("É requerido"),
      }),
      title: "Confirmação de Dados do Paciente",
      message:
        "Visto que existem Exames regulados e(ou) Contrareferência, os dados telefônicos do paciente devem ser atualizados.",
      handler: (values) => {
        const cleanedPhoneNumber = cleanUpMask(values.cell_phone, "", ["-", " ", "+", "(", ")"]);

        return {
          contact: {
            cell_phone: cleanedPhoneNumber,
          },
        };
      },
    },
  };

  const { handleSubmit, control, watch } = useForm({
    resolver: yupResolver(types[type]?.validations),
    defaultValues: types[type]?.initialValues,
  });

  const [raceField, phoneField] = watch(["race", "cell_phone"]);

  const onSubmit = (values) => {
    const dataToSubmit = types[type].handler(values);

    updatePatientMutation.mutate(
      { id: patientId, data: dataToSubmit },
      {
        onSuccess: () => {
          if (handleClose instanceof Function) handleClose();
          if (onSuccess instanceof Function) onSuccess(dataToSubmit, values, type);
        },
        onError: (error) => {
          notify(error.message, "error");
          if (onError instanceof Function) onError();
        },
      }
    );
  };

  return (
    <DialogMedium open={open} title={types[type]?.title} maxWidth="sm" handleClose={handleClose}>
      <Grid container component="form" spacing={2}>
        <Grid item xs={12}>
          <Typography sx={{ mb: 4 }}>{types[type]?.message}</Typography>
        </Grid>
        {type === "race" && (
          <Grid item xs={4}>
            <AutocompleteField
              options={race}
              optionLabelKey="describe"
              name="race"
              label="Raça:"
              control={control}
              required
            />
          </Grid>
        )}
        {type === "phone" && (
          <Grid item xs={12}>
            <TextField
              name="cell_phone"
              label="Celular"
              mask={RegexOf.phone}
              control={control}
              required
            />
          </Grid>
        )}
        <Grid item xs={2}>
          <Button
            onClick={handleSubmit(onSubmit)}
            loading={updatePatientMutation.isLoading}
            disabled={!raceField?.describe && !phoneField}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </DialogMedium>
  );
}
