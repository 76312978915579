import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const countiesPath = "/counties";
const countriesPath = "/countries";
const nationalitiesPath = "/nationalities";

function useGeographicData() {
  const getCounties = async (params, hasAcronymState) => {
    try {
      const response = await instance.get(countiesPath, {
        params: urlQuery(params)
      });

      const counties = {
        ...response.data,
        items: response.data.items.map(item => ({
          ...item,
          describe: `${item.describe} (${item.acronym_state})`
        }))
      };

      return !hasAcronymState ? response.data : counties;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  const getCountries = async (params) => {
    try {
      const response = await instance.get(countriesPath, {
        params: urlQuery(params)
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  const getNationalities = async (params) => {
    try {
      const response = await instance.get(nationalitiesPath, {
        params: urlQuery(params)
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  return {
    getCounties,
    getCountries,
    getNationalities
  }
}

export default useGeographicData;