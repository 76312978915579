import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const path = "against-references/";

function useAgainstReferences() {
  async function getAgainstReferences(params) {
    try {
      const response = await instance.get(path, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function postAgainstReference(data) {
    try {
      const response = await instance.post(path, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function putAgainstReference({ id, data }) {
    try {
      const response = await instance.put(path + id, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function putReferenceResponse(objectResponse) {
    try {
      const response = await instance.put(path + objectResponse.id + "/answer", {
        response: objectResponse.response,
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function suspendReference(id) {
    try {
      const response = await instance.put(path + id + "/suspend");
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  return {
    getAgainstReferences,
    putReferenceResponse,
    suspendReference,
    postAgainstReference,
    putAgainstReference,
  };
}
export default useAgainstReferences;
