import { TextField } from "@mui/material";
import React from "react";

const InputComponent = ({ inputRef, ...other }) => <div {...other} />;
const OutlinedContainer = ({ children, label, InputProps, height, color }) => {
  return (
    <TextField
      variant="outlined"
      label={label}
      multiline
      sx={{
        width: "100%",
        height: height,
        "& *": {
          color: color,
        },
        "& fieldset": {
          borderColor: color,
        },
      }}
      InputLabelProps={{
        shrink: true,
        style: {
          fontWeight: "500",
          fontSize: 16,
        },
      }}
      InputProps={{
        inputComponent: InputComponent,
        ...InputProps,
      }}
      inputProps={{ children: children }}
    />
  );
};

export default OutlinedContainer;
