import React from "react";
import { accessObjectByString } from "../../utils";
import { Box, styled, Typography } from "@mui/material";

const TypographyContainer = styled(Box)(({ displayValues, compact }) => {
  const { numberColumns, numberItems, currentNumberItem } = displayValues;

  const endColumns = numberItems % numberColumns;
  const isLastRow = currentNumberItem >= numberItems - endColumns;

  return {
    width: isLastRow ? `${100 / endColumns}%` : `${100 / numberColumns}%`,
    display: "flex",
    gap: 2,
    flexDirection: compact ? "row" : "column",
    ...(compact && { flexWrap: "wrap" }),
  };
});

const Title = styled(Typography)(({ compact }) => ({
  fontSize: compact ? 12 : 14,
  fontWeight: 600,
}));

const Subtitle = styled(Typography)(() => ({
  fontSize: 12,
}));

export default function TypographyList({
  data,
  shape,
  containerStyles,
  numberColumns = 2,
  compact = false,
}) {
  const formatter = (type, data, shape) => {
    let value;

    if (type === "label") {
      value = shape.label ? shape.label : accessObjectByString(data, shape.labelKey);
    } else {
      value = accessObjectByString(data, shape.valueKey);
    }

    if (shape.formatter instanceof Function) {
      return shape.formatter(value, data);
    }

    return value;
  };

  return (
    <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap", ...containerStyles }}>
      {shape.map((item, index) => {
        if (item.hidden instanceof Function) {
          const value = accessObjectByString(data, item.valueKey ?? item.labelKey);

          if (item.hidden(value, data)) return null;
        }

        return (
          <TypographyContainer
            key={`${item.label ?? item.labelKey ?? item.valueKey}-${index}`}
            displayValues={{
              numberItems: shape.length,
              currentNumberItem: index,
              numberColumns,
            }}
            compact={compact}
          >
            <Title compact={compact}>
              {item.label ? item.label : accessObjectByString(data, item.labelKey)}
              {compact && ": "}
            </Title>
            <Subtitle compact={compact}>{formatter("value", data, item)}</Subtitle>
          </TypographyContainer>
        );
      })}
    </Box>
  );
}
