import { Helmet } from "react-helmet";
import { TITLE_NAME } from "../../config/config";
import { BoxContainer, IndividualForm } from "../../components";

function Individual() {
  return (
    <>
      <Helmet >
        <title> {TITLE_NAME} | Cadastro individual </title>
      </Helmet>

      <BoxContainer title="Cadastro individual">
        <IndividualForm />
      </BoxContainer>
    </>
  )
}

export default Individual;