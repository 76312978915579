import { Button, Grid, Paper, Popover } from "@mui/material";
import { CheckboxField, TextField } from "../../../../../components/FormFields";
import { Check } from "@mui/icons-material";
import { useMemo } from "react";

export default function HandleRegionValues({
  anchorEl,
  open,
  handleClose,
  control,
  subRegion,
  region,
  problems,
  foot,
}) {
  const optionsToCheck = useMemo(
    () =>
      problems?.reduce((prev, { type, ...problem }) => {
        if (type.includes(region)) {
          return [...prev, problem];
        }
        return prev;
      }, []),
    [region, problems]
  );

  if (!foot) return null;

  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={() => {
        handleClose();
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Paper sx={{ width: 440, padding: 2 }}>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} display={"flex"} flexDirection={"column"}>
            <CheckboxField
              control={control}
              name={`${foot}.${
                subRegion ? `${region}.${subRegion}` : region
              }.problems`}
              orientation="column"
              optionCompareKey={"id"}
              optionLabelKey={"describe"}
              options={optionsToCheck}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              control={control}
              name={`${foot}.${
                subRegion ? `${region}.${subRegion}` : region
              }.notes`}
              label={"Observações"}
              multiline
              minRows={3}
              maxRows={3}
            />
          </Grid>
          <Grid item xs={12} justifyContent={"end"} display={"flex"}>
            <Button onClick={() => handleClose()} variant="contained">
              <Check />
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Popover>
  );
}
