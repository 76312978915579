import { Box } from "@mui/material";
import { Tabs } from "../../../components";
import PrescriptionList from "./PrescriptionList";

function PrescriptionHistory({
  patient,
  setActiveTab,
  drugsPermission,
  examsPermission,
  setExamPrescriptions,
  setDrugPrescriptions,
  setDrugOnSitePrescriptions,
  canCopy,
}) {
  const tabs = [
    {
      active: drugsPermission,
      label: "Receituários",
      content: (
        <PrescriptionList
          type="medications"
          patient={patient}
          setPrescriptionItems={setDrugPrescriptions}
          canCopy={canCopy}
          setActiveTab={setActiveTab}
        />
      ),
    },
    {
      active: drugsPermission,
      label: "Prescrições de Medicamentos",
      content: (
        <PrescriptionList
          type="medications_on_site"
          patient={patient}
          setPrescriptionItems={setDrugOnSitePrescriptions}
          canCopy={canCopy}
          setActiveTab={setActiveTab}
        />
      ),
    },
    {
      active: examsPermission,
      label: "Prescrições de Exames",
      content: (
        <PrescriptionList
          type="exams"
          patient={patient}
          setPrescriptionItems={setExamPrescriptions}
          canCopy={canCopy}
          setActiveTab={setActiveTab}
        />
      ),
    },
  ];

  return (
    <Box
      sx={{
        marginY: 1,
        marginX: "auto",
        maxWidth: "85%",
      }}
    >
      <Tabs tabs={tabs.filter((i) => i.active)} />
    </Box>
  );
}

export default PrescriptionHistory;
