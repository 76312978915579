import {
  AccessibleRounded,
  Assignment,
  LocalHospitalRounded,
  LocationOnRounded,
  PeopleRounded,
} from "@mui/icons-material";
import { ReactComponent as HeartRounded } from "../../../../../assets/svg/ecg_heart.svg";
import { ReactComponent as RibCageRounded } from "../../../../../assets/svg/ribcage.svg";
import { ReactComponent as EmergencyRounded } from "../../../../../assets/svg/emergency.svg";
import React from "react";
import { Button, Grid, Tooltip, Typography, useTheme } from "@mui/material";
import DynamicSections from "../../../../../components/DynamicSections";
import { DialogMedium } from "../../../../../helper";
import DetailCard from "../../../../../components/Panels/DetailCard";
import SimpleCard from "../../../../../components/SimpleCard";
import TypographyList from "../../../../../components/TypographyList";
import { useCockpit } from "../../../../../service";
import { useNotifier } from "../../../../../hooks";
import { useQuery } from "react-query";
import { PaginatedScrollList } from "../../../../../components/PaginatedScrollList";
import { v4 as uuidv4 } from "uuid";
import { accessObjectByString } from "../../../../../utils";
import { openURL } from "../../../../../pdfModels";

export default function HealthConditions({ data, filters, analyticalReport }) {
  const [detailing, setDetailing] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [additionalFilter, setAdditionalFilter] = React.useState(null);
  const [familyList, setFamilyList] = React.useState([]);
  const notify = useNotifier();
  const theme = useTheme();
  const { getACSIndividualRegistersAnalytics } = useCockpit();

  const exportData = (data) => {
    const fileUrl = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
    const Content_Dispostition = data.headers?.["content-disposition"];
    const match = Content_Dispostition?.match(/filename\s*?="(.+)"/);

    let filename = `painel_acs_${uuidv4()}.xlsx`;

    if (match && match[1]) {
      filename = match[1];
    }

    openURL(fileUrl, filename);
  };

  const healthConditionsAnalytics = useQuery(
    ["health-conditions-analytics", filters, additionalFilter, page],
    () => getACSIndividualRegistersAnalytics({ ...filters, ...additionalFilter, page, limit: 5 }),
    {
      enabled: !!filters && !!additionalFilter,
      onSuccess(data) {
        if (Object.keys(data).length === 5) {
          setFamilyList((current) => (page === 0 ? data.items : [...current, ...data.items]));
        } else {
          exportData(data);
        }
      },
      onError(error) {
        notify(error.message, "error");
        setFamilyList([]);
      },
    }
  );

  const handleClickCard = (card, section) => {
    const otherCards = section?.cards?.filter((item) => item?.title !== card?.title);
    setDetailing({
      title: card.title,
      data: card.content,
      cards: otherCards,
      open: true,
    });
    setPage(0);
  };

  const getContent = (key) => {
    const value = accessObjectByString(data, key);
    return value || 0;
  };

  const createCard = (title, contentKey, additionalFilter) => ({
    title,
    content: getContent(contentKey),
    handleClick: (card, section) => {
      if (analyticalReport) {
        handleClickCard(card, section);
        setAdditionalFilter(additionalFilter);
      }
    },
  });

  const healthCardsConfig = [
    {
      title: "Acamado",
      contentKey: "health_information.bedridden",
      filter: { is_bedridden: true },
    },
    {
      title: "AVC / Derrame",
      contentKey: "health_information.stroke",
      filter: { had_stroke: true },
    },
    { title: "Câncer", contentKey: "health_information.cancer", filter: { has_cancer: true } },
    {
      title: "Com Hanseníase",
      contentKey: "health_information.leprosy",
      filter: { has_leprosy: true },
    },
    {
      title: "Com Tuberculose",
      contentKey: "health_information.tuberculosis",
      filter: { has_tuberculosis: true },
    },
    {
      title: "Diabetes",
      contentKey: "health_information.diabetes",
      filter: { has_diabetes: true },
    },
    { title: "Tabagista", contentKey: "health_information.smoker", filter: { is_smoker: true } },
    { title: "Gestante", contentKey: "health_information.pregnant", filter: { pregnant: true } },
    {
      title: "Hipertensão",
      contentKey: "health_information.hypertension",
      filter: { has_hypertension: true },
    },
    {
      title: "Infarto",
      contentKey: "health_information.heart_attack",
      filter: { had_heart_attack: true },
    },
    {
      title: "Problema de Saúde Mental",
      contentKey: "health_information.mental_problem",
      filter: { mental_problem_by_healthcare_professional: true },
    },
    {
      title: "Uso de Álcool",
      contentKey: "health_information.alcohol_user",
      filter: { is_alcohol_user: true },
    },
    {
      title: "Uso de Drogas",
      contentKey: "health_information.drug_user",
      filter: { is_drug_user: true },
    },
  ];

  const homelessnessCardsConfig = [
    {
      title: "Acompanhado por Outra Instituição",
      contentKey: "homelessness_information.accompanied_by_another_institution",
      filter: { is_accompanied_by_another_institution: true },
    },
    {
      title: "Possui Referência Familiar",
      contentKey: "homelessness_information.does_it_have_any_family_references",
      filter: { does_it_have_any_family_references: true },
    },
    {
      title: "Recebe Algum Benefício",
      contentKey: "homelessness_information.receive_some_benefit",
      filter: { receive_some_benefit: true },
    },
    {
      title: "Visita Algum Familiar com Frequência",
      contentKey: "homelessness_information.visit_family_members_frequently",
      filter: { visit_family_members_frequently: true },
    },
  ];

  const disabilityCardsConfig = [
    { title: "Auditiva", contentKey: "disabilities.auditive", filter: { disability_type: "12" } },
    { title: "Visual", contentKey: "disabilities.visual", filter: { disability_type: "13" } },
    {
      title: "Intelectual / Cognitiva",
      contentKey: "disabilities.intellectual_cognitive",
      filter: { disability_type: "14" },
    },
    { title: "Física", contentKey: "disabilities.physics", filter: { disability_type: "15" } },
    { title: "Outra", contentKey: "disabilities.other", filter: { disability_type: "16" } },
  ];

  const heartDiseaseCardsConfig = [
    {
      title: "Insuficiencia Cardíaca",
      contentKey: "heart_disease.heart_failure",
      filter: { heart_disease: "24" },
    },
    { title: "Outra", contentKey: "heart_disease.other", filter: { heart_disease: "25" } },
    { title: "Não Sabe", contentKey: "heart_disease.dont_know", filter: { heart_disease: "26" } },
  ];

  const respiratoryDiseasesCardsConfig = [
    {
      title: "Asma",
      contentKey: "respiratory_diseases.asma",
      filter: { respiratory_diseases: "30" },
    },
    {
      title: "DPOC / Enfisema",
      contentKey: "respiratory_diseases.copd_emphysema",
      filter: { respiratory_diseases: "31" },
    },
    {
      title: "Outra",
      contentKey: "respiratory_diseases.other",
      filter: { respiratory_diseases: "32" },
    },
    {
      title: "Não Sabe",
      contentKey: "respiratory_diseases.dont_know",
      filter: { respiratory_diseases: "33" },
    },
  ];

  const kidneyDiseasesCardsConfig = [
    {
      title: "Insuficiência Renal",
      contentKey: "kidney_diseases.renal_insufficiency",
      filter: { kidney_disease: "27" },
    },
    {
      title: "Não Sabe",
      contentKey: "kidney_diseases.dont_know",
      filter: { kidney_disease: "29" },
    },
    { title: "Outra", contentKey: "kidney_diseases.other", filter: { kidney_disease: "28" } },
  ];

  const sections = [
    {
      icon: <LocalHospitalRounded color="white" fontSize="large" />,
      title: "CONDIÇÕES DE SAÚDE",
      cards: healthCardsConfig.map(({ title, contentKey, filter }) =>
        createCard(title, contentKey, filter)
      ),
    },
    {
      icon: <LocationOnRounded color="white" fontSize="large" />,
      title: "SITUAÇÃO DE RUA",
      cards: homelessnessCardsConfig.map(({ title, contentKey, filter }) =>
        createCard(title, contentKey, filter)
      ),
    },
    {
      icon: <AccessibleRounded color="white" fontSize="large" />,
      title: "DEFICIÊNCIA",
      cards: disabilityCardsConfig.map(({ title, contentKey, filter }) =>
        createCard(title, contentKey, filter)
      ),
    },
    {
      icon: <HeartRounded fill="white" fontSize="large" />,
      title: "DOENÇAS CARDÍACAS",
      cards: heartDiseaseCardsConfig.map(({ title, contentKey, filter }) =>
        createCard(title, contentKey, filter)
      ),
    },
    {
      icon: <RibCageRounded fill="white" fontSize="large" />,
      title: "DOENÇAS RESPIRATÓRIAS",
      cards: respiratoryDiseasesCardsConfig.map(({ title, contentKey, filter }) =>
        createCard(title, contentKey, filter)
      ),
    },
    {
      icon: <EmergencyRounded fill="white" fontSize="large" />,
      title: "PROBLEMA NOS RINS",
      cards: kidneyDiseasesCardsConfig.map(({ title, contentKey, filter }) =>
        createCard(title, contentKey, filter)
      ),
    },
  ];

  const shapeList = [
    {
      label: "Código",
      valueKey: "patient.patient_family.family.number",
      formatter: (value) => value || "Não informado",
    },
    {
      label: "Familia",
      valueKey: "patient.patient_family.family.name",
      formatter: (value) => value || "Não informado",
    },
    {
      label: "Equipe",
      valueKey: "team_name",
      formatter: (value) => value || "Não informado",
    },
    {
      label: "Membros",
      valueKey: "quantity_members",
      formatter: (value) => value || "Não informado",
    },
  ];

  return (
    <>
      <DialogMedium
        title={`${detailing?.title} - Relatório Analítico`}
        fullWidth
        open={detailing?.open}
        handleClose={() => setDetailing((curr) => ({ ...curr, open: false }))}
        maxWidth="lg"
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <DetailCard
              title={detailing?.title}
              content={detailing?.data}
              sx={{
                backgroundColor: theme.palette.primary.medium,
                color: theme.palette.primary.light,
                borderRadius: 1.5,
              }}
            />
          </Grid>
          {/* {detailing?.cards?.map((card, i) => (
            <Grid item xs={3} key={i}>
              <DetailCard
                title={card.title}
                content={card.content}
                handleClick={() => card?.handleClick(card, detailing)}
                sx={{
                  backgroundColor: theme.palette.primary.medium,
                  color: theme.palette.primary.light,
                  borderRadius: 1.5,
                }}
              />
            </Grid>
          ))} */}
          <Grid item xs={12} pl={2} mt={2}>
            <PaginatedScrollList
              component={(item, index) => (
                <SimpleCard
                  key={index}
                  icon={<PeopleRounded sx={{ fontSize: 22, color: "white", borderRadius: 10 }} />}
                  title={
                    <Typography
                      color={item?.patient?.has_any_condition && theme.palette.error.main}
                      fontWeight={600}
                    >
                      {item?.patient?.name || "Não Informado"}
                    </Typography>
                  }
                  containerStyles={{ paddingY: 1.5 }}
                >
                  <TypographyList numberColumns={2} compact data={item} shape={shapeList} />
                </SimpleCard>
              )}
              hasDivider={false}
              maxHeight="250px"
              minHeight="auto"
              handlePage={setPage}
              loading={
                healthConditionsAnalytics.isLoading || healthConditionsAnalytics.isRefetching
              }
              page={page}
              totalPage={healthConditionsAnalytics.data?.total}
              data={familyList || []}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Exportar para Excel" placement="right">
              <Button
                disabled={!familyList?.length}
                variant="contained"
                onClick={() => setAdditionalFilter((curr) => ({ ...curr, to_excel: true }))}
              >
                <Assignment color="white" />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogMedium>
      <Grid container spacing={2}>
        {!!data && (
          <Grid item container xs={12} spacing={1}>
            <DynamicSections
              shape={sections}
              header
              cardSx={{ cursor: analyticalReport ? "pointer" : "default" }}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
