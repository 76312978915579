import { Box, Button, Grid, Tooltip, Typography, useTheme } from "@mui/material";
import React from "react";
import {
  Assignment,
  BathtubRounded,
  BoltRounded,
  DeleteRounded,
  HomeRounded,
  NearMeRounded,
  PaidRounded,
  PeopleRounded,
  ShowerRounded,
} from "@mui/icons-material";
import DetailCard from "../../../../../components/Panels/DetailCard";
import { ReactComponent as WaterDropRounded } from "../../../../../assets/svg/water.svg";
import { DialogMedium } from "../../../../../helper";
import SimpleCard from "../../../../../components/SimpleCard";
import TypographyList from "../../../../../components/TypographyList";
import DynamicSections from "../../../../../components/DynamicSections";
import { useCockpit } from "../../../../../service";
import { useQuery } from "react-query";
import { useNotifier } from "../../../../../hooks";
import { PaginatedScrollList } from "../../../../../components/PaginatedScrollList";
import { openURL } from "../../../../../pdfModels";
import { v4 as uuidv4 } from "uuid";

export default function Housing({ data, analyticalReport, filters }) {
  const theme = useTheme();
  const [detailing, setDetailing] = React.useState(null);
  const [familyList, setFamilyList] = React.useState([]);
  const [filterId, setFilterId] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const { getACSDomiciliariesAnalytics } = useCockpit();
  const notify = useNotifier();

  const exportData = (data) => {
    const fileUrl = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
    const Content_Dispostition = data.headers?.["content-disposition"];
    const match = Content_Dispostition?.match(/filename\s*?="(.+)"/);

    let filename = `painel_acs_${uuidv4()}.xlsx`;

    if (match && match[1]) {
      filename = match[1];
    }

    openURL(fileUrl, filename);
  };

  const housingConditionsAnalytics = useQuery(
    ["housing-conditions-analytics", filters, filterId, page],
    () => getACSDomiciliariesAnalytics({ ...filters, ...filterId, page, limit: 5 }),
    {
      enabled: !!filterId && !!filters,
      onSuccess(data) {
        if (Object.keys(data).length === 5) {
          setFamilyList((current) => (page === 0 ? data.items : [...current, ...data.items]));
        } else {
          exportData(data);
        }
      },
      onError(error) {
        notify(error.message, "error");
        setFamilyList([]);
      },
    }
  );

  const handleClickCard = (card, section) => {
    const otherCards = section?.cards?.filter((item) => item?.title !== card?.title);
    if (analyticalReport) {
      setDetailing({
        title: card.title,
        data: card.content,
        cards: otherCards,
        open: true,
      });
      setPage(0);
      setFilterId({ [section.name]: card.id });
    }
  };

  const sections = [
    {
      icon: <HomeRounded color="white" fontSize="large" />,
      title: "MORADIA E SANEAMENTO",
      cards: [
        {
          title: "Imóveis Contabilizados",
          content: data?.total_domicile,
          handleClick: (card, section) => handleClickCard(card, section),
        },
      ],
    },
    {
      icon: <NearMeRounded color="white" fontSize="large" />,
      title: "LOCALIZAÇÃO",
      name: "home_location_id",
      cards: [
        {
          title: "Rural",
          id: "84",
          content: data?.home_location?.Rural,
          handleClick: (card, section) => handleClickCard(card, section),
        },
        {
          title: "Urbana",
          id: "83",
          content: data?.home_location?.Urban,
          handleClick: (card, section) => handleClickCard(card, section),
        },
      ],
    },
    {
      icon: <BoltRounded color="white" fontSize="large" />,
      title: "ENERGIA ELÉTRICA",
      name: "availability_of_electricity",
      cards: [
        {
          title: "Com Energia",
          id: true,
          content: data?.availability_of_electricity?.true,
          handleClick: (card, section) => handleClickCard(card, section),
        },
        {
          title: "Sem Energia",
          id: false,
          content: data?.availability_of_electricity?.false,
          handleClick: (card, section) => handleClickCard(card, section),
        },
      ],
    },
    {
      icon: <WaterDropRounded fill="white" fontSize="large" />,
      title: "ABASTECIMENTO DE ÁGUA",
      name: "water_supply_id",
      cards: [
        {
          title: "Rede Encanada Até o Domicílio",
          id: "117",
          content: data?.water_supply?.piped_network,
          handleClick: (card, section) => handleClickCard(card, section),
        },
        {
          title: "Poço / Nascente no Domicílio",
          id: "118",
          content: data?.water_supply?.pit,
          handleClick: (card, section) => handleClickCard(card, section),
        },
        {
          title: "Cisterna",
          id: "119",
          content: data?.water_supply?.cistern,
          handleClick: (card, section) => handleClickCard(card, section),
        },
        {
          title: "Carro Pipa",
          id: "120",
          content: data?.water_supply?.water_tanker,
          handleClick: (card, section) => handleClickCard(card, section),
        },
        {
          title: "Outros",
          id: "121",
          content: data?.water_supply?.other,
          handleClick: (card, section) => handleClickCard(card, section),
        },
      ],
    },
    {
      icon: <ShowerRounded color="white" fontSize="large" />,
      title: "ÁGUA PARA CONSUMO",
      name: "water_consumption_id",
      cards: [
        {
          title: "Clorada",
          id: "99",
          content: data?.water_consumption?.chlorinated,
          handleClick: (card, section) => handleClickCard(card, section),
        },
        {
          title: "Fervida",
          id: "98",
          content: data?.water_consumption?.boiled,
          handleClick: (card, section) => handleClickCard(card, section),
        },
        {
          title: "Filtrada",
          id: "97",
          content: data?.water_consumption?.filtered,
          handleClick: (card, section) => handleClickCard(card, section),
        },
        {
          title: "Mineral",
          id: "152",
          content: data?.water_consumption?.mineral,
          handleClick: (card, section) => handleClickCard(card, section),
        },
        {
          title: "Sem Tratamento",
          id: "100",
          content: data?.water_consumption?.untreated,
          handleClick: (card, section) => handleClickCard(card, section),
        },
      ],
    },
    {
      icon: <BathtubRounded color="white" fontSize="large" />,
      title: "ESCOAMENTO DO BANHEIRO",
      name: "sanitary_drainage_id",
      cards: [
        {
          title: "Rede Coletora de Esgoto / Pluvial",
          id: "122",
          content: data?.sanitary_drainage?.collection_system,
          handleClick: (card, section) => handleClickCard(card, section),
        },
        {
          title: "Fossa Séptica",
          id: "123",
          content: data?.sanitary_drainage?.septic_pit,
          handleClick: (card, section) => handleClickCard(card, section),
        },
        {
          title: "Fossa Rudimentar",
          id: "124",
          content: data?.sanitary_drainage?.rudimentary_pit,
          handleClick: (card, section) => handleClickCard(card, section),
        },
        {
          title: "Direto para um Rio / Lago / Mar",
          id: "125",
          content: data?.sanitary_drainage?.straight_into_a_river,
          handleClick: (card, section) => handleClickCard(card, section),
        },
        {
          title: "Céu Aberto",
          id: "126",
          content: data?.sanitary_drainage?.open_sky,
          handleClick: (card, section) => handleClickCard(card, section),
        },
        {
          title: "Outra Forma",
          id: "127",
          content: data?.sanitary_drainage?.other,
          handleClick: (card, section) => handleClickCard(card, section),
        },
      ],
    },
    {
      icon: <DeleteRounded color="white" fontSize="large" />,
      title: "DESTINO DO LIXO",
      name: "destination_garbage_id",
      cards: [
        {
          title: "Céu Aberto",
          id: "95",
          content: data?.destination_garbage?.open_sky,
          handleClick: (card, section) => handleClickCard(card, section),
        },
        {
          title: "Coletado",
          id: "93",
          content: data?.destination_garbage?.collected,
          handleClick: (card, section) => handleClickCard(card, section),
        },
        {
          title: "Queimado / Enterrado",
          id: "94",
          content: data?.destination_garbage?.burned_buried,
          handleClick: (card, section) => handleClickCard(card, section),
        },
        {
          title: "Outro",
          id: "96",
          content: data?.destination_garbage?.other,
          handleClick: (card, section) => handleClickCard(card, section),
        },
      ],
    },
    {
      icon: <PaidRounded color="white" fontSize="large" />,
      title: "RENDA FAMILIAR",
      name: "family_income_id",
      cards: [
        {
          title: "1/4 de Salário Mínimo",
          id: "1",
          content: data?.family_income?.less_than_half_the_minimum_wage,
          handleClick: (card, section) => handleClickCard(card, section),
        },
        {
          title: "Meio Salário Mínimo",
          id: "2",
          content: data?.family_income?.half_the_minimum_wage,
          handleClick: (card, section) => handleClickCard(card, section),
        },
        {
          title: "Um Salário Mínimo",
          id: "3",
          content: data?.family_income?.one_minimum_wage,
          handleClick: (card, section) => handleClickCard(card, section),
        },
        {
          title: "Dois Salários Mínimos",
          id: "4",
          content: data?.family_income?.two_minimum_wages,
          handleClick: (card, section) => handleClickCard(card, section),
        },
        {
          title: "Três Salários Mínimos",
          id: "7",
          content: data?.family_income?.three_minimum_wages,
          handleClick: (card, section) => handleClickCard(card, section),
        },
        {
          title: "Quatro Salários Mínimos",
          id: "5",
          content: data?.family_income?.four_minimum_wages,
          handleClick: (card, section) => handleClickCard(card, section),
        },
        {
          title: "Acima de Quatro Salários Mínimos",
          id: "6",
          content: data?.family_income?.above_four_minimum_wages,
          handleClick: (card, section) => handleClickCard(card, section),
        },
      ],
    },
  ];

  const shapeList = [
    {
      label: "Código",
      valueKey: "number",
    },
    {
      label: "Familia",
      valueKey: "name",
    },
    {
      label: "Equipe",
      valueKey: "team_name",
    },
    {
      label: "Membros",
      valueKey: "quantity_members",
    },
  ];

  const DetailingDescription = ({ data }) => (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      pt={0.8}
      color={theme.palette.primary.light}
    >
      <Typography fontSize={12}>Total de Famílias esse Mês</Typography>
      <Typography fontSize={18} fontWeight={700}>
        {data}
      </Typography>
      <Typography
        component="span"
        bgcolor={theme.palette.primary.light}
        color="white"
        borderRadius={3}
        paddingX={1}
        fontSize={12}
      >
        +3.5%
      </Typography>
    </Box>
  );

  return (
    <>
      <DialogMedium
        title={`${detailing?.title} - Relatório Analítico`}
        fullWidth
        open={detailing?.open}
        handleClose={() => setDetailing((curr) => ({ ...curr, open: false }))}
        maxWidth="md"
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <DetailCard
              title={detailing?.title}
              content={detailing?.data}
              sx={{
                backgroundColor: theme.palette.primary.medium,
                color: theme.palette.primary.light,
                borderRadius: 1.5,
              }}
            />
          </Grid>
          {/* {detailing?.cards?.map((card, i) => (
            <Grid item xs={3} key={i}>
              <DetailCard
                title={card.title}
                content={card.content}
                sx={{
                  backgroundColor: theme.palette.primary.medium,
                  color: theme.palette.primary.light,
                  borderRadius: 1.5,
                }}
              />
            </Grid>
          ))} */}
          <Grid item xs={12} px={2} mt={2}>
            <PaginatedScrollList
              component={(item, index) => (
                <SimpleCard
                  key={index}
                  icon={<PeopleRounded sx={{ fontSize: 22, color: "white", borderRadius: 10 }} />}
                  title={
                    <Typography
                      color={item.has_any_condition && theme.palette.error.main}
                      fontWeight={600}
                    >
                      {item?.responsible || "Não Informado"}
                    </Typography>
                  }
                  containerStyles={{ paddingY: 1.5 }}
                >
                  <TypographyList numberColumns={2} compact data={item} shape={shapeList} />
                </SimpleCard>
              )}
              hasDivider={false}
              maxHeight="250px"
              minHeight="auto"
              handlePage={setPage}
              loading={
                housingConditionsAnalytics.isLoading || housingConditionsAnalytics.isRefetching
              }
              page={page}
              totalPage={housingConditionsAnalytics.data?.total}
              data={familyList || []}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Exportar para Excel" placement="right">
              <Button
                disabled={!familyList?.length}
                variant="contained"
                onClick={() => setFilterId((curr) => ({ ...curr, to_excel: true }))}
              >
                <Assignment color="white" />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogMedium>
      <Grid container spacing={2}>
        {!!data && (
          <Grid item container xs={12} spacing={1}>
            <DynamicSections
              shape={sections}
              header
              cardSx={{ cursor: analyticalReport ? "pointer" : "default" }}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
