import React from "react";
import TableFilter from "../../../components/Table/TableFilter/Table";

export default function ProceduresTable({ procedures, check, activeActions }) {
  const columns = [
    { name: "Procedimento", field: "procedure?.name" },
    { name: "Nota", field: "notes" },
    {
      name: "Profissional",
      field: "employee_specialty",
      use: (value) => value?.employee?.name ? value?.employee?.name : "-",
    }
  ];

  return (
    <>
      <TableFilter
        data={procedures || []}
        columns={columns}
        noHeaderOptions
        actions={activeActions}
        actionsTypes={["finish", "delete"]}
        customizeActions={{
          finish: {
            title: "Coletado",
            verb: "Marcar como realizado",
          },
          delete: {
            title: "Não Coletado",
            verb: "Marcar como Não realizado",
          },
        }}
        rowStatus={(item) =>
          item.done === true ? "active" : item.done === false ? "disabled" : ""
        }
        disableActions={(item, action) => !(item.done === null)}
        actionHandleFinish={check}
        actionHandleDelete={(_, i, v) => check(_, i, v, false)}
      />
    </>
  );
}
