import React from "react";
import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
function SubTitle({ text,marginTop = 2  }, ) {
  return (
    <Box sx={{ marginTop:marginTop, mb: 2 }}>
      <Typography
        variant="h7"
        color="secondary"
        fontWeight="500"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        {text}
      </Typography>
      <Divider sx={{ mt: 1 }} />
    </Box>
  );
}

export default SubTitle;
