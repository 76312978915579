import { useForm } from "react-hook-form";
import {
  Button,
  PaginatedAutocompleteField,
  RadioField,
  SwitchField,
  TextField,
} from "../../FormFields";
import { Box, Grid, Typography } from "@mui/material";
import RegexOf from "../../../config/regex";
import {
  domiciliaryDefaultValues,
  domiciliaryPayloadConstructor,
  domiciliaryValidations,
} from "./territorialValidations";
import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect, useState } from "react";
import { useCep, useDomiciliaries, useFamily, useMicroArea, useSusCode } from "../../../service";
import { useMutation, useQuery } from "react-query";
import { validateCEP } from "../../../config/validations";
import { useNotifier } from "../../../hooks";
import { DialogMedium } from "../../../helper";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  animals,
  buildingMaterials,
  destinationGarbages,
  homeAccessTypes,
  homeLocations,
  homeTypes,
  housingSituations,
  sanitaryDrainages,
  waterConsumptions,
  waterSupplies,
} from "../../../config/susCodeStandardSelect";
import TerritorialAddressForm from "./TerritorialAddressForm";
import TerritorialHousingConditionForm from "./TerritorialHousingCondintionForm";
import TerritorialEmployeeForm from "./TerritorialEmployeeForm";

function TerritorialForm() {
  const {
    control,
    watch,
    setValue,
    reset,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: domiciliaryDefaultValues,
    resolver: yupResolver(domiciliaryValidations),
  });
  const { fetchCep } = useCep();
  const { getMicroArea } = useMicroArea();
  const { postDomiciliary, getDomiciliary, getDomiciliaries } = useDomiciliaries();
  const { susCodeTerritorialPaths, getSusCodeByProperty } = useSusCode();
  const [isEditing, setIsEditing] = useState(true);
  const [canSearchDomicliary, setCanSearchDomicliary] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const cep = watch("cep");
  const { id } = useParams();
  const [search] = useSearchParams();
  const notify = useNotifier();
  const navigate = useNavigate();
  const territorialMutate = useMutation(postDomiciliary);
  const { getFamily } = useFamily();
  const { userData } = useContext(AuthContext);

  const isRejectTerms = watch("terms_of_use");
  const disableAllFieldsByProperty = [2, 3, 4, 5, 6, 12, 99].includes(watch("property_id")?.id);
  const disablePartialFieldsByProperty = [7, 8, 9, 10, 11].includes(watch("property_id")?.id);

  useEffect(() => {
    const searchParam = search.get("isEditing");
    const isEditing = !!searchParam || (!searchParam && !id);
    setIsEditing(isEditing);
  }, [search]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      notify("Alguns campos não foram preenchidos corretamente. Revise o formulário", "error");
    }
  }, [errors]);

  const cepData = useQuery(["cep", cep], () => fetchCep(cep), {
    enabled: !!validateCEP(cep),
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (response) => {
      if (!response.data.erro) {
        if (response.data.localidade !== "São Paulo") {
          handleClearCepFields();
          return notify("o CEP deve pertencer ao municipio de São Paulo", "warning");
        }

        if (!id) notify("CEP encontrado", "success");
        handleChangeCepFields(response.data);
      } else {
        handleClearCepFields();
        notify("CEP não encontrado", "error");
      }
    },
    onError: () => {
      handleClearCepFields();
      notify("Ocorreu um erro ao buscar o CEP", "error");
    },
  });

  useQuery(
    ["neighborhood", cepData],
    () =>
      getSusCodeByProperty(susCodeTerritorialPaths.neighborhood, {
        cep: cepData?.data?.data?.cep?.replace("-", ""),
      }),
    {
      enabled: !!cepData.data?.data?.cep,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (response) => {
        if (response.items?.length) {
          setValue("neighborhood", response.items[0]);
        }
      },
    }
  );

  const domiciliaryById = useQuery(["territory", id], () => getDomiciliary(id), {
    enabled: !!id,
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 0,
    onSuccess: (response) => {
      if (response.address_location_stay) {
        handleFormValuesByDomiciliary(response);
        if (!id) {
          notify("Já existe um cadastro com este CEP, Número e Complemento", "success");
          setIsDialogOpen(true);
        }
      } else {
        notify("Nenhum cadastro foi encontrado com este CEP e Número", "success");
      }
    },
  });

  const domiciliaryByCepParam = cepData.data?.data
    ? {
        cep: cepData.data.data.cep,
        number: watch("number"),
        complement: watch("complement"),
      }
    : {};

  const domiciliaryByCep = useQuery(
    ["territory", domiciliaryByCepParam],
    () => getDomiciliaries(domiciliaryByCepParam),
    {
      enabled: canSearchDomicliary,
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
      onSuccess: (response) => {
        setCanSearchDomicliary(false);
        if (response.items?.length) {
          setIsDialogOpen(true);
          handleFormValuesByDomiciliary(response.items[0]);
          notify("Já existe um cadastro com este CEP, Número e Complemento", "success");
        } else {
          notify("Nenhum cadastro foi encontrado com este CEP, Número e Complemento", "success");
        }
      },
      onError: () => {
        setCanSearchDomicliary(false);
      },
    }
  );

  const domiciliary =
    !id && domiciliaryByCep.data?.items?.length
      ? domiciliaryByCep.data?.items[0]
      : domiciliaryById.data;

  useQuery("property-id", () => getSusCodeByProperty(susCodeTerritorialPaths.propertyTypes), {
    enabled: !!domiciliary?.property_id,
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      if (response.length) {
        setValue(
          "property_id",
          response.find((e) => e.id === domiciliary?.property_id)
        );
      }
    },
  });

  useQuery(
    "street-type",
    () =>
      getSusCodeByProperty(susCodeTerritorialPaths.streetTypes, {
        id: domiciliary?.address_location_stay?.street_type_id,
      }),
    {
      enabled: !!domiciliary?.address_location_stay?.street_type_id,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        if (response.items.length) setValue("street_type", response.items[0]);
      },
    }
  );

  useQuery(
    "microarea",
    () =>
      getMicroArea({
        id: domiciliary?.microarea_id,
        company_id: userData?.company?.id,
      }),
    {
      enabled: !!domiciliary?.microarea_id,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        if (response.items.length) setValue("microarea_id", response.items[0]);
      },
    }
  );

  useQuery(
    "land-possession-use",
    () => getSusCodeByProperty(susCodeTerritorialPaths.landPossessionUses),
    {
      enabled: !!id && !!domiciliary?.housing_condition?.land_possession_use_id,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        setValue(
          "land_possession_use",
          response.items.find(
            (e) => e.id === domiciliary?.housing_condition?.land_possession_use_id
          )
        );
      },
    }
  );

  const handleEdit = () => {
    setIsEditing(true);
    setIsDialogOpen(false);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
    setIsEditing(false);
  };

  const handleChangeCepFields = (cepValues) => {
    setValue("street", cepValues.logradouro);
    setValue("uf", cepValues.uf);
    setValue("neighborhood", cepValues.bairro);
    setValue("street", cepValues.logradouro);
    if (!id) setValue("complement", cepValues.complemento);
  };

  const handleClearCepFields = () => {
    setValue("street", "");
    setValue("uf", "");
    setValue("neighborhood", null);
    setValue("street", "");
    setValue("complement", "");
  };

  const handleFormValuesByDomiciliary = (values) => {
    setValue("cep", values.address_location_stay.cep.replace(/^(\d{5})(\d{3})$/, "$1-$2"));
    setValue("number", values.address_location_stay.number);
    setValue("complement", values.address_location_stay.complement);
    setValue("not_number", values.address_location_stay.not_number);
    setValue("reference", values.address_location_stay.reference);
    setValue(
      "phone",
      values.address_location_stay.phone?.replace(/(\d{2})(\d{5})(\d{4})/, "($1)$2-$3")
    );
    setValue(
      "cellphone",
      values.address_location_stay.cellphone?.replace(/(\d{2})(\d{5})(\d{4})/, "($1)$2-$3")
    );
    setValue(
      "home_location",
      homeLocations.find((e) => e.id === values.housing_condition?.home_location_id)
    );
    setValue(
      "housing_situation",
      housingSituations.find((e) => e.id === values.housing_condition?.housing_situation_id)
    );
    setValue(
      "home_access_type",
      homeAccessTypes.find((e) => e.id === values.housing_condition?.home_access_type_id)
    );
    setValue(
      "home_type",
      homeTypes.find((e) => e.id === values.housing_condition?.home_type_id)
    );
    setValue("household_rooms", values.housing_condition?.household_rooms);
    setValue(
      "building_material",
      buildingMaterials.find((e) => e.id === values.housing_condition?.building_material_id)
    );
    setValue(
      "water_supply",
      waterSupplies.find((e) => e.id === values.housing_condition?.water_supply_id)
    );
    setValue(
      "water_consumption",
      waterConsumptions.find((e) => e.id === values.housing_condition?.water_consumption_id)
    );
    setValue(
      "sanitary_drainage",
      sanitaryDrainages.find((e) => e.id === values.housing_condition?.sanitary_drainage_id)
    );
    setValue(
      "destination_garbage",
      destinationGarbages.find((e) => e.id === values.housing_condition?.destination_garbage_id)
    );
    setValue("availability_of_electricity", values.housing_condition?.availability_of_electricity);
    setValue(
      "animals",
      animals?.filter((e) => values.animals?.includes(e.id))
    );
    setValue("quantity_animals", values.quantity_animals);
    setValue("institution_permanence_name", values.institution_permanence?.name);
    setValue(
      "institution_permanence_associated_professionals",
      values.institution_permanence?.associated_professionals
    ); // Return here
    setValue(
      "institution_permanence_responsible_name",
      values.institution_permanence?.responsible_name
    );
    setValue(
      "institution_permanence_responsible_sus_card",
      values.institution_permanence?.responsible_sus_card
    ); // Return here
    setValue(
      "institution_permanence_position_institution",
      values.institution_permanence?.position_institution
    );
    setValue(
      "institution_permanence_responsible_cellphone",
      values.institution_permanence?.responsible_cellphone
    ); // Return here
    setValue("families", values.families || []);
  };

  const handleClearHousingConditions = (cleanHomeLocation) => {
    if (cleanHomeLocation) setValue("home_location", null);
    setValue("housing_situation", null);
    setValue("home_access_type", null);
    setValue("land_possession_use", null);
    setValue("home_type", null);
    setValue("household_rooms", "");
    setValue("building_material", null);
    setValue("water_supply", null);
    setValue("water_consumption", null);
    setValue("sanitary_drainage", null);
    setValue("destination_garbage", null);
    setValue("availability_of_electricity", null);
    setValue("animals", []);
    setValue("quantity_animals", "");
    setValue("institution_permanence_name", "");
    setValue("institution_permanence_associated_professionals", "");
    setValue("institution_permanence_responsible_name", "");
    setValue("institution_permanence_responsible_sus_card", "");
    setValue("institution_permanence_position_institution", "");
    setValue("institution_permanence_responsible_cellphone", "");
    setValue("families", []);
  };

  const handleClearPartialHousingConditions = () => {
    setValue("housing_situation", null);
    setValue("home_access_type", null);
    setValue("land_possession_use", null);
    setValue("home_type", null);
    setValue("household_rooms", "");
    setValue("building_material", null);
    setValue("animals", []);
    setValue("quantity_animals", "");
  };

  const handleChangeField = (field, value) => {
    if (isEditing) {
      switch (field) {
        case "not_number":
          clearErrors("number");
          if (!!value) setValue("number", "");
          break;
        case "animals":
          if (!value?.length) setValue("quantity_animals", "");
          break;
        case "home_location":
          handleClearHousingConditions();
          break;
        case "terms_of_use":
          if (value === true) {
            handleClearHousingConditions(true);
          }
          break;
        case "property_id":
          clearErrors(["institution_permanence_name", "institution_permanence_responsible_name"]);
          if ([2, 3, 4, 5, 6, 12, 99].includes(value?.id)) {
            handleClearHousingConditions(true);
          } else if ([7, 8, 9, 10, 11].includes(value?.id)) {
            handleClearPartialHousingConditions();
          }
          break;
        default:
          break;
      }
    }
  };

  const onSubmit = async (values) => {
    values["ibge"] = cepData.data?.data?.ibge;
    values["employee_specialty_id"] = values.employee_cbo.employee_specialty_id;
    const domiciliaryId = !!domiciliary?.id ? domiciliary.id : id;
    const payload = await domiciliaryPayloadConstructor(values, domiciliaryId);

    if (payload) {
      territorialMutate.mutate(payload, {
        onSuccess() {
          notify(
            `${!!domiciliary?.items?.length ? "Atualizado" : "Cadastrado"} com sucesso!`,
            "success"
          );
          reset();
          navigate("/patient/territorial");
        },
        onError(error) {
          notify(error.message, "error");
        },
      });
    }
  };

  return (
    <Box>
      <DialogMedium
        title="Ficha domiliciar já existe, deseja editá-la?"
        open={isDialogOpen}
        fullWidth={true}
        maxWidth="sm"
        handleClose={handleClose}
      >
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Button onClick={handleEdit}>Editar</Button>
          </Grid>
          <Grid item xs={2}>
            <Button variant="text" onClick={handleClose}>
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </DialogMedium>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <TerritorialEmployeeForm control={control} watch={watch} setValue={setValue} />

          <TerritorialAddressForm
            id={id}
            control={control}
            watch={watch}
            userData={userData}
            cepData={cepData}
            domiciliary={domiciliary}
            isEditing={isEditing}
            isRejectTerms={isRejectTerms}
            setIsEditing={setIsEditing}
            handleChangeField={handleChangeField}
            setCanSearchDomicliary={setCanSearchDomicliary}
          />

          <Grid item xs={12}></Grid>
          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <Typography variant="h5" color="secondary">
              Termo de recusa
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <SwitchField
              control={control}
              name="terms_of_use"
              label="Usuário recusou o cadastro por meio do Termo de Recusa do Cadastro."
              customOnChange={(value) => handleChangeField("terms_of_use", value)}
              disabled={!isEditing || disableAllFieldsByProperty}
            />
          </Grid>

          <TerritorialHousingConditionForm
            control={control}
            watch={watch}
            isEditing={isEditing}
            isRejectTerms={isRejectTerms}
            handleChangeField={handleChangeField}
            disableAllFieldsByProperty={disableAllFieldsByProperty}
            disablePartialFieldsByProperty={disablePartialFieldsByProperty}
          />

          <Grid item xs={12}></Grid>
          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <Typography variant="h5" color="secondary">
              Famílias
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <PaginatedAutocompleteField
              name={"families"}
              label={"Familias"}
              control={control}
              service={(params) => getFamily({ ...params })}
              queryKey="families"
              optionLabelKey="name"
              filterKey="name"
              disabled={!isEditing || isRejectTerms}
              multiple
            />
          </Grid>

          <Grid item xs={12}></Grid>
          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <Typography variant="h5" color="secondary">
              Instituição de Permanência
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <TextField
              control={control}
              disabled={!isEditing || isRejectTerms || !disablePartialFieldsByProperty}
              label={"NOME DA INSTITUIÇÃO DE PERMANÊNCIA"}
              name={"institution_permanence_name"}
            />
          </Grid>

          <Grid item xs={8}>
            <RadioField
              control={control}
              disabled={!isEditing || isRejectTerms || !disablePartialFieldsByProperty}
              direction="row"
              name={"institution_permanence_associated_professionals"}
              label={
                "Existem outros profissionais de saúde vinculados à instituição de permanência?"
              }
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
              optionLabelKey="label"
              optionValueKey="value"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" color="secondary">
              Identificação do Responsável Técnico
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <TextField
              control={control}
              label={"NOME DO RESPONSÁVEL TÉCNICO"}
              disabled={!isEditing || isRejectTerms || !disablePartialFieldsByProperty}
              name={"institution_permanence_responsible_name"}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              control={control}
              mask={RegexOf.cns}
              label={"CNS DO RESPONSÁVEL TÉCNICO"}
              disabled={!isEditing || isRejectTerms || !disablePartialFieldsByProperty}
              name={"institution_permanence_responsible_sus_card"}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              control={control}
              label={"CARGO NA INSTITUIÇÃO"}
              disabled={!isEditing || isRejectTerms || !disablePartialFieldsByProperty}
              name={"institution_permanence_position_institution"}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              control={control}
              mask={RegexOf.phone}
              label={"TEL DO RESPONSÁVEL TÉCNICO"}
              disabled={!isEditing || isRejectTerms || !disablePartialFieldsByProperty}
              name={"institution_permanence_responsible_cellphone"}
              inputProps={{ maxLength: 15 }}
            />
          </Grid>

          <Grid item xs={12}>
            <Button type="submit">Salvar</Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default TerritorialForm;
