import React, { useContext } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import {
  useHealthInsurance,
  useHealthInsurancePlan,
  useProcedures,
  useProvider,
  useProvenance,
} from "../../../service";
import { useQuery } from "react-query";
import { useState } from "react";
import * as Yup from "yup";
import useModalities from "../../../service/useModalities";
import useLocations from "../../../service/useLocations";
import { useForm } from "react-hook-form";
import {
  AutocompleteField,
  TextField,
  PaginatedAutocompleteField,
  DateField,
} from "../../../components/FormFields";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthContext } from "../../../contexts/AuthContext";
import { useNotifier } from "../../../hooks";
import { verifyAttendanceFlow } from "../../../utils";

const proceduresTussCode = ["0211049000", "0211049018"];

function SecondStep({ scheduling, handleFinish, handleBack }) {
  const notify = useNotifier();
  const { userData } = useContext(AuthContext);
  const { getProcedures } = useProcedures();
  const { getProvenances } = useProvenance();
  const { getModalities } = useModalities();
  const { getLocations } = useLocations();

  const validation = Yup.object().shape({
    insurance: Yup.object().required("Escolha o convênio.").nullable(),
    card_number: Yup.string()
      .when(["insurance"], {
        is: (insurance) => insurance?.describe === "SUS",
        then: Yup.string().nullable(),
        otherwise: Yup.string().required("Insira o numero da carteira."),
      })
      .nullable(),
    sus_number: Yup.string()
      .when(["insurance"], {
        is: (insurance) => insurance?.describe === "SUS",
        then: Yup.string().nullable(),
        otherwise: Yup.string()
          .required("Insira o numero da carteira.")
          .nullable(),
      })
      .nullable(),
    employee: Yup.object().required("Escolha o médico.").nullable(),
    expiration_date: Yup.date()
      .when(["insurance"], {
        is: (insurance) => insurance?.describe === "SUS",
        then: Yup.date().nullable(),
        otherwise: Yup.date()
          .min(new Date(), "A data não pode ser menor que a data de hoje")
          .typeError("Data inválida"),
      })
      .nullable(),
    insurancePlan_id: Yup.object()
      .nullable()
      .when(["insurance"], {
        is: (insurance) => insurance?.describe === "SUS",
        then: Yup.object().nullable(),
        otherwise: Yup.object().required("Escolha o plano.").nullable(),
      }),
    procedure: Yup.object()
      .required("Um procedimento deve ser selecionado")
      .nullable(),
    procedure_woman_diagnostic: Yup.array()
      .nullable()
      .when("procedure", (procedure, schema) => {
        if (proceduresTussCode.includes(procedure?.tuss_code)) {
          return schema.required(
            "Ao menos um procedimento deve ser selecionado"
          );
        }

        return schema;
      }),
    provenance: Yup.object().required("Deve ser informado.").nullable(),
    specialty: Yup.object().required("Deve ser informado.").nullable(),
    locations: Yup.object().required("Deve ser informado.").nullable(),
    modalities: Yup.object().nullable(),
  });

  const initialValues = {
    employee: scheduling?.employee,
    sector: scheduling?.sector,
    insurancePlan_id: scheduling?.insurancePlan_id || null,
    card_number:
      scheduling?.card_number ||
      scheduling?.patient?.planCards?.[0]?.card_number,
    sus_number: scheduling?.sus_number || scheduling?.patient?.sus_card,
    expiration_date: scheduling?.expiration_date || null,
    service: scheduling?.service,
    type: scheduling?.treatment_type,
    procedure: scheduling?.procedure || null,
    procedure_woman_diagnostic: [],
    specialty: scheduling?.specialty || null,
    provenance: scheduling?.provenance || null,
    insurance: scheduling?.insurance || null,
    locations: scheduling?.locations || null,
    modalities: scheduling?.modalities || null,
  };

  const { handleSubmit, control, watch, setValue } = useForm({
    resolver: yupResolver(validation),
    defaultValues: initialValues,
  });

  const [
    insuranceField,
    locationsField,
    employeeField,
    specialtyField,
    procedure,
  ] = watch(["insurance", "locations", "employee", "specialty", "procedure"]);

  const { getHealthInsurances } = useHealthInsurance();

  const HealthInsurance = useQuery(
    ["HealthInsuranceList"],
    () => getHealthInsurances({ filter: "active" }),
    {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const provenance = useQuery(
    ["ProvenanceList"],
    () => getProvenances({ filter: "active" }),
    {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const locations = useQuery(
    ["LocationsList"],
    () => getLocations({ filter: "active" }),
    {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const modalities = useQuery(
    ["ModalitiesList"],
    () => getModalities({ filter: "active" }),
    {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const { getProviders } = useProvider();
  const { getHealthInsurancePlan } = useHealthInsurancePlan();

  const [plans, setPlans] = useState([]);

  const providersService = (params) => {
    if (!!scheduling?.employee) {
      return [scheduling?.employee];
    }

    return getProviders(params);
  };

  async function getInsurancePlan(insurance) {
    if (!!insurance) {
      if (insurance?.describe === "SUS") {
        setValue("expiration_date", null);
        setValue("card_number", null);
      }

      try {
        const result = await getHealthInsurancePlan(insurance?.id || null);
        setPlans(result[0].insurancePlans);
      } catch (error) {
        notify(error.message, "error");
      }
    }

    setValue("insurancePlan_id", null);
  }

  const onSubmit = handleSubmit(({ sus_number, card_number, ...data }) => {
    const attendance = {
      data,
      patient: scheduling?.patient,
      scheduling: {
        ...scheduling,
        specialty: data.specialty,
        procedure: data.procedure,
        provenance: data.provenance,
        locations: data.locations,
        modalities: data.modalities,
        insurancePlan_id: data.insurancePlan_id,
        insurance: data.insurance,
        sus_number,
        card_number,
      },
      justification_note: scheduling.justification_note,
      date_late: scheduling?.date,
    };

    if (data.procedure_woman_diagnostic.length) {
      attendance.preload = data.procedure_woman_diagnostic.map((procedure) => ({
        type: "intervention",
        procedure_id: procedure.id,
      }));
    }

    if (data.insurance?.describe === "SUS") {
      attendance.data.sus_number = sus_number ? sus_number.toString() : null;
    } else {
      attendance.data.card_number = card_number.toString();
    }
    handleFinish(attendance);
  });

  return (
    <Box>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Grid item xs={6}>
          <Typography fontSize={16} color="secondary.main">
            Paciente{" "}
            <Typography component="span" fontWeight="bold" fontSize={16}>
              {scheduling?.patient?.name}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Typography fontSize={16} color="secondary.main">
            Tipo de atend:{" "}
            <Typography component="span" fontWeight="bold" fontSize={16}>
              {scheduling?.attendanceType}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      <Grid component="form" onSubmit={onSubmit} container spacing={2}>
        <Grid xs={4} item>
          <AutocompleteField
            options={HealthInsurance?.data || []}
            customOnChange={(value) => getInsurancePlan(value)}
            optionLabelKey="describe"
            optionCompareKey={"id"}
            name="insurance"
            label={"Convênio"}
            control={control}
          />
        </Grid>
        <Grid xs={4} item>
          <AutocompleteField
            options={plans || scheduling?.patient?.planCards}
            optionLabelKey="describe"
            optionCompareKey={"id"}
            disabled={insuranceField?.describe === "SUS"}
            name={"insurancePlan_id"}
            label={"Plano"}
            control={control}
          />
        </Grid>
        <Grid xs={4} item>
          {insuranceField?.describe === "SUS" && (
            <TextField
              type="number"
              control={control}
              name="sus_number"
              label="Número da carteira"
            />
          )}
          {insuranceField?.describe !== "SUS" && (
            <TextField
              type="number"
              control={control}
              name="card_number"
              label="Número da carteira"
            />
          )}
        </Grid>
        <Grid xs={4} item>
          <AutocompleteField
            options={[scheduling?.sector]}
            optionLabelKey="describe"
            optionCompareKey={"id"}
            name="sector"
            label={"Setor"}
            disabled
            control={control}
          />
        </Grid>
        <Grid xs={4} item>
          <PaginatedAutocompleteField
            service={providersService}
            queryKey={"employeeQuery"}
            optionLabelKey="name"
            optionCompareKey={"id"}
            label={"Médico assistente"}
            name="employee"
            filterKey={"name"}
            disabled={!!scheduling?.employee}
            control={control}
          />
        </Grid>
        <Grid xs={4} item>
          <DateField
            name={
              insuranceField?.describe === "SUS"
                ? "disabled"
                : "expiration_date"
            }
            label="Validade"
            fullWidth
            minDate={new Date()}
            required={!insuranceField?.describe === "SUS"}
            disabled={insuranceField?.describe === "SUS"}
            control={control}
          />
        </Grid>
        <Grid xs={4} item>
          <AutocompleteField
            options={provenance.data || []}
            optionLabelKey="describe"
            optionCompareKey={"id"}
            name="provenance"
            label="Procedência"
            control={control}
          />
        </Grid>
        <Grid xs={4} item>
          <AutocompleteField
            options={locations.data || []}
            optionLabelKey="description"
            optionCompareKey={"id"}
            name="locations"
            label="Local de Atendimento"
            control={control}
          />
        </Grid>
        <Grid xs={4} item>
          <AutocompleteField
            options={modalities.data || []}
            optionLabelKey="siga_code"
            optionCompareKey={"id"}
            name="modalities"
            label="Modalidade"
            disabled={locationsField?.id !== 4}
            control={control}
          />
        </Grid>
        <Grid xs={4} item>
          <AutocompleteField
            options={employeeField?.specialties ?? []}
            optionLabelKey="describe"
            optionCompareKey={"id"}
            disabled={!employeeField}
            name="specialty"
            label={"Especialidade"}
            control={control}
            customOnChange={() => {
              setValue("procedure", null);
              setValue("procedure_woman_diagnostic", []);
            }}
          />
        </Grid>
        <Grid xs={4} item>
          <AutocompleteField
            options={[scheduling.service]}
            optionLabelKey="describe"
            optionCompareKey={"id"}
            name="service"
            label={"Serviço"}
            disabled
            control={control}
          />
        </Grid>
        <Grid xs={4} item>
          <AutocompleteField
            options={[scheduling.treatment_type]}
            optionLabelKey="describe"
            optionCompareKey={"id"}
            name="type"
            label={"Tipo de agendamento"}
            disabled
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <PaginatedAutocompleteField
            service={(params) => {
              const types = ["administrative"];

              if (userData?.company.type.CEM) {
                types.push("administrative_cem");
              }

              return getProcedures({
                ...params,
                type: types,
                filter: "active",
                patient_id: scheduling.patient.id,
                attendance_flow: verifyAttendanceFlow(
                  scheduling.risk_classification_id
                ),
                specialty_id: specialtyField?.id,
              });
            }}
            refetchService={[specialtyField]}
            queryKey={"proceduresQuery"}
            filterKey="filters"
            optionCompareKey="id"
            name="procedure"
            label="Procedimentos"
            autoCompleteProps={{
              getOptionLabel: (data) => {
                let label = data?.name;

                if (data?.tuss_code) {
                  label = `${data?.tuss_code} - ${label}`;
                }

                return label;
              },
            }}
            required
            disabled={!specialtyField}
            control={control}
            customOnChange={() => {
              setValue("procedure_woman_diagnostic", []);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <PaginatedAutocompleteField
            service={(params) => {
              const types = ["woman_diagnostic"];

              if (procedure?.tuss_code === "0211049018") {
                types.push("woman_diagnostic_with_densitometry");
              }

              return getProcedures({
                ...params,
                type: types,
                filter: "active",
                patient_id: scheduling.patient.id,
                specialty_id: specialtyField?.id,
              });
            }}
            refetchService={[procedure]}
            queryKey={"procedures-woman-diagnostic"}
            filterKey="filters"
            optionCompareKey="id"
            name="procedure_woman_diagnostic"
            label="Procedimentos (Apoio ao diagnóstico da mulher)"
            autoCompleteProps={{
              getOptionLabel: (data) => {
                let label = data?.name;

                if (data?.tuss_code) {
                  label = `${data?.tuss_code} - ${label}`;
                }

                return label;
              },
            }}
            multiple
            required
            disabled={!proceduresTussCode.includes(procedure?.tuss_code)}
            control={control}
          />
        </Grid>
        <Grid
          md={12}
          sx={{ display: "flex", justifyContent: "space-between" }}
          item
        >
          <Button onClick={handleBack}>Voltar</Button>
          <Button type="submit" variant="contained">
            Proximo
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SecondStep;
