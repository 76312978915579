import { Box, Icon, Typography, useTheme } from "@mui/material";
import React from "react";

export default function SimpleCard({
  icon,
  title,
  subtitle,
  containerStyles,
  iconStyles,
  children,
}) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "relative",
        borderRadius: 2,
        borderWidth: 1,
        boxShadow: theme.shadows[1],
        px: 2,
        ...containerStyles,
      }}
    >
      {title && (
        <Typography
          color={theme.palette.primary.light}
          fontWeight={600}
          marginRight={5}
        >
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography
          color={theme.palette.primary.light}
          fontWeight={500}
          fontSize={11}
          marginBottom={0.5}
          marginRight={5}
        >
          {subtitle}
        </Typography>
      )}
      <Box
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
          width: 50,
          height: 50,
          borderTopRightRadius: 12,
          borderBottomLeftRadius: 100,
          backgroundColor: theme.palette.primary.light,
        }}
      >
        <Icon
          sx={{
            position: "absolute",
            top: 8,
            right: 12,
            ...iconStyles,
          }}
        >
          {icon}
        </Icon>
      </Box>
      {children}
    </Box>
  );
}
