import React, { useState } from "react";
import TableFilter from "../../../components/Table/TableFilter";
import { Box, TablePagination } from "@mui/material";
import { useSOAP } from "../../../service";
import { useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { format, parseISO } from "date-fns";
import { BoxContainer } from "../../../components";
import { openURL } from "../../../pdfModels";
import { useNotifier } from "../../../hooks";

export default function ExternalSoap() {
  const { id } = useParams();
  const { getSoapsByPatient } = useSOAP();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const notify = useNotifier();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const columns = [
    {
      name: "N° do Atendimento",
      field: "number_soap",
      type: "text",
    },
    {
      name: "Serviço",
      field: "attendance?.service?.describe",
      type: "text",
    },
    {
      name: "Data do Atendimento",
      field: "createdAt",
      type: "date",
      use: (createdAt) => (createdAt ? format(parseISO(createdAt), "dd/MM/yyyy") : "Não informado"),
    },
    {
      name: "Profissional",
      field: "employee_specialty?.employee?.name",
      type: "text",
    },
  ];

  const soapHistoryQuery = useQuery(
    ["soap-history", page, limit, id],
    () =>
      getSoapsByPatient(
        id,
        {
          page,
          limit,
          type: ["normal"],
          signed: true,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      ),
    {
      enabled: !!id && !!token,
      initialData: {
        total: 0,
        items: [],
      },
      onError: (response) => {
        notify(response?.message, "error");
      },
    }
  );

  return (
    <BoxContainer title="Atendimentos Externos" hideBackButton>
      <TableFilter
        data={soapHistoryQuery.data.items}
        columns={columns}
        loading={soapHistoryQuery.isLoading}
        actions
        actionsTypes={["view"]}
        actionHandleView={(_, row) => {
          openURL(row.signed_soap.result);
        }}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          count={soapHistoryQuery.data?.totalItems || 0}
          component="div"
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 40, 75]}
          onRowsPerPageChange={(event) => {
            setLimit(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </Box>
    </BoxContainer>
  );
}
