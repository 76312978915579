import React, { useContext, useState } from "react";
import Yup from "../../../config/yup";
import { Grid, Box, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircleOutline";
import { AppContext } from "../../../contexts/AppContext";
import { usePresets, useProcedures } from "../../../service";
import {
  Button,
  PaginatedAutocompleteField,
  SelectField,
  TextField,
  TransferList,
} from "../../../components/FormFields";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNotifier } from "../../../hooks";
import ExamPrescriptionCard from "./ExamPrescriptionCard";
import PatientDataConfirm from "../PatientDataConfirm";
import usePrescriptions from "../../../service/usePrescriptions";
import { openURL, sadtPDF } from "../../../pdfModels";
import useSignaturePassword from "../../../hooks/useSignaturePassword";
import { DialogMedium } from "../../../helper";
import { groupPrescriptionCollection } from "../../../utils";

function ExamPrescription({ attendance, isExternal }) {
  const notify = useNotifier();
  const validateSignature = useSignaturePassword();
  const {
    disabledFields,
    patientData,
    setPatientData,
    userData,
    examPrescriptions,
    setExamPrescriptions,
  } = useContext(AppContext);
  const [openGroupForm, setOpenGroupForm] = useState(false);
  const [groupToEdit, setGroupToEdit] = useState(null);
  const [updatePatientType, setUpdatePatientType] = useState(() =>
    !patientData?.race ? "race" : null
  );

  const queryClient = useQueryClient();
  const { getProcedures } = useProcedures();
  const { getPresets } = usePresets();
  const { postPrescription, patchPrescription, getPrescriptions } = usePrescriptions();

  const postPrescriptionMutation = useMutation(postPrescription);
  const patchPrescriptionMutation = useMutation(patchPrescription);

  const currentPrescriptionQuery = useQuery(
    ["current-exam-prescription"],
    () =>
      getPrescriptions(attendance.patient.id, {
        page: 0,
        limit: 1,
        created_by_me: true,
        created_from_soap: !isExternal,
        attendance_id: attendance.id,
        has_soap: false,
      }),
    {
      onSuccess(response) {
        setExamPrescriptions((items) => {
          const unsignedItems = items.filter((item) => !item.id_prescription);
          return response.items[0].exams.concat(unsignedItems);
        });
      },
    }
  );

  const initialValues = {
    type: null,
    describe: null,
    notes: "",
    segment_area: "",
    diagnostic_hypothesis: "",
  };

  const validations = Yup.object().shape({
    type: Yup.string().required("É requerido").nullable(),
    describe: Yup.mixed().required("É requerido").nullable(),
    notes: Yup.string().nullable(),
    segment_area: Yup.string(),
    diagnostic_hypothesis: Yup.string().nullable(),
  });

  const groupForm = useForm({
    defaultValues: {
      group: [],
    },
  });

  const prescriptionForm = useForm({
    resolver: yupResolver(validations),
    defaultValues: initialValues,
  });

  const [groupedExams] = groupForm.watch(["group"]);

  const [type] = prescriptionForm.watch(["type"]);

  function toggleGroupForm() {
    if (openGroupForm) {
      groupForm.reset();
    }

    setOpenGroupForm((open) => !open);
  }

  function handlePresets(selectedItems) {
    const presets = selectedItems?.flatMap((value) => value.data) || [];
    presets.forEach((preset, index) => {
      preset.id = examPrescriptions.length + 1 + index + 1;
    });

    setExamPrescriptions((exams) => {
      const filteredExams = exams.filter(
        ({ notPreseted, id_prescription }) => notPreseted || id_prescription
      );
      return filteredExams.concat(presets);
    });
  }

  const handleAddPrescription = prescriptionForm.handleSubmit((values) => {
    delete values.preset;

    const exam = {
      ...values,
      id: examPrescriptions.length + 1,
      type: values.type,
      procedure_type: values.describe.type,
      procedure_id: values.describe.id,
      describe: values.describe.name,
      notPreseted: true,
    };

    setExamPrescriptions((prescriptions) => prescriptions.concat(exam));

    prescriptionForm.setValue("type", null);
    prescriptionForm.reset();
  });

  function handleEditGroup(data) {
    groupForm.reset({ group: data }, { keepDefaultValues: true });
    toggleGroupForm();
    setGroupToEdit(data);
  }

  const handleRemovePrescription = (data) => {
    const isGroup = Array.isArray(data);

    const newPrescriptions = examPrescriptions.filter((prescription) => {
      if (isGroup) {
        return prescription.tab_id !== data[0].tab_id;
      }

      return prescription.id !== data.id;
    });

    setExamPrescriptions(newPrescriptions);
  };

  function handlePrint(data) {
    const firstItem = data[0];
    const isXray = firstItem.procedure.type.includes("xray");
    let type = "lab";

    if (isXray) {
      type = "xray";
    } else if (firstItem.type === "Imagem") {
      type = "image";
    }

    const signedDocument = currentPrescriptionQuery.data.items[0].signed_documents.find(
      (signedDocument) => {
        return signedDocument.type === type && signedDocument.tab_id === firstItem.tab_id;
      }
    );

    if (signedDocument.result) {
      return openURL(signedDocument.result);
    }

    const pdf = sadtPDF(type, data, patientData, true);
    pdf.open();
  }

  async function mountSADTs(exams) {
    const examsToSign = [];
    const groupTypes = [
      {
        name: "xray",
        type: "sadt_xray",
        groups: [],
      },
      {
        name: "images",
        type: "images",
        groups: [],
      },
      {
        name: "lab",
        type: "sadt_lab",
        groups: [],
      },
    ];

    exams.forEach((exam) => {
      if (exam.procedure_type?.includes("xray")) {
        groupPrescriptionCollection(groupTypes[0].groups, exam);
      } else if (exam.type === "Imagem") {
        groupPrescriptionCollection(groupTypes[1].groups, exam);
      } else {
        groupPrescriptionCollection(groupTypes[2].groups, exam);
      }
    });

    for (const groupType of groupTypes) {
      for (const group of groupType.groups) {
        const pdf = sadtPDF(groupType.name, group, patientData, true);

        examsToSign.push({
          id: pdf.name.replace(".pdf", ""),
          tab_id: group[0].tab_id,
          original_file_name: pdf.name,
          data: await pdf.base64(),
          type: groupType.type,
          signature_settings: {
            visible_sign_x: 50,
            visible_sign_y: 430,
            visible_sign_page: "*",
          },
        });
      }
    }

    return examsToSign;
  }

  async function onSubmitPrescription() {
    let exams = examPrescriptions;

    if (currentPrescriptionQuery.isSuccess) {
      exams = examPrescriptions.filter((exam) => !exam.id_prescription);
    }

    const hasRegulatedExams = exams.some((exam) => {
      return exam.procedure_type?.includes("regulation");
    });

    if (hasRegulatedExams && !patientData.contact?.cell_phone) {
      return setUpdatePatientType("phone");
    }

    await validateSignature(async () => {
      const documentsToSign = await mountSADTs(exams);

      const cleanedExams = exams.map(({ id, procedure_type, ...exam }) => {
        if ("notPreseted" in exam) {
          delete exam.notPreseted;
        }

        return exam;
      });

      const data = {
        attendance_id: attendance.id,
        created_from_soap: !isExternal,
        exams: cleanedExams,
        to_sign: documentsToSign,
      };

      if (currentPrescriptionQuery.isSuccess) {
        return patchPrescriptionMutation.mutate(
          { id: currentPrescriptionQuery.data.items[0].id, data },
          {
            onSuccess(response) {
              const hasPendencies = queryClient.getQueryData("verify-has-pendencies");

              if (!hasPendencies) {
                queryClient.invalidateQueries("verify-has-pendencies");
              }

              setExamPrescriptions([]);
              currentPrescriptionQuery.refetch();
              notify(response.message, "success");
            },
            onError(error) {
              notify(error.message, "error");
            },
          }
        );
      }

      postPrescriptionMutation.mutate(data, {
        onSuccess(response) {
          setExamPrescriptions([]);
          currentPrescriptionQuery.refetch();
          notify(response.message, "success");
        },
        onError(error) {
          notify(error.message, "error");
        },
      });
    });
  }

  const groupPrescriptions = groupForm.handleSubmit(({ group }) => {
    let lastGroupId = 0;
    const newPrescriptions = [];

    if (groupToEdit) {
      lastGroupId = group[0].tab_id - 1;
    } else {
      lastGroupId = examPrescriptions.reduce((previous, current) => {
        if (current.tab_id) {
          return previous < current.tab_id ? current.tab_id : previous;
        }

        return previous;
      }, lastGroupId);
    }

    examPrescriptions.forEach((prescription) => {
      const isGrouped = group.some((groupedPrescription) => {
        return groupedPrescription.id === prescription.id;
      });

      if (isGrouped) {
        prescription.tab_id = lastGroupId + 1;
      } else if (prescription.tab_id === group[0].tab_id) {
        delete prescription.tab_id;
      }

      newPrescriptions.push(prescription);
    });

    setExamPrescriptions(newPrescriptions);
    toggleGroupForm();
    setGroupToEdit(null);
  });

  function hasNoUngroupedExams() {
    return !examPrescriptions.some((exam) => !exam.tab_id);
  }

  function verifyDisabledSave(exams) {
    let hasUngrouped = false;
    let hasOnlyRegistered = true;

    exams.forEach((exam) => {
      const isGroup = Array.isArray(exam);

      if (!hasUngrouped) {
        hasUngrouped = !isGroup;
      }

      if (hasOnlyRegistered && isGroup) {
        hasOnlyRegistered = !!exam[0].id_prescription;
      }
    });

    return disabledFields || hasUngrouped || hasOnlyRegistered;
  }

  const examTypes = [
    { label: "Exame laboratorial", value: "Laboratorial" },
    { label: "Exame de imagem", value: "Imagem" },
  ];

  const exams = [];
  const ungroupedExams = [];

  if (groupToEdit) {
    ungroupedExams.push(...groupToEdit);
  }

  examPrescriptions.forEach((exam) => {
    if (exam.tab_id) {
      groupPrescriptionCollection(exams, exam);
    } else {
      exams.push(exam);
      ungroupedExams.push(exam);
    }
  });

  return (
    <>
      <PatientDataConfirm
        open={!!updatePatientType}
        patientId={patientData.id}
        type={updatePatientType}
        handleClose={() => setUpdatePatientType(null)}
        onSuccess={(data, _, type) => {
          setPatientData(() => {
            if (type === "phone") {
              return {
                ...patientData,
                contact: {
                  ...patientData.contact,
                  ...data.contact,
                },
              };
            } else {
              return { ...patientData, race: data.race };
            }
          });
        }}
      />
      <DialogMedium
        open={openGroupForm}
        title="Agrupamento de exames"
        fullWidth
        handleClose={toggleGroupForm}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxWidth: "800px",
              "& .MuiDialogContent-root": {
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                gap: "20px",
                "& > div + div": {
                  display: "flex",
                  gap: "20px",
                },
              },
            },
          },
        }}
      >
        <TransferList
          name="group"
          control={groupForm.control}
          options={ungroupedExams}
          optionLabelKey="describe"
          optionCompareKey="id"
          filterOptions={(option) => {
            if (!groupedExams.length) return true;

            const isXrayGroup = groupedExams[0].procedure_type?.includes("xray");
            const isAxrayOption = option.procedure_type?.includes("xray");

            if (isXrayGroup && isAxrayOption) {
              return true;
            }

            if (!isXrayGroup && groupedExams[0].type === option.type) {
              return true;
            }

            return false;
          }}
        />
        <Box>
          <Button color="error" onClick={toggleGroupForm}>
            Cancelar
          </Button>
          <Button disabled={!groupedExams.length} onClick={groupPrescriptions}>
            Agrupar
          </Button>
        </Box>
      </DialogMedium>
      <Grid component="form" container spacing={2} marginTop="0.5rem">
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography fontSize={16} color="secondary" fontWeight="600">
              Selecionar pré definição
            </Typography>
          </Grid>
          <Grid md={5} xs={8} item>
            <PaginatedAutocompleteField
              multiple
              control={prescriptionForm.control}
              name="preset"
              label="Predefinição"
              queryKey={"presets"}
              service={async (params) => getPresets({ ...params, type: "exams" })}
              optionLabelKey={"describe"}
              customOnChange={handlePresets}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography fontSize={16} color="secondary" fontWeight="600">
              Exame
            </Typography>
          </Grid>
          <Grid xs={4} item>
            <SelectField
              required
              control={prescriptionForm.control}
              name="type"
              label="Tipo de exame"
              customOnChange={(value) => {
                prescriptionForm.setValue("describe", null);
              }}
              options={examTypes}
              optionLabelKey="label"
              optionValueKey="value"
            />
          </Grid>
          <Grid xs={8} item>
            <PaginatedAutocompleteField
              required
              label="Exame"
              name="describe"
              disabled={!type}
              control={prescriptionForm.control}
              filterKey="filters"
              queryKey={"preseting"}
              refetchService={[type]}
              optionLabelKey={"describe"}
              service={(params) => {
                const filters = {
                  ...params,
                  type: [type === "Laboratorial" ? "lab" : "image"],
                  filter: "active",
                  patient_id: patientData?.id,
                };

                if (
                  userData?.company.type.TWENTY_FOUR_HOURS &&
                  attendance.risk_classification_id &&
                  type?.type === "lab"
                ) {
                  filters.match_type_exactly = true;
                  filters.type.push("twenty_four_hours");
                }

                return getProcedures(filters);
              }}
              autoCompleteProps={{
                getOptionLabel: (data) => {
                  let label = data?.name;

                  if (data?.tuss_code) {
                    label = `${data?.tuss_code} - ${label}`;
                  }

                  return label;
                },
              }}
            />
          </Grid>
          <Grid xs={12} item>
            <TextField
              name="segment_area"
              label="AREA E/OU SEGMENTO ANATÔMICO A SER EXAMINADO:"
              control={prescriptionForm.control}
            />
          </Grid>
          <Grid xs={12} item>
            <TextField
              name="diagnostic_hypothesis"
              label="Hipotese Diagnóstica"
              control={prescriptionForm.control}
            />
          </Grid>
          <Grid xs={12} item>
            <TextField name="notes" label="Observação" control={prescriptionForm.control} />
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr auto",
          gridAutoFlow: "column",
          margin: "1.2rem auto",
          gap: "8px",
        }}
      >
        <Button
          variant="text"
          onClick={toggleGroupForm}
          sx={{
            justifySelf: "start",
          }}
          disabled={hasNoUngroupedExams()}
        >
          Agrupar
        </Button>
        <Button variant="text" onClick={handleAddPrescription} startIcon={<AddCircleIcon />}>
          Adicionar Exame
        </Button>
      </Box>
      <Grid container spacing={2}>
        {exams?.map((data, index) => (
          <ExamPrescriptionCard
            key={Array.isArray(data) ? data[0].id : data.id}
            data={data}
            handleRemove={() => handleRemovePrescription(data)}
            handleEdit={() => handleEditGroup(data)}
            handlePrint={() => handlePrint(data)}
          />
        ))}
      </Grid>
      <Box display="flex" justifyContent="center" margin="1.5rem auto">
        <Button
          onClick={onSubmitPrescription}
          loadingMessage={currentPrescriptionQuery.isFetching ? "Carregando..." : "Enviando..."}
          loading={
            postPrescriptionMutation.isLoading ||
            patchPrescriptionMutation.isLoading ||
            currentPrescriptionQuery.isFetching
          }
          disabled={verifyDisabledSave(exams)}
        >
          Salvar
        </Button>
      </Box>
    </>
  );
}

export default ExamPrescription;
