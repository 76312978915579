import { Box, Grid, Grow, styled, Typography, useTheme } from "@mui/material";
import React from "react";
import { accessObjectByString } from "../../../utils";
import { DeleteIconButton, PrintIconButton } from "../../../components/Buttons";

const IconContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: 4,
  position: "absolute",
  top: 6,
  right: 15,
});

export default function DeclarationCard({ data, descriptions, handleRemove, handlePrint }) {
  const theme = useTheme();

  return (
    <Grid xs={12} md={9} item>
      <Grow in={true} timeout={300}>
        <Grid
          container
          sx={{
            alignItems: "center",
            flexWrap: "wrap",
            columnGap: 2,
            backgroundColor: theme.palette.primary.medium,
            borderRadius: 2,
            paddingY: "1rem",
            paddingX: "0.8rem",
            color: theme.palette.primary.light,
            position: "relative",
          }}
        >
          {descriptions.map((description) => {
            if (description.visible && !description.visible(data)) return null;

            return (
              <Grid item key={description.label} xs={description.grid}>
                <Typography
                  variant="p"
                  component="p"
                  sx={{
                    fontSize: 14,
                    color: theme.palette.primary.light,
                  }}
                >
                  <Typography
                    variant="span"
                    component="span"
                    sx={{
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  >
                    {description.labelFormatter
                      ? description.labelFormatter(data)
                      : description.label}
                    :{" "}
                  </Typography>
                  {description.valueFormatter
                    ? description.valueFormatter(data)
                    : accessObjectByString(data, description.valueKey)}
                </Typography>
              </Grid>
            );
          })}
          <IconContainer>
            {!data.signed_document && <DeleteIconButton onClick={handleRemove} />}
            {data.signed_document && <PrintIconButton onClick={() => handlePrint(data)} />}
          </IconContainer>
        </Grid>
      </Grow>
    </Grid>
  );
}
