import { useContext } from "react";
import { useAttendaces } from "../../../service";
import { useMutation } from "react-query";
import { AuthContext } from "../../../contexts/AuthContext";
import { ConfirmDialog } from "../../../helper";
import { useLocation, useNavigate } from "react-router-dom";
import { useNotifier } from "../../../hooks";

export default function ConfirmTransferDialog({
  open,
  handleClose,
  navigateTo,
  attendance,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const notify = useNotifier()
  const { userData } = useContext(AuthContext);
  const { transferAttendance } = useAttendaces();
  const transferMutation = useMutation({
    mutationFn: transferAttendance,
    mutationKey: "transfer-attendance",
  });

  function navigateToSoap(transfer) {
    if (transfer) {
      transferMutation.mutate(
        {
          attendanceId: attendance.id,
          data: { forced: true, id_employee: userData.id },
        },
        {
          onSuccess(data) {
            navigate(navigateTo, {
              state: {
                backTo: location.pathname,
                canReevaluate: !!attendance?.classification,
                attendance_data: attendance,
              },
            });
            notify(data.message,"success")
            handleClose();
          },
          onError(data){
            notify(data.message,"error")
          }
        }
      );
    } else {
      navigate(navigateTo, {
        state: {
          isHistory: true,
          backTo: location.pathname,
          canReevaluate: !!attendance?.classification,
          attendance_data: attendance,
        },
      });
      handleClose();
    }
  }

  return (
    <ConfirmDialog
      message={"Atendimento pertence a outro profissional, deseja *ASSUMIR* o atendimento?"}
      hideBackDrop={false}
      open={!!open}
      maxWidth="sm"
      fullWidth={true}
      handleClose={() => {
        handleClose();
      }}
      cancelLabel={"Não"}
      confirmLabel={"Sim"}
      actions
      handleConfirm={() => {
        navigateToSoap(true);
      }}
      handleCancel={() => navigateToSoap(false)}
    />
  );
}
