import React, { useContext, useMemo, useState } from "react";
import PatientsCard from "../../PatientsCard/PatientsCard";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  TablePagination,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import TableFilter from "../../Table/TableFilter";
import { format, isSameDay, parseISO } from "date-fns";
import {
  DoNotDisturbAltRounded,
  NoteAddRounded,
  VisibilityRounded,
} from "@mui/icons-material";
import { DialogMedium } from "../../../helper";
import DynamicFeedback from "../../DynamicFeedback";
import ComplementCollectiveActivity from "./ComplementCollectiveActivity";
import { AuthContext } from "../../../contexts/AuthContext";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useCollectiveActivity } from "../../../service";
import { useNotifier } from "../../../hooks";
import { useForm } from "react-hook-form";
import { Button, TextField } from "../../FormFields";
import Accordion from "../../Accordion";

const shapeFeedback = [
  {
    type: "header",
    label: "#1 DADOS DA ATIVIDADE COLETIVA",
  },
  {
    type: "description",
    label: "Criado em",
    valueKey: "createdAt",
    formatter: (createdAt) =>
      format(parseISO(createdAt), "dd/MM/yyyy 'às' HH:mm"),
  },
  {
    type: "description",
    label: "Prestador",
    valueKey: "createdBy.name",
  },
  {
    type: "description",
    label: "Tipo do Prestador",
    valueKey: "createdBy.employee_Type.description",
  },
  {
    type: "description",
    label: "Atividade",
    valueKey: "activityType.describe",
  },
  {
    type: "description",
    label: "Práticas Educativas Realizadas por Profissional de Educação",
    valueKey: "pse_education",
    formatter: (pse_education) => (pse_education ? "Sim" : "Não"),
  },
  {
    type: "description",
    label: "Práticas de Saúde Realizadas por Profissional da Saúde",
    valueKey: "pse_health",
    formatter: (pse_health) => (pse_health ? "Sim" : "Não"),
  },

  {
    type: "description",
    label: "Turno",
    valueKey: "shift",
  },
  {
    type: "description",
    label: "INEP",
    valueKey: "inep",
  },
  {
    type: "description",
    label: "CNES",
    valueKey: "cnes",
  },
  {
    type: "description",
    label: "Outra localidade",
    valueKey: "other_location",
  },
  {
    type: "description",
    label: "Prestadores Participantes",
    valueKey: "employees",
    formatter: (employees) => {
      return employees?.map((employee) => employee?.name).join(", ");
    },
  },
  {
    type: "description",
    label: "Práticas em Saúde",
    valueKey: "health_practice",
    formatter: (health_practice) => {
      return health_practice?.map((hp) => hp?.describe).join(", ");
    },
  },
  {
    type: "description",
    label: "Temas para Reunião",
    valueKey: "meeting_topic",
    formatter: (meeting_topic) => {
      return meeting_topic?.map((mt) => mt?.describe).join(", ");
    },
  },
  {
    type: "description",
    label: "Temas para Saúde",
    valueKey: "health_topic",
    formatter: (health_topic) => {
      return health_topic?.map((ht) => ht?.describe).join(", ");
    },
  },
  {
    type: "description",
    label: "Público Alvo",
    valueKey: "target_public",
    formatter: (target_public) => {
      return target_public?.map((tp) => tp?.describe).join(", ");
    },
  },
  {
    type: "description",
    label: "Procedimento",
    valueKey: "procedure.name",
  },
  {
    type: "description",
    label: "Suspenso",
    valueKey: "suspended",
    formatter: () => "Sim",
    hidden: (suspended) => !suspended,
  },
  {
    type: "description",
    label: "Razão da suspensão",
    valueKey: "suspended_reason",
    hidden: (suspended_reason) => !suspended_reason,
  },
];

const complementTableColumns = [
  {
    name: "Criado em",
    field: "createdAt",
    use: (createdAt) => format(parseISO(createdAt), "dd/MM/yyyy 'às' HH:mm"),
    type: "text",
  },
  {
    name: "Peso",
    field: "weight",
    use: (weight) => (weight ? `${weight} kg` : "Não informado"),
    type: "text",
  },
  {
    name: "Altura",
    field: "height",
    use: (height) => (height ? `${height} cm` : "Não informado"),
    type: "text",
  },
  {
    name: "Procedimento",
    field: "procedure",
    use: (procedure) => (procedure ? procedure?.name : "Não informado"),
    type: "text",
  },
  {
    name: "Anotações",
    field: "notes",
    use: (notes) => (notes ? notes : "Não informado"),
    type: "text",
  },
];

const AccordionTitle = ({
  collectiveAttendance,
  setCollectiveActivity,
  setCollectiveActivityId,
  setSuspensionDialog,
  setComplementDialog,
  setViewCollectiveActivity,
  userData,
}) => (
  <Box
    display="flex"
    justifyContent="space-between"
    width="100%"
    alignItems="center"
  >
    <Box>
      <Typography fontSize={9}>Data e Hora:</Typography>
      <Typography fontSize={11}>
        {format(
          parseISO(collectiveAttendance?.createdAt),
          "dd/MM/yyyy 'às' HH:mm"
        )}
      </Typography>
    </Box>
    <Box>
      <Typography fontSize={9}>Profissional:</Typography>
      <Typography fontSize={11}>
        {collectiveAttendance?.createdBy?.name}
      </Typography>
    </Box>
    <Box>
      <Typography fontSize={9}>Tipo de Prestador:</Typography>
      <Typography fontSize={11}>
        {collectiveAttendance?.createdBy?.employee_Type?.description}
      </Typography>
    </Box>
    <Box>
      <Typography fontSize={9}>Atividade:</Typography>
      <Typography fontSize={11}>
        {collectiveAttendance?.activityType?.describe}
      </Typography>
    </Box>
    <Box display="flex" justifyContent="space-between" mr={4}>
      <Tooltip title="Visualizar" placement="top">
        <IconButton
          onClick={() => {
            setCollectiveActivity(collectiveAttendance);
            setViewCollectiveActivity(true);
          }}
          sx={{
            color: "white",
            "&:disabled": {
              opacity: 0.9,
            },
          }}
        >
          <VisibilityRounded />
        </IconButton>
      </Tooltip>
      <Tooltip title="Complemento" placement="top">
        <IconButton
          disabled={
            !userData?.employee_type?.higher_education ||
            collectiveAttendance?.suspended ||
            !isSameDay(new Date(), parseISO(collectiveAttendance.createdAt))
          }
          onClick={() => {
            setCollectiveActivity(collectiveAttendance);
            setComplementDialog(true);
          }}
          sx={{
            color: "white",
            "&:disabled": {
              opacity: 0.9,
            },
          }}
        >
          <NoteAddRounded />
        </IconButton>
      </Tooltip>
      <Tooltip title="Suspender" placement="top">
        <IconButton
          disabled={
            !userData?.employee_type?.higher_education ||
            collectiveAttendance?.suspended
          }
          onClick={() => {
            setCollectiveActivityId(collectiveAttendance?.id);
            setSuspensionDialog(true);
          }}
          sx={{
            color: "white",
            "&:disabled": {
              opacity: 0.9,
            },
          }}
        >
          <DoNotDisturbAltRounded />
        </IconButton>
      </Tooltip>
    </Box>
  </Box>
);

export default function CollectiveActivity({ attendance }) {
  const theme = useTheme();
  const { userData } = useContext(AuthContext);
  const notify = useNotifier();
  const queryClient = useQueryClient();
  const {
    getCollectiveAttendances,
    suspendCollectiveAttendance,
    suspendComplementOfCollectiveActivity,
    getComplementsByCollectiveActivityId,
  } = useCollectiveActivity();
  const [viewCollectiveActivity, setViewCollectiveActivity] = useState(false);
  const [complementDialog, setComplementDialog] = useState(false);
  const [complementSuspensionDialog, setComplementSuspensionDialog] =
    useState(false);
  const [suspensionDialog, setSuspensionDialog] = useState(false);
  const [collectiveActivity, setCollectiveActivity] = useState(null);
  const [collectiveActivityId, setCollectiveActivityId] = useState(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const suspendCollectiveAttendanceMutation = useMutation(
    suspendCollectiveAttendance
  );
  const suspendComplementOfCollectiveActivityMutation = useMutation(
    suspendComplementOfCollectiveActivity
  );

  const collectiveActivitiesQuery = useQuery(
    ["collective-activities", page, limit],
    () =>
      getCollectiveAttendances({
        page,
        limit,
        patient_id: attendance.id_patient,
      }),
    {
      onError(error) {
        notify(error.message, "error");
      },
    }
  );

  const complementCollectiveActivitiesQuery = useQuery(
    ["complement-collective-activities", collectiveActivityId],
    () =>
      getComplementsByCollectiveActivityId({
        page: 0,
        limit: 100,
        collective_attendance_id: collectiveActivityId,
        complemented: true,
      }),
    {
      enabled: !!collectiveActivityId,
      onError(error) {
        notify(error.message, "error");
      },
    }
  );

  const collectiveActivitiesItems = useMemo(() => {
    if (collectiveActivitiesQuery.isSuccess) {
      return collectiveActivitiesQuery.data?.items?.map(
        (collectiveAttendance) => ({
          bgColor: collectiveAttendance?.suspended
            ? theme.palette.error.light
            : theme.palette.secondary.main,
          customOnChange: () =>
            setCollectiveActivityId(collectiveAttendance.id),
          title: (
            <AccordionTitle
              collectiveAttendance={collectiveAttendance}
              setCollectiveActivity={setCollectiveActivity}
              setCollectiveActivityId={setCollectiveActivityId}
              setComplementDialog={setComplementDialog}
              setSuspensionDialog={setSuspensionDialog}
              setViewCollectiveActivity={setViewCollectiveActivity}
              userData={userData}
              key={collectiveAttendance.id}
            />
          ),
          body: (
            <Box key={collectiveAttendance.id}>
              <TableFilter
                data={complementCollectiveActivitiesQuery.data?.items || []}
                columns={complementTableColumns}
                loading={complementCollectiveActivitiesQuery.isLoading}
                emptyMessage={"Não há complementos para esta atividade"}
                actions
                actionsTypes={["suspend"]}
                rowStatus={(row) => {
                  if (row?.suspended_complement) {
                    return "error";
                  }
                }}
                disableActions={(row) =>
                  row?.suspended_complement ||
                  !userData?.employee_type?.higher_education
                }
                actionHandleSuspend={() => {
                  setCollectiveActivityId(collectiveAttendance?.id);
                  setComplementSuspensionDialog(true);
                }}
                noFilter
              />
            </Box>
          ),
        })
      );
    }
    return [];
  }, [
    collectiveActivitiesQuery.data,
    complementCollectiveActivitiesQuery.data,
  ]);

  const suspensionForm = useForm({
    defaultValues: { suspended_reason: "" },
  });

  const complementSuspensionForm = useForm({
    defaultValues: { suspended_reason: "" },
  });

  const onSubmitSuspension = (values) => {
    suspendCollectiveAttendanceMutation.mutate(
      { id: collectiveActivityId, data: values },
      {
        onSuccess: () => {
          notify("Atividade coletiva suspensa", "success");
          suspensionForm.reset();
          queryClient.invalidateQueries("collective-activities");
          queryClient.invalidateQueries("complement-collective-activities");
          handleClose();
        },
        onError: (data) => {
          notify(data.message, "error");
        },
      }
    );
  };

  const onSubmitComplementSuspension = (values) => {
    suspendComplementOfCollectiveActivityMutation.mutate(
      {
        collectiveAttendanceId: collectiveActivityId,
        patientId: attendance?.id_patient,
        data: values,
      },
      {
        onSuccess: () => {
          notify("Complemento suspenso", "success");
          complementSuspensionForm.reset();
          queryClient.invalidateQueries("collective-activities");
          queryClient.invalidateQueries("complement-collective-activities");
          handleClose();
        },
        onError: (data) => {
          notify(data.message, "error");
        },
      }
    );
  };

  const handleClose = () => {
    setViewCollectiveActivity(false);
    setComplementDialog(false);
    setSuspensionDialog(false);
    setComplementSuspensionDialog(false);
  };

  return (
    <>
      <DialogMedium
        open={viewCollectiveActivity}
        maxWidth="md"
        fullWidth={true}
        title="Visualização da Atividade Coletiva"
        handleClose={handleClose}
      >
        <DynamicFeedback data={collectiveActivity} shape={shapeFeedback} />
      </DialogMedium>
      <DialogMedium
        open={complementDialog}
        maxWidth="md"
        fullWidth={true}
        title={attendance?.patient?.name}
        handleClose={handleClose}
      >
        <ComplementCollectiveActivity
          collectiveActivity={collectiveActivity}
          attendance={attendance}
          handleClose={handleClose}
        />
      </DialogMedium>
      <DialogMedium
        open={complementSuspensionDialog}
        maxWidth="md"
        fullWidth={true}
        title={attendance?.patient?.name}
        handleClose={handleClose}
      >
        <Grid
          container
          spacing={1}
          component="form"
          onSubmit={complementSuspensionForm.handleSubmit(
            onSubmitComplementSuspension
          )}
        >
          <Grid item xs={12}>
            <TextField
              control={complementSuspensionForm.control}
              name="suspended_reason"
              label="Motivo da suspensão do complemento:"
              multiline
              minRows={3}
            />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="end">
            <Button type="submit" variant="contained">
              Salvar
            </Button>
          </Grid>
        </Grid>
      </DialogMedium>
      <DialogMedium
        open={suspensionDialog}
        maxWidth="md"
        fullWidth={true}
        title={attendance?.patient?.name}
        handleClose={handleClose}
      >
        <Grid
          container
          spacing={1}
          component="form"
          onSubmit={suspensionForm.handleSubmit(onSubmitSuspension)}
        >
          <Grid item xs={12}>
            <TextField
              control={suspensionForm.control}
              name="suspended_reason"
              label="Motivo da suspensão:"
              multiline
              minRows={3}
            />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="end">
            <Button type="submit" variant="contained">
              Salvar
            </Button>
          </Grid>
        </Grid>
      </DialogMedium>
      <PatientsCard
        patient={attendance}
        handleClick={() => null}
        old={false}
        history={attendance.history}
        onDialog
        hideOptions
      />
      <Divider variant="fullWidth" sx={{ my: 2 }} />
      <Grid container spacing={2}>
        {!collectiveActivitiesItems.length && (
          <Grid xs={12} display="flex" justifyContent="center" my={1.5}>
            <Typography color={theme.palette.secondary.main}>
              Não há atividades coletivas pra este paciente
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Accordion defaultOpen={false} configs={collectiveActivitiesItems} />
        </Grid>
      </Grid>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          count={collectiveActivitiesQuery.data?.totalItems || 0}
          component="div"
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 40, 75]}
          onRowsPerPageChange={(event) => {
            setLimit(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </Box>
    </>
  );
}
