import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import formatPhoneNumber from "../utils/formatPhoneNumber";
import convertDateToBirthday from "../utils/convertDateToBirthday";
import {
  ANDROID_QR,
  APPLE_QR,
  HEALTH_CITY_SP_LOGO,
  SM_LOGO,
  SP_LOGO,
  SUS_LOGO,
  SUS_SP_LOGO,
  WEB_QR,
} from "./pdfSources";

function logoSPSUS() {
  return {
    margin: [-30, 0, 0, 0],
    svg: SUS_SP_LOGO,
  };
}

const getUserData = () => {
  const userData = JSON.parse(localStorage.getItem("user-data"));
  return userData;
};

const header = (company) => {
  let currentCompany = company;

  if (!company) {
    const userData = getUserData();
    currentCompany = userData.company;
  } else if (company.virtual) {
    currentCompany = company.reference_company;
  }

  return {
    header: {
      margin: [30, 20, 40, 40],
      fontSize: 10,
      columnGap: 5,
      columns: [
        {
          type: "none",
          margin: [-5, 0, 0, 0],
          ul: [
            {
              image: "santaMarcelina",
              width: 130,
              height: 35,
              margin: [5, 0, 0, 5],
            },
            {
              margin: [-5, 0, 0, 0],
              text: currentCompany.corporate_name,
              bold: true,
            },
          ],
        },
        {
          image: "sus",
          width: 50,
          height: 25,
          margin: [0, 5, 0, 0],
        },
        {
          image: "sp",
          width: 70,
          height: 35,
        },
      ],
    },
    images: {
      santaMarcelina: SM_LOGO,
      sus: SUS_LOGO,
      sp: SP_LOGO,
    },
  };
};

const sign = (employee, uuid, signatureValidationPath) => {
  let currentEmployee = getUserData();

  if (employee) {
    currentEmployee = {
      ...employee,
      council: {
        description: employee.employee_Type.council.description,
        council_registration_number: employee.council_registration_number,
      },
    };
  }

  const signTemplate = {
    columns: [
      {
        margin: [0, 0, -100, 0],
        width: 425,
        stack: [
          {
            canvas: [
              {
                type: "line",
                x1: 140,
                y1: -70,
                x2: 400,
                y2: -70,
                lineWidth: 1,
              },
            ],
          },
          {
            margin: [0, -60, 0, 30],
            alignment: "center",
            type: "none",
            ul: [
              { text: currentEmployee?.name, bold: true },
              { text: currentEmployee?.specialty?.describe },
              {
                text: !!currentEmployee?.council
                  ? `${currentEmployee?.council?.description} ${currentEmployee?.council?.council_registration_number}`
                  : "Não informado",
              },
            ],
          },
        ],
      },
    ],
  };
  if (uuid) {
    const url = `${window.QRCODES_URL.replace(/\/$/, "")}${
      signatureValidationPath
        ? `/tportal/validate/${uuid}/types/${signatureValidationPath}`
        : `/tportal/validate/${uuid}`
    }`;

    signTemplate.columns.push({
      margin: [0, -135, 0, 0],
      width: 115,
      qr: url,
      fit: "160",
      version: 12,
      eccLevel: "H",
    });

    signTemplate.columns.push(
      writeRotatedText({
        text: [
          `Este documento é cópia do original, assinado digitalmente por ${currentEmployee?.name}.`,
          `Para conferir o original acesse o site https://validar.iti.gov.br, e utilize o Validar (QRcode) disponível no documento e insira os primeiros quatro dígitos do seu CPF.`,
        ],
        fontSize: "8px",
        rotate: "-90deg",
        absolutePosition: { x: 2, y: 90 },
      })
    );
  }

  return signTemplate;
};

const footer = (
  date,
  hasSignature,
  employee,
  company,
  uuid,
  margin = [],
  signatureValidationPath
) => {
  let currentCompany = company;

  if (!company) {
    const userData = getUserData();
    currentCompany = userData.company;
  } else if (company.virtual) {
    currentCompany = company.reference_company;
  }

  const footerTemplate = {
    margin,
    stack: [
      {
        text: format(date ? parseISO(date) : new Date(), "dd 'de' LLLL 'de' yyyy", {
          locale: ptBR,
        }),
        margin: [430, 0, 0, 20],
      },
      {
        canvas: [{ type: "line", x1: 40, y1: -15, x2: 540, y2: -15, lineWidth: 1 }],
      },
      {
        margin: [30, 0, 40, 10],
        columns: [
          {
            type: "none",
            ul: [
              {
                text: currentCompany.corporate_name,
                bold: true,
                margin: [0, 0, 0, 5],
              },
              {
                text: `${currentCompany.street}${
                  currentCompany.address_number ? `, ${currentCompany.address_number}` : ""
                }`,
              },
              { text: currentCompany.county },
              { text: `${currentCompany.uf} - ${currentCompany.address_cep}` },
            ],
          },
          {
            type: "none",
            ul: [
              { text: "CONTATO", bold: true, margin: [0, 0, 0, 5] },
              {
                text: `Telefone: ${
                  currentCompany.phone ? formatPhoneNumber(currentCompany.phone) : "Não informado"
                }`,
              },
            ],
          },
        ],
      },
    ],
  };

  if (hasSignature) {
    const signature = sign(employee, uuid, signatureValidationPath);
    footerTemplate.stack.unshift(signature);
  }

  return footerTemplate;
};

const title = (title) => ({
  text: title.toUpperCase(),
  style: "header",
  bold: true,
  fontSize: 20,
  alignment: "center",
  margin: [0, 0, 0, 30],
});

const patientInformations = (patient) => ({
  margin: [-10, 0, 0, 0],
  columnGap: -40,
  columns: [
    {
      type: "none",
      ul: [
        {
          text: [
            { text: "Paciente: ", bold: true },
            {
              text: patient.social_prioritize
                ? patient.social_name
                  ? patient.social_name.toUpperCase()
                  : "Não informado"
                : patient.name
                ? patient.name.toUpperCase()
                : "Não informado",
            },
          ],
          margin: [0, 0, 0, 5],
        },
        {
          text: [
            { text: "Cód. Paciente: ", bold: true },
            {
              text: patient?.number || "Não informado",
            },
          ],
          margin: [0, 0, 0, 5],
        },
        {
          text: [
            { text: "Data de nascimento: ", bold: true },
            {
              text: patient?.birth_date
                ? format(parseISO(patient.birth_date), "dd/MM/yyyy ")
                : "Não informado",
            },
          ],
          margin: [0, 0, 0, 5],
        },
        {
          text: [{ text: "Sexo: ", bold: true }, { text: patient?.gender || "Não informado" }],
          margin: [0, 0, 0, 5],
        },
      ],
    },
    {
      type: "none",
      ul: [
        {
          text: [
            { text: "CPF: ", bold: true },
            { text: patient?.physic_national || "Não informado" },
          ],
          margin: [0, 0, 0, 5],
        },
        {
          text: [{ text: "CNS: ", bold: true }, { text: patient?.sus_card || "Não informado" }],
          margin: [0, 0, 0, 5],
        },
      ],
    },
  ],
});

function createElement(config) {
  const cellColor = "#e0e0e0";

  const types = {
    text: (value, rowMargin, _, unused, fontSize) => ({
      text: value,
      margin: rowMargin,
      fontSize,
    }),
    line: (value, rowMargin) => ({
      margin: rowMargin,
      layout: {
        paddingTop: () => 1,
        paddingBottom: () => 0,
        hLineWidth: (i, node) => (i === node.table.widths.length ? 1 : 0),
        vLineWidth: () => 0,
      },
      table: {
        widths: ["*"],
        body: [[value]],
      },
    }),
    number: (value, rowMargin, width, height) => ({
      margin: rowMargin,
      layout: {
        paddingTop: () => 1,
        paddingBottom: () => 0,
        hLineWidth: (i, node) => (i === 0 ? 0 : 1),
        vLineWidth: () => 1,
      },
      table: {
        widths: value.map(() => width),
        heights: [height || 4],
        body: [value],
      },
    }),
    date: (value, rowMargin, width, height) => {
      const date = value ? value.split("/") : ["", "", ""];

      return {
        margin: rowMargin,
        layout: {
          paddingTop: () => 1,
          paddingBottom: () => 0,
          hLineWidth: (i, node) => (i === 0 ? 0 : 1),
          vLineWidth: () => 1,
        },
        table: {
          widths: date.map(() => width || 10),
          heights: [height || 4],
          body: [date],
        },
      };
    },
    time: (value, rowMargin, width, height) => {
      const date = value ? value.split(":") : ["", ""];

      return {
        margin: rowMargin,
        layout: {
          paddingTop: () => 1,
          paddingBottom: () => 0,
          hLineWidth: (i, node) => (i === 0 ? 0 : 1),
          vLineWidth: () => 1,
        },
        table: {
          widths: date.map(() => width || 10),
          heights: [height || 4],
          body: [date],
        },
      };
    },
    none: (value) => value,
  };

  const labels = config.labels.map(({ title, name, fill, margin, rowMargin, rows, fontSize }) => {
    const newRows = !rows
      ? []
      : rows.map(({ type, value, width, height, fontSize }, index, array) => {
          let defaultRowMargin = rowMargin ? rowMargin : [0, 0, 0, 3];

          if (index === array.length - 1) {
            defaultRowMargin = [0, 0, 0, 1];
          }

          return types[type](value, defaultRowMargin, width, height, fontSize);
        });

    return {
      fillColor: fill || title ? cellColor : "#FFFFFF",
      margin: margin,
      stack: [
        {
          text: title || name,
          fontSize,
          bold: true,
          margin: title ? [2, -1, 0, -1] : [0, 0, 0, 5],
        },
        ...newRows,
      ],
    };
  });

  return {
    width: config.widths.reduce((previous, current) => previous + current),
    margin: config.margin,
    layout: config.noBorders
      ? "noBorders"
      : {
          hLineWidth: (i, node) => (i === 0 || i === node.table.body.length ? 1 : -2),
          vLineWidth: (i, node) => (i === 0 || i === labels.length ? 1 : 0),
        },
    table: {
      widths: config.widths,
      heights: [config.height],
      body: [labels],
    },
  };
}

const newHeader = (title, patient, company) => {
  return {
    header: {
      margin: [30, 20, 20, 0],
      stack: [
        {
          columns: [
            {
              margin: [0, -30, 0, 0],
              svg: HEALTH_CITY_SP_LOGO,
            },
            {
              text: `Prefeitura da Cidade de São Paulo
                        Secretaria Municipal da Saúde`,
              fontSize: 14,
              bold: true,
            },
            {
              image: "sus",
              width: 50,
              height: 25,
              margin: [0, 5, 0, 0],
            },
          ],
        },
        newTitle(title, company),
        infoContactPatient(patient),
      ],
    },
    images: {
      sus: SUS_LOGO,
    },
  };
};

const newTitle = (title, company) => {
  let currentCompany = company;

  if (!company) {
    const userData = getUserData();
    currentCompany = userData.company;
  } else if (company.virtual) {
    currentCompany = company.reference_company;
  }

  return {
    margin: [0, -20, 0, 0],
    columns: [
      {
        table: {
          body: [
            [
              {
                table: {
                  heights: [20, 20, 10, 10, 10],
                  widths: [1, 180, 1],
                  border: [0, 0, 0, 0],
                  body: [
                    [
                      "",
                      {
                        text: title,
                        fontSize: 26,
                        bold: true,
                        margin: [20, 0, 0, 0],
                      },
                      "",
                    ],
                    [
                      {
                        text: "Duas vias se antimicrobiano e/ou medicamentos sujeitos a controle especial",
                        colSpan: 3,
                      },
                      "",
                      "",
                    ],
                    [
                      {
                        text: "n°SINAN/eSUS notifica: ",
                        colSpan: 3,
                        border: [1, 1, 1, 0],
                      },
                      "",
                      "",
                    ],
                    [
                      { text: "", border: [1, 0, 0, 0] },
                      {
                        text: "",
                        border: [0, 0, 0, 1],
                      },
                      { text: "", border: [0, 0, 1, 0] },
                    ],
                    [
                      {
                        text: "(quando necessário)",
                        colSpan: 3,
                        border: [1, 0, 1, 1],
                      },
                      "",
                      "",
                    ],
                  ],
                },
                layout: { defaultBorder: false },
              },
            ],
          ],
        },
        layout: { defaultBorder: false },
      },
      {
        margin: [-50, -2, 0, 0],
        table: {
          body: [
            [
              {
                table: {
                  heights: [40, 35, 10],
                  widths: [1, 266, 1],
                  body: [
                    ["", "", ""],
                    [
                      {
                        text: `${currentCompany.name} || ${currentCompany.street},n° ${
                          currentCompany.address_number || ""
                        }, ${currentCompany.county} - ${currentCompany.uf} | CEP: ${
                          currentCompany.address_cep
                        } - Telefone: ${formatPhoneNumber(currentCompany.phone)}`,
                        bold: true,
                        alignment: "center",
                        rowSpan: 2,
                        colSpan: 3,
                        border: [1, 1, 1, 0],
                      },
                      "",
                      "",
                    ],
                    ["", "", ""],
                    [
                      {
                        text: "(identificação do estabelecimento de saúde)",
                        colSpan: 3,
                        border: [1, 0, 1, 1],
                        margin: [30, 0, 0, 0],
                      },
                      "",
                      "",
                    ],
                  ],
                },
                layout: "noBorders",
              },
            ],
          ],
        },
      },
    ],
  };
};

const infoContactPatient = (patient) => {
  const masc = patient.gender === "Masculino" ? `x` : `  `;
  const fem = patient.gender === "Feminino" ? `x` : `  `;
  const igr = patient.gender !== "Feminino" && patient.gender !== "Masculino" ? `x` : `  `;
  return {
    margin: [5, 0, 0, 0],
    table: {
      body: [
        [
          {
            margin: [-22, 0, 0, 0],
            table: {
              widths: [75, 256, 35, 120],
              body: [
                [
                  { text: "Nome:", alignment: "right" },
                  {
                    text: patient.name,
                    border: [0, 0, 0, 1],
                  },
                  { text: "idade:" },
                  {
                    text: `${convertDateToBirthday(patient.birth_date, ["years"])} - ${format(
                      parseISO(patient.birth_date),
                      "dd/MM/yyyy"
                    )}`,
                    border: [0, 0, 0, 1],
                    alignment: "left",
                  },
                ],
                [
                  { text: "Nome Social:", alignment: "right" },
                  { text: patient.social_name, border: [0, 0, 0, 1] },
                  { text: "Sexo:" },
                  { text: `(${fem})F (${masc})M (${igr})Ignorado` },
                ],
                [
                  { text: `Endereço:`, alignment: "right" },
                  {
                    text: patient.contact
                      ? `${patient.contact.street || ""} ${
                          patient.contact.address_number
                            ? `n° ${patient.contact.address_number}`
                            : ""
                        } ${patient.contact.county ? `, ${patient.contact.county}` : ""}${
                          patient.contact.state ? ` - ${patient.contact.state}` : ""
                        } ${patient.contact.cep ? `CEP: ${patient.contact.cep}` : ""}`
                      : "Não informado",
                    colSpan: 3,
                    border: [0, 0, 0, 1],
                  },
                  "",
                  "",
                ],
              ],
            },
            layout: { defaultBorder: false },
          },
        ],
      ],
    },
  };
};

const styleField = () => {
  return {
    canvas: [
      {
        type: "rect",
        x: 5,
        y: 0,
        w: 506,
        h: 60,
        r: 5,
        lineColor: "black",
      },
      {
        type: "rect",
        x: 45,
        y: 21,
        w: 275,
        h: 0.1,
        r: 5,
        lineColor: "black",
      },
      {
        type: "rect",
        x: 220,
        y: -128,
        w: 290,
        h: 125,
        r: 5,
        lineColor: "black",
      },
      {
        type: "rect",
        x: 70,
        y: 38,
        w: 249,
        h: 0.1,
        r: 5,
        lineColor: "black",
      },
      {
        type: "rect",
        x: 58,
        y: 55,
        w: 435,
        h: 0.1,
        r: 5,
        lineColor: "black",
      },
      {
        type: "rect",
        x: 360,
        y: 21,
        w: 130,
        h: 0.1,
        r: 5,
        lineColor: "black",
      },
    ],
  };
};

const identUser = (employee) => {
  return {
    margin: [42, 0, 0, 0],
    table: {
      body: [
        [
          {
            table: {
              widths: [100, 220, 56, 90],
              body: [
                [
                  {
                    text: "IDENTIFICAÇÃO DO USUÁRIO OU RESPONSÁVEL(PREENCHIDO PELA FARMÁCIA)",
                    colSpan: 4,
                    margin: [55, 0, 0, 0],
                  },
                  0,
                  0,
                  0,
                ],
                [
                  { text: "Nome (Nome Social):" },
                  { text: "", border: [0, 0, 0, 1] },
                  { text: "RG ou CPF:" },
                  { text: "", border: [0, 0, 0, 1] },
                ],
                [
                  { text: "Endereço:", alignment: "right" },
                  { text: "", border: [0, 0, 0, 1] },
                  { text: "Tel:", alignment: "right" },
                  { text: "", border: [0, 0, 0, 1] },
                ],
              ],
            },
            layout: { defaultBorder: false },
          },
        ],
      ],
    },
  };
};

const describeFooter = () => {
  return {
    margin: [35, 0, 0, 0],
    table: {
      widths: [220, 15, 60, 15, 60, 15, 60],
      body: [
        [
          `Consulte aqui a disponibilidade aonde retirar o(s) medicamento(s) nas farmácias públicas municipais.`,
          {
            margin: [0, 20, 0, 0],
            svg: APPLE_QR,
          },
          {
            qr: "https://apps.apple.com/br/app/e-saudesp/id1534036982",
            fit: "60",
            version: 10,
            eccLevel: "H",
          },
          {
            margin: [0, 20, 0, 0],
            svg: ANDROID_QR,
          },
          {
            qr: "https://play.google.com/store/apps/details?id=br.com.duosystem.avancasaude.sp.prod&hl=pt_BR&gl=US",
            fit: "60",
            version: 10,
            eccLevel: "H",
          },
          {
            margin: [0, 20, 0, 0],
            svg: WEB_QR,
          },
          {
            qr: "https://e-saudesp.prefeitura.sp.gov.br/#/",
            fit: "60",
            version: 10,
            eccLevel: "H",
          },
        ],
      ],
    },
    layout: { defaultBorder: false },
  };
};

const locations = (date, hasSignature, employee, uuid) => {
  let signature = [];

  if (hasSignature) {
    let currentEmployee = getUserData();
    if (employee) {
      currentEmployee = {
        ...employee,
        council: {
          description: employee.employee_Type.council.description,
          council_registration_number: employee.council_registration_number,
        },
      };
    }

    const url = `${window.QRCODES_URL.replace(/\/$/, "")}/tportal/validate/${uuid}`;

    signature = [
      [
        {
          canvas: [
            {
              type: "rect",
              x: 0,
              y: 100,
              w: 195,
              h: 0.1,
              r: 5,
              lineColor: "black",
            },
          ],
        },
        {
          margin: [-20, 5, 0, 0],
          alignment: "center",
          type: "none",
          ul: [
            { text: currentEmployee?.name, bold: true },
            { text: currentEmployee?.specialty?.describe },
            {
              text: !!currentEmployee?.council
                ? `${currentEmployee?.council?.description} ${currentEmployee?.council?.council_registration_number}`
                : "Não informado",
            },
          ],
        },
      ],
      [
        {
          qr: url,
          fit: "160",
          version: 12,
          eccLevel: "H",
        },
        writeRotatedText({
          text: [
            `Este documento é cópia do original, assinado digitalmente por ${currentEmployee?.name}.`,
            `Para conferir o original acesse o site https://validar.iti.gov.br, e utilize o Validar (QRcode) disponível no documento e insira os primeiros quatro dígitos do seu CPF.`,
          ],
          fontSize: "8px",
          rotate: "-90deg",
          absolutePosition: { x: 2, y: 80 },
        }),
      ],
    ];
  }

  return {
    margin: [35, -65, 0, 0],
    table: {
      widths: [160, 220, 115],
      body: [
        [
          [
            {
              margin: [0, 88, 0, 0],
              table: {
                widths: [57, 60],
                body: [
                  [
                    { text: "São Paulo," },
                    {
                      text: format(date ? parseISO(date) : new Date(), "dd'/'MM'/'yyyy", {
                        locale: ptBR,
                      }),
                      border: [0, 0, 0, 1],
                    },
                  ],
                ],
              },
              layout: { defaultBorder: false },
            },
          ],
          ...signature,
        ],
      ],
    },
    layout: { defaultBorder: false },
  };
};

const headerHorizontalStyle = (company) => {
  let currentCompany = company;

  if (!company) {
    const userData = getUserData();
    currentCompany = userData.company;
  } else if (company.virtual) {
    currentCompany = company.reference_company;
  }

  return {
    header: {
      margin: [140, 20, 40, 40],
      table: {
        body: [
          [
            {
              image: "santaMarcelina",
              width: 130,
              height: 35,
              fit: [110, 100],
              margin: [5, 0, 15, 5],
            },
            {
              image: "sus",
              width: 50,
              height: 25,
              fit: [50, 50],
              margin: [0, 5, 15, 0],
            },
            {
              image: "sp",
              width: 70,
              fit: [100, 100],
              height: 35,
            },
          ],
          [
            {
              margin: [-100, 0, 0, 0],
              text: currentCompany.corporate_name,
              bold: true,
              colSpan: 2,
            },
            "",
            "",
          ],
        ],
      },
      layout: "noBorders",
    },
    images: {
      santaMarcelina: SM_LOGO,
      sus: SUS_LOGO,
      sp: SP_LOGO,
    },
  };
};

function writeRotatedText({ text, fontSize, rotate, color, ...settings }) {
  const svg = document.createElement("svg");
  const svgText = document.createElement("text");

  svg.style.overflow = "visible";
  svg.style.transform = `rotate(${rotate ?? 0})`;
  svgText.x = "5";
  svgText.y = "15";
  svgText.style.font = `normal ${fontSize} Roboto`;
  svgText.style.fill = color ?? "#000";

  if (Array.isArray(text)) {
    svgText.innerHTML = text.reduce((content, text) => {
      const tspan = document.createElement("tspan");
      tspan.setAttribute("x", "0");
      tspan.setAttribute("dy", "1.2em");
      tspan.innerText = text;

      return content + tspan.outerHTML;
    }, "");
  } else {
    svgText.innerHTML = text;
  }

  svg.appendChild(svgText);

  return {
    svg: svg.outerHTML,
    ...settings,
  };
}

export {
  header,
  footer,
  title,
  patientInformations,
  createElement,
  getUserData,
  newHeader,
  newTitle,
  infoContactPatient,
  styleField,
  locations,
  identUser,
  describeFooter,
  logoSPSUS,
  headerHorizontalStyle,
  writeRotatedText,
};
