import { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import {
  openURL,
  medicalStatementPDF,
  attendanceStatementPDF,
  followUpStatementPDF,
  againstReferencePDF,
  medicalReportPDF,
  medicalOpinionPDF,
  maternityLeavePDF,
  capsPDF,
} from "../../pdfModels";
import DialogMedium from "../../helper/DialogMedium";
import { Box, Button } from "@mui/material";
import { getUserData } from "../../pdfModels/pdfComponents";

function PrintDocsDialog({ open, handleClose, odonto, ceo }) {
  const {
    declarations,
    againstReference,
    signedDocuments,
    soapDocumentData,
    patientData,
  } = useContext(AppContext);
  const { documents, against_references } = signedDocuments;
  const transferUBSRequest = "4318ef76-8340-48dc-ba45-c7bd6fd3f513"; // Encaminhamento UBS
  const userData = getUserData();

  function printDeclarations(declaration) {
    if (declaration.type === "Comparecimento")
      attendanceStatementPDF(declaration, patientData).open();
    if (declaration.type === "Atestado")
      medicalStatementPDF(declaration, patientData, odonto, ceo).open();
    if (declaration.type === "Acompanhamento")
      followUpStatementPDF(declaration, patientData).open();
    if (declaration.type === "Relatorio")
      medicalReportPDF(declaration, patientData).open();
    if (declaration.type === "Laudo")
      medicalOpinionPDF(declaration, patientData).open();
    if (declaration.type === "AtestadoMaternidade")
      maternityLeavePDF(declaration, patientData).open();
  }

  function printCounterReference(reference) {
    againstReferencePDF(
      reference,
      patientData,
      reference?.cids_object || []
    ).open();
  }

  function printCapsReport(reference) {
    if (!!userData.caps) {
      capsPDF(reference, patientData, soapDocumentData).open();
    }
  }

  return (
    <DialogMedium
      open={open}
      fullWidth={true}
      maxWidth="lg"
      title={"Imprimir documento"}
      handleClose={handleClose}
    >
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        {againstReference?.map((reference, index) => {
          const signedReference = against_references[index].signed_document;

          if (reference.id_specialty === transferUBSRequest) {
            return (
              <Button
                variant="outlined"
                onClick={() => {
                  printCapsReport(reference);
                }}
              >
                Relatório de referência e Contrarreferência - SAÚDE MENTAL{" "}
                {index}
              </Button>
            );
          }

          return (
            <Button
              variant="outlined"
              onClick={() => {
                if (signedReference.result) {
                  openURL(signedReference.result);
                } else {
                  printCounterReference(reference);
                }
              }}
            >
              Referência / Contrarreferência {index + 1}
            </Button>
          );
        })}
        {declarations?.map((declaration, index) => (
          <Button
            variant="outlined"
            onClick={() => {
              const signedDeclaration = documents[index].signed_document;

              if (signedDeclaration.result) {
                openURL(signedDeclaration.result);
              } else {
                printDeclarations(declaration);
              }
            }}
          >
            {declaration.type} {index + 1}
          </Button>
        ))}
      </Box>
    </DialogMedium>
  );
}

export default PrintDocsDialog;
