import { Box, Typography } from "@mui/material";
import { PaginatedScrollList } from "../../../../components/PaginatedScrollList";
import ThriftHistoryCard from "./Card";

export default function ThriftHistory({
  handleViewHistory,
  data,
  handlePage,
  page,
  totalPage,
}) {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      rowGap={"5px"}
      width={"100%"}
    >
      <Typography fontSize={15} fontWeight={"bold"} color={"primary"}>
        Histórico de Análises
      </Typography>
      <PaginatedScrollList
        hasDivider={false}
        data={data}
        page={page}
        handlePage={handlePage}
        totalPage={totalPage}
        emptyMessage="Nenhuma analise anterior encontrada,"
        maxHeight="255px"
        component={(item, index) => (
          <ThriftHistoryCard
            key={index}
            {...item}
            handleView={handleViewHistory}
          />
        )}
      />
    </Box>
  );
}
