import { Button, IconButton, Popover, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { GridFilterListIcon } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { AddCircle, ClearAll, CheckBox } from "@mui/icons-material";
import FilterInput from "./FilterInput";
import mapObject from "../../../utils/mapObject";
import { v4 as uuidv4 } from "uuid";
import { useDebounceCallback } from "../../../hooks";

export default function HeaderActions({
  options = [],
  handleFilter,
  headerActions = [],
  checkAllAction,
  noFilter,
  handleCheckAll,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filters, setFilters] = React.useState({});

  const actions = [...headerActions];

  if (!noFilter) {
    actions.push({
      title: "Limpar Filtros",
      icon: <ClearAll />,
      handler: () => {
        setFilters({});
        debounceSearch({});
      },
    });
  }

  if (checkAllAction) {
    actions.push({
      title: "Marcar/Desmarcar todos",
      icon: <CheckBox />,
      handler: handleCheckAll,
    });
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (!Object.keys(filters).length) {
      setInputList([
        <FilterInput
          options={options}
          handleSearch={handleSearch}
          key={uuidv4()}
          fieldKey={uuidv4()}
          removeInput={removeInput}
        />,
      ]);
      return;
    }
    let inputsInfo = Object.keys(filters).map((key) => {
      let column = options.findIndex(
        (op) =>
          op.field === Object.keys(filters[key])[0] ||
          op.filter === Object.keys(filters[key])[0]
      );
      let value = filters[key][Object.keys(filters[key])[0]];
      return { key, column, value };
    });
    setInputList(
      inputsInfo.map((i) => (
        <FilterInput
          options={options}
          handleSearch={handleSearch}
          key={i.key}
          fieldKey={i.key}
          column={i.column}
          value={i.value}
          removeInput={removeInput}
        />
      ))
    );
  };

  const removeInput = (inputToremove) => {
    setInputList((curr) => {
      if (curr.length > 1) {
        return curr.filter((i) => i.props?.fieldKey !== inputToremove);
      } else {
        return curr;
      }
    });
    setFilters((curr) => {
      const { [inputToremove]: _, ...rest } = curr;
      return rest;
    });
  };
  const [inputList, setInputList] = React.useState([
    <FilterInput
      options={options}
      handleSearch={handleSearch}
      key={uuidv4()}
      fieldKey={uuidv4()}
      removeInput={removeInput}
    />,
  ]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const debounceSearch = useDebounceCallback(
    (nextValue) => handleFilter && handleFilter(nextValue),
    700
  );

  useEffect(() => {
    if (!anchorEl) return;
    debounceSearch(
      mapObject(filters, (i) => i).reduce(function (result, current) {
        return Object.assign(result, current);
      }, {})
    );
  }, [filters]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function handleSearch(selectedColumn, value, key) {
    setFilters((curr) => ({
      ...curr,
      [key]: {
        [selectedColumn.filter || selectedColumn.field]: selectedColumn?.mask
          ? value.replace(/[^a-zA-Z0-9]/g, "")
          : value,
      },
    }));
  }

  function addInput() {
    if (inputList.length < options.length) {
      const hasFilter = mapObject(filters, (value) => {
        return Object.keys(value)[0];
      });
      setInputList((curr) => [
        ...curr,
        <FilterInput
          options={options.filter(
            (o) =>
              !(hasFilter.includes(o.filter) || hasFilter.includes(o.field))
          )}
          handleSearch={handleSearch}
          key={uuidv4()}
          fieldKey={uuidv4()}
          removeInput={removeInput}
        />,
      ]);
    }
  }

  return (
    <Box
      sx={{
        width: "100%",
        padding: `${noFilter ? "12px" : "6px"} 12px`,
        display: "flex",
        gap: "10px",
        color: "white",
        justifyContent: "space-between",
        backgroundColor: (theme) => theme.palette.primary.light,
      }}
    >
      {!noFilter ? (
        <>
          <Button
            aria-describedby={id}
            onClick={handleClick}
            startIcon={<GridFilterListIcon />}
            sx={{
              color: "white",
            }}
          >
            Filtrar
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            PaperProps={{
              sx: {
                paddingTop: "15px",
                display: "grid",
                gap: "10px",
              },
            }}
            all
          >
            {inputList}
            <Button
              variant="text"
              disabled={!(inputList.length < options.length)}
              fullWidth
              onClick={addInput}
            >
              {inputList.length < options.length ? <AddCircle /> : null}
            </Button>
          </Popover>
        </>
      ) : null}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "6px",
          justifyContent: "end",
          flex: 1,
        }}
      >
        {actions.map((action, index) => (
          <Tooltip title={action.title} key={`${action.title}-${index}`}>
            <IconButton
              onClick={action.handler}
              sx={{
                color: "white",
              }}
              disabled={action.disabled}
            >
              {action.icon}
            </IconButton>
          </Tooltip>
        ))}
      </Box>
    </Box>
  );
}
