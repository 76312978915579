import React, { useContext, useState } from "react";
import { Typography, useTheme } from "@mui/material";
import { AppContext } from "../../../contexts/AppContext";
import { DialogMedium } from "../../../helper";
import usePrescriptions from "../../../service/usePrescriptions";
import { useQuery } from "react-query";
import useNotifier from "../../../hooks/useNotifier";
import { PaginatedScrollList } from "../../../components/PaginatedScrollList";
import PrescriptionCard from "./PrescriptionCard";
import TableFilter from "../../../components/Table/TableFilter";
import { format, parseISO } from "date-fns";
import { useConfirm } from "../../../helper/BlockNavigation";

function PrescriptionList({
  type = "medications",
  patient,
  setPrescriptionItems,
  canCopy,
  setActiveTab,
}) {
  const theme = useTheme();
  const notify = useNotifier();
  const [page, setPage] = useState(0);
  const [prescriptions, setPrescriptions] = useState([]);
  const [prescription, setPrescription] = useState(null);
  const { patientData } = useContext(AppContext);
  const { confirm } = useConfirm();

  const { getPrescriptions } = usePrescriptions();

  const prescriptionsQuery = useQuery(
    ["prescriptions", [patient?.id, patientData.id, type, page]],
    () =>
      getPrescriptions(patient?.id || patientData.id, {
        page,
        limit: 5,
        filter: type,
      }),
    {
      onSuccess: (response) => {
        setPrescriptions((prescriptions) => prescriptions.concat(response.items));
      },
      onError: (error) => {
        notify(error.message, "error");
      },
    }
  );

  function handleCopy(data) {
    const message = `${data.employee_specialty.employee.name} - CRM - ${
      data.employee_specialty.employee.id_council_state
    } - ${data.employee_specialty.employee.council_registration_number} - ${format(
      parseISO(data.createdAt),
      "HH:mm'h'"
    )}`;

    confirm(`Você realmente deseja copiar " *${message}* "`, () => {
      setPrescriptionItems((items) => {
        const cleanedItems = data[type].map((item, index) => {
          let activeTab = 0;
          let prescriptionItem;

          if (type === "exams") {
            activeTab = 2;
            prescriptionItem = {
              id: items.length + index + 1,
              type: item.type,
              describe: item.describe,
              notes: item.notes,
              segment_area: item.segment_area,
              diagnostic_hypothesis: item.diagnostic_hypothesis,
              procedure_type: item.procedure_type,
              procedure_id: item.procedure_id,
              notPreseted: true,
            };
          } else {
            if (type === "medications") {
              activeTab = 1;
            }

            prescriptionItem = {
              id: items.length + index + 1,
              name: item.name,
              quantity: item.quantity,
              presentation: item.presentation,
              special: item.special,
              posology: item.posology,
              notes: item.notes,
              medication_assisted_treatment: item.medication_assisted_treatment,
              treatment_cycle: item.treatment_cycle,
              tuss_code: item.procedure ? item.procedure.tuss_code : null,
              procedure_id: item.procedure_id,
              continuous_use: item.continuous_use,
              notPreseted: true,
            };
          }

          setActiveTab(activeTab);
          return prescriptionItem;
        });
        return items.concat(cleanedItems);
      });
    });
  }

  const tableColumns = {
    medications: [
      { name: "Medicamento", field: "name" },
      { name: "Quantidade", field: "quantity" },
      { name: "Apresentação", field: "presentation" },
      { name: "Posologia", field: "posology" },
      {
        name: "Uso contínuo",
        field: "continuous_use",
        use: (continuousUse) => (continuousUse ? "Sim" : "Não"),
      },
      { name: "Suspenso", field: "suspended", use: (suspended) => (suspended ? "Sim" : "Não") },
      { name: "Descrição do Medicamento", field: "notes" },
    ],
    medications_on_site: [
      { name: "Medicamento", field: "name" },
      { name: "Quantidade", field: "quantity" },
      { name: "Apresentação", field: "presentation" },
      { name: "Posologia", field: "posology" },
      { name: "Suspenso", field: "suspended", use: (suspended) => (suspended ? "Sim" : "Não") },
      { name: "Descrição do Medicamento", field: "notes" },
    ],
    exams: [
      { name: "Tipo", field: "type" },
      { name: "Exame", field: "describe" },
      { name: "Status", field: "status" },
      {
        name: "Suspenso em",
        field: "suspended_at",
        use: (suspendedAt) =>
          suspendedAt ? format(parseISO(suspendedAt), "dd/MM/yyyy") : "Não suspenso",
      },
      { name: "Observação", field: "notes" },
    ],
  };

  return (
    <>
      <DialogMedium
        title="Lista de Medicamentos"
        maxWidth="md"
        fullWidth={true}
        open={!!prescription}
        handleClose={() => setPrescription(null)}
      >
        <TableFilter
          columns={tableColumns[type]}
          rowStatus={(row) => (row.suspended ? "disabled" : "")}
          disableActions={(row, action) => row.suspended && action === "suspend"}
          data={prescription ? prescription[type] : []}
        />
      </DialogMedium>
      <Typography
        component="h3"
        sx={{
          fontSize: 16,
          fontWeight: 700,
          color: theme.palette.primary.light,
          mt: "20px",
          mb: "12px",
        }}
      >
        {type === "medications_on_site"
          ? "Histórico de medicações"
          : type === "medications"
          ? "Histórico de receituários"
          : "Histórico de exames"}
      </Typography>
      <PaginatedScrollList
        data={prescriptions}
        page={page}
        loading={prescriptionsQuery.isFetching}
        hasDivider={false}
        handlePage={setPage}
        totalPage={prescriptionsQuery.data?.total ?? 0}
        emptyMessage="Nenhuma prescritação encontrada"
        endMessage="Não há mais prescritões para este paciente"
        maxHeight="280px"
        minHeight="140px"
        sx={{
          gap: "20px",
          border: "1px solid " + theme.palette.primary.main,
          borderRadius: "10px",
          padding: "10px",
        }}
        component={(item) => (
          <PrescriptionCard
            key={item.id}
            type={type}
            data={item}
            canCopy={canCopy}
            handleClick={setPrescription}
            handleCopy={handleCopy}
          />
        )}
      />
    </>
  );
}

export default PrescriptionList;
