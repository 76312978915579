import React, { useState } from "react";
import Yup from "../../../config/yup";
import { Grid, Typography } from "@mui/material";
import TableFilter from "../../../components/Table/TableFilter";
import { usePresets, useProcedures } from "../../../service";
import { useMutation, useQueryClient } from "react-query";
import useNotifier from "../../../hooks/useNotifier";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  PaginatedAutocompleteField,
  SelectField,
  TextField,
} from "../../../components/FormFields";
import { useForm } from "react-hook-form";

function ExamsPreset({ type, data }) {
  const notify = useNotifier();
  const [exams, setExams] = useState(() => (type === "edit" ? data.data : []));

  const queryClient = useQueryClient();
  const { getProcedures } = useProcedures();
  const { postPresets, patchPreset } = usePresets();

  const postPresetsMutation = useMutation(postPresets);
  const patchPresetsMutation = useMutation(patchPreset);

  const validations = Yup.object().shape({
    preset_name: Yup.string().required("É requerido"),
    type: Yup.string().required("É requerido"),
    in_system: Yup.string(),
    describe: Yup.mixed().required("É requerido").nullable(),
    notes: Yup.string().nullable(),
  });

  const { control, watch, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(validations),
    defaultValues: {
      preset_name: type === "edit" ? data.describe : "",
      type: "",
      describe: null,
      notes: "",
      segment_area: "",
      diagnostic_hypothesis: "",
    },
  });

  const [presetName, examType] = watch(["preset_name", "type"]);

  const addPrescription = ({ preset_name, ...values }) => {
    const isNotGroup = !Array.isArray(values.describe);

    if (isNotGroup) {
      values.describe = [values.describe];
    }

    let groupCount = 1;

    exams.forEach((exam) => {
      if (groupCount <= exam.tab_id) {
        groupCount = exam.tab_id + 1;
      }
    });

    const groupedExams = values.describe.map((exam) => ({
      ...values,
      type: values.type,
      isRegulated: exam.type.includes("regulation"),
      procedure_id: exam.id,
      describe: exam.name,
      tab_id: groupCount,
      exam_payload: exam,
    }));

    setExams((prescriptions) => prescriptions.concat(groupedExams));

    setValue("type", null);
    reset({ preset_name });
  };

  const removePrescription = (index) => {
    const newPrescriptions = exams.filter((_, examIndex) => examIndex !== index);
    setExams(newPrescriptions);
  };

  const submitPresets = () => {
    const presetData = {
      describe: presetName,
      type: "exams",
      data: exams,
    };

    if (type === "edit") {
      patchPresetsMutation.mutate(
        { presetId: data.id, data: presetData },
        {
          onSuccess(response) {
            notify(response.message, "success");
            queryClient.invalidateQueries("presets");
          },
          onError(error) {
            notify(error.message, "error");
          },
        }
      );
    } else {
      postPresetsMutation.mutate(presetData, {
        onSuccess(response) {
          reset({ preset_name: "" });
          setExams([]);
          notify(response.message, "success");
          queryClient.invalidateQueries("presets");
        },
        onError(error) {
          notify(error.message, "error");
        },
      });
    }
  };

  const examTypes = [
    {
      label: "Exame laboratorial",
      value: "Laboratorial",
    },
    {
      label: "Exame de imagem",
      value: "Imagem",
    },
  ];

  const tableColumns = [
    { name: "Nome", field: "describe" },
    { name: "Tipo", field: "type" },
    {
      name: "Grupo",
      field: "tab_id",
      use: (tab_id) => tab_id ?? "Sem grupo",
    },
    { name: "Descrição", field: "notes" },
  ];

  return (
    <Grid container spacing={2} component="form" marginTop="0.5rem">
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <Typography fontSize={18} color="secondary" fontWeight="600">
            Dados da pré definição
          </Typography>
        </Grid>
        <Grid md={3} xs={4} item>
          <TextField name="preset_name" label="Nome da pré definição" control={control} required />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <Typography fontSize={18} color="secondary" fontWeight="600">
            Dados dos exames
          </Typography>
        </Grid>
        <Grid md={3} xs={4} item>
          <SelectField
            name="type"
            label="Tipo de exame"
            control={control}
            optionLabelKey="label"
            optionValueKey="value"
            options={examTypes}
            required
            customOnChange={(value) => {
              setValue("describe", value === "Laboratorial" ? [] : null);
            }}
          />
        </Grid>
        <Grid md={4} xs={5} item>
          <PaginatedAutocompleteField
            optionLabelKey="describe"
            name="describe"
            label="Exame"
            required
            multiple={examType === "Laboratorial"}
            control={control}
            filterKey="filters"
            refetchService={[examType]}
            queryKey="procedures"
            service={(params) =>
              getProcedures({
                ...params,
                type: examType === "Laboratorial" ? "lab" : "image",
                filter: "active",
              })
            }
            autoCompleteProps={{
              getOptionLabel: (data) => {
                let label = data?.name;

                if (data?.tuss_code) {
                  label = `${data?.tuss_code} - ${label}`;
                }

                return label;
              },
            }}
          />
        </Grid>
        <Grid md={7} xs={9} item>
          <TextField
            name="segment_area"
            label="AREA E/OU SEGMENTO ANATÔMICO A SER EXAMINADO:"
            control={control}
          />
        </Grid>
        <Grid md={7} xs={9} item>
          <TextField name="diagnostic_hypothesis" label="Hipotese Diagnóstica" control={control} />
        </Grid>
        <Grid md={7} xs={9} item>
          <TextField name="notes" label="Observação" control={control} />
        </Grid>
        <Grid item xs={3}>
          <Button onClick={handleSubmit(addPrescription)}>Adicionar exame</Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TableFilter
          data={exams}
          columns={tableColumns}
          noHeaderOptions
          actions
          actionsTypes={["delete"]}
          confirmMessage="Você realmente deseja *remover* esse exame?"
          actionHandleDelete={removePrescription}
        />
      </Grid>
      <Grid item xs={12}>
        <Button onClick={submitPresets} disabled={!exams.length || !presetName}>
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
}

export default ExamsPreset;
