import { Box, Grid, Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import { Link, useSearchParams } from "react-router-dom";
import {
  AutocompleteField,
  Button,
  PaginatedAutocompleteField,
  SwitchField,
  TextField,
} from "../../FormFields";
import { ListAlt, ModeEdit } from "@mui/icons-material";
import RegexOf from "../../../config/regex";
import { useDebounceCallback } from "../../../hooks";
import { useMicroArea, useSusCode } from "../../../service";

function TerritorialAddressForm({
  id,
  control,
  watch,
  userData,
  cepData,
  domiciliary,
  isEditing,
  isRejectTerms,
  setIsEditing,
  handleChangeField,
  setCanSearchDomicliary,
}) {
  const [search] = useSearchParams();
  const isHistoric = search.get("isHistoric");
  const { susCodeTerritorialPaths, getSusCodeByProperty } = useSusCode();
  const { getMicroArea } = useMicroArea();

  const debouncedSearch = useDebounceCallback((event) => {
    if (!!cepData.data?.data && watch("number") !== "" && watch("complement") !== "") {
      setCanSearchDomicliary(true);
    }
  }, 800);

  return (
    <>
      <Grid item xs={6}>
        <Typography variant="h5" color="secondary">
          Endereço
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            pt: 2,
            gap: "1rem",
          }}
        >
          {domiciliary?.is_fully_registered && (
            <Typography variant="p" color="secondary">
              Última edição: <br />
              {format(parseISO(domiciliary?.updatedAt), "dd/MM/yyyy")}
            </Typography>
          )}
          {domiciliary && !isHistoric && (
            <Link to={`/cds/territorial/historic/${domiciliary?.register_identifier}`}>
              <Button sx={{ textDecoration: "none" }}>
                <ListAlt /> Histórico
              </Button>
            </Link>
          )}
          {((id && !isEditing && !isHistoric) || (domiciliary && !isEditing && !isHistoric)) && (
            <Button onClick={() => setIsEditing(true)}>
              <ModeEdit /> Editar
            </Button>
          )}
        </Box>
      </Grid>

      <Grid item xs={4}>
        <PaginatedAutocompleteField
          control={control}
          label="Tipo de Imóvel"
          name="property_id"
          placeholder="Selecione"
          required
          disabled={!isEditing}
          service={(params) =>
            getSusCodeByProperty(susCodeTerritorialPaths.propertyTypes, { ...params })
          }
          queryKey={"property-id"}
          filterKey={"describe"}
          optionLabelKey="describe"
          customOnChange={(value) => handleChangeField("property_id", value)}
        />
      </Grid>
      <Grid item xs={4}>
        <PaginatedAutocompleteField
          control={control}
          label={"Tipo de Logradouro"}
          name="street_type"
          placeholder="Selecione"
          disabled={!isEditing}
          required={!isRejectTerms}
          service={(params) =>
            getSusCodeByProperty(susCodeTerritorialPaths.streetTypes, { ...params })
          }
          queryKey={"streety-type"}
          filterKey={"describe"}
          optionLabelKey="describe"
        />
      </Grid>

      <Grid item xs={4}>
        <PaginatedAutocompleteField
          control={control}
          label={"Microárea"}
          name="microarea_id"
          placeholder="Selecione"
          required
          disabled={!isEditing}
          service={(params) => getMicroArea({ ...params, company_id: userData?.company?.id })}
          queryKey={"microarea"}
          filterKey={"name"}
          optionLabelKey="name"
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          control={control}
          required={!isRejectTerms}
          label={"CEP"}
          name="cep"
          placeholder="00000-000"
          mask={RegexOf.cep}
          disabled={!isEditing}
        />
      </Grid>
      <Grid item xs={3}>
        <AutocompleteField
          control={control}
          label={"Bairro"}
          name={"neighborhood"}
          placeholder="Digite um CEP para carregar o bairro"
          optionLabelKey={"neighborhood"}
          optionCompareKey="id"
          required={!isRejectTerms}
          disabled
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          control={control}
          label={"Município"}
          name="county"
          placeholder="Município"
          disabled
          value="São Paulo"
        />
      </Grid>
      <Grid item xs={3}>
        <TextField control={control} label={"UF"} name="uf" placeholder="UF" disabled value="SP" />
      </Grid>
      <Grid item xs={4}>
        <TextField
          control={control}
          label={"Nome do logradouro"}
          name="street"
          placeholder="Rua ... "
          required={!isRejectTerms}
          disabled={!isEditing}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          control={control}
          label={"Número"}
          name="number"
          placeholder="00"
          disabled={!isEditing || watch("not_number")}
          required={!isRejectTerms}
          customOnChange={debouncedSearch}
          type="number"
          inputProps={{ minLength: 1, maxLength: 10, min: 1 }}
        />
      </Grid>
      <Grid item xs={1}>
        <SwitchField
          control={control}
          name={"not_number"}
          label={"S/N"}
          disabled={!isEditing}
          customOnChange={(value) => handleChangeField("not_number", value)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          control={control}
          label={"Complemento"}
          name="complement"
          placeholder="Complemento"
          disabled={!isEditing}
          customOnChange={debouncedSearch}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          control={control}
          label={"Ponto de Referência"}
          name="reference"
          placeholder="Ponto de referência"
          disabled={!isEditing}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          control={control}
          label={"Telefone Residêncial"}
          name={"phone"}
          placeholder="(00) 00000-0000"
          mask={RegexOf.phone}
          disabled={!isEditing}
          inputProps={{ maxLength: 15 }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          control={control}
          label={"Telefone Contato"}
          name={"cellphone"}
          placeholder="(00) 00000-0000"
          mask={RegexOf.phone}
          disabled={!isEditing}
          inputProps={{ maxLength: 15 }}
        />
      </Grid>
    </>
  );
}

export default TerritorialAddressForm;
