import React, { useContext, useEffect, useState } from "react";
import { PatientsCard, Tabs } from "../../../components";
import DrugPrescription from "./DrugPrescription";
import ExamPrescription from "./ExamPrescription";
import DrugList from "./DrugList";
import ExamList from "./ExamList";
import PrescriptionHistory from "./PrescriptionHistory";
import usePermissions from "../../../config/usePermissions";
import { AppContext } from "../../../contexts/AppContext";

function Prescription({
  attendance,
  allergies,
  initialTab = 0,
  tabsToShow = ["registers", "lists"],
  showPatient,
  isExternal = false,
}) {
  const drugPermissions = usePermissions("/soap/drugPrescription");
  const examsPermissions = usePermissions("/soap/examsPrescription");
  const { drugPrescriptions, setDrugPrescriptions, setExamPrescriptions } = useContext(AppContext);
  const [activeTab, setActiveTab] = useState(initialTab);
  const [drugOnSitePrescriptions, setDrugOnSitePrescriptions] = useState([]);

  useEffect(() => {
    return () => {
      setDrugPrescriptions([]);
      setExamPrescriptions([]);
    };
  }, []);

  const tabTypes = {
    registers: [
      {
        active: drugPermissions.create,
        label: "Medicamentos",
        content: (
          <DrugPrescription
            type="medications_on_site"
            attendance={attendance}
            allergies={allergies}
            isExternal={isExternal}
            drugPrescriptions={drugOnSitePrescriptions}
            setDrugPrescriptions={setDrugOnSitePrescriptions}
          />
        ),
      },
      {
        active: drugPermissions.create,
        label: "Receituário",
        content: (
          <DrugPrescription
            type="medications"
            attendance={attendance}
            allergies={allergies}
            isExternal={isExternal}
            drugPrescriptions={drugPrescriptions}
            setDrugPrescriptions={setDrugPrescriptions}
          />
        ),
      },
      {
        active: examsPermissions.create,
        label: "Prescrição de Exames",
        content: <ExamPrescription attendance={attendance} isExternal={isExternal} />,
      },
    ],
    lists: [
      {
        active: drugPermissions.read,
        label: "Lista de Medicamentos",
        content: <DrugList patient={attendance.patient} />,
      },
      {
        active: examsPermissions.read,
        label: "Lista de Exames",
        content: <ExamList patient={attendance.patient} />,
      },
      {
        active: drugPermissions.read || examsPermissions.read,
        label: "Histórico de Prescrições",
        content: (
          <PrescriptionHistory
            patient={attendance.patient}
            drugsPermission={drugPermissions.read}
            examsPermission={examsPermissions.read}
            setExamPrescriptions={setExamPrescriptions}
            setDrugPrescriptions={setDrugPrescriptions}
            setDrugOnSitePrescriptions={setDrugOnSitePrescriptions}
            canCopy={tabsToShow.includes("registers")}
            setActiveTab={setActiveTab}
          />
        ),
      },
    ],
  };

  const tabs = [];

  tabsToShow.forEach((type) => {
    tabs.push(...tabTypes[type]);
  });

  return (
    <>
      {showPatient && <PatientsCard patient={attendance} onDialog={true} hideOptions />}
      <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
    </>
  );
}

export default Prescription;
