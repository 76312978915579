import React, { useContext, useRef } from "react";
import { BoxContainer } from "../../components";
import TableFilter from "../../components/Table/TableFilter";
import { Button } from "../../components/FormFields";
import { Box, TablePagination, Typography, useTheme, Tooltip } from "@mui/material";
import { format, parseISO } from "date-fns";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FileDownloadOff, InfoRounded, Publish } from "@mui/icons-material";
import { Dialog } from "../../helper";
import { useSignedDocuments } from "../../service";
import { DynamicFeedback } from "../../components";
import {
  againstReferencePDF,
  attendanceStatementPDF,
  drugOnSitePrescriptionPDF,
  drugPrescriptionPDF,
  evolutionsPDF,
  examPrescriptionPDF,
  followUpStatementPDF,
  formMakerPDF,
  medicalStatementPDF,
  patientDischargePDF,
  sadtPDF,
  soapPDF,
  specialPrescriptionPDF,
} from "../../pdfModels";
import { FormMakerContext } from "../../contexts/FormMakerContext";
import { useNotifier } from "../../hooks";
import useSignaturePassword from "../../hooks/useSignaturePassword";
import { convertDateToBirthday } from "../../utils";

const medicalRecordTitles = {
  medications: "Evolução Farmaceutica",
  medications_on_site: "Evolução de Medicamentos",
  lab: "Evolução de Exames Laboratoriais",
  xray: "Evolução de Raio-X",
  ecg: "Evolução de ECG",
  procedure: "Evolução de Procedimentos",
};

export default function PendingSignature() {
  const maxItems = 100;
  const theme = useTheme();
  const notify = useNotifier();
  const validateSignature = useSignaturePassword();
  const { feedbackFormatter } = useContext(FormMakerContext);
  const [document, setDocument] = useState(null);
  const [pageMapper, setPageMapper] = useState({});
  const submitType = useRef(null);
  const [filters, setFilters] = useState({
    page: 0,
    limit: 20,
  });

  const signatureForm = useForm({
    defaultValues: {
      documents_to_sign: [],
    },
  });

  const documentsToSign = signatureForm.watch("documents_to_sign");

  const queryClient = useQueryClient();
  const { getSignedDocuments, signDocuments } = useSignedDocuments();

  const initialData = {
    totalItems: 0,
    items: [],
    metadata: {
      status: {
        to_sign: false,
      },
    },
  };

  const documentsQuery = useQuery(
    ["documents-to-sign", [filters]],
    () => getSignedDocuments(filters),
    {
      initialData,
      onError() {
        queryClient.setQueryData("documents-to-sign", initialData);
      },
    }
  );

  const signDocumentMutation = useMutation(signDocuments);

  const handleSign = signatureForm.handleSubmit(({ documents_to_sign }) => {
    const data = {
      type: submitType.current,
      documents: documents_to_sign,
    };

    validateSignature(() => {
      signDocumentMutation.mutate(data, {
        onSuccess(response) {
          notify(response.message, "success");
        },
        onError(error) {
          notify(error.message, "error");
        },
        onSettled() {
          submitType.current = null;
          signatureForm.reset();

          documentsQuery.refetch();
        },
      });
    });
  });

  function handleFilter(values) {
    setFilters(({ limit }) => ({
      page: 0,
      limit,
      ...values,
    }));
  }

  function handleTitle(title) {
    if (document?.type === "declaration") {
      return `Declaração de ${document.documentationHistory.type}`;
    }

    if (document?.type === "medical_record") {
      return medicalRecordTitles[document.medicalRecord.type];
    }

    return title;
  }

  async function handleCheckAll() {
    if (signDocumentMutation.isLoading || !!documentsQuery.data.metadata.status.to_sign) {
      return;
    }

    const totalItemsToSign = documentsToSign.length;
    const totalItems = documentsQuery.data.items.length;
    const mapper = pageMapper[filters.page];

    if (mapper) {
      const newDocumentsToSign = [...documentsToSign];
      newDocumentsToSign.splice(mapper.startIndex, mapper.endIndex);

      let lastRange;
      const mapperEntries = Object.entries(pageMapper);

      mapperEntries.sort((a, b) => a[1]?.startIndex - b[1]?.startIndex);

      const newMapper = mapperEntries.reduce((mapper, [page, range]) => {
        const parsedPage = Number.parseInt(page);

        if (filters.page === parsedPage) {
          lastRange = range;
          mapper[parsedPage] = null;
        } else if (lastRange) {
          lastRange.endIndex = range.endIndex - lastRange.endIndex;
          mapper[parsedPage] = lastRange;
          lastRange = range;
        } else {
          lastRange = range;
        }

        return mapper;
      }, {});

      signatureForm.setValue("documents_to_sign", newDocumentsToSign);
      setPageMapper(newMapper);
    } else if (totalItemsToSign < maxItems) {
      const itemsToFill = maxItems - documentsToSign.length;
      const maxIndex = totalItems < itemsToFill ? totalItems : itemsToFill;
      const slicedItems = documentsQuery.data.items.slice(0, maxIndex);
      const itemsToAddPromise = [];
      const itemsIds = [];

      for (const item of slicedItems) {
        const signedDocumentData = documentTypes[item.type].handler(item, true);

        itemsIds.push(item.id);
        itemsToAddPromise.push(signedDocumentData);
      }

      const itemsToAdd = await Promise.all(itemsToAddPromise);

      const startIndex = totalItemsToSign;
      const endIndex = totalItemsToSign + maxIndex;

      const newDocumentsToSign = [...documentsToSign];
      newDocumentsToSign.splice(startIndex, endIndex);

      setPageMapper((mapper) => ({
        ...mapper,
        [filters.page]: {
          startIndex,
          endIndex,
        },
      }));

      signatureForm.setValue("documents_to_sign", newDocumentsToSign.concat(itemsToAdd));
    }
  }

  async function handleCheck(value, row) {
    const item = value?.find((item) => item.id === row.id);

    if (item) {
      const signedDocumentData = await documentTypes[row.type].handler(item, true);

      value[value.length - 1] = signedDocumentData;
    }

    signatureForm.setValue("documents_to_sign", value);
  }

  const documentTypes = {
    medications: {
      title: "Receituário",
      handler: async (document, returning) => {
        const patient = document.attendance.patient;
        const medications = document.prescription.medications.filter((exam) => {
          return !exam.special && exam.tab_id === document.tab_id;
        });

        const pdf = drugPrescriptionPDF(medications, patient, document);

        if (returning) {
          return {
            id: document.id,
            type: document.type,
            document_id: document.prescription.id,
            original_file_name: `${document.id}.pdf`,
            data: await pdf.base64(),
            signature_settings: {
              visible_sign_x: 215,
              visible_sign_y: 590,
              visible_sign_page: "*",
            },
          };
        }

        pdf.open();
      },
    },
    medications_on_site: {
      title: "Prescrição de Medicamentos",
      handler: async (document, returning) => {
        const patient = document.attendance.patient;
        const medications = document.prescription.medications_on_site.filter((exam) => {
          return exam.tab_id === document.tab_id;
        });

        const pdf = drugOnSitePrescriptionPDF(medications, patient, document);

        if (returning) {
          return {
            id: document.id,
            type: document.type,
            document_id: document.prescription.id,
            original_file_name: `${document.id}.pdf`,
            data: await pdf.base64(),
            signature_settings: {
              visible_sign_x: 180,
              visible_sign_y: 600,
              visible_sign_page: "*",
            },
          };
        }

        pdf.open();
      },
    },
    special_medications: {
      title: "Receituário Especial",
      handler: async (document, returning) => {
        const patient = document.attendance.patient;
        const medications = document.prescription.medications.filter((exam) => {
          return exam.special && exam.tab_id === document.tab_id;
        });

        const pdf = specialPrescriptionPDF(medications, patient, document);

        if (returning) {
          return {
            id: document.id,
            type: document.type,
            document_id: document.prescription.id,
            original_file_name: `${document.id}.pdf`,
            data: await pdf.base64(),
            signature_settings: {
              visible_sign_x: 215,
              visible_sign_y: 590,
              visible_sign_page: "*",
            },
          };
        }

        pdf.open();
      },
    },
    xray: {
      title: "SADT(RAIO-X)",
      handler: async (document, returning) => {
        const patient = document.attendance.patient;
        const documentData = {
          employee: document.employee_specialty.employee,
          company: document.company,
        };

        const exams = document.prescription.exams.filter((exam) => {
          return (
            exam.type === "Imagem" &&
            exam.procedure.type.includes("xray") &&
            exam.tab_id === document.tab_id
          );
        });

        const pdf = sadtPDF("xray", exams, patient, documentData);

        if (returning) {
          return {
            id: document.id,
            type: document.type,
            document_id: document.prescription.id,
            original_file_name: `${document.id}.pdf`,
            data: await pdf.base64(),
            signature_settings: {
              visible_sign_x: 30,
              visible_sign_y: 630,
              visible_sign_page: "*",
            },
          };
        }

        pdf.open();
      },
    },
    image: {
      title: "SADT(IMAGEM)",
      handler: async (document, returning) => {
        const patient = document.attendance.patient;
        const documentData = {
          employee: document.employee_specialty.employee,
          company: document.company,
        };

        const exams = document.prescription.exams.filter((exam) => {
          return (
            exam.type === "Imagem" &&
            !exam.procedure.type.includes("xray") &&
            exam.tab_id === document.tab_id
          );
        });

        const pdf = sadtPDF("xray", exams, patient, documentData);

        if (returning) {
          return {
            id: document.id,
            type: document.type,
            document_id: document.prescription.id,
            original_file_name: `${document.id}.pdf`,
            data: await pdf.base64(),
            signature_settings: {
              visible_sign_x: 30,
              visible_sign_y: 630,
              visible_sign_page: "*",
            },
          };
        }

        pdf.open();
      },
    },
    lab: {
      title: "SADT(LABORATORIAL)",
      handler: async (document, returning) => {
        const patient = document.attendance.patient;
        const documentData = {
          employee: document.employee_specialty.employee,
          company: document.company,
        };

        const exams = document.prescription.exams.filter((exam) => {
          return exam.type === "Laboratorial" && exam.tab_id === document.tab_id;
        });

        const pdf = sadtPDF("lab", exams, patient, documentData);

        if (returning) {
          return {
            id: document.id,
            type: document.type,
            document_id: document.prescription.id,
            original_file_name: `${document.id}.pdf`,
            data: await pdf.base64(),
            signature_settings: {
              visible_sign_x: 30,
              visible_sign_y: 630,
              visible_sign_page: "*",
            },
          };
        }

        pdf.open();
      },
    },
    exams: {
      title: "Prescrição de Exames",
      handler: async (document, returning) => {
        const patient = document.attendance.patient;

        const pdf = examPrescriptionPDF(document.prescription.exams, patient, {
          createdAt: document.createdAt,
          employee: document.employee_specialty.employee,
          company: document.company,
        });

        if (returning) {
          return {
            id: document.id,
            type: document.type,
            document_id: document.prescription.id,
            original_file_name: `${document.id}.pdf`,
            data: await pdf.base64(),
          };
        }

        pdf.open();
      },
    },
    declaration: {
      title: "Declaração",
      handler: async (document, returning) => {
        const {
          documentationHistory,
          soap: {
            attendance: { patient },
          },
        } = document;

        let pdf;

        if (documentationHistory.type === "Comparecimento") {
          pdf = attendanceStatementPDF(documentationHistory, patient);
        }

        if (documentationHistory.type === "Atestado") {
          pdf = medicalStatementPDF(documentationHistory, patient);
        }

        if (documentationHistory.type === "Acompanhamento") {
          pdf = followUpStatementPDF(documentationHistory, patient);
        }

        if (returning) {
          return {
            id: document.id,
            type: document.type,
            document_id: documentationHistory.id,
            original_file_name: `${document.id}.pdf`,
            data: await pdf.base64(),
            signature_settings: {
              visible_sign_page: "*",
              visible_sign_x: 170,
              visible_sign_y: 595,
            },
          };
        }

        pdf.open();
      },
    },
    against_reference: {
      title: "Ficha de Referência / Contra Referência",
      handler: async (document, returning) => {
        const patient = document.attendance.patient;

        const pdf = againstReferencePDF(
          document.against_reference,
          patient,
          document.against_reference.cids
        );

        if (returning) {
          return {
            id: document.id,
            type: document.type,
            document_id: document.against_reference.id,
            original_file_name: `${document.id}.pdf`,
            data: await pdf.base64(),
            signature_settings: {
              visible_sign_x: 200,
              visible_sign_y: 730,
              visible_sign_page: "*",
            },
          };
        }

        pdf.open();
      },
    },
    patient_discharge: {
      title: "Alta",
      handler: async (document, returning) => {
        const {
          patientDischarge,
          attendance: { patient },
        } = document;

        const pdf = patientDischargePDF(patientDischarge, patient, {
          createdAt: patientDischarge.createdAt,
          employee: document.employee_specialty.employee,
        });

        if (returning) {
          return {
            id: document.id,
            type: document.type,
            document_id: patientDischarge.id,
            original_file_name: `${document.id}.pdf`,
            data: await pdf.base64(),
            signature_settings: {
              visible_sign_page: "*",
              visible_sign_x: 170,
              visible_sign_y: 595,
            },
          };
        }

        pdf.open();
      },
    },
    soap: {
      title: "Prontuário",
      handler: async (document, returning) => {
        const soapData = document.soap;
        soapData.attendance = document.attendance;

        const completaryData = {
          createdAt: document.createdAt,
          employee: document.employee_specialty.employee,
        };

        const pdf = soapPDF(soapData, document.attendance.patient, completaryData);

        if (returning) {
          return {
            id: document.id,
            type: document.type,
            document_id: soapData.id,
            original_file_name: `${document.id}.pdf`,
            data: await pdf.base64(),
            signature_settings: {
              visible_sign_x: 170,
              visible_sign_y: 765,
              visible_sign_page: "*",
            },
          };
        }

        pdf.open();
      },
    },
    medical_record: {
      title: "Evolução",
      handler: async (document, returning) => {
        const medicalRecord = document.medicalRecord;

        const pdf = evolutionsPDF(medicalRecord, document.attendance.patient, {
          title: medicalRecordTitles[medicalRecord.type],
          createdAt: medicalRecord.createdAt,
          employee: medicalRecord.finished_by,
        });

        if (returning) {
          return {
            id: document.id,
            type: document.type,
            document_id: medicalRecord.id,
            original_file_name: `${document.id}.pdf`,
            data: await pdf.base64(),
            signature_settings: {
              visible_sign_x: 170,
              visible_sign_y: 595,
              visible_sign_page: "*",
            },
          };
        }

        pdf.open();
      },
    },
    risk_classification: {
      title: "Classificação de Risco",
      handler: async (document, returning) => {
        const patient = document?.attendance?.patient;
        const classification = document.riskClassification;
        const documentRecord = classification.document_record;

        const patientData = [
          {
            label: "Paciente",
            value: patient?.social_prioritize
              ? patient.social_name
              : patient?.name || "Não informado",
          },
          {
            label: "CPF",
            value: patient?.physic_national || "Não informado",
          },
          {
            label: "Ficha",
            value: classification.token_audit.token_describe,
          },
          { label: "CNS", value: patient?.sus_card || "Não informado" },
          {
            label: "Data da classificação",
            value: format(parseISO(classification.updatedAt), "dd/MM/yyyy"),
          },
          {
            label: "Data de nascimento",
            value: patient?.birth_date
              ? format(parseISO(patient.birth_date), "dd/MM/yyyy")
              : "Não informado",
          },
          {
            label: "Horário da classificação",
            value: format(parseISO(classification.updatedAt), "HH:mm"),
          },
        ];

        documentRecord.document_data.document_id = {
          versions: documentRecord.document_structure.versions,
        };

        const formattedRecord = feedbackFormatter(documentRecord.document_data);

        const documentDataToPDF = {
          name: documentRecord.document_structure.name,
          status: "Finalizado",
          version: documentRecord.document_data.version,
          employee: document.employee_specialty.employee,
          created_at: documentRecord.createdAt,
          fields: formattedRecord,
        };

        const pdf = formMakerPDF(documentDataToPDF, patientData, true, "risk_classification");

        if (returning) {
          return {
            id: document.id,
            type: document.type,
            document_id: classification.id,
            original_file_name: `${document.id}.pdf`,
            data: await pdf.base64(),
            signature_settings: {
              visible_sign_x: 170,
              visible_sign_y: 615,
              visible_sign_page: "*",
            },
          };
        }

        pdf.open();
      },
    },
  };

  const documentTypesOptions = Object.entries(documentTypes).map(([key, value]) => ({
    label: value.title,
    value: key,
  }));

  const headerActions = [
    {
      title: "Documentos selecionados",
      icon: (
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          {documentsQuery.data.metadata.status.to_sign || documentsToSign.length}/{maxItems}
        </Typography>
      ),
    },
  ];

  const tableColumns = [
    {
      name: "N. atendimento",
      field: "attendance?.number",
      filter: "attendance_number",
      type: "text",
      use: (number) => number ?? "Não informado",
    },
    {
      name: "Serviço",
      field: "attendance?.service.describe",
      filter: "service_describe",
      type: "text",
      use: (service) => service ?? "Não informado",
    },
    {
      name: "Compania",
      field: "company?.name",
      filter: "company_name",
      type: "text",
      use: (company) => company ?? "Não informado",
    },
    {
      name: "Paciente",
      field: "attendance?.patient",
      filter: "patient_name",
      type: "text",
      use: (patient) => {
        if (!patient) return "Não identificado";

        return patient.social_prioritize ? patient.social_name : patient.name;
      },
    },
    {
      name: "Documento",
      field: "type",
      type: "select",
      use: (type, row) => {
        if (type === "declaration") {
          return `Declaração de ${row.documentationHistory.type}`;
        }

        if (row.type === "medical_record") {
          return medicalRecordTitles[row.medicalRecord.type];
        }

        return documentTypes[type].title;
      },
      options: documentTypesOptions,
    },
    {
      name: "Criado em",
      field: "createdAt",
      use: (date) => format(parseISO(date), "dd/MM/yyyy"),
    },
    {
      name: "Status",
      alignInRow: "center",
      use: (_, row) => (
        <Tooltip title={row.status === "to_sign" ? "Assinando" : "Sem assinatura"}>
          {row.status === "to_sign" ? (
            <Publish color="primary" fontSize="large" />
          ) : (
            <FileDownloadOff color="primary" fontSize="large" />
          )}
        </Tooltip>
      ),
    },
  ];

  const othersFilters = [
    {
      name: "Data incial",
      type: "date",
      filter: "initial_date",
    },
    {
      name: "Data final",
      type: "date",
      filter: "final_date",
    },
  ];

  const feedbackShape = [
    {
      type: "header",
      label: "#1 Dados do paciente",
    },
    {
      type: "description",
      label: "Cod. paciente",
      valueKey: "attendance.patient.number",
    },
    {
      type: "description",
      label: "Nome",
      valueKey: "attendance.patient",
      formatter: (patient) => {
        if (!patient) return "Não identificado";

        return patient.social_prioritize ? patient.social_name : patient.name;
      },
    },
    {
      type: "description",
      label: "Gênero",
      valueKey: "attendance.patient.gender",
    },
    {
      type: "description",
      label: "Idade",
      valueKey: "attendance.patient.birth_date",
      formatter: (date) =>
        date ? convertDateToBirthday(date, ["years", "months"]) : "Não informado",
    },
    {
      type: "description",
      label: "Data de nascimento",
      valueKey: "attendance.patient.birth_date",
      formatter: (date) => (date ? format(parseISO(date), "dd/MM/yyyy") : "Não informado"),
    },
    {
      type: "description",
      label: "CPF",
      valueKey: "attendance.patient.physic_national",
      formatter: (value) => value ?? "Não informado",
    },
    {
      type: "description",
      label: "RG",
      valueKey: "attendance.patient.ident_national",
      formatter: (value) => value ?? "Não informado",
    },
    {
      type: "description",
      label: "CNS",
      valueKey: "attendance.patient.sus_card",
      formatter: (value) => value ?? "Não informado",
    },
    {
      type: "header",
      label: "#2 Dados do prestador",
    },
    {
      type: "description",
      label: "Nome",
      valueKey: "employee_specialty.employee.name",
    },
    {
      type: "description",
      label: "Especialidade",
      valueKey: "employee_specialty.specialty.describe",
    },
    {
      type: "header",
      label: "#3 Informações complementares",
    },
    {
      type: "description",
      label: "N. atendimento",
      valueKey: "attendance.number",
      formatter: (number) => number ?? "Não informado",
    },
    {
      type: "description",
      label: "Serviço",
      valueKey: "attendance.service.describe",
      formatter: (service) => service ?? "Não informado",
    },
    {
      type: "description",
      label: "Compania",
      valueKey: "company.name",
      formatter: (company) => company ?? "Não informado",
    },
    {
      type: "description",
      label: "Data do documento",
      valueKey: "createdAt",
      formatter: (date) => (date ? format(parseISO(date), "dd/MM/yyyy") : "Não informado"),
    },
  ];

  return (
    <BoxContainer title="Pendência de assinatura">
      <Dialog
        title={handleTitle(documentTypes[document?.type]?.title)}
        open={!!document}
        handleClose={() => setDocument(null)}
      >
        {document && <DynamicFeedback data={document} shape={feedbackShape} />}
      </Dialog>
      {(!!documentsQuery.data.metadata.status.to_sign || signDocumentMutation.isLoading) && (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: "8px",
            margin: "20px auto 32px auto",
            maxWidth: "800px",
            padding: "12px 22px",
            borderRadius: "8px",
            backgroundColor: theme.palette.primary.medium,
            boxShadow: theme.shadows[1],
          }}
        >
          <InfoRounded color="primary" fontSize="large" />
          <Typography
            sx={{
              fontSize: "14px",
            }}
          >
            {!!documentsQuery.data.metadata.status.to_sign
              ? "Os documentos marcados para assinar estão em processamento para aplicação da sua assinatura digital. Você poderá assinar outros documentos assim que os anteriores forem finalizados, sinta-se a vontade para utilizar o sistema normalmente."
              : "Os documentos marcados para assinar estão sendo processados em modo de prioridade para aplicãção da sua assinatura digital, por favor, permaneça na tela e aguarde."}
          </Typography>
        </Box>
      )}
      <Box display="flex" justifyContent="flex-end" my="12px">
        <Button onClick={() => documentsQuery.refetch()}>Atualizar</Button>
      </Box>
      <TableFilter
        data={documentsQuery.data?.items}
        columns={tableColumns}
        actions
        actionsTypes={["checkbox", "print", "view"]}
        headerActions={headerActions}
        handleFilter={handleFilter}
        othersFilter={othersFilters}
        loading={documentsQuery.isFetching}
        emptyMessage={documentsQuery.error?.message}
        disableActions={() =>
          signDocumentMutation.isLoading || !!documentsQuery.data.metadata.status.to_sign
        }
        actionHandleView={(_, row) => setDocument(row)}
        actionHandlePrint={(_, row) => documentTypes[row.type].handler(row)}
        checkboxActionController={signatureForm}
        actionUniqueIdentifier="id"
        checkBoxName="documents_to_sign"
        handleCheckAll={handleCheckAll}
        handleCheck={handleCheck}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          count={documentsQuery.data.totalItems}
          component="div"
          page={filters.page}
          onPageChange={(_, newPage) => {
            setFilters((filters) => ({ ...filters, page: newPage }));
          }}
          rowsPerPage={filters.limit}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={(event) => {
            const limit = parseInt(event.target.value);
            setPageMapper({});
            signatureForm.setValue("documents_to_sign", []);
            setFilters((filters) => ({ ...filters, limit, page: 0 }));
          }}
        />
      </Box>
      <Box display="flex" gap="20px">
        <Button
          loading={
            (signDocumentMutation.isLoading && submitType.current === "batch_signature") ||
            !!documentsQuery.data.metadata.status.to_sign
          }
          disabled={signDocumentMutation.isLoading || !documentsToSign.length}
          loadingMessage="Assinando documentos"
          onClick={() => {
            submitType.current = "batch_signature";
            handleSign();
          }}
        >
          Assinar em lote
        </Button>
        <Button
          loading={signDocumentMutation.isLoading && submitType.current === "urgency_signature"}
          disabled={
            !documentsToSign.length ||
            documentsToSign.length > 15 ||
            !!documentsQuery.data.metadata.status.to_sign
          }
          color="error"
          loadingMessage="Assinando documentos"
          onClick={() => {
            submitType.current = "urgency_signature";
            handleSign();
          }}
        >
          Assinar com prioridade
        </Button>
      </Box>
    </BoxContainer>
  );
}
