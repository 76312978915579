import React, { useState } from "react";
import { Button, Grid, Tooltip, Typography, useTheme } from "@mui/material";
import {
  Assignment,
  AssignmentRounded,
  DoNotDisturbOnRounded,
  HomeRounded,
  PeopleRounded,
  RefreshRounded,
} from "@mui/icons-material";
import { useQuery } from "react-query";
import { useCockpit } from "../../../../service";
import { useNotifier } from "../../../../hooks";
import { DialogMedium, Loading } from "../../../../helper";
import FormFilters from "../FormFilters";
import DynamicSections from "../../../../components/DynamicSections";
import DetailCard from "../../../../components/Panels/DetailCard";
import { PaginatedScrollList } from "../../../../components/PaginatedScrollList";
import SimpleCard from "../../../../components/SimpleCard";
import TypographyList from "../../../../components/TypographyList";
import { openURL } from "../../../../pdfModels";
import { v4 as uuidv4 } from "uuid";

export default function CockPitACSManagement({ filters, onSubmitFilters, initialValues }) {
  const [individualRegistersFilters, setIndividualRegistersFilters] = useState(null);
  const [domicliariesFilters, setDomiciliariesFilters] = useState(null);
  const [visitsFilters, setVisitsFilters] = useState(null);
  const [familyList, setFamilyList] = useState([]);
  const [familyListDomiciliaries, setFamilyListDomiciliaries] = useState([]);
  const [familyListVisits, setFamilyListVisits] = useState([]);
  const [detailing, setDetailing] = useState(null);
  const [pageIndividualRegisters, setPageIndividualRegisters] = useState(0);
  const [pageDomiciliaries, setPageDomiciliaries] = useState(0);
  const [pageVisits, setPageVisits] = useState(0);
  const notify = useNotifier();
  const theme = useTheme();
  const {
    getACSManagements,
    getACSIndividualRegistersAnalytics,
    getACSDomiciliariesAnalytics,
    getACSAnalyticsVisits,
  } = useCockpit();

  const analyticalReport = filters?.report_type === "Analítico";

  const exportData = (data) => {
    const fileUrl = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
    const Content_Dispostition = data.headers?.["content-disposition"];
    const match = Content_Dispostition?.match(/filename\s*?="(.+)"/);

    let filename = `painel_acs_${uuidv4()}.xlsx`;

    if (match && match[1]) {
      filename = match[1];
    }

    openURL(fileUrl, filename);
  };

  const managementsQuery = useQuery(["populations", filters], () => getACSManagements(filters), {
    enabled: !!filters,
    onError(error) {
      notify(error.message, "error");
    },
  });

  const individualRegistersAnalytics = useQuery(
    [
      "individual-registers-analytics",
      filters,
      individualRegistersFilters,
      pageIndividualRegisters,
    ],
    () =>
      getACSIndividualRegistersAnalytics({
        ...filters,
        ...individualRegistersFilters,
        page: pageIndividualRegisters,
        limit: 5,
      }),
    {
      enabled: !!individualRegistersFilters && !!filters,
      onSuccess(data) {
        if (Object.keys(data).length === 5) {
          setFamilyList((current) =>
            pageIndividualRegisters === 0 ? data.items : [...current, ...data.items]
          );
        } else {
          exportData(data);
        }
      },
      onError(error) {
        notify(error.message, "error");
        setFamilyList([]);
      },
    }
  );

  const domiciliariesAnalytics = useQuery(
    ["domiciliaries-analytics", filters, domicliariesFilters, pageDomiciliaries],
    () =>
      getACSDomiciliariesAnalytics({
        ...filters,
        ...domicliariesFilters,
        page: pageDomiciliaries,
        limit: 5,
      }),
    {
      enabled: !!domicliariesFilters && !!filters,
      onSuccess(data) {
        if (Object.keys(data).length === 5) {
          setFamilyListDomiciliaries((current) =>
            pageDomiciliaries === 0 ? data.items : [...current, ...data.items]
          );
        } else {
          exportData(data);
        }
      },
      onError(error) {
        notify(error.message, "error");
        setFamilyListDomiciliaries([]);
      },
    }
  );

  const visitsAnalytics = useQuery(
    ["visits-analytics", filters, visitsFilters, pageVisits],
    () => getACSAnalyticsVisits({ ...filters, ...visitsFilters, pageVisits, limit: 5 }),
    {
      enabled: !!visitsFilters && !!filters,
      onSuccess(data) {
        if (Object.keys(data).length === 5) {
          setFamilyListVisits((current) =>
            pageVisits === 0 ? data.items : [...current, ...data.items]
          );
        } else {
          exportData(data);
        }
      },
      onError(error) {
        notify(error.message, "error");
        setFamilyListVisits([]);
      },
    }
  );

  const handleClickCard = (card, section) => {
    const otherCards = section?.cards?.filter((item) => item?.title !== card?.title);
    setDetailing({
      title: card.title,
      data: card.content,
      name: card.name,
      cards: otherCards,
      open: true,
    });
    setFamilyList([]);
    setFamilyListDomiciliaries([]);
    setFamilyListVisits([]);
    setPageDomiciliaries(0);
    setPageIndividualRegisters(0);
    setPageVisits(0);
  };

  const sections = [
    {
      icon: <HomeRounded color="white" fontSize="large" />,
      title: "VISITAS",
      cards: [
        {
          title: "Visita Periódica",
          name: "visit",
          content: managementsQuery.data?.visits?.visit_is_family,
          handleClick: (card, section) => {
            if (analyticalReport) {
              handleClickCard(card, section);
              setVisitsFilters({ type: "family", visit_conclusion: "completed" });
            }
          },
        },
        {
          title: "Visita Individual",
          name: "visit",
          content: managementsQuery.data?.visits?.visit_is_member,
          handleClick: (card, section) => {
            if (analyticalReport) {
              handleClickCard(card, section);
              setVisitsFilters({ type: "patient", visit_conclusion: "completed" });
            }
          },
        },
        {
          title: "Visita Recusada",
          name: "visit",
          content: managementsQuery.data?.visits?.refused,
          handleClick: (card, section) => {
            if (analyticalReport) {
              handleClickCard(card, section);
              setVisitsFilters({ visit_conclusion: "refused" });
            }
          },
        },
        {
          title: "Visita Ausente",
          name: "visit",
          content: managementsQuery.data?.visits?.absentee,
          handleClick: (card, section) => {
            if (analyticalReport) {
              handleClickCard(card, section);
              setVisitsFilters({ visit_conclusion: "absentee" });
            }
          },
        },
      ],
    },
    {
      icon: <AssignmentRounded color="white" fontSize="large" />,
      title: "NOVOS CADASTROS / REGISTROS",
      cards: [
        {
          title: "Ficha de Cadastro Individual",
          name: "individual",
          content: managementsQuery.data?.new_registers?.individual,
          handleClick: (card, section) => {
            if (analyticalReport) {
              handleClickCard(card, section);
              setIndividualRegistersFilters({
                individual_registration_rejection: false,
                first_register: true,
              });
            }
          },
        },
        {
          title: "Ficha de Cadastro Domiciliar e Territorial",
          name: "domiciliary",
          content: managementsQuery.data?.new_registers?.domiciliary,
          handleClick: (card, section) => {
            if (analyticalReport) {
              handleClickCard(card, section);
              setDomiciliariesFilters({ terms_of_use: false, first_register: true });
            }
          },
        },
      ],
    },
    {
      icon: <RefreshRounded color="white" fontSize="large" />,
      title: "ATUALIZAÇÕES",
      cards: [
        {
          title: "Ficha de Cadastro Individual",
          name: "individual",
          content: managementsQuery.data?.update_registers?.individual,
          handleClick: (card, section) => {
            if (analyticalReport) {
              handleClickCard(card, section);
              setIndividualRegistersFilters({
                individual_registration_rejection: false,
                first_register: false,
              });
            }
          },
        },
        {
          title: "Ficha de Cadastro Domiciliar e Territorial",
          name: "domiciliary",
          content: managementsQuery.data?.update_registers?.domiciliary,
          handleClick: (card, section) => {
            if (analyticalReport) {
              handleClickCard(card, section);
              setDomiciliariesFilters({ terms_of_use: false, first_register: false });
            }
          },
        },
      ],
    },
    {
      icon: <DoNotDisturbOnRounded color="white" fontSize="large" />,
      title: "RECUSAS",
      cards: [
        {
          title: "Ficha de Cadastro Individual",
          name: "individual",
          content: managementsQuery.data?.refused?.individual,
          handleClick: (card, section) => {
            if (analyticalReport) {
              handleClickCard(card, section);
              setIndividualRegistersFilters({ individual_registration_rejection: true });
            }
          },
        },
        {
          title: "Ficha de Cadastro Domiciliar e Territorial",
          name: "domiciliary",
          content: managementsQuery.data?.refused?.domiciliary,
          handleClick: (card, section) => {
            if (analyticalReport) {
              handleClickCard(card, section);
              setDomiciliariesFilters({ terms_of_use: true });
            }
          },
        },
      ],
    },
  ];

  const shapeListIndividualRegister = [
    {
      label: "Código",
      valueKey: "patient.patient_family.family.number",
      formatter: (value) => value || "Não informado",
    },
    {
      label: "Familia",
      valueKey: "patient.patient_family.family.name",
      formatter: (value) => value || "Não informado",
    },
    {
      label: "Equipe",
      valueKey: "team_name",
      formatter: (value) => value || "Não informado",
    },
    {
      label: "Membros",
      valueKey: "quantity_members",
      formatter: (value) => value || "Não informado",
    },
  ];

  const shapeListDomiciliary = [
    {
      label: "Código",
      valueKey: "number",
    },
    {
      label: "Familia",
      valueKey: "name",
    },
    {
      label: "Equipe",
      valueKey: "team_name",
    },
    {
      label: "Membros",
      valueKey: "quantity_members",
    },
  ];

  const shapeListVisit = [
    {
      label: "Código",
      valueKey: "family.number",
      formatter: (value) => value || "Não informado",
    },
    {
      label: "Familia",
      valueKey: "family.name",
      formatter: (value) => value || "Não informado",
    },
    {
      label: "Equipe",
      valueKey: "family.team_name",
      formatter: (value) => value || "Não informado",
    },
    {
      label: "Membros",
      valueKey: "family.quantity_members",
      formatter: (value) => value || "Não informado",
    },
  ];

  const SimpleCardToScrollList = ({ item, index, shape }) => (
    <SimpleCard
      key={index}
      icon={<PeopleRounded sx={{ fontSize: 22, color: "white", borderRadius: 10 }} />}
      title={
        <Typography color={item?.has_any_condition && theme.palette.error.main} fontWeight={600}>
          {item?.patient?.name || item?.responsible || "Não Informado"}
        </Typography>
      }
      containerStyles={{ paddingY: 1.5 }}
    >
      <TypographyList numberColumns={2} compact data={item} shape={shape} />
    </SimpleCard>
  );

  const PaginatedScrollListDynamic = ({ shape, loading, totalPage, data, setPage, page }) => (
    <PaginatedScrollList
      component={(item, index) => (
        <SimpleCardToScrollList item={item} index={index} shape={shape} />
      )}
      hasDivider={false}
      maxHeight="250px"
      minHeight="auto"
      handlePage={setPage}
      loading={loading}
      page={page}
      totalPage={totalPage}
      data={data || []}
    />
  );

  const PaginatedList = () => {
    if (detailing?.name === "individual") {
      return (
        <>
          <Grid item xs={12}>
            <PaginatedScrollListDynamic
              shape={shapeListIndividualRegister}
              loading={
                individualRegistersAnalytics.isRefetching || individualRegistersAnalytics.isLoading
              }
              page={pageIndividualRegisters}
              totalPage={individualRegistersAnalytics.data?.total}
              data={familyList}
              setPage={setPageIndividualRegisters}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Exportar para Excel" placement="right">
              <Button
                disabled={!familyList?.length}
                variant="contained"
                onClick={() =>
                  setIndividualRegistersFilters((curr) => ({ ...curr, to_excel: true }))
                }
              >
                <Assignment color="white" />
              </Button>
            </Tooltip>
          </Grid>
        </>
      );
    } else if (detailing?.name === "domiciliary") {
      return (
        <>
          <Grid item xs={12}>
            <PaginatedScrollListDynamic
              shape={shapeListDomiciliary}
              data={familyListDomiciliaries}
              page={pageDomiciliaries}
              setPage={setPageDomiciliaries}
              totalPage={domiciliariesAnalytics.data?.total}
              loading={domiciliariesAnalytics.isRefetching || domiciliariesAnalytics.isLoading}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Exportar para Excel" placement="right">
              <Button
                disabled={!familyListDomiciliaries?.length}
                variant="contained"
                onClick={() => setDomiciliariesFilters((curr) => ({ ...curr, to_excel: true }))}
              >
                <Assignment color="white" />
              </Button>
            </Tooltip>
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid item xs={12}>
            <PaginatedScrollListDynamic
              shape={shapeListVisit}
              data={familyListVisits}
              page={pageVisits}
              setPage={setPageVisits}
              totalPage={visitsAnalytics.data?.total}
              loading={visitsAnalytics.isRefetching || visitsAnalytics.isLoading}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Exportar para Excel" placement="right">
              <Button
                disabled={!familyListDomiciliaries?.length}
                variant="contained"
                onClick={() => setVisitsFilters((curr) => ({ ...curr, to_excel: true }))}
              >
                <Assignment color="white" />
              </Button>
            </Tooltip>
          </Grid>
        </>
      );
    }
  };

  return (
    <>
      <DialogMedium
        title={`${detailing?.title} - Relatório Analítico`}
        fullWidth
        open={detailing?.open}
        handleClose={() => setDetailing((curr) => ({ ...curr, open: false }))}
        maxWidth="md"
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <DetailCard
              title={detailing?.title}
              content={detailing?.data}
              sx={{
                backgroundColor: theme.palette.primary.medium,
                color: theme.palette.primary.light,
                borderRadius: 1.5,
              }}
            />
          </Grid>
          {/* {detailing?.cards?.map((card, i) => (
            <Grid item xs={4} key={i}>
              <DetailCard
                title={card.title}
                content={card.content}
                sx={{
                  backgroundColor: theme.palette.primary.medium,
                  color: theme.palette.primary.light,
                  borderRadius: 1.5,
                }}
              />
            </Grid>
          ))} */}
          <Grid item xs={12} pl={2} mt={2}>
            <PaginatedList />
          </Grid>
        </Grid>
      </DialogMedium>
      <FormFilters onSubmit={(values) => onSubmitFilters(values)} values={initialValues} />
      {managementsQuery.isLoading && <Loading />}
      <Grid container spacing={2}>
        {!!managementsQuery.data && (
          <Grid item container xs={12} spacing={1}>
            <DynamicSections
              shape={sections}
              header
              gridItemSize={3}
              cardSx={{ cursor: analyticalReport ? "pointer" : "default" }}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
