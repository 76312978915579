import { Box, Grid, Typography, useTheme } from "@mui/material";
import { ReactComponent as SOAP } from "../../../assets/svg/SOAP.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { Assignment } from "@mui/icons-material";
import useNotifier from "../../../hooks/useNotifier";
import { useContext, useState } from "react";
import ConfirmTransferDialog from "../transferDialogConfirm";
import { AuthContext } from "../../../contexts/AuthContext";
export default function OdontoServices({ attendance }) {
  const navigate = useNavigate();
  const location = useLocation();
  const notify = useNotifier();
  const { userData } = useContext(AuthContext);
  const [confirmTransfer, setConfirmTransfer] = useState({
    open: false,
    navigateTo: "",
  });

  const onlyHistory = location.pathname === "/patient";
  const isWithoutEmployee = !attendance.patient.id_employee;
  const isOwner = userData.id === attendance.patient.id_employee;

  const services = [
    {
      icon: <SOAP />,
      label: "Odonto",
      handleClick: () => {
        if (isWithoutEmployee || isOwner || onlyHistory) {
          navigate(`/soap/odonto/${attendance.patient?.id}/${attendance.id}`, {
            state: {
              isHistory: onlyHistory,
              backTo: location.pathname,
              canReevaluate: !!attendance?.classification,
            },
          });
        } else {
          setConfirmTransfer({
            open: true,
            navigateTo: `/soap/ceo/${attendance.patient?.id}/${attendance.id}`,
          });
        }
      },
    },
    {
      icon: <SOAP />,
      label: "CEO",
      handleClick: () => {
        if (isWithoutEmployee || isOwner || onlyHistory) {
          navigate(`/soap/ceo/${attendance.patient?.id}/${attendance.id}`, {
            state: {
              isHistory: location.pathname === "/patient",
              backTo: location.pathname,
              canReevaluate: !!attendance?.classification,
            },
          });
        } else {
          setConfirmTransfer({
            open: true,
            navigateTo: `/soap/ceo/${attendance.patient?.id}/${attendance.id}`,
          });
        }
      },
    },
    {
      icon: (
        <Box display={"flex"} alignContent={"center"} alignItems={"center"}>
          <Assignment
            color="primary"
            sx={{
              fontSize: 70,
            }}
          />
          <Typography
            sx={{
              fontSize: 40,
              color: "#9B7FFF",
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "18px",
            }}
          >
            AFERIÇÃO
          </Typography>
        </Box>
      ),
      label: "Aferição",
      handleClick: () => {
        notify("Módulo em desenvolvimento", "warning");
      },
    },
  ];
  const theme = useTheme();
  return (
    <>
      <ConfirmTransferDialog
        open={confirmTransfer.open}
        handleClose={() =>
          setConfirmTransfer({
            open: false,
          })
        }
        navigateTo={confirmTransfer.navigateTo}
        attendance={attendance}
      />
      <Grid
        container
        xs={"auto"}
        sx={{ marginTop: 2, maxWidth: "90vw" }}
        gap={2}
      >
        {services.map((service, index) => (
          <Grid
            sx={{
              cursor: !!service.handleClick ? "pointer" : "",
              height: "125px",
              textAlign: "center",
              border: `1px solid ${theme.palette.primary.main}`,
              borderRadius: "10px",
              fontWeight: 400,
              fontSize: "17px",
              lineHeight: "18px",
              fontFamily: "Montserrat",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
            }}
            key={index}
            onClick={service.handleClick}
            item
            xs={index !== 2 ? 5.8 : 12}
          >
            {service.icon}
            <Typography fontSize={14} color="secondary.main">
              {service.label}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
