import { Button, TablePagination } from "@mui/material";
import { Box } from "@mui/system";
import { parseInt } from "lodash";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ConfirmDialog, Dialog } from "../../../helper";
import useMonitoring from "../../../service/useMonitoring";
import DeclarationsTable from "../Declarations/DeclarationsTable";
import { PatientInfo } from "../PatientInfo";
import PatientListTable from "./PatientListTable";
import useNotifier from "../../../hooks/useNotifier";

export default function DeclarationsPatientsList({ patientId, initializeAttendance }) {
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [selectedMonitoring, setSelectedMonitoring] = React.useState(0);
  const [limitOfDosuments, setLimitOfDocuments] = React.useState(10);
  const [documentsPage, setDocumentsPage] = React.useState(0);
  const [searchFilter, setSearchFilter] = React.useState({});
  const [openDialog, setOpenDialog] = React.useState(false);
  const queryClient = useQueryClient();
  const [openConfirmStartAttendance, setOpenConfirmStartAttendance] = React.useState({
    open: false,
  });
  const { getMonitorings, getPatientDocuments, updateWorkList } = useMonitoring();
  const notify = useNotifier();
  const updateWorkListStatusMutation = useMutation(updateWorkList);

  const documentsPatientsList = useQuery(
    ["documentsPatientsList"],
    () =>
      getMonitorings({
        page,
        limit,
        work_list_status: patientId ? "finished" : "unfinished",
        ...searchFilter,
        type: "documentation",
        id_patient: patientId,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: () => {
        queryClient.setQueriesData("documentsPatientsList", []);
      },
    }
  );

  const documentsFromPatient = useQuery(["documentsFromPatient"], () => {
    if (selectedMonitoring)
      return getPatientDocuments(selectedMonitoring?.attendance?.patient?.id, {
        page: documentsPage,
        limit: limitOfDosuments,
        attendance_id: selectedMonitoring?.attendance?.id,
        has_soap: true,
      });
  });

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedMonitoring();
    documentsPatientsList.refetch();
  };

  const updateWorkListStatus = async (attendanceID) => {
    updateWorkListStatusMutation.mutate(
      { attendanceID, changes: { documentation: "finished" } },
      {
        onSuccess: () => {
          documentsPatientsList.refetch();
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      }
    );
  };

  React.useEffect(() => {
    documentsFromPatient.refetch();
  }, [selectedMonitoring, documentsPage, limitOfDosuments]);

  React.useEffect(() => {
    documentsPatientsList.refetch();
  }, [searchFilter]);

  return (
    <Box sx={{ pt: 5 }}>
      <ConfirmDialog
        open={openConfirmStartAttendance.open && !!selectedMonitoring?.attendance.id}
        message={"Iniciar atendimento?."}
        handleClose={() => {
          setOpenConfirmStartAttendance({ open: false });
        }}
        handleConfirm={() => {
          initializeAttendance(selectedMonitoring?.attendance.id, true);
          openConfirmStartAttendance.onConfirm();
        }}
        actions
        maxWidth={"sm"}
        fullWidth
      />
      <Button
        variant="contained"
        sx={{
          marginBottom: "10px",
        }}
        disabled={documentsPatientsList.isLoading}
        onClick={() => {
          notify("Lista Atualizada");
          documentsPatientsList.refetch();
        }}
      >
        Atualizar
      </Button>
      <Dialog title={"Documentos"} open={openDialog} handleClose={handleClose}>
        <PatientInfo attendance={selectedMonitoring?.attendance} />
        <DeclarationsTable
          documents={documentsFromPatient.data?.items}
          patient={selectedMonitoring?.attendance?.patient}
        />
        <TablePagination
          count={documentsFromPatient.data?.totalItems}
          component="div"
          page={documentsPage}
          onPageChange={(_, newPage) => {
            setDocumentsPage(newPage);
          }}
          rowsPerPage={limitOfDosuments}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={(event) => {
            setLimitOfDocuments(parseInt(event.target.value, 10));
            setDocumentsPage(0);
          }}
        />
      </Dialog>
      <PatientListTable
        data={documentsPatientsList.data?.items}
        loading={documentsPatientsList.isFetching}
        actionsTypes={patientId ? ["checklist"] : ["checklist", "finish"]}
        callType={"documentation"}
        totalItems={documentsFromPatient.data?.totalItems}
        error={documentsFromPatient.error}
        noEvolutionStatus
        setOpenDialog={() =>
          setOpenConfirmStartAttendance({
            open: true,
            onConfirm: () => {
              setOpenDialog(true);
            },
          })
        }
        {...{
          page,
          limit,
          setLimit,
          setPage,
          setSearchFilter,
          setSelectedMonitoring,
          updateWorkListStatus,
        }}
      />
    </Box>
  );
}
