import { Warning } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

export default function WarningSpan({ text, fontSize }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "start",
        justifyContent: "center",
        columnGap: 1,
        color: (t) => t.palette.error.main,
        bgcolor: "rgba(216, 0, 0, 0.1)",
        padding: 1,
        borderRadius: "10px",
        width: "fit-content",
      }}
    >
      <Warning sx={{ fontSize: '16px' }} />
      <Typography fontSize={fontSize ?? "initial"} component={"span"}>
        {text}
      </Typography>
    </Box>
  );
}
