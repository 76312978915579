import instance from "../config/api"
import urlQuery from "../helper/urlQuery"
const path = "/domiciliaries"

function useDomiciliaries() {
  const getDomiciliaries = async (params) => {
    try {
      const response = await instance.get(path, {
        params: urlQuery(params)
      });
      return response.data;
    } catch (err) {
      throw new Error (err.response.data.message);
    }
  }
  
  const getDomiciliary = async (id) => {
    try {
      const response = await instance.get(`${path}/${id}`);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  const getDomiciliaryHistoric = async (id, params) => {
    try {
      const response = await instance.get(`${path}/historics/${id}`, {
        params: urlQuery(params)
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  const postDomiciliary = async (data) => {
    try {
      const response = await instance.post(path, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  return {
    getDomiciliaries,
    getDomiciliary,
    getDomiciliaryHistoric,
    postDomiciliary,
  }
}

export default useDomiciliaries