import React, { useContext, useEffect, useState } from "react";
import Yup from "../../../config/yup";
import { Grid, Box, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircleOutline";
import { AppContext } from "../../../contexts/AppContext";
import { usePresets, useProcedures } from "../../../service";
import { drugAdmininstrations } from "../../../config/standardSelects";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  AutocompleteField,
  Button,
  PaginatedAutocompleteField,
  SwitchField,
  TextField,
  TransferList,
} from "../../../components/FormFields";
import { useNotifier } from "../../../hooks";
import AllergyButton from "../../Patient/AllergyHistory/AllergyButton";
import { AuthContext } from "../../../contexts/AuthContext";
import useSignaturePassword from "../../../hooks/useSignaturePassword";
import {
  drugOnSitePrescriptionPDF,
  drugPrescriptionPDF,
  openURL,
  specialPrescriptionPDF,
} from "../../../pdfModels";
import usePrescriptions from "../../../service/usePrescriptions";
import { useMutation, useQuery, useQueryClient } from "react-query";
import DrugPrescriptionCard from "./DrugPrescriptionCard";
import { DialogMedium } from "../../../helper";
import { groupPrescriptionCollection } from "../../../utils";

const pdfTypes = {
  medications_on_site: drugOnSitePrescriptionPDF,
  medications: drugPrescriptionPDF,
  special_medications: specialPrescriptionPDF,
};

function DrugPrescription({
  type,
  drugPrescriptions,
  setDrugPrescriptions,
  attendance,
  allergies,
  isExternal,
}) {
  const notify = useNotifier();
  const validateSignature = useSignaturePassword();
  const { userData } = useContext(AuthContext);
  const { disabledFields, patientData } = useContext(AppContext);
  const [openAllergyAlert, setOpenAllergyAlert] = useState(false);
  const [openGroupForm, setOpenGroupForm] = useState(false);
  const [groupToEdit, setGroupToEdit] = useState(null);

  const queryClient = useQueryClient();
  const { getProcedures } = useProcedures();
  const { postPrescription, patchPrescription, getPrescriptions } = usePrescriptions();
  const { getPresets } = usePresets();

  const postPrescriptionMutation = useMutation(postPrescription);
  const patchPrescriptionMutation = useMutation(patchPrescription);

  const currentPrescriptionQuery = useQuery(
    ["current-prescription", type],
    () =>
      getPrescriptions(attendance.patient.id, {
        page: 0,
        limit: 1,
        created_by_me: true,
        created_from_soap: !isExternal,
        has_soap: false,
        attendance_id: attendance.id,
      }),
    {
      onSuccess(response) {
        setDrugPrescriptions((items) => {
          const unsignedItems = items.filter((item) => !item.id_prescription);
          return response.items[0][type].concat(unsignedItems);
        });
      },
    }
  );

  const initialValues = {
    in_system: true,
    name: null,
    quantity: "",
    presentation: "",
    continuous_use: false,
    special: false,
    posology: "",
    drug_administration: null,
    notes: "",
    medication_assisted_treatment: false,
    treatment_cycle: null,
    preset: [],
  };

  const validations = Yup.object().shape({
    in_system: Yup.boolean(),
    name: Yup.mixed().when("in_system", {
      is: true,
      then: Yup.object().required("É requerido").nullable(),
      otherwise: Yup.string().typeError("Valor inválido").required("É requerido").nullable(),
    }),
    quantity: Yup.string()
      .when("continuous_use", {
        is: false,
        then: (schema) =>
          schema.min(0.1, "A quantidade deve ser maior que 0").required("É requerido"),
        otherwise: (schema) => schema,
      })
      .nullable(),
    presentation: Yup.string().required("É requerido").nullable(),
    posology: Yup.string().required("É requerido"),
    continuous_use: type === "medications" ? Yup.boolean().required("É requerido") : Yup.boolean(),
    drug_administration: Yup.object().required("É requerido").nullable(),
    notes: Yup.string().nullable(),
    treatment_cycle: Yup.number().when("medication_assisted_treatment", {
      is: true,
      then: (schema) =>
        schema
          .min(1, "Quantidade de dias deve ser maior que 0")
          .required("Quantidade de dias requerido")
          .nullable(),
      otherwise: (schema) => schema.nullable(),
    }),
  });

  const groupForm = useForm({
    defaultValues: {
      group: [],
    },
  });

  const [groupedMedications] = groupForm.watch(["group"]);

  const prescriptionForm = useForm({
    resolver: yupResolver(validations),
    defaultValues: initialValues,
  });

  const [in_system, medication_assisted_treatment, continuous_use] = prescriptionForm.watch([
    "in_system",
    "medication_assisted_treatment",
    "continuous_use",
  ]);

  function toggleGroupForm() {
    if (openGroupForm) {
      groupForm.reset();
    }

    setOpenGroupForm((open) => !open);
  }

  function handlePresets(selectedItems) {
    const presets = selectedItems?.flatMap((value) => value.data) || [];
    presets.forEach((preset, index) => {
      preset.id = drugPrescriptions.length + 1 + index + 1;
    });

    setDrugPrescriptions((medications) => {
      const filteredMedications = medications.filter(
        ({ notPreseted, id_prescription }) => notPreseted || id_prescription
      );
      return filteredMedications.concat(presets);
    });
  }

  const handleAddPrescription = prescriptionForm.handleSubmit((values) => {
    const medication = {
      ...values,
      id: drugPrescriptions.length + 1,
      name: typeof values.name === "string" ? values.name : values.name?.name,
      tuss_code: values.name?.tuss_code,
      procedure_id: values.drug_administration?.procedure_id || null,
      special: values.special,
      notPreseted: true,
    };

    if (userData.company.id === "55e1278d-8749-4576-896f-25df0167a478" && !medication.tuss_code) {
      medication.tuss_code = "0301100012";
    }

    delete medication.drug_administration;
    delete medication.in_system;
    delete medication.preset;

    if (type === "medications" && medication.continuous_use) {
      medication.quantity = null;
    }

    if (type === "medications_on_site") {
      delete medication.continuous_use;
    }

    setDrugPrescriptions((prescriptions) => [...prescriptions, medication]);
    prescriptionForm.reset({ preset: values.preset });
  });

  function handleEditGroup(data) {
    groupForm.reset({ group: data }, { keepDefaultValues: true });
    toggleGroupForm();
    setGroupToEdit(data);
  }

  const handleRemovePrescription = (data) => {
    const isGroup = Array.isArray(data);

    const newMedications = drugPrescriptions.filter((medication) => {
      if (isGroup) {
        return medication.tab_id !== data[0].tab_id;
      }

      return medication.id !== data.id;
    });
    setDrugPrescriptions(newMedications);
  };

  function verifyDisabledSave(medications) {
    let hasUngrouped = false;
    let hasOnlyRegistered = true;

    medications.forEach((medication) => {
      const isGroup = Array.isArray(medication);

      if (!hasUngrouped) {
        hasUngrouped = !isGroup;
      }

      if (hasOnlyRegistered && isGroup) {
        hasOnlyRegistered = !!medication[0].id_prescription;
      }
    });

    return disabledFields || hasUngrouped || hasOnlyRegistered;
  }

  function handlePrint(data) {
    const firstItem = data[0];
    const isSpecial = firstItem.special;
    let documentType = type;

    if (isSpecial) {
      documentType = "special_medications";
    }

    const signedDocument = currentPrescriptionQuery.data.items[0].signed_documents.find(
      (signedDocument) => {
        return signedDocument.type === documentType && signedDocument.tab_id === firstItem.tab_id;
      }
    );

    if (signedDocument.result) {
      return openURL(signedDocument.result);
    }

    const pdf = pdfTypes[documentType](data, patientData, true);
    pdf.open();
  }

  async function mountDocuments(groups) {
    const documentosToSign = [];
    let signSettingsDrugPresc = {
      visible_sign_x: 215,
      visible_sign_y: 590,
      visible_sign_page: "*",
    };

    for (const group of groups) {
      let documentType = type;

      if (type === "medications_on_site") {
        signSettingsDrugPresc = {
          visible_sign_x: 180,
          visible_sign_y: 600,
          visible_sign_page: "*",
        };
      }

      if (group[0].special) {
        documentType = "special_medications";
      }

      const pdf = pdfTypes[documentType](group, patientData);

      documentosToSign.push({
        id: pdf.name.replace(".pdf", ""),
        tab_id: group[0].tab_id,
        original_file_name: pdf.name,
        data: await pdf.base64(),
        type: documentType,
        signature_settings: signSettingsDrugPresc,
      });
    }

    return documentosToSign;
  }

  async function onSubmitPrescription() {
    await validateSignature(async () => {
      const cleanedMedications = [];
      const groupedMedications = [];

      drugPrescriptions.forEach(({ id, ...medication }) => {
        if (!medication.id_prescription) {
          cleanedMedications.push(medication);
        }

        groupPrescriptionCollection(groupedMedications, medication);
      });

      const data = {
        attendance_id: attendance.id,
        created_from_soap: !isExternal,
        to_sign: await mountDocuments(groupedMedications),
        [type]: cleanedMedications,
      };

      if (currentPrescriptionQuery.isSuccess) {
        return patchPrescriptionMutation.mutate(
          { id: currentPrescriptionQuery.data.items[0].id, data },
          {
            onSuccess(response) {
              const hasPendencies = queryClient.getQueryData("verify-has-pendencies");

              if (!hasPendencies) {
                queryClient.invalidateQueries("verify-has-pendencies");
              }

              prescriptionForm.reset();
              setDrugPrescriptions([]);
              currentPrescriptionQuery.refetch();
              notify(response.message, "success");
            },
            onError(error) {
              notify(error.message, "error");
            },
          }
        );
      }

      postPrescriptionMutation.mutate(data, {
        onSuccess(response) {
          prescriptionForm.reset();
          setDrugPrescriptions([]);
          currentPrescriptionQuery.refetch();
          notify(response.message, "success");
        },
        onError(error) {
          notify(error.message, "error");
        },
      });
    });
  }

  const groupPrescriptions = groupForm.handleSubmit(({ group }) => {
    let lastGroupId = 0;
    const newPrescriptions = [];

    if (groupToEdit) {
      lastGroupId = group[0].tab_id - 1;
    } else {
      lastGroupId = drugPrescriptions.reduce((previous, current) => {
        if (current.tab_id) {
          return previous < current.tab_id ? current.tab_id : previous;
        }

        return previous;
      }, lastGroupId);
    }

    drugPrescriptions.forEach((prescription) => {
      const isGrouped = group.some((groupedPrescription) => {
        return groupedPrescription.id === prescription.id;
      });

      if (isGrouped) {
        prescription.tab_id = lastGroupId + 1;
      } else if (prescription.tab_id === group[0].tab_id) {
        delete prescription.tab_id;
      }

      newPrescriptions.push(prescription);
    });

    setDrugPrescriptions(newPrescriptions);
    toggleGroupForm();
    setGroupToEdit(null);
  });

  function hasNoUngroupedMedications() {
    return !drugPrescriptions.some((exam) => !exam.tab_id);
  }

  useEffect(() => {
    const hasAllergy =
      allergies && Object.entries(allergies?.allergy_substances).some(([_, value]) => value);

    if (hasAllergy) {
      setOpenAllergyAlert(true);
      notify("Paciente contém alergia.", "warning");
    }
  }, [allergies]);

  const medications = [];
  const ungroupedMedications = [];

  if (groupToEdit) {
    ungroupedMedications.push(...groupToEdit);
  }

  drugPrescriptions.forEach((exam) => {
    if (exam.tab_id) {
      groupPrescriptionCollection(medications, exam);
    } else {
      medications.push(exam);
      ungroupedMedications.push(exam);
    }
  });

  return (
    <>
      <DialogMedium
        open={openGroupForm}
        title="Agrupamento de medicamentos"
        fullWidth
        handleClose={toggleGroupForm}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxWidth: "800px",
              "& .MuiDialogContent-root": {
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                gap: "20px",
                "& > div + div": {
                  display: "flex",
                  gap: "20px",
                },
              },
            },
          },
        }}
      >
        <TransferList
          name="group"
          control={groupForm.control}
          options={ungroupedMedications}
          optionLabelKey="name"
          optionCompareKey="id"
          filterOptions={(option) => {
            if (!groupedMedications.length) return true;

            const isSpecial = groupedMedications[0].special;

            if (isSpecial && option.special) {
              return true;
            }

            if (!isSpecial && !option.special) {
              return true;
            }

            return false;
          }}
        />
        <Box>
          <Button color="error" onClick={toggleGroupForm}>
            Cancelar
          </Button>
          <Button disabled={!groupedMedications.length} onClick={groupPrescriptions}>
            Agrupar
          </Button>
        </Box>
      </DialogMedium>
      <Grid component="form" container spacing={3} marginTop="0.5rem" position={"relative"}>
        <Grid container item xs={12} spacing={2}>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            paddingRight={5}
            alignItems={"start"}
          >
            <Typography fontSize={16} color="secondary" fontWeight="600">
              Selecionar pré definição
            </Typography>
            <AllergyButton
              openAllergyAlert={openAllergyAlert}
              setOpenAllergyAlert={setOpenAllergyAlert}
              attendance={attendance}
              allergies={allergies}
            />
          </Grid>
          <Grid md={5} xs={8} item>
            <PaginatedAutocompleteField
              multiple
              name="preset"
              control={prescriptionForm.control}
              queryKey={"presets"}
              label="Predefinição"
              optionLabelKey={"describe"}
              customOnChange={handlePresets}
              service={(params) => getPresets({ ...params, type }) || []}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography fontSize={16} color="secondary" fontWeight="600">
              Medicamento
            </Typography>
          </Grid>
          <Grid item xs={3.7}>
            <SwitchField
              name="in_system"
              label="Medicamento no sistema ?"
              control={prescriptionForm.control}
              customOnChange={() => prescriptionForm.setValue("name", null)}
              required
            />
          </Grid>
          <Grid xs={8.3} item>
            {in_system ? (
              <PaginatedAutocompleteField
                control={prescriptionForm.control}
                name="name"
                label="Medicamento"
                queryKey={"preseting"}
                service={async (params) =>
                  getProcedures({
                    ...params,
                    type: "medications",
                    filter: "active",
                  })
                }
                getOptionLabel={(data) => {
                  let label = data?.name;

                  if (data?.tuss_code) {
                    label = `${data?.tuss_code} - ${label}`;
                  }

                  return label;
                }}
                filterKey="filters"
                required
                optionLabelKey={"describe"}
              />
            ) : (
              <TextField
                control={prescriptionForm.control}
                name="name"
                label="Medicamento"
                required
              />
            )}
          </Grid>
          <Grid xs={2.8} item>
            <TextField
              name="quantity"
              label="Quantidade"
              type="number"
              disabled={continuous_use}
              required={!continuous_use}
              control={prescriptionForm.control}
            />
          </Grid>
          <Grid xs={6} md={3} item>
            <TextField
              name="presentation"
              inputProps={{ maxLength: 50 }}
              label="Apresentação"
              required
              control={prescriptionForm.control}
            />
          </Grid>
          <Grid xs={type === "medications_on_site" ? 3 : 3.2} item>
            <AutocompleteField
              control={prescriptionForm.control}
              name="drug_administration"
              label="Via de admininstração"
              optionCompareKey="procedure_id"
              options={drugAdmininstrations}
              autoCompleteProps={{
                getOptionLabel: (data) => data.describe + " - " + data.title,
              }}
              required
            />
          </Grid>
          {type === "medications_on_site" && (
            <Grid item xs={3.2}>
              <SwitchField
                name="medication_assisted_treatment"
                label="Medicação Assistida ?"
                control={prescriptionForm.control}
              />
            </Grid>
          )}
          {type === "medications" && (
            <Grid xs={3} item container justifyContent="center">
              <SwitchField
                name="continuous_use"
                label="Uso contínuo ?"
                control={prescriptionForm.control}
              />
            </Grid>
          )}
          <Grid xs={type === "medications" || medication_assisted_treatment ? 9 : 12} item>
            <TextField
              name="posology"
              inputProps={{ maxLength: 100 }}
              label="Posologia"
              required
              control={prescriptionForm.control}
            />
          </Grid>
          {type === "medications_on_site" && medication_assisted_treatment && (
            <Grid xs={3} item>
              <TextField
                name="treatment_cycle"
                label="Quantidade em dias"
                type="number"
                placeholder="quantidade em dias ex: 20"
                required={medication_assisted_treatment}
                control={prescriptionForm.control}
              />
            </Grid>
          )}
          {type === "medications" && (
            <Grid xs={3} item container justifyContent="center">
              <SwitchField
                name="special"
                label="Receituário Especial?"
                control={prescriptionForm.control}
              />
            </Grid>
          )}
          <Grid xs={12} item>
            <TextField name="notes" label="Observação" control={prescriptionForm.control} />
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr auto",
          gridAutoFlow: "column",
          margin: "1.2rem auto",
          gap: "8px",
        }}
      >
        <Button
          variant="text"
          onClick={toggleGroupForm}
          sx={{
            justifySelf: "start",
          }}
          disabled={hasNoUngroupedMedications()}
        >
          Agrupar
        </Button>
        <Button variant="text" onClick={handleAddPrescription} startIcon={<AddCircleIcon />}>
          Adicionar Medicamento
        </Button>
      </Box>
      <Grid container spacing={2}>
        {medications.map((data) => {
          return (
            <DrugPrescriptionCard
              key={Array.isArray(data) ? data[0].id : data.id}
              data={data}
              handleRemove={() => handleRemovePrescription(data)}
              handleEdit={() => handleEditGroup(data)}
              handlePrint={() => handlePrint(data)}
            />
          );
        })}
      </Grid>
      <Box display="flex" justifyContent="center" margin="1.5rem auto">
        <Button
          loadingMessage={currentPrescriptionQuery.isFetching ? "Carregando..." : "Enviando..."}
          loading={
            postPrescriptionMutation.isLoading ||
            patchPrescriptionMutation.isLoading ||
            currentPrescriptionQuery.isFetching
          }
          disabled={verifyDisabledSave(medications)}
          onClick={onSubmitPrescription}
        >
          Salvar
        </Button>
      </Box>
    </>
  );
}

export default DrugPrescription;
