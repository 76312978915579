import { Box, Grid, Grow, Typography, styled } from "@mui/material";
import { useAttendaces, useProcedures } from "../../../service";
import AddCircleIcon from "@mui/icons-material/AddCircleOutline";
import { useContext } from "react";
import { PaginatedScrollList } from "../../../components/PaginatedScrollList";
import { AppContext } from "../../../contexts/AppContext";
import Yup from "../../../config/yup";
import { DeleteIconButton } from "../../../components/Buttons";
import {
  Button,
  PaginatedAutocompleteField,
  SwitchField,
  TextField,
} from "../../../components/FormFields";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { verifyAttendanceFlow } from "../../../utils";
import { useFormikContext } from "formik";
import { useQuery } from "react-query";
import { AuthContext } from "../../../contexts/AuthContext";

const CardPrescription = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  gap: 15,
  backgroundColor: theme.palette.primary.medium,
  borderRadius: 8,
  padding: "0.8rem",
  color: theme.palette.primary.light,
  position: "relative",
}));

const PrescriptionHeader = styled(Typography, {
  shouldForwardProp: (prop) => {
    return prop !== "color" && prop !== "sx";
  },
})(({ theme }) => ({
  fontSize: 20,
  fontWeight: 600,
  color: theme.palette.primary.light,
}));

const PrescriptionDesc = styled(Typography, {
  shouldForwardProp: (prop) => {
    return prop !== "color" && prop !== "sx";
  },
})(({ theme }) => ({
  fontSize: 13,
  marginRight: 20,
  color: theme.palette.primary.light,
  wordBreak: "break-word",
}));

export default function InterventionForm({
  attendance,
  handleSave = () => null,
}) {
  const { userData } = useContext(AuthContext);
  const {
    interventions,
    setInterventions,
    disableFields,
    patientData,
    isRenderInterventions,
    setIsRenderInterventions,
  } = useContext(AppContext);
  const { values } = useFormikContext();

  const { getProcedures } = useProcedures();
  const { getPreloadsByAttendance } = useAttendaces();

  const initialValues = {
    procedure: null,
    done: null,
    notes: "",
  };

  const schema = Yup.object().shape({
    procedure: Yup.object().nullable().required("É requerido"),
  });

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const hasPreloadedInterventions = interventions.some(
    (intervention) => !!intervention.preload_id
  );

  useQuery(
    ["intervetion-preloads"],
    () =>
      getPreloadsByAttendance(attendance.id, {
        type: "intervention",
        created_by_me: !userData?.company.type.CEM,
      }),
    {
      enabled: !isRenderInterventions && !hasPreloadedInterventions,
      onSuccess(response) {
        const formattedInterventions = response.map((preload) => ({
          done: true,
          name: preload.procedure.name,
          tuss_code: preload.procedure.tuss_code,
          notes: null,
          procedure_id: preload.procedure.id,
          preload_id: preload.id,
        }));

        setInterventions((interventions) =>
          interventions.concat(formattedInterventions)
        );
        setIsRenderInterventions(true);
      },
    }
  );

  const handleAdd = (values) => {
    values.name = values.procedure.name;
    values.procedure_id = values.procedure.id;
    values.done = values.done === false ? null : values.done;
    values.tuss_code = values.procedure.tuss_code;

    delete values.procedure;

    setInterventions((curr) => [...curr, values]);
    reset();
  };

  const removeProcedure = (index) => {
    setInterventions((curr) => curr.filter((_, i) => i !== index));
  };

  const ProcedureListItem = ({ addedProcedure, index }) => {
    return (
      <Grid xs={12} item>
        <Grow in={true} timeout={300}>
          <CardPrescription>
            <PrescriptionHeader variant="h3" component="h1">
              {`${addedProcedure.tuss_code} - ${addedProcedure.name}`}
            </PrescriptionHeader>
            <PrescriptionDesc variant="p" component="p">
              {`${addedProcedure.notes || ""} - ${
                addedProcedure.done ? "Realizado" : "Não realizado"
              }`}
            </PrescriptionDesc>
            <DeleteIconButton
              sx={{
                position: "absolute",
                top: 6,
                right: 15,
              }}
              onClick={() => removeProcedure(index)}
            />
          </CardPrescription>
        </Grow>
      </Grid>
    );
  };

  return (
    <>
      <Grid
        component="form"
        container
        item
        xs={12}
        spacing={2}
        marginTop="0.5rem"
      >
        <Grid item xs={12}>
          <Typography fontSize={16} color="secondary" fontWeight="600">
            Procedimento
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <PaginatedAutocompleteField
            name="procedure"
            control={control}
            queryKey={"procedures"}
            label="Procedimento"
            optionLabelKey={"name"}
            filterKey="filters"
            autoCompleteProps={{
              getOptionLabel: (data) => {
                let label = data?.name;

                if (data?.tuss_code) {
                  label = `${data?.tuss_code} - ${label}`;
                }

                return label;
              },
            }}
            service={async (params) => {
              const types = ["procedure"];

              if (userData?.company.type.CEM) {
                types.push(
                  "woman_diagnostic_with_densitometry",
                  "woman_diagnostic"
                );
              }

              return getProcedures({
                ...params,
                type: types,
                filter: "active",
                patient_id: patientData?.id,
                attendance_flow: verifyAttendanceFlow(
                  attendance.risk_classification_id
                ),
                cid_id: values?.cid?.map(({ id }) => id),
              });
            }}
          />
        </Grid>
        <Grid item xs={3} display={"flex"} alignItems={"center"}>
          <SwitchField name={"done"} label={"Realizado"} control={control} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="notes"
            label="Anotações"
            multiline
            minRows={8}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" margin="1.2rem auto">
            <Button
              type="submit"
              variant="text"
              onClick={handleSubmit(handleAdd)}
              sx={{ color: (t) => t.palette.primary.light }}
              startIcon={<AddCircleIcon />}
            >
              Adicionar procedimento
            </Button>
          </Box>
        </Grid>
      </Grid>
      <PaginatedScrollList
        component={(item, index) => (
          <ProcedureListItem
            addedProcedure={item}
            key={item.procedure_id}
            index={index}
          />
        )}
        data={interventions}
        emptyMessage="Adicione procedimentos."
        endMessage=""
        minHeight="50px"
      />
      <Box display="flex" justifyContent="center" margin="1.5rem auto">
        <Button
          type="submit"
          onClick={handleSave}
          disabled={disableFields || !interventions.length}
        >
          Salvar
        </Button>
      </Box>
    </>
  );
}
