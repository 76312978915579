import React from "react";
import { useMutation, useQuery } from "react-query";
import { DialogMedium } from "../../../helper";
import { usePagination, useProvider, useServices } from "../../../service";
import TableFilter from "../../../components/Table/TableFilter";
import { Box, Button, Grid, TablePagination, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import Yup from "../../../config/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AutocompleteField from "../../../components/FormFields/AutocompleteField";
import SettingsEditService from "../SettingsUpdatesDialogs/SettingsEditService";
import { useTheme } from "@emotion/react";
import { TextField } from "../../../components/FormFields";
import { useNotifier } from "../../../hooks";

export default function ServicesStep() {
  const theme = useTheme();

  const notify = useNotifier();
  const { getServices, postService } = useServices();
  const { getTypes } = useProvider();

  const [limit, setLimit] = React.useState(5);
  const [selectedService, setSelectedService] = React.useState();
  const [editServiceDialog, setEditServiceDialog] = React.useState(false);

  const createServiceMutation = useMutation(postService);

  const servicesQuery = useQuery(["services"], getServices, {
    retry: false,
    initialData: [],
    onError: (err) => {
      notify(err.message, "error");
    },
  });

  const typesQuery = useQuery(["providers"], () => getTypes(), {
    retry: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    initialData: { data: [] },
    onError: (error) => {
      notify(error.message, "error");
    },
  });

  const { paginationInfo, handlePageChange } = usePagination(
    servicesQuery.data,
    limit
  );

  const validatorService = Yup.object().shape({
    describe: Yup.string().required("É requerido"),
    employee_types: Yup.array()
      .of(Yup.object())
      .min(1, "Selecione ao menos um tipo de profissional"),
    prefix: Yup.string()
      .max(4, "O prefixo deve ter no máximo 4 caracteres.")
      .test(
        "not-number",
        "Prefixo inválido.",
        (value) => !value || value?.match(/^[A-Za-z]+$/)
      ),
    info: Yup.string(),
    subtitle: Yup.string()
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      describe: "",
      employee_types: [],
      classification_risk: false,
      prefix: "",
      info: "",
      subtitle: ""
    },
    resolver: yupResolver(validatorService),
  });

  const columnsServices = [
    {
      field: "number",
      name: "Código",
      type: "string",
    },
    {
      field: "describe",
      name: "Descrição",
      type: "string",
    },
    {
      field: "active",
      name: "Status",
      type: "string",
      use: (value) => (value ? "Ativo" : "Inativo"),
    },
    {
      field: "classification_risk",
      name: "Classificação",
      type: "string",
      use: (value) => (value ? "Ativo" : "Inativo"),
    },
    {
      field: "unscheduled",
      name: "Não agendado",
      type: "string",
      use: (value) => (value ? "Sim" : "Não"),
    },
    {
      field: "work_list",
      name: "Work-List",
      type: "string",
      use: (value) => (value ? "Ativo" : "Inativo"),
    },
    {
      field: "has_priority",
      name: "Prioridade no Totem",
      type: "string",
      use: (value) => (value ? "Sim" : "Não"),
      alignInRow: "center",
      alignInHead: "center",
    },
  ];

  const onSubmit = handleSubmit((data) => {
    const dataSubmit = {
      describe: data.describe,
      prefix: data.prefix,
      info: data.info,
      employee_types: data.employee_types.map((i) => i.id),
      subtitle: data.subtitle
    };

    createServiceMutation.mutate(dataSubmit, {
      onSuccess: (res) => {
        notify(res.message, "success");
        reset();
        servicesQuery.refetch();
      },
      onError: (err) => {
        notify(err.message, "error");
      },
    });
  });

  const handleCloseDialog = () => {
    setEditServiceDialog(false);
    setSelectedService(null);
  };

  return (
    <>
      <DialogMedium
        fullWidth
        maxWidth="md"
        open={editServiceDialog}
        title={"Edição de serviço"}
        handleClose={handleCloseDialog}
      >
        <SettingsEditService
          service={selectedService}
          employeeTypes={typesQuery.data.data || []}
          handleClose={handleCloseDialog}
        />
      </DialogMedium>
      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        <Typography
          color="textSecondary"
          style={{
            fontFamily: "Montserrat",
            color: theme.palette.primary.light,
            fontSize: "16px",
          }}
        >
          Serviços
        </Typography>
      </Box>
      <Box pt={3}>
        <TableFilter
          data={paginationInfo.items || []}
          columns={columnsServices}
          actions
          loading={servicesQuery.isLoading}
          actionUniqueIdentifier={"id"}
          actionsTypes={["edit"]}
          actionHandleEdit={(_, schedule) => {
            setSelectedService(schedule);
            setEditServiceDialog(true);
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={paginationInfo.totalItems}
            component="div"
            page={paginationInfo.currentPage - 1}
            onPageChange={(_, newPage) => {
              handlePageChange(newPage + 1);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={({ target }) => {
              setLimit(parseInt(target.value, 10));
              handlePageChange(1);
            }}
          />
        </Box>
      </Box>

      <Grid container xs={12} spacing={3}>
        <Grid item xs={12}>
          <Typography
            sx={{
              color: theme.palette.primary.light,
              fontWeight: "bold",
            }}
          >
            Adicionar Serviço
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            control={control}
            label="Descrição"
            name="describe"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteField
            control={control}
            id="employee_types"
            name="employee_types"
            label="Tipo de Profissional"
            options={typesQuery.data.data || []}
            optionLabelKey="description"
            multiple
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField control={control} label="Prefixo" name="prefix" />
        </Grid>
        <Grid item xs={6}>
          <TextField
            control={control}
            label="Legenda em inglês"
            name="subtitle"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            control={control}
            label="Descrição"
            name="info"
            multiline
            rows={8}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={onSubmit}>
            Cadastrar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
