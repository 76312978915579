export default function diabeticFootWarning(result) {
  const options = {
    "Neuropatia ausente": `${result}: Reavaliação Anual`,
    "Neuropatia presente, sem deformidades": `${result}: Reavaliação Semestral`,
    "Neuropatia presente, sinais de doença vascular e/ou deformidade presente": `${result}: Reavaliação Trimestral`,
    "Úlcera/amputação prévia": `${result}: Reavaliação de 1 a 3 Meses`,
  };

  return options[result];
}
