import { headerHorizontalStyle, footer, title } from "./pdfComponents";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { v4 as uuidv4 } from "uuid";
import { ptBR } from "date-fns/locale";
import { format, parseISO } from "date-fns";

function followUpStatementPDF(data, patient) {
  const pdfName = uuidv4();
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const description = {
    alignment: "center",
    text: [
      {
        text: "Declaro para os devidos fins que o(a) sr(a) ",
      },
      {
        text: patient.social_prioritize ? patient.social_name : patient.name,
        bold: true,
      },
      {
        text: ", data de nascimento : ",
      },
      {
        text: format(parseISO(patient.birth_date), "dd/MM/yyyy", {
          locale: ptBR,
        }),
        bold: true,
      },
      { text: ". Compareceu nesta unidade de saúde no período das " },
      { text: `${data.arrival_time}hrs`, bold: true },
      { text: " ás " },
      { text: `${data.departure_time}hrs`, bold: true },
      { text: " com o acompanhamento de " },
      { text: `${data.companion_name}`, bold: true },
      { text: `${data.cid_id}`, bold: true },
      { text: "." },
      { text: `\n\nObservação: ${data.observations}` },
    ],
  };

  const urlType = pdfName + "/types/declaration";

  let employee;

  if (data.soap?.employee_specialty) {
    employee = {
      ...data.soap.employee_specialty.employee,
      specialty: data.soap.employee_specialty.specialty,
    };
  }

  const definitions = {
    info: { title: pdfName },
    pageMargins: [40, 105, 40, 120],
    defaultStyle: {
      fontSize: 10,
    },
    ...headerHorizontalStyle(data?.attendance?.company),
    footer: footer(data.createdAt, true, employee, data?.attendance?.company, urlType),
    content: [title("Declaração de acompanhamento"), description],
  };

  const documentPDF = pdfMake.createPdf(definitions);
  documentPDF.name = `${pdfName}.pdf`;
  documentPDF.base64 = async () => {
    const pathBase64 = await new Promise((resolve) => {
      documentPDF.getBase64((base64) => {
        resolve(base64);
      });
    });

    return pathBase64;
  };

  return documentPDF;
}

export default followUpStatementPDF;
