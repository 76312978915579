import React from "react";
import { Tabs } from "../../../components";
import ListReference from "./ListReference";
import NewReference from "./NewReference";

function Reference({ attendance, isExternal }) {
  const tabs = [
    {
      label: "Cadastrar",
      content: <NewReference attendance={attendance} isExternal={isExternal} />,
    },
    {
      label: "Lista de Referência / Contrarreferências ",
      content: <ListReference attendance={attendance} />,
    },
  ];
  return (
    <>
      <Tabs tabs={tabs} />
    </>
  );
}

export default Reference;
