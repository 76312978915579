import { differenceInDays, isValid } from "date-fns";
import { cleanUpMask } from "../../../config/mask";
import Yup from "../../../config/yup";
import { v4 as uuidv4 } from "uuid";

export const domiciliaryDefaultValues = {
  createdAt: null,
  employee: null,
  employee_cbo: null,
  employee_cnes: null,
  employee_ine: null,
  property_id: null,
  street_type: null,
  microarea_id: null,
  cep: "",
  street: "",
  number: "",
  not_number: false,
  complement: "",
  reference: "",
  neighborhood: null,
  cellphone: "",
  phone: "",
  ibge: "",
  terms_of_use: false,
  home_location: null,
  housing_situation: null,
  home_access_type: null,
  land_possession_use: null,
  home_type: null,
  household_rooms: null,
  building_material: null,
  water_supply: null,
  water_consumption: null,
  sanitary_drainage: null,
  destination_garbage: null,
  availability_of_electricity: null,
  animals: [],
  quantity_animals: null,
  institution_permanence_name: "",
  institution_permanence_responsible_name: "",
  institution_permanence_responsible_cellphone: "",
  institution_permanence_responsible_sus_card: "",
  institution_permanence_associated_professionals: null,
  institution_permanence_position_institution: "",
  families: []
};

export const domiciliaryValidations = Yup.object().shape({
  createdAt: Yup.date()
    .required("Campo obrigatório.")
    .typeError("Campo obrigatório.")
    .test(
      "create_date_validation",
      "A data deve ser igual à data atual ou até 7 dias antes.",
      function (value) {
        return (
          !!value &&
          isValid(value) &&
          differenceInDays(new Date(), new Date(value)) >= 0 &&
          differenceInDays(new Date(), new Date(value)) <= 7
        );
      }
    ),
  employee: Yup.object().required("Campo obrigatório").typeError("Campo obrigatório."),
  employee_cbo: Yup.object().required("Campo obrigatório.").typeError("Campo obrigatório."),
  employee_cnes: Yup.object().required("Campo obrigatório").typeError("Campo obrigatório."),
  employee_ine: Yup.object().nullable(),
  property_id: Yup.object()
    .required("Campo obrigatório.")
    .typeError("Campo obrigatório."),
  street_type: Yup.object()
    .when("terms_of_use", {
      is: false,
      then: (schema) => schema
        .required("Campo obrigatório.")
        .typeError("Campo obrigatório."),
      otherwise: (schema) => schema.nullable()
    }),
  terms_of_use: Yup.boolean()
    .required("Campo obrigatório")
    .typeError("Campo obrigatório."),
  microarea_id: Yup.object()
    .required("Campo obrigatório.")
    .typeError("Campo obrigatório."),
  cep: Yup
    .string()
    .when("terms_of_use", {
      is: false,
      then: (schema) => schema
        .cepValid()
        .required("Campo obrigatório.")
        .typeError("Campo obrigatório."),
      otherwise: (schema) => schema.nullable()
    }),
  number: Yup.string()
    .when(["not_number", "terms_of_use"], {
      is: (not_number, terms_of_use) => !terms_of_use && !not_number,
      then: (scheme) => scheme.required("Campo obrigatório."),
      when: (scheme) => scheme.nullable(),
    }),
  not_number: Yup.boolean().nullable(),
  street: Yup.string()
    .when("terms_of_use", {
      is: false,
      then: (schema) => schema
        .min(3, "O logradouro deve ter ao menos 3 caracteres.")
        .required("Campo obrigatório.")
        .typeError("Campo obrigatório."),
      otherwise: (schema) => schema.nullable()
    }),
  complement: Yup.string().nullable(),
  reference: Yup.string().nullable(),
  neighborhood: Yup.object()
    .when("terms_of_use", {
      is: false,
      then: (schema) => schema
        .required("Campo obrigatório.")
        .typeError("Campo obrigatório."),
      otherwise: (schema) => schema.nullable()
    }),
  phone: Yup.string()
    .test(
      "phoneValidation",
      "Número inválido.",
      function (value) {
        return !value || !!value && value.length >= 14 && value.length <= 15
      }
    )
    .nullable(),
  cellphone: Yup.string()
    .test(
      "callphoneValidation",
      "Número inválido.",
      function (value) {
        return !value || !!value && value.length >= 14 && value.length <= 15
      }
    )
    .nullable(),
  home_location: Yup.object()
    .when(["terms_of_use", "property_id"], {
      is: (terms_of_use, property_id) => property_id?.id === "1" && !terms_of_use,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable()
    })
    .typeError("Campo obrigatório."),
  housing_situation: Yup.object()
    .when(["terms_of_use", "property_id"], {
      is: (terms_of_use, property_id) => property_id?.id === "1" && !terms_of_use,
      then: (scheme) => scheme.required("Campo obrigatório."),
      otherwise: (scheme) => scheme.nullable()
    })
    .typeError("Campo obrigatório."),
  home_access_type: Yup.object().nullable(),
  land_possession_use: Yup.object()
    .when("home_location", {
      is: (value) => value?.id === "84",
      then: (scheme) => scheme.required("Campo obrigatório."),
      otherwise: (scheme) => scheme.nullable()
    })
    .typeError("Campo obrigatório."),
  home_type: Yup.object().nullable(),
  household_rooms: Yup.string().max(2, "Valor máximo de 99").nullable(),
  building_material: Yup.object().nullable(),
  water_supply: Yup.object().nullable(),
  water_consumption: Yup.object().nullable(),
  sanitary_drainage: Yup.object().nullable(),
  destination_garbage: Yup.object().nullable(),
  availability_of_electricity: Yup.boolean().nullable(),
  animals: Yup.array().nullable(),
  quantity_animals: Yup.string().nullable(),
  institution_permanence_name: Yup.string().nullable(),
  institution_permanence_responsible_name: Yup.string()
    .when(["terms_of_use", "property_id"], {
      is: (terms_of_use, property_id) => [7, 8, 9, 10, 11].includes(property_id?.id) && !terms_of_use,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable()
    })
    .typeError("Campo obrigatório."),
  institution_permanence_responsible_sus_card: Yup.string()
    .nullable(),
  // .test(
  //   "responsibleCNS",
  //   "CNS inválido.",
  //   function (value) {
  //     return value === "" || (!!value && validateCNS789(value))
  //   }
  // ),
  institution_permanence_position_institution: Yup.string().nullable(),
  institution_permanence_responsible_cellphone: Yup.string()
    // .test(
    //   "responsivelCellphoneValidation",
    //   "Número inválido.",
    //   function (value) {
    //     return value === "" || (!!value && value.length >= 14 && value.length <= 15)
    //   }
    // )
    .nullable(),
  institution_permanence_associated_professionals: Yup.string().nullable(),
  families: Yup.array().nullable()
});

export const domiciliaryPayloadConstructor = async (formValues, id) => {
  const payload = {
    id: id || uuidv4(),
    createdAt: formValues.createdAt,
    employee_specialty_id: formValues.employee_specialty_id,
    terms_of_use: !formValues.terms_of_use,
    institution_permanence: {
      name: formValues.institution_permanence_name,
      responsible_name: formValues.institution_permanence_responsible_name,
      responsible_cellphone: formValues.institution_permanence_responsible_cellphone,
      responsible_sus_card: formValues.institution_permanence_responsible_sus_card,
      position_institution: formValues.institution_permanence_position_institution,
      associated_professionals: formValues.institution_permanence_associated_professionals
    },
    animals_exist: !!formValues.animals.length,
    animals: formValues.animals.length ? formValues.animals.map(e => e.id) : null,
    quantity_animals: formValues.quantity_animals || null,
    property_id: formValues?.property_id?.id.toString() || null,
    microarea_id: formValues.microarea_id?.id || null,
    housing_condition: {
      household_rooms: formValues.household_rooms,
      availability_of_electricity: formValues.availability_of_electricity,
      water_supply_id: formValues.water_supply?.id || null,
      land_possession_use_id: formValues.land_possession_use?.id.toString() || null,
      destination_garbage_id: formValues.destination_garbage?.id || null,
      sanitary_drainage_id: formValues.sanitary_drainage?.id || null,
      home_location_id: formValues.home_location?.id || null,
      building_material_id: formValues.building_material?.id || null,
      housing_situation_id: formValues.housing_situation?.id || null,
      home_access_type_id: formValues.home_access_type?.id || null,
      home_type_id: formValues.home_type?.id || null,
      water_consumption_id: formValues.water_consumption?.id || null,
    },
    address_location_stay: {
      neighborhood: formValues.neighborhood?.neighborhood,
      cep: formValues.cep.replace("-", ""),
      street: formValues.street,
      number: formValues.number?.replace(/\D/g, ''),
      complement: formValues.complement,
      cellphone: formValues.cellphone ? cleanUpMask(formValues.cellphone, "", ["-", " ", "+", "(", ")"]) : null,
      phone: formValues.phone ? cleanUpMask(formValues.phone, "", ["-", " ", "+", "(", ")"]) : null,
      not_number: formValues.not_number,
      reference: formValues.reference,
      ibge: formValues.ibge,
      street_type_id: formValues.street_type?.id,
      county: "São Paulo",
      uf: "SP",
      latitude: formValues.latitude,
      longitude: formValues.longitude,
    },
    families: formValues.families?.length ? formValues.families?.map(e => e.id) : null,
  }

  return payload;
}

