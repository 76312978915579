import { Helmet } from "react-helmet";
import { TITLE_NAME } from "../../config/config";
import { BoxContainer, TerritorialForm } from "../../components";

function TerritorialRegister() {
  return (
    <>
      <Helmet >
        <title> {TITLE_NAME} | Cadastro territorial </title>
      </Helmet>

      <BoxContainer title="Cadastro territorial">
        <TerritorialForm />
      </BoxContainer>
    </>
  )
}

export default TerritorialRegister;