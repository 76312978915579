import { Box, Button, TablePagination } from "@mui/material";
import React, { useEffect } from "react";
import { ConfirmDialog, Dialog, Loading } from "../../../helper";
import PrescriptionInfo from "../PrescriptionInfo";
import TAccordion from "../../../components/Accordion";
import { useState } from "react";
import useMonitoring from "../../../service/useMonitoring";
import { format } from "date-fns";
import { useMutation, useQuery, useQueryClient } from "react-query";
import PatientListTable from "./PatientListTable";
import EvolutionList from "./Evolution/EvolutionList";
import { PatientInfo } from "../PatientInfo";
import useNotifier from "../../../hooks/useNotifier";
import usePrescriptions from "../../../service/usePrescriptions";

export default function PrescriptionPatientList({
  filter,
  table,
  updateKey,
  exam_type,
  statusField,
  patientId,
  disableEvolutionResponse,
  initializeAttendance,
}) {
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const firstRender = React.useRef(true);
  const [limitOfPrescriptions, setLimitOfPrescriptions] = React.useState(10);
  const [accordionConf, setAccordionConf] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [prescriptionPage, setPrescriptionPage] = React.useState(0);
  const [selectedMonitoring, setSelectedMonitoring] = useState();
  const [searchFilter, setSearchFilter] = useState({});
  const { updatePrescriptionsItems, updateWorkList, getMonitorings } = useMonitoring();
  const { getPrescriptions } = usePrescriptions();
  const queryClient = useQueryClient();
  const [prescription, setPrescription] = useState(null);
  const [openEvolutionWithPrescription, setOpenEvolutionWithPrescription] = useState(false);
  const [openEvolutionWithAttendance, setOpenEvolutionWithAttendance] = useState(false);
  const [openConfirmStartAttendance, setOpenConfirmStartAttendance] = useState({
    open: false,
  });
  const notify = useNotifier();
  const updateWorkListStatusMutation = useMutation(updateWorkList);
  const [medicationOnSiteInfo, setMedicationOnSiteInfo] = useState(false);
  const patientListQuery = useQuery(
    [`monitoringPatients`, filter, exam_type, page, limit, patientId],
    () =>
      getMonitorings({
        page,
        limit,
        work_list_status: patientId ? "finished" : "unfinished",
        ...searchFilter,
        type: updateKey,
        id_patient: patientId,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: () => {
        queryClient.setQueriesData(`monitoringPatients`, []);
      },
    }
  );

  const updateWith = async (_, item, toSend, value = true) => {
    await updatePrescriptionsItems({
      prescriptionId: item.id_prescription,
      type: updateKey.replaceAll("_", "-"),
      id: item.id,
      value,
    });

    if (filter === "medications_on_site") {
      setMedicationOnSiteInfo(true);
    }

    prescriptionQuery.refetch();
  };

  const prescriptionQuery = useQuery(
    ["getPatientPrescriptions", selectedMonitoring, prescriptionPage, limitOfPrescriptions],
    () => {
      if (!selectedMonitoring) return [];
      const pendencies = selectedMonitoring.has_pendencies && !selectedMonitoring.has_plan;
      const prescriptionFilters = {
        date: format(new Date(selectedMonitoring.createdAt), "yyyy-MM-dd"),
        page: prescriptionPage,
        limit: limitOfPrescriptions,
        filter,
        exam_type,
        attendance_id: selectedMonitoring?.attendance?.id,
      };

      if (pendencies) {
        prescriptionFilters["pendencies"] = pendencies;
        prescriptionFilters["monitoring_date"] = selectedMonitoring.createdAt;
        delete prescriptionFilters.date;
        delete prescriptionFilters.attendance_id;
      }

      return getPrescriptions(selectedMonitoring?.attendance?.patient.id, {
        date: format(new Date(selectedMonitoring.createdAt), "yyyy-MM-dd"),
        page: prescriptionPage,
        limit: limitOfPrescriptions,
        filter,
        exam_type,
        attendance_id: selectedMonitoring?.attendance?.id,
        has_soap: true,
      });
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      retry: false,
      onSuccess: (data) => {
        const hasActions = ["exams", "medications_on_site", "procedures", "medications"].includes(
          filter
        );
        const actions = [
          {
            name: "response",
            handler: (prescription) => {
              setPrescription(prescription);
              setOpenEvolutionWithPrescription(true);
            },
          },
        ];

        const conf = data?.items?.map((prescription) => {
          return {
            title: (
              <PrescriptionInfo
                info={prescription}
                statusField={statusField}
                actions={hasActions ? actions : []}
              />
            ),
            body: table(selectedMonitoring, prescription, updateWith, !patientId),
          };
        });
        setAccordionConf(conf);
      },
      onError() {
        setAccordionConf([]);
      },
      onSettled() {
        firstRender.current = false;
      },
    }
  );

  function handleClose() {
    initializeAttendance(selectedMonitoring.attendance.id, false);
    firstRender.current = true;
    setOpenDialog(false);
    patientListQuery.refetch();
  }

  const updateWorkListStatus = async (attendanceID, to = "finished") => {
    updateWorkListStatusMutation.mutate(
      { attendanceID, changes: { [updateKey]: to } },
      {
        onSuccess: () => {
          patientListQuery.refetch();
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      }
    );
  };

  function handleEvolutionActions() {
    if (disableEvolutionResponse instanceof Function && selectedMonitoring) {
      const result = disableEvolutionResponse(selectedMonitoring);

      if (result) return !result;
    }

    return !patientId;
  }

  useEffect(() => {
    patientListQuery.refetch();
  }, [searchFilter, updateKey]);

  const titles = {
    medications: "Evolução Farmaceutica",
    medications_on_site: "Evolução de Medicamentos",
    lab: "Evolução de Exames Laboratoriais",
    xray: "Evolução de Raio-X",
    ecg: "Evolução de ECG",
    procedure: "Evolução de Procedimentos",
  };

  return (
    <Box sx={{ pt: 5 }}>
      <ConfirmDialog
        open={medicationOnSiteInfo}
        message={
          "Certifique-se de realizar a evolução da prescrição após a administração / não administração do item."
        }
        handleClose={() => {
          setMedicationOnSiteInfo(false);
        }}
        handleConfirm={() => {
          setMedicationOnSiteInfo(false);
        }}
        actions
        maxWidth={"sm"}
        fullWidth
      />
      <ConfirmDialog
        open={openConfirmStartAttendance.open && !!selectedMonitoring?.attendance.id}
        message={"Iniciar atendimento?."}
        handleClose={() => {
          setOpenConfirmStartAttendance({ open: false });
        }}
        handleConfirm={() => {
          initializeAttendance(selectedMonitoring?.attendance.id, true);
          openConfirmStartAttendance.onConfirm();
        }}
        actions
        maxWidth={"sm"}
        fullWidth
      />
      <Dialog
        title={titles[updateKey]}
        open={openEvolutionWithPrescription}
        handleClose={() => {
          initializeAttendance(selectedMonitoring.attendance.id, false);
          setOpenEvolutionWithPrescription(false);
        }}
      >
        <PatientInfo
          attendance={selectedMonitoring?.attendance}
          viewAllergies={["medications_on_site"].includes(updateKey)}
        />
        <EvolutionList
          title={titles[updateKey]}
          patient={selectedMonitoring?.attendance.patient}
          attendance={selectedMonitoring?.attendance}
          prescription={prescription}
          formType={updateKey}
          activeActions={handleEvolutionActions()}
        />
      </Dialog>
      <Dialog
        title={titles[updateKey]}
        open={openEvolutionWithAttendance}
        handleClose={() => {
          initializeAttendance(selectedMonitoring?.attendance.id, false);
          setOpenEvolutionWithAttendance(false);
          patientListQuery.refetch();
        }}
      >
        <PatientInfo
          attendance={selectedMonitoring?.attendance}
          viewAllergies={["medications_on_site"].includes(updateKey)}
        />
        <EvolutionList
          title={titles[updateKey]}
          patient={selectedMonitoring?.attendance.patient}
          attendance={selectedMonitoring?.attendance}
          formType={updateKey}
          activeActions={handleEvolutionActions()}
        />
      </Dialog>
      <Dialog title={"Prescrições"} open={openDialog} handleClose={handleClose}>
        {prescriptionQuery.isFetching && firstRender.current ? (
          <Loading />
        ) : (
          <>
            <PatientInfo
              attendance={selectedMonitoring?.attendance}
              viewAllergies={["medications_on_site"].includes(updateKey)}
            />
            <TAccordion configs={accordionConf} />
            <TablePagination
              count={prescriptionQuery.data?.totalItems || 1}
              component="div"
              page={prescriptionPage}
              onPageChange={(_, newPage) => {
                setPrescriptionPage(newPage);
              }}
              rowsPerPage={limitOfPrescriptions}
              rowsPerPageOptions={[5, 10, 20, 75]}
              onRowsPerPageChange={(event) => {
                setLimitOfPrescriptions(parseInt(event.target.value, 10));
                setPage(0);
              }}
            />
          </>
        )}
      </Dialog>
      <Button
        variant="contained"
        sx={{
          marginBottom: "10px",
        }}
        disabled={patientListQuery.isLoading}
        onClick={() => {
          notify("Lista Atualizada");
          patientListQuery.refetch();
        }}
      >
        Atualizar
      </Button>
      <PatientListTable
        data={patientListQuery.data?.items}
        loading={patientListQuery.isFetching}
        error={patientListQuery.failureCount}
        callType={updateKey}
        noOutstanding={!exam_type}
        actionsTypes={patientId ? ["checklist", "response"] : undefined}
        setOpenEvolutionWithAttendance={() =>
          setOpenConfirmStartAttendance({
            open: true,
            onConfirm: () => {
              setOpenEvolutionWithAttendance(true);
            },
          })
        }
        setOpenDialog={() =>
          setOpenConfirmStartAttendance({
            open: true,
            onConfirm: () => {
              setOpenDialog(true);
            },
          })
        }
        {...{
          page,
          limit,
          setLimit,
          setPage,
          setSelectedMonitoring,
          updateWorkListStatus,
          setSearchFilter,
        }}
        totalItems={patientListQuery.data?.totalItems}
      />
    </Box>
  );
}
