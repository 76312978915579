import React from "react";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { Box, useTheme, Typography } from "@mui/material";
import { styled } from "@mui/system";

const ChartDesc = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.primary.light}`,
  fontSize: 13,
  fontWeight: "600",
}));

const PercentageDesc = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.primary.light}`,
  fontSize: 13,
  fontWeight: "300",
  marginRight: "8px",
}));

function LineAreaChart({
  width = 300,
  height = 80,
  gradientConfig = [],
  areaConfig = [],
  typeArea = "natural",
  title = "",
  dot = false,
  description = null,
  data,
  xAxis = false,
  yAxis = false,
  xDataKey,
  yDataKey,
  percentage = "",
}) {

  const gradients = (
    <defs>
      {gradientConfig.map(({ id, color, from, to }) => {
        return (
          <linearGradient key={id} id={id} x1="1" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={color ? color : to} stopOpacity={color ? 1 : 0.22} />
            <stop offset="90%" stopColor={color ? color : from} stopOpacity={color ? 1 : 0.04} />
          </linearGradient>
        );
      })}
    </defs>
  );

  const areas = areaConfig.map(({ key, lineId, bgId }) => {
    return (
      <Area
        key={key}
        type={typeArea}
        dot={dot}
        dataKey={key}
        stroke={`url(#${lineId})`}
        strokeWidth={3}
        fillOpacity={1}
        fill={`url(#${bgId})`}
      />
    );
  });

  return (
    <>
      <Box maxWidth={width} width="115%" height={height}>
        <Box display="flex" justifyContent="space-between">
          <ChartDesc>{title}</ChartDesc>
          <PercentageDesc>{percentage}</PercentageDesc>
        </Box>
        {description && 
        <Box display="flex" justifyContent="center">
          {description}
        </Box>}
        <ResponsiveContainer width={"100%"} height={"120%"}>
          <AreaChart
            data={data}
            margin={{
              top: 12,
              bottom: 3,
              left: 12,
              right: 12,
            }}
          >
            <XAxis hide={!xAxis} dataKey={!!xAxis ? xDataKey : null} />
            <YAxis hide={!yAxis} dataKey={!!yAxis ? yDataKey : null} />
            <Tooltip />
            {gradients}
            {areas}
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    </>
  );
}

export default LineAreaChart;
