import { styled } from "@mui/material/styles";
import EditRounded from "@mui/icons-material/EditRounded";
import { IconButton } from "@mui/material";

const StyledIconButton = styled(IconButton)(({ disabled }) => ({
  opacity: disabled ? 0.5 : 1,
}));

export default function EditIconButton(props) {
  return (
    <StyledIconButton {...props}>
      <EditRounded color="primary" />
    </StyledIconButton>
  );
}
