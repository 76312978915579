import { createTheme } from "@mui/material";
import { ptBR } from "@mui/material/locale";
import darkScrollbar from "@mui/material/darkScrollbar";
import { LightScrollbar } from "../components";

let theme = createTheme(
  {
    palette: {
      mode: "light",
      primary: {
        main: "#9B7FFF",
        light: "#6A57EE",
        medium: "#F7F7F7",
        font: "#CCAAFF",
      },
      secondary: {
        main: "#6A57EE",
        light: "#fdfcff",
        medium: "#E1DDFF",
      },
    },
    shadows: {
      0: "0px 0px 0px rgba(133, 157, 177,0.2);",
      1: "0px 2px 10px rgba(133, 157, 177,0.2);",
      8: "0px 2px 10px rgba(133, 157, 177,0.2);",
      24: "0px 2px 10px rgba(133, 157, 177,0.2);",
    },
    typography: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
    },
  },
  ptBR
);
theme = createTheme(theme, {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body:
          theme.palette.mode === "dark" ? darkScrollbar() : LightScrollbar(),
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          maxHeight: "205px",
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.dark,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: theme.palette.primary.main,
          "&:hover": {
            borderColor: theme.palette.primary.main,
          },
        },
        input: {
          color: theme.palette.secondary.dark,
          "&::placeholder": {
            color: theme.palette.secondary.dark,
          },
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(106, 87, 238, 0.05)",
          borderRadius: "20px",
          color: theme.palette.secondary.main,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.light,
          fontWeight: "medium",
          "&.Mui-disabled": {
            color: theme.palette.primary.light,
            backgroundColor: "rgba(106, 87, 238, 0.1)",
          },
          "&.Mui-selected": {
            color: "white",
            backgroundColor: theme.palette.primary.light,
          },
          "&.MuiPickersDay-today": {
            borderColor: theme.palette.secondary.main,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {},
      },
    },
  },
});

export { theme };
