import instance from "../config/api";
import urlQuery from "../helper/urlQuery";

function useSusCode() {  
  const susCodeIndividualPaths = {
    heartDiseases: "/heart-diseases",
    respiratoryDiseases: "/respiratory-diseases",
    kidneyDiseases: "/kidney-diseases",
    weights: "/weights",
    personalHygienes: "/personal-hygienes",
    originFoods: "/origin-foods",
    howTimesFeedDay: "/how-times-feed-day",
    homelessnessTimes: "/homelessness-times",
    races: "/races",
    genders: "/genders",
    disabilityTypes: "/disability-types",
    educationalAttainments: "/educational-attainments",
    sexualOrientations: "/sexual-orientations",
    workSituations: "/work-situations",
    genderIdentities: "/gender-identities",
    childrenResponsibles: "/weights",
    traditionalCommunityNames: "/traditional-community-names",
    ethnicities: "/ethnicities",
    profession: "/professions"
  };

  const susCodeTerritorialPaths = {
    propertyTypes: "/property-types",
    streetTypes: "/street-types",
    neighborhood : "/address",
    homeLocations: "/home-locations",
    housingSituations: "/housing-situations",
    homeAccessTypes: "/home-access-types",
    landPossessionUses: "/land-possession-uses",
    homeTypes: "/home-types",
    buildingMaterials: "/building-materials",
    waterSupplies: "/water-supplies",
    waterConsumptions: "/water-consumptions",
    sanitaryDrainages: "/sanitary-drainages",
    destinationGarbages: "/destination-garbages",
    animals: "/animals"
  };

  const getSusCodeByProperty = async (path, params) => {
    if (!path) throw new Error("É necessário informar uma rota para a requisição.");

    try {
      const response = await instance.get(path, {
        params: urlQuery(params)
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  return {
    susCodeIndividualPaths,
    susCodeTerritorialPaths,
    getSusCodeByProperty,
  }
}

export default useSusCode;