import { cleanerString } from "../helper";

const numberRegex = /^\d+$/;
const cpfRegex = /^[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}$/;

const searchEmployeeByNameCpfCns = (params) => {
  const value = params.name;
  delete params["name"];
  if ((numberRegex.test(value) && value.length <= 11) || cpfRegex.test(value)) {
    return {employee_cpf: cleanerString(value, "", [".", "-"]), ...params}
  } else if (numberRegex.test(value) && value.length > 11) {
    return {employee_cns: value, ...params}
  } else {
    return {name: value, ...params}
  }
}

export default searchEmployeeByNameCpfCns;