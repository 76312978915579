import { useTheme } from "@emotion/react";
import {
  AccessTime,
  AutorenewRounded,
  CampaignRounded,
  Check,
  Close,
  SwapHorizRounded,
  MedicationRounded,
  Groups,
} from "@mui/icons-material";
import { Button, Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { Box } from "@mui/system";
import { AvatarGender } from "..";
import React, { useContext, useEffect, useState } from "react";
import PatientServicesDialog from "../PatientServicesDialog";
import { AppContext } from "../../contexts/AppContext";
import { parseISO, format } from "date-fns";
import { DoneRounded, WarningRounded } from "@mui/icons-material";
import { prioritiesIcon } from "../../config/config";
import { useNavigate } from "react-router-dom";
import CopyToClipboardField from "../CardPatient/CopyToClipboardField";
import convertDateTobirthday from "../../utils/convertDateToBirthday";
import { ReactComponent as Injury } from "../../assets/svg/injury.svg";

import { AuthContext } from "../../contexts/AuthContext";
import { useConfirm } from "../../helper/BlockNavigation";
function PatientsCard({
  patient,
  old,
  pendencie,
  handleClick,
  handleFinish,
  handleTransferAttendance,
  handleCollectiveActivity,
  isCollectiveActivity,
  onDialog = false,
  history = false,
  noTextSelection,
  hideOptions,
  ...props
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { confirm } = useConfirm(null, null, false);
  const [openPatientServices, setOpenPatientServices] = useState(false);
  const [callCount, setCallCount] = useState(patient?.token_audit?.calls_count || 0);
  const { setEmployeeData, setPatientData } = useContext(AppContext);
  const { userData } = useContext(AuthContext);

  const hasPendencies = pendencie?.id === patient?.id;

  const classificationColors = {
    Vermelho: "#ef4444",
    Verde: "#22c55e",
    Azul: "#3b82f6",
    Amarelo: "#facc15",
    null: "#d1d5db",
  };

  const BoxContainer = styled(Grid)(({ theme }) => ({
    border: `1px solid ${theme.palette.primary.main}`,
    borderRight: patient?.classification
      ? `10px solid ${classificationColors[patient.classification]}`
      : `1px solid ${theme.palette.primary.main}`,
    borderRadius: "0px 5px 5px 5px",
    userSelect: noTextSelection ? "none" : "inherit",
    position: "relative",
  }));

  const BoxItem = styled(Grid)({
    borderRight: `1px solid ${theme.palette.primary.main}`,
    display: "flex",
    position: "relative",
    flexDirection: "column",
    justifyContent: "center",
    padding: "10px",
    "&:last-child": {
      position: "relative",
      borderRight: "none",
    },
  });

  const BoxAfter = styled(Box)({
    display: "flex",
    gap: 4,
    alignItems: "center",
    color: theme.palette.secondary.main,
    fontSize: "0.7em",
    background: theme.palette.primary.medium,
    padding: "5px",
    borderTopRightRadius: "5px",
    borderBottomLeftRadius: "5px",
  });

  const BoxAvatar = styled(Box)({
    display: "flex",
    alignItems: "center",
    position: "relative",
    gap: "20px",
  });

  function handleClose() {
    setOpenPatientServices({ isOpen: false });
  }

  useEffect(() => {
    setCallCount(patient?.token_audit?.calls_count);
  }, [patient?.token_audit?.calls_count]);

  return (
    <>
      <PatientServicesDialog
        title={"Atendimento Inicial"}
        pendencie={pendencie}
        handleClose={handleClose}
        open={openPatientServices}
      />
      <BoxContainer
        {...props}
        container
        onDoubleClick={() => {
          if (onDialog || old) return;

          if (!hasPendencies && pendencie) {
            const patientName = pendencie.patient.social_prioritize
              ? pendencie.patient.social_name
              : pendencie.patient.name;

            return confirm(
              `Identificamos uma pendência na finalização do atendimento do paciente *${patientName}*. Por favor, conclua o SOAP para poder atender outro paciente.`
            );
          }

          setEmployeeData(patient?.employee);
          setPatientData(patient?.patient);
          setOpenPatientServices({ isOpen: true, patient, history });
        }}
        sx={{
          transition: ".3s",
          cursor: !!!old && !onDialog && "pointer",
          "&:hover": !!!old &&
            !onDialog && {
              background: "rgba(22, 103, 154, 0.03)",
            },
          background:
            !!isCollectiveActivity && isCollectiveActivity()
              ? "rgba(22, 103, 154, 0.07)"
              : "none",
        }}
      >
        <BoxItem
          item
          xs={5}
          sx={{
            position: "relative",
          }}
        >
          <BoxAvatar>
            <AvatarGender
              gender={patient?.patient?.gender ? patient?.patient?.gender : ""}
              size={50}
            />
            <Stack>
              <Typography fontSize={12} color="secondary.main">
                Cd.Paciente :{" "}
                <Typography component="span" fontWeight="bold" fontSize={12}>
                  {patient?.patient?.number}
                </Typography>
              </Typography>
              <Typography
                fontSize={16}
                color="secondary.main"
                sx={{
                  maxWidth: 210,
                }}
              >
                {patient?.patient?.social_prioritize
                  ? patient?.patient?.social_name
                  : patient?.patient?.name}
              </Typography>
              <CopyToClipboardField
                textColor="primary.font"
                label="Mãe"
                text={patient?.patient?.mother_name}
              />
              <CopyToClipboardField
                textColor="primary.font"
                label="CPF"
                text={patient?.patient?.physic_national}
              />
              <CopyToClipboardField
                textColor="primary.font"
                label="CNS"
                text={patient?.patient?.sus_card}
              />
              <Typography fontSize={12} color="primary.font">
                Data de nascimento:{" "}
                {patient?.patient?.birth_date
                  ? format(parseISO(patient?.patient?.birth_date), "dd/MM/yyyy")
                  : "Não informado"}
              </Typography>
              <Typography fontSize={12} color="primary.font">
                {convertDateTobirthday(patient?.patient?.birth_date)}
              </Typography>
              <Typography fontSize={12} color="primary.font">
                Gênero : {patient?.patient?.gender}
              </Typography>
            </Stack>
          </BoxAvatar>
          <Box
            sx={{
              position: "absolute",
              top: 1,
              right: 2,
              display: "flex",
              alignItems: "center",
              columnGap: "5px",
              flexWrap: "wrap",
              justifyContent: "flex-end",
            }}
            maxWidth={"120px"}
          >
            {!hideOptions && patient?.patient.pending_medication && (
              <Tooltip
                title={
                  patient?.patient.medication_assisted_date
                    ? `Medicamento(s) Assistido(s): Último medicamento administrado em ${format(
                        parseISO(patient?.patient.medication_assisted_date),
                        "dd/MM/yyyy 'às' HH:mm"
                      )}`
                    : "Paciente Sobre Medicamento Assistido: Nenhuma administração até o momento. "
                }
                placement="top"
              >
                <IconButton>
                  <MedicationRounded
                    color={
                      patient?.patient.pending_medication &&
                      !patient?.patient.medication_assisted_date
                        ? "warning"
                        : "primary"
                    }
                    sx={{
                      transition: "0.3s",
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}

            {!hideOptions && !old && (
              <>
                {patient?.reevaluation === "Reavaliar" && (
                  <Tooltip title="Em Reavaliação" placement="top">
                    <IconButton>
                      <AutorenewRounded
                        color="primary"
                        sx={{
                          transition: "0.3s",
                          "&:hover": {
                            color: (theme) => theme.palette.secondary.main,
                          },
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
                {patient?.reevaluation === "Em monitoramento" && (
                  <Tooltip title="Em Monitoramento" placement="top">
                    <IconButton>
                      <AutorenewRounded color="error" />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
            {!hideOptions && !old && !patient?.finished ? (
              <>
                {(!patient?.id_scheduling ||
                  patient?.service.describe === "Acolhimento" ||
                  !!patient?.risk_classification_id) && (
                  <Tooltip title="Finalizar atendimento" placement="top">
                    <IconButton onClick={() => handleFinish(patient.id)}>
                      <DoneRounded
                        color="primary"
                        sx={{
                          transition: "0.3s",
                          "&:hover": {
                            color: (theme) => theme.palette.success.light,
                          },
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}

                <Tooltip title={`Chamar o(a) paciente ${patient?.patient?.name}`} placement="top">
                  <IconButton
                    onClick={() => {
                      handleClick(patient?.token_audit.id);
                      setCallCount((count) => ++count);
                    }}
                  >
                    <CampaignRounded
                      color="primary"
                      sx={{
                        transition: "0.3s",
                        "&:hover": {
                          color: (theme) => theme.palette.secondary.main,
                        },
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </>
            ) : patient?.finished && !hideOptions ? (
              <Tooltip
                title={`O atendimento do paciente ${patient?.patient?.name} já foi finalizado.`}
                placement="top"
              >
                <Check
                  color="success"
                  sx={{
                    transition: "0.3s",
                  }}
                />
              </Tooltip>
            ) : (
              !hideOptions && (
                <Tooltip
                  title={`O atendimento do paciente ${patient?.patient?.name} não foi finalizado.`}
                  placement="top"
                >
                  <Close
                    color="error"
                    sx={{
                      transition: "0.3s",
                      zIndex: "20000",
                      top: 1,
                      right: 4,
                    }}
                  />
                </Tooltip>
              )
            )}
            {prioritiesIcon[patient?.token_audit?.priority?.describe.toLowerCase()] && (
              <Tooltip title={patient?.token_audit?.priority?.describe}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  width={"36px"}
                  height={"36px"}
                  justifyContent={"center"}
                >
                  {prioritiesIcon[patient?.token_audit?.priority?.describe.toLowerCase()]}
                </Box>
              </Tooltip>
            )}
          </Box>
        </BoxItem>
        <BoxItem item xs={3.5}>
          <Box
            sx={{
              position: "absolute",
              top: 1,
              right: 2,
              display: "flex",
              alignItems: "center",
              columnGap: "5px",
              flexWrap: "wrap",
              justifyContent: "flex-end",
            }}
          >
            {patient?.in_attendance && !onDialog && (
              <Tooltip title="Em Atendimento" placement="top">
                <IconButton>
                  <Injury />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Stack>
            <Typography component="span" color="secondary.main" fontWeight="medium" fontSize={12}>
              Dt. do Atendimento :{" "}
              <Typography component="span" color="primary" fontSize={12}>
                {`${
                  patient?.createdAt
                    ? format(parseISO(patient?.createdAt), "dd/MM/yyyy 'às' HH:mm")
                    : ""
                }`}
              </Typography>{" "}
            </Typography>
            <Typography component="span" color="secondary.main" fontWeight="medium" fontSize={12}>
              N. Atendimento :{" "}
              <Typography component="span" color="primary" fontSize={12}>
                {patient?.number}
              </Typography>{" "}
            </Typography>
            <Typography component="span" color="secondary.main" fontWeight="medium" fontSize={12}>
              Agendamento :{" "}
              <Typography component="span" color="primary" fontSize={12}>
                {patient?.scheduling ? `às ${patient?.scheduling?.hour}` : "Demanda espontanea"}
              </Typography>{" "}
            </Typography>
            {patient?.token_audit?.createdAt && (
              <Typography component="span" color="secondary.main" fontWeight="medium" fontSize={12}>
                Recepção :{" "}
                <Typography component="span" color="primary" fontSize={12}>
                  {patient?.token_audit?.createdAt
                    ? format(parseISO(patient?.token_audit?.createdAt), "dd/MM/yyy 'às' HH:mm")
                    : ""}
                </Typography>{" "}
              </Typography>
            )}
            <>
              {patient?.date_late && (
                <Typography fontSize={12} color="secondary.main">
                  <Typography
                    component="span"
                    color="secondary.main"
                    fontWeight="medium"
                    fontSize={12}
                  >
                    Dt. de Referência :{" "}
                    <Typography component="span" color="primary" fontSize={12}>
                      {format(new Date(patient.date_late), "dd/MM/yyyy")}
                    </Typography>{" "}
                  </Typography>
                </Typography>
              )}
            </>
            {!!patient?.scheduling && (
              <Typography fontSize={12} color="secondary.main">
                <Typography
                  component="span"
                  color="secondary.main"
                  fontWeight="medium"
                  fontSize={12}
                >
                  Procedimento :{" "}
                  <Typography component="span" color="primary" fontSize={12}>
                    {`${
                      patient?.procedure?.name ||
                      patient?.scheduling?.procedure?.name ||
                      "Não Informado"
                    }`}
                  </Typography>{" "}
                </Typography>
              </Typography>
            )}
          </Stack>
        </BoxItem>
        <BoxItem item xs={3.5}>
          {patient?.scheduling?.treatment_type && (
            <Typography sx={{ marginTop: 2 }} fontSize={12} color="secondary.main">
              <Typography component="span" color="secondary.main" fontWeight="medium" fontSize={12}>
                Consulta :{" "}
                <Typography component="span" color="primary" fontSize={12}>
                  {patient?.scheduling?.treatment_type?.describe}
                </Typography>{" "}
              </Typography>
            </Typography>
          )}
          <Typography fontSize={12} color="secondary.main">
            <Typography component="span" color="secondary.main" fontWeight="medium" fontSize={12}>
              Serviço :{" "}
              <Typography component="span" color="primary" fontSize={12}>
                {patient?.service?.describe}
              </Typography>{" "}
            </Typography>
          </Typography>
          <Typography fontSize={12} color="secondary.main">
            <Typography component="span" color="secondary.main" fontWeight="medium" fontSize={12}>
              Senha :{" "}
              <Typography component="span" color="primary" fontWeight={700} fontSize={12}>
                {patient?.token_audit?.token_describe}
              </Typography>{" "}
            </Typography>
          </Typography>
          {!!!old && (
            <Typography fontSize={12} color="secondary.main">
              <Typography component="span" color="secondary.main" fontWeight="medium" fontSize={12}>
                Chamada :{" "}
                <Typography component="span" color="primary" fontSize={12}>
                  {callCount}
                </Typography>{" "}
              </Typography>
            </Typography>
          )}
          <BoxAfter>
            <Box
              sx={{
                position: "absolute",
                right: patient?.scheduling?.regulated
                  ? 160
                  : patient?.scheduling
                  ? 140
                  : 110,
                display: "flex",
                alignItems: "center",
                columnGap: "5px",
                flexWrap: "wrap",
                justifyContent: "flex-end",
              }}
              maxWidth={"120px"}
            >
              {!hideOptions &&
                !onDialog &&
                userData.employee_type.higher_education && (
                  <Tooltip title="Atividade Coletiva" placement="top">
                    <IconButton
                      onClick={() =>
                        handleCollectiveActivity(patient, isCollectiveActivity)
                      }
                    >
                      <Groups
                        sx={{
                          transition: "0.3s",
                          color:
                            !!isCollectiveActivity && isCollectiveActivity()
                              ? theme.palette.secondary.main
                              : "disabled",
                          "&:hover": {
                            color: theme.palette.primary.main,
                          },
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
            </Box>
            <AccessTime sx={{ fontSize: "16px" }} />
            <Typography fontSize={11}>
              {patient?.scheduling?.regulated
                ? "Agendamento regulado"
                : patient?.scheduling
                ? "Agendamento local"
                : "Não agendado"}
            </Typography>
          </BoxAfter>

          {!!patient?.attendance_transferences.length ? (
            <>
              <Typography fontSize={12} color="secondary.main">
                <Typography
                  component="span"
                  color="secondary.main"
                  fontWeight="medium"
                  fontSize={12}
                >
                  Transferido de:{" "}
                  <Typography component="span" color="primary" fontSize={12}>
                    {patient.attendance_transferences[0].transferred_from.name}
                  </Typography>{" "}
                </Typography>
              </Typography>
              <Typography fontSize={12} color="secondary.main">
                <Typography
                  component="span"
                  color="secondary.main"
                  fontWeight="medium"
                  fontSize={12}
                >
                  Para profissional:{" "}
                  <Typography component="span" color="primary" fontSize={12}>
                    {patient.attendance_transferences[0].transferred_to.name}
                  </Typography>{" "}
                </Typography>
              </Typography>
            </>
          ) : (
            <Typography fontSize={12} color="secondary.main">
              <Typography component="span" color="secondary.main" fontWeight="medium" fontSize={12}>
                Profissional :{" "}
                <Typography component="span" color="primary" fontSize={12}>
                  {!patient?.employee ? "Não informado" : patient?.employee?.name}
                </Typography>{" "}
              </Typography>
            </Typography>
          )}
          <Box
            position={"absolute"}
            bottom={10}
            right={10}
            display={"flex"}
            columnGap={2}
            alignItems={"center"}
          >
            {(!old || (old && history)) &&
            (patient?.service?.odontology || patient?.service?.ceo) ? (
              <Box>
                <Button
                  onClick={() => {
                    setPatientData(patient?.patient);
                    navigate(`/attendance/triage/${patient?.patient?.id}/${patient.id}`, {
                      state: {
                        isCeo: patient?.service?.ceo,
                        menuServiceOpen: onDialog,
                      },
                    });
                  }}
                  sx={{ width: "100px" }}
                  variant="contained"
                >
                  {patient.service.ceo ? "Grupo" : "Triagem"}
                </Button>
              </Box>
            ) : null}
            {handleTransferAttendance &&
              patient.has_soap &&
              patient.id_employee === userData?.id && (
                <Tooltip title="Transferir atendimento" placement="top">
                  <IconButton
                    onClick={() => handleTransferAttendance(patient.id)}
                    sx={{
                      background: (theme) => theme.palette.primary.light,
                      "&:hover": {
                        background: theme.palette.primary.main,
                        opacity: 0.9,
                      },
                    }}
                  >
                    <SwapHorizRounded
                      sx={{
                        color: "white",
                        transition: "0.3s",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )}
          </Box>
        </BoxItem>
      </BoxContainer>
    </>
  );
}

export default PatientsCard;
