import {
  AccessibleRounded,
  EscalatorWarningRounded,
  PregnantWomanRounded,
} from "@mui/icons-material";
import { ReactComponent as Overweight } from "../assets/svg/overweight.svg";
import { ReactComponent as Autistic } from "../assets/svg/autistic.svg";
import { Typography } from "@mui/material";
const IPS_REGEX = /[0-9][0-9][0-9][0-9]/;
//Tab Configuration
const TITLE_NAME = "T+SAUDE | ";
const BACK_IPS = {
  5000: "PRD",
  5201: "TEST",
  5001: "HML",
  default: "DEV",
};

const unprotectedRoutes = [
  /^\/login/,
  /^\/tportal\/.*\/[a-z0-9-]+$/,
  /^\/external\/.*\/[a-z0-9-]+$/,
];

const prioritiesIcon = {
  "idoso 60+": (
    <Typography
      sx={{
        fontWeight: "bold",
        fontSize: 16,
        color: (theme) => theme.palette.primary.main,
      }}
    >
      60+
    </Typography>
  ),
  "gestante/lactante": <PregnantWomanRounded color={"primary"} sx={{ fontSize: 30 }} />,
  "idoso 80+": (
    <Typography
      sx={{
        fontWeight: "bold",
        fontSize: 16,
        color: (theme) => theme.palette.primary.main,
      }}
    >
      80+
    </Typography>
  ),
  "criança de colo": <EscalatorWarningRounded color={"primary"} sx={{ fontSize: 30 }} />,
  obesidade: (
    <Overweight
      style={{
        // width: 30,
        height: 30,
        fill: "#9B7FFF",
      }}
    />
  ),
  "pessoa com deficiência": <AccessibleRounded color={"primary"} sx={{ fontSize: 30 }} />,
  autista: (
    <Autistic
      style={{
        width: 30,
        height: 30,
      }}
    />
  ),
};

export { TITLE_NAME, prioritiesIcon, BACK_IPS, unprotectedRoutes, IPS_REGEX };
