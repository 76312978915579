import React, { useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow as MUITableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import HeaderActions from "./HeaderActions";
import TableBody from "@mui/material/TableBody";
import PaginatedCircularProgress from "../../../helper/PaginatedLoading";
import { ConfirmDialog } from "../../../helper";
import { useTheme } from "@emotion/react";
import TableRow from "./TableRow";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.primary.light,
    fontSize: 13,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.primary.light,
  },
  [`&.${tableCellClasses.root}`]: {
    boxShadow: "none",
    borderBottom: "none",
  },
}));

const StyledTableRow = styled(MUITableRow)(({ theme }) => ({
  border: "none",
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.primary.medium,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&.disabled": {
    backgroundColor: "rgba(216, 0, 0, 0.1)",
  },
  "&.active": {
    backgroundColor: "rgba(0, 216, 0, 0.1)",
  },
}));

export default function TableFilter({
  loading,
  error,
  emptyMessage,
  data,
  checkboxActionController,
  checkBoxName,
  checkBoxValueKey,
  handleCheck,
  handleCheckAll,
  columns = [],
  othersFilter = [],
  size,
  actions,
  headerActions,
  customizeActions,
  noFilter,
  noHeaderOptions,
  disableActions,
  rowStatus = () => null,
  handleFilter,
  actionUniqueIdentifier,
  actionsTypes = [],
  confirmMessage = "Você realmente deseja *action* este item?",
  actionHandleMedicate,
  onCancelConfirmation = () => {},
  actionHandleView,
  actionHandleEdit,
  actionHandlePrint,
  actionHandleChecklist,
  actionHandleTransfer,
  actionHandleDownload,
  actionHandleDelete,
  actionHandleSuspend,
  actionHandleCall,
  actionHandleResponse,
  actionHandleFinish,
  actionHandleImage,
  formikFormHelper,
  callsCountField,
  collapseColumns,
  collapseDataKey,
  ...props
}) {
  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const actionDialog = React.useRef(() => {});
  const actionCancelDialog = React.useRef(() => {});

  const togleDialog = () => {
    if (openDialog) {
      setOpenDialog(false);
    } else {
      setOpenDialog(true);
    }
  };
  return (
    <TableContainer
      component={Paper}
      {...props}
      sx={{
        overflow: loading ? "hidden" : "auto",
        minHeight: loading || data?.length ? "120px" : "inherit",
        transition: "1s",
      }}
    >
      {!noHeaderOptions && (
        <HeaderActions
          options={[
            ...columns?.filter((c) => !!c.type),
            ...othersFilter?.filter((c) => !!c.type),
          ]}
          noFilter={noFilter}
          checkAllAction={actionsTypes.includes("checkbox")}
          handleFilter={handleFilter}
          headerActions={headerActions}
          handleCheckAll={handleCheckAll}
        />
      )}
      <Table sx={{ minWidth: 700 }} aria-label="customized table" size={size}>
        <TableHead>
          <MUITableRow
            sx={{
              backgroundColor: noHeaderOptions
                ? theme.palette.primary.light
                : "#FFF",
            }}
          >
            {columns.map((column, index) => (
              <StyledTableCell
                key={column?.name}
                align={column?.alignInHead ? column.alignInHead : "left"}
                sx={{
                  "&:before":
                    index + 1 !== columns.length || actions
                      ? {
                          position: "absolute",
                          content: "''",
                          width: "3px",
                          height: "50%",
                          background: "rgba(0, 0, 0, .08)",
                          right: 0,
                        }
                      : {},
                  position: "relative",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    color: noHeaderOptions
                      ? "#FFF"
                      : theme.palette.primary.light,
                  }}
                >
                  {column?.name}
                </Typography>
              </StyledTableCell>
            ))}
            {actions && callsCountField ? (
              <StyledTableCell key={"call-number"} align={"center"}>
                <Typography
                  sx={{
                    fontWeight: 500,
                    color: noHeaderOptions
                      ? "#FFF"
                      : theme.palette.primary.light,
                  }}
                >
                  Chamadas
                </Typography>
              </StyledTableCell>
            ) : null}
            {actions && (
              <StyledTableCell align="center" sx={{}}>
                <Typography
                  sx={{
                    fontWeight: 500,
                    color: noHeaderOptions
                      ? "#FFF"
                      : theme.palette.primary.light,
                  }}
                >
                  Ações
                </Typography>
              </StyledTableCell>
            )}
          </MUITableRow>
        </TableHead>
        <TableBody
          sx={{
            position: "relative",
            width: "100%",
            overflow: loading ? "hidden" : "auto",
          }}
        >
          {loading && (
            <StyledTableRow key={`TableRow-empty`}>
              <StyledTableCell
                colSpan={
                  actions
                    ? callsCountField
                      ? columns.length + 2
                      : columns.length + 1
                    : columns.length
                }
                key={"isloading"}
                align={"center"}
              >
                <PaginatedCircularProgress />
              </StyledTableCell>
            </StyledTableRow>
          )}
          {!data?.length && !loading ? (
            <StyledTableRow key={`TableRow-empty`}>
              <StyledTableCell
                colSpan={
                  actions
                    ? callsCountField
                      ? columns.length + 2
                      : columns.length + 1
                    : columns.length
                }
                key={"emptyMessage"}
                align={"center"}
              >
                {emptyMessage ? emptyMessage : "Nenhum dado encontrado"}
              </StyledTableCell>
            </StyledTableRow>
          ) : (
            data?.map((row, index) => (
              <>
                <TableRow
                  {...{
                    actionCancelDialog,
                    actionDialog,
                    actionHandleCall,
                    actionHandleChecklist,
                    actionHandleDelete,
                    actionHandleDownload,
                    actionHandleEdit,
                    actionHandleFinish,
                    actionHandlePrint,
                    actionHandleResponse,
                    actionHandleSuspend,
                    actionHandleTransfer,
                    actionHandleView,
                    actionHandleImage,
                    actions,
                    actionsTypes,
                    actionUniqueIdentifier,
                    checkboxActionController,
                    checkBoxName,
                    checkBoxValueKey,
                    handleCheck,
                    columns,
                    customizeActions,
                    disableActions,
                    formikFormHelper,
                    row,
                    setMessage,
                    togleDialog,
                    confirmMessage,
                    onCancelConfirmation,
                    callsCountField,
                  }}
                  collapseColumns={collapseColumns}
                  collapseRowData={row[collapseDataKey]}
                  rowNumber={index}
                  rowStatus={rowStatus(row)}
                  key={`row-${index}`}
                />
              </>
            ))
          )}
        </TableBody>
      </Table>
      <ConfirmDialog
        message={message}
        hideBackDrop={false}
        open={openDialog}
        maxWidth="sm"
        fullWidth={true}
        handleClose={togleDialog}
        actions
        handleConfirm={actionDialog.current}
        handleCancel={actionCancelDialog.current}
      />
    </TableContainer>
  );
}
