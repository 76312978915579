import instance from "../config/api";
import urlQuery from "../helper/urlQuery";

const path = "diabetic-feet/problems";

export default function useFootProblems() {
  async function getProblems(params) {
    try {
      const response = await instance.get(path, { params: urlQuery(params) });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  return { getProblems };
}
