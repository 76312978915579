import Dialog from "../../helper/DialogMedium";
import PatientsCard from "../PatientsCard/PatientsCard";
import { ReactComponent as SOAP } from "../../assets/svg/SOAP.svg";
import { ReactComponent as Dental } from "../../assets/svg/dental.svg";
import { ReactComponent as Vacinacao } from "../../assets/svg/cadVacinacao.svg";
import { ReactComponent as Programas } from "../../assets/svg/programas.svg";
import { ReactComponent as Atestado } from "../../assets/svg/atestado.svg";
import { ReactComponent as Exames } from "../../assets/svg/exames.svg";
import { ReactComponent as Prescricao } from "../../assets/svg/prescricao.svg";
import { ReactComponent as ProgramaAtivo } from "../../assets/svg/programaAtivo.svg";
import { Grid } from "@mui/material";
import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router";
import Prescription from "../../pages/SOAP/Prescription/Prescription";
import { useContext, useState } from "react";
import Declaration from "../../pages/SOAP/Declaration/Declaration";
import ActiveProblemsList from "./ActiveProblemsList";
import PatientProgramsList from "./PatientProgramsList";
import PatientDischarge from "@mui/icons-material/LocalHospital";
import Forms from "@mui/icons-material/InsertDriveFileOutlined";
import { Groups, UploadFile } from "@mui/icons-material";
import OdontoServices from "./OdontoServices";
import CollectiveActivity from "./CollectiveActivity";
import { AttachedDocuments } from "../../pages";
import usePermissions from "../../config/usePermissions";
import { tokenCEMPDF } from "../../pdfModels";
import DocumentScannerRounded from "@mui/icons-material/DocumentScannerRounded";
import { AuthContext } from "../../contexts/AuthContext";
import ConfirmTransferDialog from "./transferDialogConfirm";

function PatientServicesDialog({ open, handleClose, title }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [prescriptionsDialog, setPrescriptionsDialog] = useState(false);
  const [attachedDocumentsDialog, setAttachedDocumentsDialog] = useState(false);
  const [declarationDialog, setDeclarationDialog] = useState(false);
  const [odonto, setOdonto] = useState(false);
  const [activeProblemsDialog, setActiveProblemsDialog] = useState(false);
  const [confirmTransfer, setConfirmTransfer] = useState({
    open: false,
    navigateTo: "",
  });
  const [programsDialog, setProgramsDialog] = useState(false);
  const [collectiveActivityDialog, setCollectiveActivityDialog] =
    useState(false);
  const attachedDocumentPermissions = usePermissions("/attached-documents");
  const { userData } = useContext(AuthContext);

  const services = [
    {
      icon: (
        <Forms
          color="primary"
          sx={{
            fontSize: 90,
          }}
        />
      ),
      label: "Formulários",
      handleClick: () =>
        navigate(`/attendance/docs/forms/${open.patient.patient?.id}`, {
          state: { backTo: location.pathname, attendanceId: open.patient.id },
        }),
    },

    {
      icon: <SOAP />,
      label: "SOAP",
      handleClick: () => {
        const isWithoutEmployee = !open.patient.id_employee;
        const isOwner = userData.id === open.patient.id_employee;
        const onlyHistory =
          location.pathname === "/patient" ||
          open.patient.service.odontology ||
          open.patient.service.ceo;

        if (isWithoutEmployee || isOwner || onlyHistory) {
          navigate(`/soap/${open.patient.patient?.id}/${open.patient.id}`, {
            state: {
              isHistory: onlyHistory,
              backTo: location.pathname,
              canReevaluate: !!open.patient?.classification,
              attendance_data: open.patient,
            },
          });
        } else {
          setConfirmTransfer({
            open: true,
            navigateTo: `/soap/${open.patient.patient?.id}/${open.patient.id}`,
          });
        }
      },
    },
    {
      icon: (
        <Groups
          color="primary"
          sx={{
            fontSize: 90,
          }}
        />
      ),
      label: "Atividade Coletiva",
      handleClick: () => setCollectiveActivityDialog(true),
    },
    {
      icon: <Vacinacao />,
      label: "Caderneta de Vacinação",
      handleClick: () =>
        navigate(
          `/vaccine/boocklet/${open.patient?.patient?.id}/${open.patient?.id}`,
          {
            state: { backTo: location.pathname },
          }
        ),
    },
    {
      icon: <Dental />,
      label: "Saúde Bucal",
      handleClick: () => setOdonto(true),
    },
    {
      icon: <Prescricao />,
      label: "Prescrição",
      handleClick: () => setPrescriptionsDialog(true),
    },
    {
      icon: <Exames />,
      label: "Exames",
      handleClick: () => navigate(`/attendance/exams/${open.patient.id}`),
    },
    {
      icon: <Atestado />,
      label: "Atestado",
      handleClick: () => setDeclarationDialog(true),
    },
    {
      icon: <Programas />,
      label: "Programas",
      handleClick: () => setProgramsDialog(true),
    },
    {
      icon: <ProgramaAtivo />,
      label: "Problemas ativos",
      handleClick: () => setActiveProblemsDialog(true),
    },
    {
      icon: (
        <PatientDischarge
          color="primary"
          sx={{
            fontSize: 90,
          }}
        />
      ),
      label: "Alta",
      handleClick: () =>
        navigate(`/attendance/patientDischarge/${open.patient.id}`, {
          state: {
            patientId: open.patient?.patient.id,
            backTo: location.pathname,
          },
        }),
    },
  ];

  function handlePrinter() {
    const pdf = tokenCEMPDF(open?.patient?.patient);
    pdf.open();
  }

  const dialogActions = [];

  if (userData.company.type.CEM && open?.patient?.has_preload) {
    dialogActions.push({
      title: "Imprimir ficha de atendimento do paciente",
      icon: <DocumentScannerRounded />,
      handle: () => handlePrinter(),
    });
  }

  if (attachedDocumentPermissions.read) {
    dialogActions.push({
      title: "Anexar documentos",
      icon: <UploadFile />,
      handle: () => setAttachedDocumentsDialog(true),
    });
  }

  function handleClosePrescription() {
    setPrescriptionsDialog(false);
  }

  function handleCloseDeclarations() {
    setDeclarationDialog(false);
  }

  function handleCloseActiveProblems() {
    setActiveProblemsDialog(false);
  }

  function handleCloseProgram() {
    setProgramsDialog(false);
  }

  function handleCloseAttachedDocuments() {
    setAttachedDocumentsDialog(false);
  }

  return (
    <>
      <ConfirmTransferDialog
        open={confirmTransfer.open}
        handleClose={() =>
          setConfirmTransfer({
            open: false,
          })
        }
        navigateTo={confirmTransfer.navigateTo}
        attendance={open.patient}
      />
      <Dialog
        open={prescriptionsDialog}
        title={"Prescrições"}
        maxWidth="lg"
        fullWidth
        handleClose={handleClosePrescription}
      >
        <Prescription
          handleClose={handleClosePrescription}
          patient={open.patient?.patient}
        />
      </Dialog>
      <Dialog
        open={collectiveActivityDialog}
        title={"Atividade Coletiva"}
        maxWidth="xl"
        fullWidth={true}
        handleClose={() => setCollectiveActivityDialog(false)}
      >
        <CollectiveActivity attendance={open.patient} />
      </Dialog>
      <Dialog
        open={attachedDocumentsDialog}
        title={"Anexo ao prontuário"}
        maxWidth="lg"
        fullWidth
        handleClose={handleCloseAttachedDocuments}
      >
        <AttachedDocuments
          attendance_id={open.patient?.id}
          patient_id={open.patient?.patient?.id}
        />
      </Dialog>
      <Dialog
        open={declarationDialog}
        title={"Histórico de declarações"}
        maxWidth="lg"
        fullWidth
        handleClose={handleCloseDeclarations}
      >
        <Declaration
          handleClose={handleCloseDeclarations}
          patient={open.patient?.patient}
          historyOnly
        />
      </Dialog>
      <Dialog
        open={odonto}
        title={"Saúde Bucal"}
        maxWidth="sm"
        fullWidth
        handleClose={() => setOdonto(false)}
      >
        <OdontoServices attendance={open.patient} />
      </Dialog>
      <Dialog
        maxWidth={"90vw"}
        open={open.isOpen}
        handleClose={handleClose}
        title={title}
        actions={dialogActions}
      >
        <PatientsCard
          patient={open.patient}
          key={"pattientCard"}
          handleClick={() => null}
          old={false}
          history={open.history}
          onDialog
        />
        <Grid container sx={{ marginTop: 2, width: "90vw" }} gap={2}>
          {services.map((service) => (
            <Grid
              sx={{
                cursor: !!service.handleClick ? "pointer" : "",
                height: "125px",
                textAlign: "center",
                border: `1px solid ${theme.palette.primary.main}`,
                borderRadius: "10px",
                fontWeight: 400,
                fontSize: "17px",
                lineHeight: "18px",
                fontFamily: "Montserrat",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-around",
              }}
              onClick={service.handleClick}
              item
              xs={2.86}
              xl={2.88}
            >
              {service.icon}
              <Typography fontSize={14} color="secondary.main">
                {service.label}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Dialog>
      <Dialog
        open={activeProblemsDialog}
        title={"Problemas ativos"}
        maxWidth="lg"
        fullWidth
        handleClose={handleCloseActiveProblems}
      >
        <ActiveProblemsList
          handleClose={handleCloseActiveProblems}
          patientId={open.patient?.patient?.id}
        />
      </Dialog>
      <Dialog
        open={programsDialog}
        title={"Programas"}
        maxWidth="lg"
        fullWidth
        handleClose={handleCloseProgram}
      >
        <PatientProgramsList
          handleClose={handleCloseProgram}
          patientId={open.patient?.patient?.id}
        />
      </Dialog>
    </>
  );
}

export default PatientServicesDialog;
