import React, { useContext, useMemo, useState } from "react";
import { BoxContainer, Tabs } from "../../../components";
import { Loading, Dialog } from "../../../helper";
import { TablePagination, Box } from "@mui/material";
import {
  FormMakerFeedback,
  RenderFormMaker,
} from "../../../components/FormMaker";
import { format, isValid, parseISO } from "date-fns";
import { useQuery, useMutation, useQueryClient } from "react-query";
import useDocuments from "../../../service/useDocuments";
import { FormMakerContext } from "../../../contexts/FormMakerContext";
import { useLocation, useParams } from "react-router-dom";
import TableFilter from "../../../components/Table/TableFilter";
import { usePatient } from "../../../service";
import useNotifier from "../../../hooks/useNotifier";
import { Button } from "../../../components/FormFields";

const types = {
  forms: {
    id: "3a56b3c6-566f-4985-a5fd-c57ece4bf4b7",
    desc: "Formulários",
  },
  caps: {
    id: "31bce4d9-29de-4df5-a5cb-362ba4595863",
    desc: "Acolhimento Inicial",
  },
  carePlan: {
    id: "ae60d31f-7cd5-42af-ad8a-973aa441c7ee",
    desc: "Plano de Cuidado",
  },
  multi: {
    id: "7b144ccf-b5b2-46f5-94c2-3e484d67194b",
    desc: "Multi Profissionais",
  },
  anamnese: {
    id: "438e03b8-2fef-4a6f-8153-c089ef4c24bb",
    desc: "Anamnese",
  },
  terms: {
    id: "b1ca4d25-7738-4f87-bcf5-16e0f80886bc",
    desc: "Termos",
  },
  orientations: {
    id: "bd4ea251-8cc1-4611-8af2-8c1272c3994b",
    desc: "Orientações",
  },
  ampi: {
    id: "87ec0cb0-9eb3-4f4c-92c0-8667e398cb35",
    desc: "AMPI",
  },
  pregnant: {
    id: "cb7a236e-3a83-48c6-a915-d65b8b4ca03c",
  },
  child: {
    id: "f0335ae3-a46b-4c2d-8823-1a616be67a9c",
  },
  pregnant_first_evaluation: {
    id: "f386c709-af72-4c53-b11b-63ee58cd7c89",
  },
};

const EditorForms = ({
  staticType,
  staticPatientId,
  attendanceId,
  showOnlyTable,
  showTabs = true,
  disableResponse,
  disableActions,
  addvaluesToSubmit,
  onSuccess,
  onError,
  onActionSuccess,
  onActionError
}) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const notify = useNotifier();
  const [registerRecord, setRegisterRecord] = useState(false);
  const [displayRecord, setDisplayRecord] = useState(false);
  const [editRecord, setEditRecord] = useState(false);
  const queryClient = useQueryClient();
  const [documentId, setDocumentId] = useState();
  const { document, setDocument, setRecord, setRecordDocument } =
    useContext(FormMakerContext);
  const [patientData, setPatientData] = useState([]);
  const params = useParams();
  const location = useLocation();
  const type = useMemo(
    () => params.type || staticType,
    [params.type, staticType]
  );
  const patientId = useMemo(
    () => params.patientId || staticPatientId,
    [params.patientId, staticPatientId]
  );

  const {
    getDocumentsByType,
    getExternalDocumentById,
    getRecords,
    patchRecords,
    deleteDocumentRecord,
  } = useDocuments();
  const { getPatient } = usePatient();

  useQuery("patient", () => getPatient(patientId), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!patientId,
    onSuccess(response) {
      const addtionalData = [
        {
          label: "Paciente",
          value: response?.social_prioritize
            ? response.social_name
            : response?.name || "Não informado",
        },
        {
          label: "CPF",
          value: response?.physic_national || "Não informado",
        },
        { label: "CNS", value: response?.sus_card || "Não informado" },
        {
          label: "Data de nascimento",
          value: response?.birth_date
            ? format(parseISO(response.birth_date), "dd/MM/yyyy")
            : "Não informado",
        },
      ];

      setPatientData(addtionalData);
    },
  });

  const documentsByTypeQuery = useQuery(
    ["documents-by-type"],
    () => getDocumentsByType(types[type]?.id),
    {
      retry: false,
      refetchOnWindowFocus: false,
      initialData: [],
      onSuccess: (response) => {
        setDocumentId(response?.[0]?.external_document_id);
      },
      onError: (error) => {
        queryClient.setQueriesData("documents-by-type", []);
        notify(error.message, "error");
      },
    }
  );

  const documentQuery = useQuery(
    ["document", documentId],
    () => getExternalDocumentById(documentId),
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!documentId,
      initialData: [],
      onSuccess: (response) => {
        setDocument(response);
      },
      onError: (error) => {
        notify(error.message, "error");
      },
    }
  );

  const recordsQuery = useQuery(
    ["records", documentId, page, limit],
    () => getRecords({ patientId, documentId, params: { page, limit } }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!document,
      initialData: { totalItems: 0, items: [] },
      onError: (error) => {
        queryClient.setQueriesData("records", {
          totalItems: 0,
          items: [],
        });
        notify(error.message, "error");
      },
    }
  );

  const allStatus = {
    filling: "Preenchendo",
    canceled: "Cancelado",
    finished: "Finalizado",
  };

  const patchRecordsQuery = useMutation(patchRecords);
  const deleteDocumentRecordMutation = useMutation(deleteDocumentRecord);

  const handleFinish = (index) => {
    const recordDocument = recordsQuery.data.items[index];

    patchRecordsQuery.mutate(
      {
        patientId,
        documentRecordId: recordDocument.id,
        data: {
          ...recordDocument,
          status: "finished",
        },
      },
      {
        onSuccess: (response) => {
          notify(response.message, "success");
          recordsQuery.refetch();
        if(onActionSuccess instanceof Function) onActionSuccess()
        },
        onError: (error) => {
        if(onActionError instanceof Function) onActionError()
          notify(error.message, "error");
        },
      }
    );
  };

  const handleCloseDialog = () => {
    setRecord({});
    setRegisterRecord(false);
    setDisplayRecord(false);
    setEditRecord(false);
  };

  function shouldDisableAnswerButton() {
    const isFillingStatus = recordsQuery.data.items[0]?.status === "filling";
    const hasDocumentsByType = !!documentsByTypeQuery.data;

    if (disableResponse instanceof Function) {
      const disableAnswer = disableResponse(document);
      if (disableAnswer) {
        return disableAnswer;
      }
    }

    if (isFillingStatus || !hasDocumentsByType) {
      return true;
    }

    return false;
  }

  function disableAction(row, action) {
    if (disableActions instanceof Function) {
      const disableResult = disableActions(action, document);

      if (disableResult) return disableResult;
    }

    if (row.status === "filling" || action === "view") {
      return false;
    }

    return true;
  }

  const tableColumns = [
    {
      name: "Profissional",
      field: "employee_specialty?.employee?.name",
      type: "text",
      use: (employeeName) => {
        return employeeName || "Não informado";
      },
    },
    {
      name: "Nome",
      field: "document_id?.name",
      type: "text",
    },
    {
      name: "Status",
      field: "status",
      type: "text",
      use: (status) => allStatus[status],
    },
    {
      name: "Versão",
      field: "version",
      type: "text",
    },
    {
      name: "Data de resposta",
      field: "updated_at",
      use: (updated_at) => {
        return isValid(new Date(updated_at))
          ? format(parseISO(updated_at), "dd/MM/yyyy")
          : "Não informado";
      },
      type: "date",
    },
  ];

  function renderDocsContent() {
    return (
      <>
        <Dialog
          open={displayRecord}
          title={document?.name}
          handleClose={handleCloseDialog}
        >
          <FormMakerFeedback additionalData={patientData} />
        </Dialog>
        <Dialog
          open={registerRecord}
          title={document?.name}
          handleClose={handleCloseDialog}
        >
          <RenderFormMaker
            type="register"
            patientId={patientId}
            attendanceId={attendanceId}
            handleClose={handleCloseDialog}
            addvaluesToSubmit={addvaluesToSubmit}
            onSuccess={onSuccess}
            onError={onError}
          />
        </Dialog>
        <Dialog
          open={editRecord}
          title={document?.name}
          handleClose={handleCloseDialog}
        >
          <RenderFormMaker
            type="edit"
            patientId={patientId}
            attendanceId={attendanceId}
            handleClose={handleCloseDialog}
            addvaluesToSubmit={addvaluesToSubmit}
            onSuccess={onSuccess}
            onError={onError}
          />
        </Dialog>
        {showTabs && (
          <Tabs
            tabs={documentsByTypeQuery.data}
            labelIdentifier="name"
            handleClick={(document) => {
              setDocumentId(document.external_document_id);
            }}
            variant="scrollable"
            scrollButtons={true}
            sx={{ marginBottom: 1 }}
          />
        )}
        <TableFilter
          data={recordsQuery.data.items}
          columns={tableColumns}
          loading={recordsQuery.isFetching}
          emptyMessage={recordsQuery.error?.message}
          actions
          actionsTypes={["view", "edit", "finish", "delete"]}
          disableActions={disableAction}
          confirmMessage="Você realmente deseja *action* esta resposta?"
          actionHandleView={(index) => {
            setRecordDocument(recordsQuery.data.items[index]);
            setDisplayRecord(true);
          }}
          actionHandleEdit={(index) => {
            setRecordDocument(recordsQuery.data.items[index]);
            setRecord(recordsQuery.data.items[index].fields, document);
            setEditRecord(true);
          }}
          actionHandleDelete={(_, row) => {
            deleteDocumentRecordMutation.mutate(
              {
                patientId,
                documentRecordId: row.id,
              },
              {
                onSuccess: () => {
                  recordsQuery.refetch();
                  notify("Resposta excluída com sucesso", "success");
                },
                onError: (err) => {
                  notify(err.message, "error");
                },
              }
            );
          }}
          actionHandleFinish={handleFinish}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={recordsQuery.data.totalItems}
            component="div"
            page={page}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={({ target }) => {
              setLimit(parseInt(target.value, 10));
              setPage(0);
            }}
          />
        </Box>
        <Button
          onClick={() => setRegisterRecord(true)}
          loading={documentQuery.isFetching}
          loadingMessage="Carregando..."
          disabled={shouldDisableAnswerButton()}
        >
          Responder
        </Button>
      </>
    );
  }

  if (documentQuery.isLoading) return <Loading />;

  return (
    <>
      {showOnlyTable ? (
        renderDocsContent()
      ) : (
        <BoxContainer
          title={types[type]?.desc}
          backTo={location.state?.backTo}
          backState={{
            menuServiceOpen: true,
            use: location.state?.attendanceId,
          }}
        >
          {renderDocsContent()}
        </BoxContainer>
      )}
    </>
  );
};

export default EditorForms;
