import React, { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useLocalStorage } from "../hooks";
import { format, isValid } from "date-fns";
export const AppContext = React.createContext();
export const AppStorage = ({ children }) => {
  const soapPreContent = {
    subjective: { reason: "", id_ciap: [], procedure_id: null },
    admeasurement_objective: {},
    objective: {
      complementary_exams: "",
      allergy_status: "",
      allergy_substances: [],
      allergy_description: "",
      objective_notes: "",
      vaccine_up_to_date: null,
    },
    PreExistIllness: [],
    assessment: {
      query_assessment: "",
      id_ciap: [],
      cid: [],
    },
    plan: {
      query_plan: "",
      id_ciap: [],
    },
    prescription: [],
    against_reference: [],
  };

  const [soapInvalidFields, setSoapInvalidFields] = React.useState([]);
  const [soapInvalidStep, setSoapInvalidStep] = React.useState([]);
  const [prescriptionsToSign, setPrescriptionsToSign] = React.useState([]);
  const [drugOnSitePrescriptions, setDrugOnSitePrescriptions] = React.useState(
    []
  );
  const [drugPrescriptions, setDrugPrescriptions] = React.useState([]);
  const [diabeticFeetEvaluation, setDiabeticFeetEvaluation] =
    React.useState(null);
  const [specialPrescriptions, setSpecialPrescriptions] = React.useState([]);
  const [drugLMEPrescriptions, setDrugLMEPrescriptions] = React.useState([]);
  const [examPrescriptions, setExamPrescriptions] = React.useState([]);
  const [interventions, setInterventions] = React.useState([]);
  const [anesthesia, setAnesthesia] = React.useState({
    types: [],
    sterilization: [],
  });
  const [prosthesisCommand, setProsthesisCommand] = React.useState(0);
  const [declarations, setDeclarations] = React.useState([]);
  const [soapDocumentData, setSoapDocumentData] = React.useState([]);
  const [preData, setPreData] = React.useState(soapPreContent);
  const [againstReference, setAgainstReference] = React.useState([]);
  const [patientData, setPatientData] = React.useState([]);
  const [employeeData, setEmployeeData] = React.useState([]);
  const [dataSOAP, setDataSOAP] = React.useState({});
  const [company, setCompany] = React.useState(null);
  const [disableFields, setDisableFields] = React.useState(false);
  const [disabledFieldNames, setDisabledFieldNames] = React.useState([]);
  const [isRenderInterventions, setIsRenderInterventions] =
    React.useState(false);

  const [
    planAssistedMedicationAdministration,
    setPlanAssistedMedicationAdministration,
  ] = React.useState([]);
  const [activeProblemsToInactive, setActiveProblemsToInactive] =
    React.useState([]);
  const [signedDocuments, setSignedDocuments] = React.useState({
    prescription: [],
    documents: [],
    against_references: [],
  });
  const [attendancesHistory] = useLocalStorage("attendance");
  const location = useLocation();
  const clearAppContext = useCallback(() => {
    setCompany(null);
    setDrugPrescriptions([]);
    setSpecialPrescriptions([]);
    setDrugOnSitePrescriptions([]);
    setExamPrescriptions([]);
    setInterventions([]);
    setAnesthesia([]);
    setProsthesisCommand(0);
    setDeclarations([]);
    setDisableFields(false);
    setDisabledFieldNames([]);
    setActiveProblemsToInactive([]);
    setDiabeticFeetEvaluation(null);
    setSignedDocuments({
      prescription: [],
      documents: [],
      against_references: [],
    });
  }, []);

  React.useEffect(() => {
    if (
      planAssistedMedicationAdministration.length &&
      !location.pathname.includes("soap")
    ) {
      setPlanAssistedMedicationAdministration([]);
    }
  }, [location.pathname]);

  React.useEffect(() => {
    if (attendancesHistory) {
      const attendanceToVerifyDate = Object.entries(attendancesHistory)[0];
      const attendanceSavedDate = new Date(
        attendanceToVerifyDate.soap_saved_date
      );
      if (isValid(attendanceSavedDate)) {
        const today = format(new Date(), "dd/MM/yyyy");
        const formattedAttendanceSavedDate = format(
          attendanceSavedDate,
          "dd/MM/yyyy"
        );
        if (today !== formattedAttendanceSavedDate) {
          localStorage.removeItem("attendance");
        }
      }
    }
  });
  return (
    <AppContext.Provider
      value={{
        preData,
        setPreData,
        soapInvalidFields,
        setSoapInvalidFields,
        soapInvalidStep,
        setSoapInvalidStep,
        prescriptionsToSign,
        setPrescriptionsToSign,
        drugOnSitePrescriptions,
        setDrugOnSitePrescriptions,
        drugPrescriptions,
        setDrugPrescriptions,
        specialPrescriptions,
        setSpecialPrescriptions,
        examPrescriptions,
        setExamPrescriptions,
        signedDocuments,
        setSignedDocuments,
        soapDocumentData,
        setSoapDocumentData,
        declarations,
        setDeclarations,
        againstReference,
        setAgainstReference,
        patientData,
        setPatientData,
        employeeData,
        setEmployeeData,
        dataSOAP,
        setDataSOAP,
        disableFields,
        setDisableFields,
        disabledFieldNames,
        setDisabledFieldNames,
        activeProblemsToInactive,
        setActiveProblemsToInactive,
        company,
        setCompany,
        clearAppContext,
        drugLMEPrescriptions,
        setDrugLMEPrescriptions,
        interventions,
        setInterventions,
        anesthesia,
        setAnesthesia,
        planAssistedMedicationAdministration,
        setPlanAssistedMedicationAdministration,
        prosthesisCommand,
        setProsthesisCommand,
        isRenderInterventions,
        setIsRenderInterventions,
        diabeticFeetEvaluation,
        setDiabeticFeetEvaluation,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
