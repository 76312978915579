import { Grid, Typography } from "@mui/material";
import { AutocompleteField, PaginatedAutocompleteField, RadioField, TextField } from "../../FormFields";
import { useSusCode } from "../../../service";
import { animals, buildingMaterials, destinationGarbages, homeAccessTypes, homeLocations, homeTypes, sanitaryDrainages, waterConsumptions, waterSupplies } from "../../../config/susCodeStandardSelect";

function TerritorialHousingConditionForm({
  control,
  watch,
  isEditing,
  isRejectTerms,
  handleChangeField,
  disableAllFieldsByProperty,
  disablePartialFieldsByProperty
}) {
  const {
    susCodeTerritorialPaths,
    getSusCodeByProperty,
  } = useSusCode();

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" color="secondary" >
          Condições de Moradia
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <RadioField
          control={control}
          name={"home_location"}
          label={"Localização"}
          options={homeLocations}
          direction="row"
          required={!isRejectTerms}
          disabled={
            !isEditing ||
            isRejectTerms ||
            disableAllFieldsByProperty ||
            disablePartialFieldsByProperty
          }
          customOnChange={(value) => handleChangeField("home_location", value)}
          optionLabelKey="describe"
        />
      </Grid>

      <Grid item xs={4}>
        <PaginatedAutocompleteField
          control={control}
          label={"Tipo de posse de domicílio"}
          name={"land_possession_use"}
          placeholder="Selecione"
          required
          disabled={
            !isEditing ||
            isRejectTerms ||
            disableAllFieldsByProperty ||
            disablePartialFieldsByProperty ||
            watch("home_location")?.id === "83"
          }
          service={(params) => getSusCodeByProperty(susCodeTerritorialPaths.landPossessionUses, { ...params })}
          queryKey={"land-possess"}
          filterKey={"describe"}
          optionLabelKey="describe"
        />
      </Grid>

      <Grid item xs={4}>
        <PaginatedAutocompleteField
          control={control}
          label={"Situação de Moradia"}
          name={"housing_situation"}
          placeholder="Selecione"
          required
          disabled={
            !isEditing ||
            isRejectTerms ||
            disableAllFieldsByProperty ||
            disablePartialFieldsByProperty
          }
          service={(params) => getSusCodeByProperty(susCodeTerritorialPaths.housingSituations, { ...params })}
          queryKey={"housing-sutiation"}
          filterKey={"describe"}
          optionLabelKey="describe"
        />
      </Grid>

      <Grid item xs={4}>
        <AutocompleteField
          control={control}
          label={"Tipo de Acesso"}
          name={"home_access_type"}
          placeholder="Selecione"
          disabled={
            !isEditing ||
            isRejectTerms ||
            disableAllFieldsByProperty ||
            disablePartialFieldsByProperty
          }
          options={homeAccessTypes}
          filterKey={"describe"}
          optionLabelKey="describe"
        />
      </Grid>

      <Grid item xs={4}>
        <AutocompleteField
          control={control}
          label={"Tipo de Domicílio"}
          name={"home_type"}
          placeholder="Selecione"
          disabled={
            !isEditing ||
            isRejectTerms ||
            disableAllFieldsByProperty ||
            disablePartialFieldsByProperty
          }
          options={homeTypes}
          filterKey={"describe"}
          optionLabelKey="describe"
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          control={control}
          label={"Número de cômodos"}
          name={"household_rooms"}
          placeholder="00"
          disabled={
            !isEditing ||
            isRejectTerms ||
            disableAllFieldsByProperty ||
            disablePartialFieldsByProperty
          }
        />
      </Grid>

      <Grid item xs={4}>
        <AutocompleteField
          control={control}
          label={"Material predominante"}
          name={"building_material"}
          placeholder="Selecione"
          disabled={
            !isEditing ||
            isRejectTerms ||
            disableAllFieldsByProperty ||
            disablePartialFieldsByProperty
          }
          options={buildingMaterials}
          filterKey={"describe"}
          optionLabelKey="describe"
        />
      </Grid>

      <Grid item xs={4}>
        <AutocompleteField
          control={control}
          label={"Abastecimento de água"}
          name={"water_supply"}
          placeholder="Selecione"
          disabled={
            !isEditing ||
            isRejectTerms ||
            disableAllFieldsByProperty ||
            disablePartialFieldsByProperty
          }
          options={waterSupplies}
          filterKey={"describe"}
          optionLabelKey="describe"
        />
      </Grid>

      <Grid item xs={4}>
        <AutocompleteField
          control={control}
          label={"Água para consumo"}
          name={"water_consumption"}
          placeholder="Selecione"
          disabled={
            !isEditing ||
            isRejectTerms ||
            disableAllFieldsByProperty ||
            disablePartialFieldsByProperty
          }
          options={waterConsumptions}
          filterKey={"describe"}
          optionLabelKey="describe"
        />
      </Grid>

      <Grid item xs={4}>
        <AutocompleteField
          control={control}
          label={"Escoamento do banheiro"}
          name={"sanitary_drainage"}
          placeholder="Selecione"
          disabled={
            !isEditing ||
            isRejectTerms ||
            disableAllFieldsByProperty ||
            disablePartialFieldsByProperty
          }
          options={sanitaryDrainages}
          filterKey={"describe"}
          optionLabelKey="describe"
        />
      </Grid>

      <Grid item xs={4}>
        <AutocompleteField
          control={control}
          label={"Destino do Lixo"}
          name={"destination_garbage"}
          placeholder="Selecione"
          disabled={
            !isEditing ||
            isRejectTerms ||
            disableAllFieldsByProperty ||
            disablePartialFieldsByProperty
          }
          options={destinationGarbages}
          filterKey={"describe"}
          optionLabelKey="describe"
        />
      </Grid>


      <Grid item xs={12}>
        <RadioField
          control={control}
          name={"availability_of_electricity"}
          label={"Energia elétrica"}
          options={[
            { id: 1, label: "Possui", value: true },
            { id: 2, label: "Não possui", value: false },
          ]}
          direction="row"
          optionLabelKey="label"
          optionValueKey="value"
          disabled={
            !isEditing ||
            isRejectTerms ||
            disableAllFieldsByProperty ||
            disablePartialFieldsByProperty
          }
        />
      </Grid>

      <Grid item xs={4}>
        <AutocompleteField
          multiple
          control={control}
          name="animals"
          label="Animais no domicílio"
          disabled={
            !isEditing ||
            isRejectTerms ||
            disableAllFieldsByProperty ||
            disablePartialFieldsByProperty
          }
          options={animals}
          customOnChange={(value) => handleChangeField("animals", value)}
          filterKey={"describe"}
          optionLabelKey="describe"
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          control={control}
          label={"Número de animais"}
          mask={[/\d/, /\d/]}
          name={"quantity_animals"}
          required={watch("animals")?.length}
          disabled={
            !isEditing ||
            isRejectTerms ||
            disableAllFieldsByProperty ||
            disablePartialFieldsByProperty ||
            !watch("animals")?.length
          }
        />
      </Grid>
    </>
  )
}

export default TerritorialHousingConditionForm;