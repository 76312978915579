import { Box, Grid, Grow, styled, Typography } from "@mui/material";
import React from "react";
import { DeleteIconButton, EditIconButton, PrintIconButton } from "../../../components/Buttons";

const CardReference = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  backgroundColor: theme.palette.primary.medium,
  borderRadius: 8,
  padding: "0.8rem",
  color: theme.palette.primary.light,
  position: "relative",
}));

const ReferenceDesc = styled(Typography, {
  shouldForwardProp: (prop) => {
    return prop !== "color" && prop !== "sx";
  },
})(({ theme }) => ({
  fontSize: 13,
  marginRight: 20,
  color: theme.palette.primary.light,
  wordBreak: "break-word",
}));

export default function ReferenceCard({ data, handleEdit, handleDelete, handlePrint }) {
  return (
    <Grid xs={12} item>
      <Grow in={true} timeout={300}>
        <CardReference>
          <ReferenceDesc variant="p" component="p" display="flex" gap={0.5}>
            <Typography
              variant="span"
              component="span"
              sx={{
                fontSize: 13,
                fontWeight: 700,
              }}
            >
              Especialidade:
            </Typography>
            {data.requested_specialty.describe}
          </ReferenceDesc>
          <ReferenceDesc variant="p" component="p" mr={10} display="flex" gap={0.5}>
            <Typography
              variant="span"
              component="span"
              sx={{
                fontSize: 13,
                fontWeight: 700,
              }}
            >
              Prioridade:
            </Typography>
            {data.priority}
          </ReferenceDesc>
          <ReferenceDesc variant="p" component="p" mr={10} display="flex" gap={0.5}>
            <Typography
              variant="span"
              component="span"
              sx={{
                fontSize: 13,
                fontWeight: 700,
              }}
            >
              Profissional Requisitado:
            </Typography>
            {data.requested_employee?.name}
          </ReferenceDesc>
          <Box
            sx={{
              position: "absolute",
              top: 6,
              right: 15,
            }}
          >
            {!data.signed_document && <EditIconButton onClick={handleEdit} />}
            {!data.signed_document && <DeleteIconButton onClick={handleDelete} />}
            {data.signed_document && <PrintIconButton onClick={() => handlePrint(data)} />}
          </Box>
        </CardReference>
      </Grow>
    </Grid>
  );
}
