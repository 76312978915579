import React from "react";
import {
  Dialog as MaterialDialog,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { Box } from "@mui/system";
import highlightText from "./highlightText";

function ConfirmDialog({
  message,
  open,
  handleClose,
  children,
  icon,
  maxWidth,
  fullWidth,
  hideBackDrop = false,
  actions,
  handleConfirm,
  handleCancel,
  cancelLabel = "Cancelar",
  confirmLabel = "Confirmar",
}) {
  return (
    <MaterialDialog
      open={open}
      onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      hideBackdrop={hideBackDrop}
      onClick={(event) => event.stopPropagation()}
      sx={{
        padding: 1,
        "& .MuiDialogTitle-root": {
          p: 2,
        },
      }}
    >
      <DialogTitle
        sx={{
          marginTop: 2,
          mb: 2,
          fontWeight: 500,
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
        color="secondary"
      >
        <Box sx={{ display: "flex", alignItems: "center" }} gap={2}>
          <Typography variant="p" textAlign={"center"}>
            {highlightText(message)}
          </Typography>
          <Box>{icon}</Box>
        </Box>
        <IconButton onClick={handleClose}>
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          mt: -3,
          "&.MuiDialogContent-root": {
            padding: 2,
          },
        }}
      >
        {children}
      </DialogContent>
      {actions && (
        <DialogActions>
          <Button
            onClick={() => {
              if (handleCancel instanceof Function) handleCancel();
              handleClose();
            }}
            color="error"
          >
            {cancelLabel}
          </Button>

          <Button
            onClick={() => {
              if (handleConfirm instanceof Function) handleConfirm();
              handleClose();
            }}
          >
            {confirmLabel}
          </Button>
        </DialogActions>
      )}
    </MaterialDialog>
  );
}

export default ConfirmDialog;
