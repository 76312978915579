import { differenceInDays, differenceInYears, format, isValid } from "date-fns";
import Yup from "../../../config/yup";
import { v4 as uuidv4 } from "uuid";
import { cleanUpMask } from "../../../config/mask";
import { validateCNS789, validateCPF } from "../../../config/validations";

const regexConfig = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]*$/;

const userDataInitialValues = {
  physic_national: "",
  sus_card: "",
  name: "",
  social_name: "",
  birth_date: null,
  gender: null,
  race: null,
  ethnicity: null,
  nis_pis_pasep: "",
  dad_name: "",
  does_not_know_dad: [],
  mother_name: "",
  does_not_know_mother: [],
  marital_status: null,
};

const nationalityinitialValues = {
  nationality: null,
  naturalization_country: null,
  naturality: null,
  date_naturalization: null,
  naturalization_ordinance: "",
  date_entry_country: null,
};

const familyResponsibleInitialValues = {
  is_family_responsible: null,
  responsible_name: "",
  responsible_physic_national: "",
  responsible_sus_card: "",
  kinship: null,
  responsible_email: null,
  responsible_phone: "",
  outside_area: null,
  microarea: null,
};

const contactInitialValues = {
  phone: null,
  email: null,
};

const socioDemographicInitialValues = {
  attends_scholl_or_daycare: null,
  educational_attainment: null,
  work_situation: null,
  profession: null,
  children_responsible: [],
  attends_traditional_caregiver: null,
  participate_in_community_group: null,
  have_health_insurance: null,
  is_member_of_traditional_community: null,
  traditional_community_name: null,
  is_sexual_orientation: null,
  sexual_orientation: null,
  is_gender_identity: null,
  gender_identity: null,
  organ_donor: null,
  type_blood: null,
  disabled: null,
  disability_type: [],
};

const healthConditionsInitialValues = {
  pregnant: null,
  reference_maternity: "",
  weight: null,
  has_hypertension: null,
  has_cancer: null,
  is_smoker: null,
  is_drug_user: null,
  is_alcohol_user: null,
  had_heart_attack: null,
  has_diabetes: null,
  has_leprosy: null,
  is_bedridden: null,
  has_tuberculosis: null,
  is_domicilied: null,
  had_stroke: null,
  uses_other_complementary_integrative_practices: null,
  mental_problem_by_healthcare_professional: null,
  had_any_hospitalizations_in_the_last_12_months: null,
  reason_for_hospitalization: "",
  uses_medicinal_plants: null,
  medicinal_plants: "",
  has_respiratory_diseases: null,
  respiratory_diseases: [],
  has_heart_disease: null,
  heart_disease: [],
  has_kidney_disease: null,
  kidney_disease: [],
};

const homelessConditionInitialValues = {
  is_homeless: null,
  receive_some_benefit: null,
  homelessness_time: null,
  have_access_to_personal_hygiene: null,
  personal_hygiene: [],
  is_accompanied_by_another_institution: null,
  instituition_name: "",
  how_many_times_do_you_feed_a_day: null,
  what_is_the_origin_of_the_food: [],
  visit_family_members_frequently: null,
  does_it_have_any_family_references: null,
  family_members_references: "",
};

export const individualDefaultValues = {
  ...userDataInitialValues,
  ...nationalityinitialValues,
  ...familyResponsibleInitialValues,
  ...contactInitialValues,
  ...socioDemographicInitialValues,
  ...healthConditionsInitialValues,
  ...homelessConditionInitialValues,
  createdAt: null,
  employee: null,
  employee_cbo: null,
  employee_cnes: null,
  employee_ine: null,
  individual_registration_rejection: false,
  food_finished_before_buy_more: null,
  ate_some_food_that_had_money_rounded: null,
  other_health_conditions: "",
  other_health_conditions_2: "",
  other_health_conditions_3: "",
  reason_for_leaving_the_register: null,
  date_deceased: null,
  do_number: "",
};

const userDataScheme = {
  physic_national: Yup.string().nullable().typeError("CPF é obrigatório."),
  sus_card: Yup.string().nullable().typeError("CNS é obrigatório."),
  name: Yup.string().when("individual_registration_rejection", {
    is: false,
    then: (schema) =>
      schema
        .min(5, "Muito Curto")
        .matches(regexConfig, "Nome Inválido")
        .required("Campo obrigatório.")
        .typeError("Campo obrigatório."),
    otherwise: (schema) => schema.nullable(),
  }),
  social_name: Yup.string().nullable(),
  birth_date: Yup.date().when("individual_registration_rejection", {
    is: false,
    then: (schema) =>
      schema
        .min(new Date("01/01/1900"), "O Ano deve ser posterior ou igual a 1900.")
        .required("Campo obrigatório.")
        .typeError("Data inválida.")
        .test(
          "birth_date_validation",
          "A data não pode ser posterior a data de hoje.",
          function (value) {
            return !!value && isValid(value) && differenceInDays(new Date(), value) >= 0;
          }
        ),
    otherwise: (schema) => schema.nullable(),
  }),
  gender: Yup.object().when("individual_registration_rejection", {
    is: false,
    then: (schema) => schema.required("Campo obrigatório.").typeError("Campo obrigatório."),
    otherwise: (schema) => schema.nullable(),
  }),
  race: Yup.object().when("individual_registration_rejection", {
    is: false,
    then: (schema) => schema.required("Campo obrigatório.").typeError("Campo obrigatório."),
    otherwise: (schema) => schema.nullable(),
  }),
  ethnicity: Yup.object()
    .when(["individual_registration_rejection", "race"], {
      is: (individual_registration_rejection, race) =>
        !individual_registration_rejection && race && race.sus_code_id === 5,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  nis_pis_pasep: Yup.string()
    .test("nis_validation", "O NIS (PIS/PASEP) do cidadão inválido.", function (value) {
      return value === undefined || value === "" || value?.length === 14;
    })
    .nullable()
    .notRequired(),
  dad_name: Yup.string().when(["individual_registration_rejection", "does_not_know_dad"], {
    is: (individual_registration_rejection, does_not_know_dad) =>
      !individual_registration_rejection && does_not_know_dad && !does_not_know_dad[0],
    then: (schema) =>
      schema
        .required("Campo obrigatório.")
        .min(5, "Muito Curto")
        .matches(regexConfig, "Nome Inválido"),
    otherwise: (schema) => schema.nullable(),
  }),
  does_not_know_dad: Yup.array().nullable(),
  mother_name: Yup.string().when(["individual_registration_rejection", "does_not_know_mother"], {
    is: (individual_registration_rejection, does_not_know_mother) =>
      !individual_registration_rejection && does_not_know_mother && !does_not_know_mother[0],
    then: (schema) =>
      schema
        .required("Campo obrigatório.")
        .min(5, "Muito Curto")
        .matches(regexConfig, "Nome Inválido"),
    otherwise: (schema) => schema.nullable(),
  }),
  does_not_know_mother: Yup.array().nullable(),
  marital_status: Yup.object().nullable(),
};

const nationalityScheme = {
  nationality: Yup.object().when("individual_registration_rejection", {
    is: false,
    then: (schema) => schema.required("Campo obrigatório.").typeError("Campo obrigatório."),
    otherwise: (schema) => schema.nullable(),
  }),
  naturalization_country: Yup.object().when(["individual_registration_rejection", "nationality"], {
    is: (individual_registration_rejection, nationality) =>
      !individual_registration_rejection && nationality && nationality.sus_code_id === 3,
    then: (schema) => schema.required("Campo obrigatório.").typeError("Campo obrigatório."),
    otherwise: (schema) => schema.nullable(),
  }),
  naturality: Yup.object().when(["individual_registration_rejection", "nationality"], {
    is: (individual_registration_rejection, nationality) =>
      !individual_registration_rejection && nationality && nationality.sus_code_id === 1,
    then: (schema) => schema.required("Campo obrigatório.").typeError("Campo obrigatório."),
    otherwise: (schema) => schema.nullable(),
  }),
  date_naturalization: Yup.date().when(["individual_registration_rejection", "nationality"], {
    is: (individual_registration_rejection, nationality) =>
      !individual_registration_rejection && nationality && nationality.sus_code_id === 2,
    then: (schema) =>
      schema
        .required("Campo obrigatório.")
        .typeError("Campo obrigatório.")
        .test(
          "naturalization_date_validation",
          "A data não pode ser posterior a data de hoje.",
          function (value) {
            return (
              !!value &&
              isValid(new Date(value)) &&
              differenceInDays(new Date(value), new Date()) <= 0
            );
          }
        ),
    otherwise: (schema) => schema.nullable(),
  }),
  naturalization_ordinance: Yup.string()
    .max(16, "Acima do limite de caracteres.")
    .when(["individual_registration_rejection", "nationality"], {
      is: (individual_registration_rejection, nationality) =>
        !individual_registration_rejection && nationality && nationality.sus_code_id === 2,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  date_entry_country: Yup.date()
    .when(["individual_registration_rejection", "nationality"], {
      is: (individual_registration_rejection, nationality) =>
        !individual_registration_rejection && nationality && nationality.sus_code_id === 3,
      then: (schema) =>
        schema
          .required("Campo obrigatório.")
          .test(
            "entry_date_validation",
            "A data não pode ser posterior a data de hoje.",
            function (value) {
              return (
                !!value &&
                isValid(new Date(value)) &&
                differenceInDays(new Date(value), new Date()) <= 0
              );
            }
          ),
      otherwise: (schema) => schema.nullable().notRequired(),
    })
    .typeError("Campo obrigatório."),
};

const familyResponsibleScheme = {
  is_family_responsible: Yup.boolean().when("individual_registration_rejection", {
    is: false,
    then: (schema) => schema.required("Campo obrigatório.").typeError("Campo obrigatório."),
    otherwise: (schema) => schema.nullable(),
  }),
  // responsible_name: Yup.string().nullable(),
  responsible_physic_national: Yup.string()
    .nullable()
    .test("responsible_physic_national_validation", "CPF inválido.", function (value) {
      return value === "" || validateCPF(value);
    }),
  responsible_sus_card: Yup.string()
    .nullable()
    .test("responsible_sus_card_validation", "CNS inválido.", function (value) {
      return !value || value === "" || validateCNS789(value);
    }),
  kinship: Yup.object()
    .when("is_family_responsible", {
      is: false,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  // responsible_email: Yup.string().email("E-mail inválido.").nullable(),
  // responsible_phone: Yup.string().nullable(),
};

const socioDemographicScheme = {
  attends_scholl_or_daycare: Yup.boolean().nullable(),
  educational_attainment: Yup.object().when("individual_registration_rejection", {
    is: false,
    then: (schema) => schema.required("Campo obrigatório.").typeError("Campo obrigatório."),
    otherwise: (schema) => schema.nullable(),
  }),
  work_situation: Yup.object()
    .when("individual_registration_rejection", {
      is: false,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  profession: Yup.object().nullable(),
  children_responsible: Yup.array()
    .when("birth_date", {
      is: (val) => val && differenceInYears(new Date(), new Date(val)) <= 9,
      then: (schema) =>
        schema
          .required("Campo obrigatório.")
          .min(1, "Campo obrigatório.")
          .test(
            "is_children_responsible_not_empty",
            "Campo obrigatório.",
            (value) => value && value.length > 0
          ),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  attends_traditional_caregiver: Yup.boolean().nullable(),
  participate_in_community_group: Yup.boolean().nullable(),
  have_health_insurance: Yup.boolean()
    .when("individual_registration_rejection", {
      is: false,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  is_member_of_traditional_community: Yup.boolean().nullable(),
  traditional_community_name: Yup.object()
    .when("is_member_of_traditional_community", {
      is: true,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  is_sexual_orientation: Yup.boolean()
    .when("individual_registration_rejection", {
      is: false,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  sexual_orientation: Yup.object()
    .when("is_sexual_orientation", {
      is: true,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  is_gender_identity: Yup.boolean()
    .when("individual_registration_rejection", {
      is: false,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  gender_identity: Yup.object()
    .when("is_gender_identity", {
      is: true,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  organ_donor: Yup.boolean().nullable(),
  type_blood: Yup.object().nullable(),
  disabled: Yup.boolean()
    .when("individual_registration_rejection", {
      is: false,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  disability_type: Yup.array()
    .when("disabled", {
      is: true,
      then: (schema) =>
        schema
          .required("Campo obrigatório.")
          .min(1, "Campo obrigatório.")
          .test(
            "is_disabled_not_empty",
            "Campo obrigatório.",
            (value) => value && value.length > 0
          ),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
};

const healthConditionsScheme = {
  pregnant: Yup.boolean()
    .when(
      ["individual_registration_rejection", "gender", "birth_date"],
      (individualRegistration, gender, birth_date, schema) => {
        const age = isValid(birth_date)
          ? differenceInYears(new Date(), new Date(birth_date))
          : null;

        if (
          !individualRegistration &&
          gender?.sus_code_id !== 0 &&
          age !== null &&
          age > 9 &&
          age < 60
        ) {
          return schema.required("Campo obrigatório.");
        }

        return schema.nullable();
      }
    )
    .typeError("Campo obrigatório."),
  reference_maternity: Yup.string().nullable(),
  weight: Yup.string()
    .when("individual_registration_rejection", {
      is: false,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  has_hypertension: Yup.boolean()
    .when("individual_registration_rejection", {
      is: false,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  has_cancer: Yup.boolean()
    .when("individual_registration_rejection", {
      is: false,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  is_smoker: Yup.boolean()
    .when("individual_registration_rejection", {
      is: false,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  is_drug_user: Yup.boolean()
    .when("individual_registration_rejection", {
      is: false,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  is_alcohol_user: Yup.boolean()
    .when("individual_registration_rejection", {
      is: false,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  had_heart_attack: Yup.boolean()
    .when("individual_registration_rejection", {
      is: false,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  has_diabetes: Yup.boolean()
    .when("individual_registration_rejection", {
      is: false,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  has_leprosy: Yup.boolean()
    .when("individual_registration_rejection", {
      is: false,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  is_bedridden: Yup.boolean()
    .when("individual_registration_rejection", {
      is: false,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  has_tuberculosis: Yup.boolean()
    .when("individual_registration_rejection", {
      is: false,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  is_domicilied: Yup.boolean()
    .when("individual_registration_rejection", {
      is: false,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  had_stroke: Yup.boolean()
    .when("individual_registration_rejection", {
      is: false,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  uses_other_complementary_integrative_practices: Yup.boolean()
    .when("individual_registration_rejection", {
      is: false,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  mental_problem_by_healthcare_professional: Yup.boolean()
    .when("individual_registration_rejection", {
      is: false,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  had_any_hospitalizations_in_the_last_12_months: Yup.boolean()
    .when("individual_registration_rejection", {
      is: false,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  reason_for_hospitalization: Yup.string().when(
    ["individual_registration_rejection", "had_any_hospitalizations_in_the_last_12_months"],
    {
      is: (individualRegistration, hasHospitalizations) =>
        !individualRegistration && hasHospitalizations,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    }
  ),
  uses_medicinal_plants: Yup.boolean()
    .when("individual_registration_rejection", {
      is: false,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  medicinal_plants: Yup.string().when(
    ["individual_registration_rejection", "uses_medicinal_plants"],
    {
      is: (individualRegistration, usePlants) => !individualRegistration && usePlants,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    }
  ),
  has_respiratory_diseases: Yup.boolean()
    .when("individual_registration_rejection", {
      is: false,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  respiratory_diseases: Yup.array()
    .when(["individual_registration_rejection", "has_respiratory_diseases"], {
      is: (individualRegistration, hasRespiratoryDiseases) =>
        !individualRegistration && hasRespiratoryDiseases,
      then: (schema) =>
        schema
          .required("Campo obrigatório.")
          .min(1, "Campo obrigatório.")
          .test(
            "is_respiratory_diseases_not_empty",
            "Campo obrigatório.",
            (value) => value && value.length > 0
          ),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  has_heart_disease: Yup.boolean()
    .when("individual_registration_rejection", {
      is: false,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  heart_disease: Yup.array()
    .when(["individual_registration_rejection", "has_heart_disease"], {
      is: (individualRegistration, hasHearthDiseases) =>
        !individualRegistration && hasHearthDiseases,
      then: (schema) =>
        schema
          .required("Campo obrigatório.")
          .min(1, "Campo obrigatório.")
          .test(
            "is_heart_disease_not_empty",
            "Campo obrigatório.",
            (value) => value && value.length > 0
          ),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  has_kidney_disease: Yup.boolean()
    .when("individual_registration_rejection", {
      is: false,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  kidney_disease: Yup.array()
    .when(["individual_registration_rejection", "has_kidney_disease"], {
      is: (individualRegistration, haskidneyDeseases) =>
        !individualRegistration && haskidneyDeseases,
      then: (schema) =>
        schema
          .required("Campo obrigatório.")
          .min(1, "Campo obrigatório.")
          .test(
            "is_kidney_disease__not_empty",
            "Campo obrigatório.",
            (value) => value && value.length > 0
          ),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  other_health_conditions: Yup.string().nullable(),
  other_health_conditions_2: Yup.string().nullable(),
  other_health_conditions_3: Yup.string().nullable(),
};

const homelessConditionScheme = {
  is_homeless: Yup.boolean().nullable(),
  homelessness_time: Yup.object().nullable(),
  receive_some_benefit: Yup.boolean().nullable(),
  have_access_to_personal_hygiene: Yup.boolean().nullable(),
  personal_hygiene: Yup.array()
    .when("have_access_to_personal_hygiene", {
      is: true,
      then: (schema) =>
        schema
          .required("Campo obrigatório.")
          .min(1, "Campo obrigatório.")
          .test(
            "is_personal_hygiene__not_empty",
            "Campo obrigatório.",
            (value) => value && value.length > 0
          ),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("Campo obrigatório."),
  is_accompanied_by_another_institution: Yup.boolean().nullable(),
  instituition_name: Yup.string()
    .when("is_accompanied_by_another_institution", {
      is: true,
      then: (scheme) => scheme.required("Campo obrigatório."),
      otherwise: (scheme) => scheme.nullable(),
    })
    .typeError("Campo obrigatório."),
  how_many_times_do_you_feed_a_day: Yup.object().nullable(),
  what_is_the_origin_of_the_food: Yup.array().nullable(),
  visit_family_members_frequently: Yup.boolean().nullable(),
  does_it_have_any_family_references: Yup.boolean().nullable(),
  family_members_references: Yup.string().when("does_it_have_any_family_references", {
    is: true,
    then: (schema) => schema.required("Campo obrigatório."),
    otherwise: (scheme) => scheme.nullable(),
  }),
};

export const individualValidations = Yup.object()
  .shape({
    ...userDataScheme,
    ...nationalityScheme,
    ...familyResponsibleScheme,
    ...socioDemographicScheme,
    ...healthConditionsScheme,
    ...homelessConditionScheme,
    createdAt: Yup.date()
      .required("Campo obrigatório.")
      .typeError("Campo obrigatório.")
      .test(
        "create_date_validation",
        "A data deve ser igual à data atual ou até 7 dias antes.",
        function (value) {
          return (
            !!value &&
            isValid(value) &&
            differenceInDays(new Date(), new Date(value)) >= 0 &&
            differenceInDays(new Date(), new Date(value)) <= 7
          );
        }
      ),
    employee: Yup.object().required("Campo obrigatório.").typeError("Campo obrigatório."),
    employee_cbo: Yup.object().required("Campo obrigatório.").typeError("Campo obrigatório."),
    employee_cnes: Yup.object().required("Campo obrigatório").typeError("Campo obrigatório."),
    employee_ine: Yup.object().nullable(),
    outside_area: null,
    phone: Yup.string().when(["individual_registration_rejection", "nationality"], {
      is: (individual_registration_rejection, nationality) =>
        !individual_registration_rejection && nationality && nationality?.sus_code_id === 1,
      then: (schema) =>
        schema
          .required("Campo obrigatório.")
          .min(14)
          .max(15, "O numéro de telefone deve ter no máximo 11 caracteres")
          .typeError("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    }),
    email: Yup.string().email("E-mail inválido.").nullable(),
    microarea: Yup.object().nullable(),
    individual_registration_rejection: Yup.boolean().nullable(),
    food_finished_before_buy_more: Yup.boolean()
      .when("individual_registration_rejection", {
        is: false,
        then: (schema) => schema.required("Campo obrigatório."),
        otherwise: (schema) => schema.nullable(),
      })
      .typeError("Campo obrigatório."),
    ate_some_food_that_had_money_rounded: Yup.boolean()
      .when("individual_registration_rejection", {
        is: false,
        then: (schema) => schema.required("Campo obrigatório."),
        otherwise: (schema) => schema.nullable(),
      })
      .typeError("Campo obrigatório."),
    reason_for_leaving_the_register: Yup.object().nullable(),
    date_deceased: Yup.date()
      .when("reason_for_leaving_the_register", {
        is: (val) => val?.value === 135,
        then: (schema) =>
          schema
            .min(new Date("01/01/1900"), "O Ano deve ser posterior ou igual a 1900.")
            .required("Campo obrigatório.")
            .typeError("Data inválida.")
            .test(
              "birth_date_validation",
              "A data não pode ser posterior a data de hoje.",
              function (value) {
                return !!value && isValid(value) && differenceInDays(new Date(), value) > 0;
              }
            ),
        otherwise: (schema) => schema.nullable(),
      })
      .typeError("Data inválida."),
    do_number: Yup.string().when("reason_for_leaving_the_register", {
      is: (val) => val?.value === 135,
      then: (schema) => schema.required("Campo obrigatório."),
      otherwise: (schema) => schema.nullable(),
    }),
  })
  .test("physic_national_or_sus_card_is_empty", "CPF ou CNS é obrigatório.", function (values) {
    const { sus_card, physic_national, individual_registration_rejection } = values;
    if (!individual_registration_rejection && sus_card === "" && physic_national === "") {
      return this.createError({
        // path: "physic_national",
        message: "CPF ou CNS é obrigatório.",
      });
    } else if (physic_national !== "" && !validateCPF(physic_national)) {
      return this.createError({
        path: "physic_national",
        message: "CPF inválido.",
      });
    } else if (!!sus_card && sus_card !== "" && !validateCNS789(sus_card)) {
      return this.createError({
        path: "sus_card",
        message: "CNS inválido.",
      });
    } else {
      return true;
    }
  });

export const payloadConstructor = async (formValues, payloadId) => {
  const healthConditions = {
    pregnant: formValues.pregnant,
    reference_maternity: formValues.reference_maternity,
    weight: formValues.weight,
    has_hypertension: formValues.has_hypertension,
    has_cancer: formValues.has_cancer,
    is_smoker: formValues.is_smoker,
    is_drug_user: formValues.is_drug_user,
    is_alcohol_user: formValues.is_alcohol_user,
    had_heart_attack: formValues.had_heart_attack,
    has_diabetes: formValues.has_diabetes,
    has_leprosy: formValues.has_leprosy,
    is_bedridden: formValues.is_bedridden,
    has_tuberculosis: formValues.has_tuberculosis,
    is_domicilied: formValues.is_domicilied,
    had_stroke: formValues.had_stroke,
    uses_other_complementary_integrative_practices:
      formValues.uses_other_complementary_integrative_practices,
    mental_problem_by_healthcare_professional: formValues.mental_problem_by_healthcare_professional,
    had_any_hospitalizations_in_the_last_12_months:
      formValues.had_any_hospitalizations_in_the_last_12_months,
    reason_for_hospitalization: formValues.reason_for_hospitalization,
    uses_medicinal_plants: formValues.uses_medicinal_plants,
    medicinal_plants: formValues.medicinal_plants,
    has_respiratory_diseases: formValues.has_respiratory_diseases,
    respiratory_diseases: formValues.respiratory_diseases?.length
      ? formValues.respiratory_diseases?.map((e) => e.sus_code_id)
      : null,
    has_heart_disease: formValues.has_heart_disease,
    heart_disease: formValues.heart_disease?.length
      ? formValues.heart_disease?.map((e) => e.sus_code_id)
      : null,
    has_kidney_disease: formValues.has_kidney_disease,
    kidney_disease: formValues.kidney_disease?.length
      ? formValues.kidney_disease?.map((e) => e.sus_code_id)
      : null,
    other_health_conditions: formValues.other_health_conditions,
    other_health_conditions_2: formValues.other_health_conditions_2,
    other_health_conditions_3: formValues.other_health_conditions_3,
  };

  const homelessness_information = {
    is_homeless: formValues.is_homeless,
    receive_some_benefit: formValues.receive_some_benefit,
    homelessness_time: formValues.homelessness_time?.sus_code_id,
    have_access_to_personal_hygiene: formValues.have_access_to_personal_hygiene,
    personal_hygiene: formValues.personal_hygiene?.length
      ? formValues.personal_hygiene?.map((e) => e.sus_code_id)
      : null,
    is_accompanied_by_another_institution: formValues.is_accompanied_by_another_institution,
    instituition_name: formValues.instituition_name,
    how_many_times_do_you_feed_a_day: formValues.how_many_times_do_you_feed_a_day?.sus_code_id,
    what_is_the_origin_of_the_food: formValues.what_is_the_origin_of_the_food?.length
      ? formValues.what_is_the_origin_of_the_food?.map((e) => e.sus_code_id)
      : null,
    visit_family_members_frequently: formValues.visit_family_members_frequently,
    does_it_have_any_family_references: formValues.does_it_have_any_family_references,
    family_members_references: formValues.family_members_references,
  };

  const sociodemographic_information = {
    attends_scholl_or_daycare: formValues.attends_scholl_or_daycare,
    attends_traditional_caregiver: formValues.attends_traditional_caregiver,
    children_responsible: formValues.children_responsible?.length
      ? formValues.children_responsible?.map((e) => e.sus_code_id)
      : null,
    disabled: formValues.disabled,
    disability_type: formValues.disability_type?.length
      ? formValues.disability_type?.map((e) => parseInt(e.sus_code_id))
      : null,
    educational_attainment: formValues.educational_attainment?.id,
    gender_identity: formValues.gender_identity?.sus_code_id || null,
    have_health_insurance: formValues.have_health_insurance,
    is_gender_identity: formValues.is_gender_identity,
    is_sexual_orientation: formValues.is_sexual_orientation,
    is_member_of_traditional_community: formValues.is_member_of_traditional_community,
    participate_in_community_group: formValues.participate_in_community_group,
    sexual_orientation: formValues.sexual_orientation?.sus_code_id || null,
    traditional_community_name: formValues.traditional_community_name?.id,
    work_situation: formValues.work_situation?.id,
    profession: formValues.profession?.cbo_code,
  };

  const payloadObject = {
    id: payloadId || uuidv4(),
    createdAt: formValues.createdAt,
    employee_specialty_id: formValues.employee_specialty_id,
    terms_of_use: !formValues.terms_of_use,
    physic_national: formValues.physic_national
      ? cleanUpMask(formValues.physic_national, "", ["_", "-", "."])
      : null,
    sus_card: formValues.sus_card ? cleanUpMask(formValues.sus_card?.toString(), "", ["_"]) : null,
    name: formValues.name?.toUpperCase().trim(),
    social_name: formValues.social_name?.toUpperCase().trim(),
    gender: formValues.gender?.sus_code_id,
    birth_date:
      formValues.birth_date && isValid(new Date(formValues.birth_date))
        ? format(formValues.birth_date, "yyyy-MM-dd")
        : null,
    date_deceased:
      formValues.date_deceased && isValid(new Date(formValues.date_deceased))
        ? format(formValues.date_deceased, "yyyy-MM-dd")
        : null,
    reason_for_leaving_the_register: formValues.reason_for_leaving_the_register,
    do_number: formValues.do_number,
    type_blood: formValues.type_blood?.label,
    organ_donor: formValues.organ_donor,
    mother_name: formValues.mother_name?.toUpperCase().trim(),
    does_not_know_mother: !!formValues.does_not_know_mother?.length,
    dad_name: formValues.dad_name?.toUpperCase().trim(),
    does_not_know_dad: !!formValues.does_not_know_dad?.length,
    race: formValues.race?.sus_code_id,
    ethnicity: formValues.ethnicity?.id,
    nationality: formValues.nationality?.sus_code_id,
    naturality: formValues.naturality?.ibge_code,
    naturalization_country: formValues.naturalization_country?.sus_code_id || 31,
    date_naturalization:
      formValues.date_naturalization && isValid(new Date(formValues.date_naturalization))
        ? format(new Date(formValues.date_naturalization), "yyyy-MM-dd")
        : null,
    date_entry_country:
      formValues.date_entry_country && isValid(new Date(formValues.date_entry_country))
        ? format(new Date(formValues.date_entry_country), "yyyy-MM-dd")
        : null,
    naturalization_ordinance: formValues.naturalization_ordinance,
    microarea: formValues.microarea?.name,
    nis_pis_pasep: cleanUpMask(formValues.nis_pis_pasep, "", ["_", "-", "."]),
    marital_status: formValues.marital_status?.label,
    contact: {
      phone: cleanUpMask(formValues.phone, "", ["-", " ", "+", "(", ")"]),
      email: formValues.email,
    },
    health_conditions: healthConditions,
    homelessness_information: homelessness_information,
    is_family_responsible: formValues.is_family_responsible,
    individual_registration_rejection: formValues.individual_registration_rejection,
    responsible: {
      // name: formValues.responsible_name,
      kinship: formValues.kinship?.sus_code_id,
      physic_national:
        cleanUpMask(formValues.responsible_physic_national, "", ["_", "-", "."]) || null,
      responsible_sus_card: formValues.responsible_physic_national
        ? ""
        : cleanUpMask(formValues.responsible_sus_card?.toString(), "", ["_"]),
      // phone: cleanUpMask(formValues.responsible_phone, "", ["-", " ", "+", "(", ")"]) || null,
      // email: formValues.responsible_email,
    },
    sociodemographic_information: sociodemographic_information,
    socioeconomic_information: {
      food_finished_before_buy_more: formValues.food_finished_before_buy_more,
      ate_some_food_that_had_money_rounded: formValues.ate_some_food_that_had_money_rounded,
    },
    profession_cbo_code: formValues.profession?.cbo_code,
    microarea_id: formValues.microarea_id || null,
  };

  return payloadObject;
};
