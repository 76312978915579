import { Box, Button, TablePagination } from "@mui/material";
import { ConfirmDialog, Dialog } from "../../../helper";
import React, { useEffect, useState } from "react";
import useMonitoring from "../../../service/useMonitoring";
import { useMutation, useQuery, useQueryClient } from "react-query";
import AgainstReferencesTable from "../AgainstReferences/AgainstReferencesTable";
import PatientListTable from "./PatientListTable";
import { PatientInfo } from "../PatientInfo";
import useNotifier from "../../../hooks/useNotifier";
import { useAgainstReferences } from "../../../service";

export default function AgainstReferencesPatientList({ patientId, initializeAttendance }) {
  const notify = useNotifier();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [referencesPage, setReferencesPage] = useState(0);
  const [searchFilter, setSearchFilter] = useState({});
  const [limitOfReferences, setLimitOfReferences] = useState(10);
  const queryClient = useQueryClient();
  const [selectedMonitoring, setSelectedMonitoring] = useState();
  const [openConfirmStartAttendance, setOpenConfirmStartAttendance] = useState({
    open: false,
  });

  const { getAgainstReferences } = useAgainstReferences();
  const { updateWorkList, updateAgainstReference, getMonitorings } = useMonitoring();

  const updateWorkListStatusMutation = useMutation(updateWorkList);
  const updateAgainstReferenceMutation = useMutation(updateAgainstReference);

  const patientListQuery = useQuery(
    ["getPatientsWithAgainstReferences"],
    () =>
      getMonitorings({
        page,
        work_list_status: patientId ? "finished" : "unfinished",
        ...searchFilter,
        limit,
        type: "against_reference",
        id_patient: patientId,
      }),
    {
      onError: () => {
        queryClient.setQueriesData("getPatientsWithAgainstReferences", []);
      },
    }
  );

  const referencesQuery = useQuery(
    ["getPatientAgainstReferences", [selectedMonitoring]],
    () =>
      getAgainstReferences({
        page: referencesPage,
        limit: limitOfReferences,
        patient_id: selectedMonitoring?.attendance.patient.id,
        attendance_id: selectedMonitoring?.attendance.id,
        suspended: false,
        has_soap: true,
      }),
    {
      enabled: !!selectedMonitoring,
      initialData: { totalItems: 0, items: [] },
    }
  );

  useEffect(() => {
    referencesQuery.refetch();
  }, [selectedMonitoring, referencesPage, limitOfReferences]);

  useEffect(() => {
    patientListQuery.refetch();
  }, [searchFilter]);

  async function check(_, item) {
    updateAgainstReferenceMutation.mutate(
      { id: item.id, data: { referred: true } },
      {
        onSuccess: (response) => {
          notify(response.message, "success");
          referencesQuery.refetch();
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      }
    );
  }

  function handleClose() {
    setOpenDialog(false);
    setSelectedMonitoring();
    patientListQuery.refetch();
  }

  const updateWorkListStatus = async (attendanceID, work_list_status = "finished") => {
    updateWorkListStatusMutation.mutate(
      { attendanceID, changes: { against_reference: work_list_status } },
      {
        onSuccess: () => {
          patientListQuery.refetch();
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      }
    );
  };
  return (
    <Box sx={{ pt: 5 }}>
      <ConfirmDialog
        open={openConfirmStartAttendance.open && !!selectedMonitoring?.attendance.id}
        message={"Iniciar atendimento?."}
        handleClose={() => {
          setOpenConfirmStartAttendance({ open: false });
        }}
        handleConfirm={() => {
          initializeAttendance(selectedMonitoring?.attendance.id, true);
          openConfirmStartAttendance.onConfirm();
        }}
        actions
        maxWidth={"sm"}
        fullWidth
      />
      <Button
        variant="contained"
        sx={{
          marginBottom: "10px",
        }}
        disabled={patientListQuery.isLoading}
        onClick={() => {
          notify("Lista Atualizada");
          patientListQuery.refetch();
        }}
      >
        Atualizar
      </Button>
      <Dialog title={"Encaminhamentos"} open={openDialog} handleClose={handleClose}>
        <PatientInfo attendance={selectedMonitoring?.attendance} />
        <AgainstReferencesTable againstReferences={referencesQuery.data.items} check={check} />
        <TablePagination
          count={referencesQuery.data.totalItems}
          component="div"
          page={referencesPage}
          onPageChange={(_, newPage) => {
            setReferencesPage(newPage);
          }}
          rowsPerPage={limitOfReferences}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={(event) => {
            setLimitOfReferences(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </Dialog>
      <PatientListTable
        noEvolutionStatus={true}
        noOutstanding={true}
        data={patientListQuery.data?.items}
        loading={patientListQuery.isFetching}
        error={patientListQuery.failureCount}
        callType={"against_reference"}
        actionsTypes={patientId ? ["checklist", "response"] : undefined}
        setOpenDialog={() =>
          setOpenConfirmStartAttendance({
            open: true,
            onConfirm: () => {
              setOpenDialog(true);
            },
          })
        }
        {...{
          page,
          setSearchFilter,
          limit,
          setLimit,
          setPage,
          setSelectedMonitoring,
          updateWorkListStatus,
        }}
        totalItems={patientListQuery.data?.totalItems}
      />
    </Box>
  );
}
