import React, { useContext, useEffect, useMemo } from "react";
import Accordion from "../../../components/Accordion";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useForm, useWatch } from "react-hook-form";
import {
  CheckboxField,
  PaginatedAutocompleteField,
  RadioField,
  SwitchField,
  TextField,
} from "../../../components/FormFields";
import TableFilter from "../../../components/Table/TableFilter";
import {
  useCollectiveActivity,
  useProcedures,
  useProvider,
} from "../../../service";
import { useMutation } from "react-query";
import { useNotifier } from "../../../hooks";
import Yup from "../../../config/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthContext } from "../../../contexts/AuthContext";

export default function CollectiveActivityForm({
  attendances,
  setAttendances,
  handleClose,
  collectiveActivityValues,
  setCollectiveActivityValues,
}) {
  const notify = useNotifier();
  const { getProviders } = useProvider();
  const { getProcedures } = useProcedures();
  const { postCollectiveAttendances } = useCollectiveActivity();
  const collectiveAttendandeMutation = useMutation(postCollectiveAttendances);
  const { userData } = useContext(AuthContext);

  const initialValues = {
    cnes: false,
    employees_participating: [],
    turn: null,
    pse_education: null,
    pse_health: null,
    inep: null,
    other_location: null,
    number_participants: attendances.length,
    activity_type: null,
    meeting_topics: [],
    target_public: [],
    health_topics: [],
    health_practices: [],
    procedure_id: null,
    altered_evaluation: false,
    stopped_smoking: null,
    quit_group: null,
    ...collectiveActivityValues,
  };

  const schemaValidation = Yup.object().shape({
    cnes: Yup.boolean().nullable(),
    employees_participating: Yup.array().when("activity_type", {
      is: 5 || 6,
      then: Yup.array().required("É requerido"),
    }),
    turn: Yup.number().required("É requerido"),
    pse_education: Yup.boolean().required("É requerido").nullable(),
    pse_health: Yup.boolean().required("É requerido").nullable(),
    inep: Yup.string()
      .when(
        ["pse_education", "pse_health"],
        (pse_education, pse_health, schema) => {
          if (pse_education || pse_health) {
            return schema.required("É requerido");
          }
          return schema;
        }
      )
      .nullable(),
    other_location: Yup.string().nullable(),
    number_participants: Yup.number().nullable(),
    activity_type: Yup.number().required("É requerido").nullable(),
    meeting_topics: Yup.array()
      .nullable()
      .when("activity_type", (activity_type, schema) => {
        if ([1, 2, 3].includes(activity_type)) {
          return schema.required("É requerido");
        }
        return schema;
      }),
    target_public: Yup.array()
      .nullable()
      .max(7, "O máximo são 7 itens")
      .when("activity_type", (activity_type, schema) => {
        if ([4, 5, 6, 7].includes(activity_type)) {
          return schema.required("É requerido");
        }
        return schema;
      }),
    health_topics: Yup.array()
      .nullable()
      .max(16, "O máximo são 16 itens")
      .when("activity_type", (activity_type, schema) => {
        if ([4, 5, 7].includes(activity_type)) {
          schema.required("É requerido");
        }
        return schema;
      }),
    health_practices: Yup.array()
      .nullable()
      .max(14, "O máximo são 14 itens")
      .when("activity_type", (activity_type, schema) => {
        if (activity_type === 6) {
          schema.required("É requerido");
        }
        return schema;
      }),
  });

  const { handleSubmit, control, reset, setValue } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schemaValidation),
  });

  const values = useWatch({ control });

  const {
    other_location,
    cnes,
    inep,
    activity_type,
    pse_education,
    pse_health,
    health_practices,
  } = values;

  useEffect(() => {
    setCollectiveActivityValues(values);
  }, [values]);

  const yesOrNotOptions = [
    { value: true, label: "Sim" },
    { value: false, label: "Não" },
  ];

  const tableColumns = [
    {
      name: "CNS",
      field: "patient?.sus_card",
    },
    {
      name: "Dt. Nascimento",
      field: "patient?.birth_date",
      use: (birth_date) => {
        if (birth_date)
          return format(parseISO(birth_date), "dd/MM/yyyy", {
            locale: ptBR,
          });
      },
    },
    {
      name: "Nome da mãe",
      field: "patient?.mother_name",
    },
    {
      name: "Avaliação Alterada",
      field: "id",
      use: (id) => {
        return (
          <RadioField
            control={control}
            options={yesOrNotOptions}
            optionValueKey="value"
            name={`altered_evaluation.${id}`}
          />
        );
      },
    },
    {
      name: "Programa Nacional de Controle de Tabagismos",
      field: "id",
      use: (id) => {
        const isSmokingControl = !health_practices.some((value) =>
          [25, 26, 27, 28].includes(value)
        );

        return (
          <>
            <Grid container>
              <Grid item xs={4}>
                <RadioField
                  control={control}
                  label="Cessou o hábito de fumar?"
                  disabled={isSmokingControl}
                  options={yesOrNotOptions}
                  optionValueKey="value"
                  name={`stopped_smoking.${id}`}
                />
              </Grid>
              <Grid item xs={4}>
                <RadioField
                  control={control}
                  label="Abandonou o grupo?"
                  disabled={isSmokingControl}
                  options={yesOrNotOptions}
                  optionValueKey="value"
                  name={`quit_group.${id}`}
                />
              </Grid>
            </Grid>
          </>
        );
      },
    },
  ];

  const onSubmit = (values) => {
    const patients = attendances.map((attendance) => ({
      attendance_id: attendance.id,
      id: attendance?.patient?.id,
      stopped_smoking: values.stopped_smoking[attendance.id],
      quit_group: values.quit_group[attendance.id],
      altered_evaluation: values.altered_evaluation[attendance.id],
    }));

    const payload = {
      inep: values.inep,
      other_location: values.other_location,
      cnes: values.cnes ? userData?.company?.cmes : null,
      pse_health: values.pse_health,
      pse_education: values.pse_education,
      employees: values.employees_participating?.map((employee) => employee.id),
      patients,
      procedure_id: values.procedure_id?.id,
      activity_type_id: values.activity_type,
      health_practice: values.health_practices,
      health_topic: values.health_topics,
      target_public: values.target_public,
      meeting_topic: values.meeting_topics,
    };

    collectiveAttendandeMutation.mutate(payload, {
      onSuccess: () => {
        notify("Atendimento Coletivo criado com Sucesso!", "success");
        reset();
        handleClose();
      },
      onError: (data) => {
        notify(data.message, "error");
      },
    });
  };

  function handleRemoveAttendance(index) {
    const newValues = attendances?.filter((_, i) => i !== index);
    if (!newValues.length) {
      handleClose();
    }
    setAttendances(newValues);
  }

  const accordionAttendances = useMemo(() => {
    return attendances.map((attendance, index) => ({
      title: (
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          <Typography>{attendance?.patient?.name}</Typography>
          <Box display="flex" justifyContent="space-between" mr={4}>
            <Tooltip title="Remover Atendimento" placement="top">
              <IconButton onClick={() => handleRemoveAttendance(index)}>
                <Delete
                  sx={{
                    transition: "0.3s",
                    color: "white",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      ),
      body: (
        <Box>
          <TableFilter data={[attendance]} columns={tableColumns} noFilter />
        </Box>
      ),
    }));
  }, [attendances, health_practices]);

  return (
    <Grid
      container
      spacing={1.5}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item xs={12}>
        <Typography variant="subtitle1">Ficha de Atividade Coletiva</Typography>
      </Grid>
      <Grid item xs={12}>
        <PaginatedAutocompleteField
          control={control}
          required={activity_type === 5 || activity_type === 6}
          name="employees_participating"
          label="Profissionais Participantes:"
          queryKey={"employeesParticipating"}
          disabled={pse_education && !pse_health}
          service={(params) =>
            getProviders({
              ...params,
              active: true,
            })
          }
          filterKey={"name"}
          optionLabelKey={"name"}
          multiple
        />
      </Grid>
      <Grid item xs={12}>
        <RadioField
          control={control}
          name="turn"
          label="Turno:"
          required
          options={[
            { value: 1, label: "Manhã" },
            { value: 2, label: "Tarde" },
            { value: 3, label: "Noite" },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
        />
      </Grid>
      <Grid item xs={4}>
        <RadioField
          control={control}
          name="pse_education"
          label="Práticas Educativas Realizadas por Profissional de Educação:"
          required
          options={yesOrNotOptions}
          customOnChange={(value) => {
            if (value) {
              setValue("employees_participating", []);
              setValue("activity_type", []);
              setValue("meeting_topics", []);
              setValue("cnes_activity_location", "");
              setValue("other_location", "");
              setValue("cnes", false);
            }
          }}
          optionLabelKey="label"
          optionValueKey="value"
        />
      </Grid>
      <Grid item xs={4}>
        <RadioField
          control={control}
          name="pse_health"
          label="Práticas de Saúde Realizadas por Profissional da Saúde:"
          required
          options={yesOrNotOptions}
          customOnChange={(value) => {
            if (value) {
              setValue("employees_participating", []);
              setValue("activity_type", []);
              setValue("meeting_topics", []);
              setValue("cnes_activity_location", "");
              setValue("other_location", "");
            }
          }}
          optionLabelKey="label"
          optionValueKey="value"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="subtitle1"
          color={(theme) => theme.palette.secondary.main}
        >
          Local da Atividade
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <TextField
          control={control}
          name="inep"
          label="N° INEP (Escola/Creche):"
          type="number"
          disabled={!!other_location || !!cnes}
          mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
        />
      </Grid>
      <Grid item xs={3}>
        <SwitchField
          control={control}
          name="cnes"
          label="Utilizar CNES da unidade?:"
          disabled={!!inep || !!other_location || pse_education || pse_health}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          control={control}
          name="other_location"
          label="Outra localidade:"
          disabled={!!inep || !!cnes || pse_education || pse_health}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          control={control}
          name="number_participants"
          label="N° de Participantes:"
          type="number"
          required
          disabled
        />
      </Grid>
      <Grid item xs={6}>
        <RadioField
          control={control}
          name="activity_type"
          label="Atividade:"
          required
          customOnChange={(value) => {
            setValue("health_practices", []);
            if ([1, 2, 3].includes(value)) {
              setValue("health_topics", []);
            }
            if ([4, 5, 6, 7].includes(value)) {
              setValue("meeting_topics", []);
            }
          }}
          disableOptions={(option) => {
            if (
              pse_education &&
              !pse_health &&
              [1, 2, 3, 5].includes(option?.value)
            ) {
              return true;
            }
            return false;
          }}
          orientation="vertical"
          options={[
            { value: 1, label: "Reunião com a equipe" },
            { value: 2, label: "Reunião com outras equipes de saúde" },
            {
              value: 3,
              label:
                "Reunião intersetorial/Conselho Local de Saúde/Controle social",
            },
            { value: 4, label: "Educação em saúde" },
            { value: 5, label: "Atendimento em grupo" },
            { value: 6, label: "Avaliação/Procedimento coletivo" },
            { value: 7, label: "Mobilização social" },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
        />
      </Grid>
      <Grid item xs={6}>
        <CheckboxField
          control={control}
          name="meeting_topics"
          label="Temas para Reunião:"
          orientation="vertical"
          disabled={
            (pse_education && !pse_health) ||
            [4, 5, 6, 7].includes(activity_type)
          }
          required={[1, 2, 3].includes(activity_type)}
          options={[
            { value: 1, label: "Questões administrativas/Funcionamento" },
            { value: 2, label: "Processos de trabalho" },
            {
              value: 3,
              label: "Diagnóstico do território/Monitoramento do território",
            },
            {
              value: 4,
              label: "Planejamento/Monitoramento das ações da equipe",
            },
            {
              value: 5,
              label: "Discussão de caso/Projeto Terapêutico Singular",
            },
            { value: 6, label: "Educação Permanente" },
            { value: 7, label: "Outros" },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
        />
      </Grid>
      <Grid item xs={6}>
        <CheckboxField
          control={control}
          name="target_public"
          orientation="vertical"
          label="Público-Alvo:"
          required={[4, 5, 6, 7].includes(activity_type)}
          disabled={[1, 2, 3].includes(activity_type)}
          options={[
            { value: 1, label: "Comunidade em geral" },
            { value: 2, label: "Criança 0 a 3 anos" },
            { value: 3, label: "Criança 4 a 5 anos" },
            { value: 4, label: "Criança 6 a 11 anos" },
            { value: 5, label: "Adolescente" },
            { value: 6, label: "Mulher" },
            { value: 7, label: "Gestante" },
            { value: 8, label: "Homem" },
            { value: 9, label: "Familiares" },
            { value: 10, label: "Idoso" },
            { value: 12, label: "Pessoas com doenças crônicas" },
            { value: 13, label: "Usuário de tabaco" },
            { value: 14, label: "Usuário de álcool" },
            { value: 15, label: "Usuário de outras drogas" },
            {
              value: 16,
              label: "Pessoa com sofrimento ou transtorno mental",
            },
            { value: 17, label: "Profissional de educação" },
            { value: 18, label: "Outros" },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
        />
      </Grid>
      <Grid item xs={6}>
        <CheckboxField
          control={control}
          name="health_topics"
          orientation="vertical"
          label="Temas para saúde:"
          disabled={[1, 2, 3].includes(activity_type)}
          customOnChange={(value) => {
            if (value?.includes(18)) {
              setValue("pse_education", null);
              setValue("pse_health", null);
              setValue("activity_type", null);
            }
          }}
          required={[4, 5, 7].includes(activity_type)}
          options={[
            { value: 29, label: "Ações de combate ao Aedes aegypti" },
            { value: 19, label: "Agravos e Doenças Negligenciadas" },
            { value: 1, label: "Alimentação saudável" },
            {
              value: 4,
              label: "Autocuidado de pessoas com doenças crônicas",
            },
            { value: 5, label: "Cidadania e direitos humanos" },
            {
              value: 7,
              label: "Prevenção ao uso de álcool, tabaco e outras drogas",
            },
            {
              value: 8,
              label: "Envelhecimento / Climatério / Andropausa / Etc",
            },
            { value: 10, label: "Plantas medicinais / Fitoterapia" },
            {
              value: 13,
              label: "Prevenção da violência e promoção da cultura da paz",
            },
            { value: 14, label: "Saúde ambiental" },
            { value: 15, label: "Saúde bucal" },
            { value: 6, label: "Saúde do trabalhador" },
            { value: 16, label: "Saúde mental" },
            { value: 17, label: "Saúde sexual e reprodutiva" },
            { value: 18, label: "Semana saúde na escola" },
            { value: 21, label: "Outros" },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
        />
      </Grid>
      <Grid item xs={12}>
        <CheckboxField
          control={control}
          name="health_practices"
          orientation="vertical"
          label="Práticas em saúde:"
          required={activity_type === 6}
          customOnChange={(value) => {
            if (value?.includes(30)) {
              setValue("procedure_id", null);
            }
          }}
          disableOptions={(option) => {
            if (![5, 6].includes(activity_type)) {
              return true;
            }

            if (
              ![20, 23, 11, 3, 12, 22].includes(option?.value) &&
              pse_education &&
              !pse_health
            ) {
              return true;
            }

            if ([2, 9].includes(option?.value) && activity_type !== 6) {
              return true;
            }

            return false;
          }}
          options={[
            { value: 20, label: "Antropometria" },
            { value: 2, label: "Aplicação tópica de flúor" },
            {
              value: 23,
              label: "Desenvolvimento da linguagemAlimentação saudável",
            },
            { value: 9, label: "Escovação dental supervisionada" },
            { value: 11, label: "Práticas corporais e Atividade física" },
            {
              value: 25,
              label: "Programa Nacional de Controle do Tabagismo 1",
            },
            {
              value: 26,
              label: "Programa Nacional de Controle do Tabagismo 2",
            },
            {
              value: 27,
              label: "Programa Nacional de Controle do Tabagismo 3",
            },
            {
              value: 28,
              label: "Programa Nacional de Controle do Tabagismo 4",
            },
            { value: 22, label: "Saúde auditiva" },
            { value: 3, label: "Saúde ocular" },
            { value: 24, label: "Verificação da situação vacinal" },
            { value: 12, label: "Outras" },
            { value: 30, label: "Outro procedimento coletivo" },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
        />
      </Grid>
      <Grid item xs={12}>
        <PaginatedAutocompleteField
          control={control}
          name="procedure_id"
          label="Código SIGTAP:"
          disabled={!health_practices.includes(30)}
          queryKey={"proceduresCodes"}
          optionCompareKey="id"
          service={(params) =>
            getProcedures({
              ...params,
              type: "collective",
            })
          }
          filterKey="filters"
          autoCompleteProps={{
            getOptionLabel: (data) => {
              let label = data?.name;

              if (data?.tuss_code) {
                label = `${data?.tuss_code} - ${label}`;
              }

              return label;
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Accordion defaultOpen={false} configs={accordionAttendances} />
      </Grid>
      <Grid item xs={12}>
        <Button type="submit" variant="contained">
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
}
