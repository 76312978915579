import { Box } from "@mui/material";
import { BoxContainer, Tabs } from "../../components";
import usePermissions from "../../config/usePermissions";
import ExamTable from "./exams/ExamTable";
import MedicationTable from "./Medications/MedicationTable";
import AgainstReferencesPatientList from "./PatientLists/AgainstReferencesPatientList";
import DeclarationsPatientsList from "./PatientLists/DeclarationsPatientsList";
import PrescriptionPatientList from "./PatientLists/PrescriptionPatientList";
import ProcedurePatientList from "./PatientLists/ProcedurePatientList";
import { verifyTwentyFourHoursCompany } from "../../utils";
import { useAttendaces } from "../../service";
import { useMutation } from "react-query";
import { useNotifier } from "../../hooks";
function Monitoring({ patientId }) {
  const a_r_p = usePermissions("/monitoring/regulation");
  const d_p = usePermissions("/monitoring/documents");
  const p_m_p = usePermissions("/monitoring/farm");
  const p_m_s_p = usePermissions("/monitoring/medications_on_site");
  const e_lab_p = usePermissions("/monitoring/exam_lab");
  const e_rx_p = usePermissions("/monitoring/exam_xray");
  const e_ecg_p = usePermissions("/monitoring/exam_ecg");
  const p_p = usePermissions("/monitoring/procedures");

  const notify = useNotifier();
  const { updateAttendanceStatus } = useAttendaces();

  const updateAttendanceMutation = useMutation({
    mutationFn: updateAttendanceStatus,
    mutationKey: "update-attendance-status",
  });

  function verifyMonitoringEnabled(monitoring) {
    return (
      verifyTwentyFourHoursCompany(monitoring.createdAt) && monitoring.status !== "outstanding"
    );
  }

  function initializeAttendance(attendanceId, in_attendance) {
    updateAttendanceMutation.mutate(
      { id: attendanceId, in_attendance },
      {
        onError(error) {
          if (in_attendance) {
            notify(error.message, "error");
          }
        },
      }
    );
  }

  const tabs = [
    {
      active: a_r_p.read,
      label: "Regulação",
      content: (
        <AgainstReferencesPatientList
          patientId={patientId}
          initializeAttendance={initializeAttendance}
        />
      ),
    },
    {
      active: d_p.read,
      label: "Declaração / Atestado",
      content: (
        <DeclarationsPatientsList
          patientId={patientId}
          initializeAttendance={initializeAttendance}
        />
      ),
    },
    {
      active: p_m_p.read,
      label: "Farmácia",
      content: (
        <PrescriptionPatientList
          filter={"medications"}
          updadateArrayName={"dispensed_medications"}
          updateKey={"medications"}
          statusField={"meds_status"}
          patientId={patientId}
          initializeAttendance={initializeAttendance}
          disableEvolutionResponse={(monitoring) => verifyMonitoringEnabled(monitoring)}
          table={(monitoring, data, checkFunction, activeActions) => (
            <MedicationTable
              medications={data.medications}
              check={checkFunction}
              activeActions={activeActions}
              disableActions={() => verifyMonitoringEnabled(monitoring)}
            />
          )}
        />
      ),
    },
    {
      active: p_m_s_p.read,
      label: "Medicação",
      content: (
        <PrescriptionPatientList
          filter={"medications_on_site"}
          updadateArrayName={"meds_on_site_done"}
          updateKey={"medications_on_site"}
          statusField={"meds_on_site_status"}
          patientId={patientId}
          initializeAttendance={initializeAttendance}
          disableEvolutionResponse={(monitoring) => verifyMonitoringEnabled(monitoring)}
          table={(monitoring, data, checkFunction, activeActions) => (
            <MedicationTable
              prescription={data}
              check={checkFunction}
              type={"medications_on_site"}
              activeActions={activeActions}
              disableActions={() => verifyMonitoringEnabled(monitoring)}
            />
          )}
        />
      ),
    },
    {
      active: e_lab_p.read,
      label: "Exames Laboratoriais",
      content: (
        <PrescriptionPatientList
          filter={"exams"}
          exam_type={"lab"}
          updateKey={"lab"}
          patientId={patientId}
          updadateArrayName={"exams_done"}
          statusField={"exams_status"}
          initializeAttendance={initializeAttendance}
          disableEvolutionResponse={(monitoring) => verifyMonitoringEnabled(monitoring)}
          table={(monitoring, data, checkFunction, activeActions) => (
            <ExamTable
              prescription={data}
              check={checkFunction}
              activeActions={activeActions}
              disableActions={() => verifyMonitoringEnabled(monitoring)}
            />
          )}
        />
      ),
    },
    {
      active: e_rx_p.read,
      label: "Raio-X",
      content: (
        <PrescriptionPatientList
          filter={"exams"}
          updateKey={"xray"}
          updadateArrayName={"exams_done"}
          patientId={patientId}
          exam_type={"xray"}
          statusField={"exams_status"}
          initializeAttendance={initializeAttendance}
          disableEvolutionResponse={(monitoring) => verifyMonitoringEnabled(monitoring)}
          table={(monitoring, data, checkFunction, activeActions) => (
            <ExamTable
              prescription={data}
              check={checkFunction}
              activeActions={activeActions}
              disableActions={() => verifyMonitoringEnabled(monitoring)}
            />
          )}
        />
      ),
    },
    {
      active: e_ecg_p.read,
      label: "ECG",
      content: (
        <PrescriptionPatientList
          filter={"exams"}
          updateKey={"ecg"}
          updadateArrayName={"exams_done"}
          patientId={patientId}
          exam_type={"ecg"}
          statusField={"exams_status"}
          initializeAttendance={initializeAttendance}
          disableEvolutionResponse={(monitoring) => verifyMonitoringEnabled(monitoring)}
          table={(monitoring, data, checkFunction, activeActions) => (
            <ExamTable
              prescription={data}
              check={checkFunction}
              activeActions={activeActions}
              disableActions={() => verifyMonitoringEnabled(monitoring)}
            />
          )}
        />
      ),
    },
    {
      active: p_p.read,
      label: "Procedimentos",
      content: (
        <ProcedurePatientList patientId={patientId} initializeAttendance={initializeAttendance} />
      ),
    },
  ];

  if (patientId) return <Tabs tabs={tabs.filter((tab) => tab.active)} />;

  return (
    <BoxContainer title={"Monitoramento"}>
      <Box sx={{ width: "100%" }}>
        <Tabs tabs={tabs.filter((tab) => tab.active)} />
      </Box>
    </BoxContainer>
  );
}

export default Monitoring;
