import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useAttendaces } from "../../../service";
import { useNotifier } from "../../../hooks";
import { Loading } from "../../../helper";

export default function SoapInIFrame() {
  const navigate = useNavigate();
  const params = useParams();
  const { getAttendances } = useAttendaces();
  const notify = useNotifier();

  useQuery(["patient-id", params], () => getAttendances({ telemedicine_external_id: params.id }), {
    enabled: true,
    onSuccess: (data) => {
      const attendance = data.items[0];
      navigate(`/soap/${attendance.id_patient}/${attendance.id}`);
    },
    onError: () => {
      notify("Erro ao buscar paciente", "error");
    },
  });

  return <Loading />;
}
