import React, { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../service/useAuth";
import Cookie from "js-cookie";
import { useLocalIp, useLocalStorage } from "../hooks";
import { unprotectedRoutes } from "../config/config";
import { useMutation, useQuery } from "react-query";
import { differenceInMinutes, parseISO } from "date-fns";
import Notifier from "../helper/Notifier";
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [valid, setValid] = useState();
  const [loginVerified, setLoginVerified] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [firstAccess, setFirstAccess] = useState(false);
  const [notifierState, setNotifierState] = useState({
    show: false,
  });
  const [userData, setUserData] = useLocalStorage("user-data", null);
  const xAccessCookie = Cookie.get(["x-access-token"]);
  const location = useLocation();
  const navigate = useNavigate();
  const { getUserIP } = useLocalIp();

  const { isTokenValid, signin, verifyUser, expireToken } = useAuth();
  const expireTokenMutation = useMutation(expireToken);

  async function verifyLogin(userCredentials) {
    try {
      const response = await verifyUser(userCredentials);
      return response;
    } catch (err) {
      throw new Error(err.message, "error");
    }
  }

  function logout() {
    expireTokenMutation.mutate(null, {
      onSettled() {
        Cookie.remove("x-access-token");
        Cookie.remove("signer-auth-token");
        localStorage.clear();
        setLoginVerified(false);
        navigate("/login");
      },
    });
  }

  async function login(userCredentials) {
    try {
      const response = await signin(userCredentials);
      const newUserData = response.data;

      Cookie.set("x-access-token", response.data.token, {
        sameSite: "none",
        secure: true,
      });
      Cookie.set("signer-auth-token", response.data.signer_access_token, {
        sameSite: "none",
        secure: true,
      });

      getUserIP((ip) => {
        newUserData.station_ip = ip;
      });

      setValid(true);
      setUserData(newUserData);

      return { auth: true };
    } catch (err) {
      throw new Error(err.message, "error");
    }
  }

  function verifyExpirationTime() {
    const loginExpirationTime = localStorage.getItem("login-expiration-time");

    const differenceTime = differenceInMinutes(
      new Date(),
      parseISO(loginExpirationTime)
    );

    if (differenceTime >= 45) {
      return logout();
    }

    if (differenceTime >= 40) {
      setNotifierState({
        show: true,
        message:
          "Em caso de inatividade no sistema, você será redirecionado para a tela de login por motivos de segurança.",
        severity: "warning",
      });
    }

    localStorage.setItem("login-expiration-time", new Date().toISOString());
  }

  const verifyTokenQuery = useQuery(
    ["verify-token", xAccessCookie],
    () => isTokenValid(xAccessCookie),
    {
      onSuccess(response) {
        const newUserData = {};

        getUserIP((ip) => {
          newUserData.station_ip = ip;
        });

        newUserData.accessList = response.data.accessList;

        setValid(response.data.valid);
        setUserData((userData) => ({ ...userData, ...newUserData }));

        if (response.data.first_access) {
          setChangePassword(true);
          setFirstAccess(true);
        }

        if (location.pathname === "/login" && response.data.valid) {
          return navigate("/");
        }
      },
      onError(error) {
        const isRouteUnprotected = unprotectedRoutes.find((unprotected) =>
          unprotected.test(location.pathname)
        );

        if (isRouteUnprotected) {
          setValid(true);
        } else if (error?.response?.status === 401) {
          setValid(false);
          logout();
        } else {
          setValid(
            "Não foi possível conectar-se ao servidor. Tente novamente em instantes."
          );
        }
      },
    }
  );

  useEffect(() => {
    if (location.pathname === "/") verifyTokenQuery.refetch();
  }, [location.pathname]);

  // useEffect(() => {
  //   if (userData) {
  //     window.addEventListener("click", verifyExpirationTime);
  //     window.addEventListener("focus", verifyExpirationTime);

  //     return () => {
  //       window.removeEventListener("click", verifyExpirationTime);
  //       window.removeEventListener("focus", verifyExpirationTime);
  //     };
  //   }
  // }, [userData]);

  return (
    <AuthContext.Provider
      value={{
        valid,
        login,
        userData,
        logout,
        verifyLogin,
        loginVerified,
        setLoginVerified,
        changePassword,
        setChangePassword,
        firstAccess,
        setFirstAccess,
      }}
    >
      <>
        <Notifier
          open={notifierState.show}
          message={notifierState.message}
          severity={notifierState.severity}
          close={() => setNotifierState(false)}
        />
        {children}
      </>
    </AuthContext.Provider>
  );
};
