const genders = [
  { label: "Masculino", sus_code_id: 0 },
  { label: "Feminino", sus_code_id: 1 },
];

const raceColors = [
  { label: "Branca", sus_code_id: 1 },
  { label: "Preta", sus_code_id: 2 },
  { label: "Parda", sus_code_id: 3 },
  { label: "Amarela", sus_code_id: 4 },
  { label: "Indígena", sus_code_id: 5 },
];

const maritalStatus = [
  { label: "Solteiro(a)" },
  { label: "Casado(a)" },
  { label: "Divorciado(a)" },
  { label: "Viúvo(a)" },
  { label: "União Estável" },
];

const nationality = [
  { label: "Brasileiro(a)", value: "Brasileira", sus_code_id: 1 },
  { label: "Naturalizado(a)", value: "Naturalizado", sus_code_id: 2 },
  { label: "Estrangeiro(a)", value: "Estrangeiro", sus_code_id: 3 },
];

const kinshipRelationship = [
  {
    sus_code_id: "137",
    describe: "Cônjuge / Companheiro(a)",
  },
  {
    sus_code_id: "138",
    describe: "Filho(a)",
  },
  {
    sus_code_id: "139",
    describe: "Enteado(a)",
  },
  {
    sus_code_id: "140",
    describe: "Neto(a) / Bisneto(a)",
  },
  {
    sus_code_id: "141",
    describe: "Pai / Mãe",
  },
  {
    sus_code_id: "142",
    describe: "Sogro(a)",
  },
  {
    sus_code_id: "143",
    describe: "Irmão / Irmã",
  },
  {
    sus_code_id: "144",
    describe: "Genro / Nora",
  },
  {
    sus_code_id: "145",
    describe: "Outro parente",
  },
  {
    sus_code_id: "146",
    describe: "Não parente",
  },
];

const childrenResponsible = [
  { describe: "Adulto responsável", sus_code_id: 1 },
  { describe: "Outra(s) criança(s)", sus_code_id: 2 },
  { describe: "Adolescente", sus_code_id: 133 },
  { describe: "Sozinha", sus_code_id: 3 },
  { describe: "Creche", sus_code_id: 134 },
  // { describe: "Não tem criança", sus_code_id: 999 },
  { describe: "Outro", sus_code_id: 4 },
];

const sexualOrientations = [
  { describe: "Heterossexual", sus_code_id: 148 },
  { describe: "Bissexual", sus_code_id: 154 },
  { describe: "Homossexual", sus_code_id: 153 },
  { describe: "Gay", sus_code_id: 196 },
  { describe: "Lésbica", sus_code_id: 197 },
  { describe: "Assexual", sus_code_id: 198 },
  { describe: "Pansexual", sus_code_id: 199 },
  { describe: "Outro", sus_code_id: 155 },
];

const genderIdentities = [
  { describe: "Homem Transsexual", sus_code_id: 149 },
  { describe: "Mulher Transsexual", sus_code_id: 150 },
  { describe: "Travesti", sus_code_id: 156 },
  { describe: "Homem cisgênero", sus_code_id: 200 },
  { describe: "Mulher cisgênero", sus_code_id: 201 },
  { describe: "Transgênero", sus_code_id: 202 },
  { describe: "Não-binário", sus_code_id: 203 },
  { describe: "Outro", sus_code_id: 151 },
];

const disabilityTypes = [
  { describe: "Auditiva", sus_code_id: 12 },
  { describe: "Visual", sus_code_id: 13 },
  { describe: "Intelectual / Cognitiva", sus_code_id: 14 },
  { describe: "Física", sus_code_id: 15 },
  { describe: "Outra", sus_code_id: 16 },
];

const respiratoryDiseases = [
  { describe: "Asma", sus_code_id: 30 },
  { describe: "DPOC / Enfisema", sus_code_id: 31 },
  { describe: "Outra", sus_code_id: 32 },
  { describe: "Não sabe", sus_code_id: 33 },
];

const heartDiseases = [
  { describe: "Insuficiência cardíaca", sus_code_id: 24 },
  { describe: "Outro", sus_code_id: 25 },
  { describe: "Não sabe", sus_code_id: 26 },
];

const kidneyDiseases = [
  { describe: "Insuficiência renal", sus_code_id: 27 },
  { describe: "Outro", sus_code_id: 28 },
  { describe: "Não sabe", sus_code_id: 29 },
];

const homelessnessTime = [
  { describe: "Menos de 6 meses", sus_code_id: 17 },
  { describe: "6 a 12 meses", sus_code_id: 18 },
  { describe: "1 a 5 anos", sus_code_id: 19 },
  { describe: "Mais de 5 anos", sus_code_id: 20 },
];

const personalHygienes = [
  { describe: "Banho", sus_code_id: 42 },
  { describe: "Acesso a sanitário", sus_code_id: 43 },
  { describe: "Higiene bucal", sus_code_id: 44 },
  { describe: "Outros", sus_code_id: 45 },
];

const howTimesFeedDay = [
  { describe: "1 vez", sus_code_id: 34 },
  { describe: "2 ou 3 vezes", sus_code_id: 35 },
  { describe: "Mais de 3 vezes", sus_code_id: 36 },
];

const originFoods = [
  { describe: "Restaurante popular", sus_code_id: 37 },
  { describe: "Doação grupo religioso", sus_code_id: 38 },
  { describe: "Doação restaurante", sus_code_id: 39 },
  { describe: "Doação popular", sus_code_id: 40 },
  { describe: "Outros", sus_code_id: 41 },
];

const typeBlood = [
  { label: "A+" },
  { label: "A-" },
  { label: "B+" },
  { label: "B-" },
  { label: "AB+" },
  { label: "AB-" },
  { label: "O+" },
  { label: "O-" },
];

const homeLocations = [
  { id: "83", describe: "Urbana" },
  { id: "84", describe: "Rural" }
];

const homeAccessTypes = [
  { id: "89", describe: "Pavimento" },
  { id: "90", describe: "Chão batido" },
  { id: "91", describe: "Fluvial" },
  { id: "92", describe: "Outro" }
];

const homeTypes = [
  { id: "85", describe: "Casa" },
  { id: "86", describe: "Apartamento" },
  { id: "87", describe: "Cômodo" },
  { id: "88", describe: "Outro" }
];

const waterSupplies = [
  { id: "117", describe: "Rede encanada até o domicílio" },
  { id: "118", describe: "Poço / Nascente no domicílio" },
  { id: "119", describe: "Cisterna" },
  { id: "120", describe: "Carro pipa" },
  { id: "121", describe: "Outro" }
];

const waterConsumptions = [
  { id: "97", describe: "Filtrada" },
  { id: "98", describe: "Fervida" },
  { id: "99", describe: "Clorada" },
  { id: "152", describe: "Mineral" },
  { id: "100", describe: "Sem tratamento" }
];

const destinationGarbages = [
  { id: "93", describe: "Coletado" },
  { id: "94", describe: "Queimado / Enterrado" },
  { id: "95", describe: "Céu aberto" },
  { id: "96", describe: "Outro" }
];

const buildingMaterials = [
  { id: "109", describe: "Alvenaria com revestimento" },
  { id: "110", describe: "Alvenaria sem revestimento" },
  { id: "111", describe: "Taipa com revestimento" },
  { id: "112", describe: "Taipa sem revestimento" },
  { id: "113", describe: "Madeira emparelhada" },
  { id: "114", describe: "Material aproveitado" },
  { id: "115", describe: "Palha" },
  { id: "116", describe: "Outro material" }
];

const sanitaryDrainages = [
  { id: "122", describe: "Rede coletora de esgoto ou pluvial" },
  { id: "123", describe: "Fossa séptica" },
  { id: "124", describe: "Fossa rudimentar" },
  { id: "125", describe: "Direto para um rio, lago ou mar" },
  { id: "126", describe: "Céu aberto" },
  { id: "127", describe: "Outra forma" }
];

const animals = [
  { id: "128", describe: "Gato" },
  { id: "129", describe: "Cachorro" },
  { id: "130", describe: "Pássaro" },
  { id: "132", describe: "Outros" }
];

const housingSituations = [
  { id: "75", describe: "Próprio" },
  { id: "76", describe: "Financiado" },
  { id: "77", describe: "Alugado" },
  { id: "78", describe: "Arrendado" },
  { id: "79", describe: "Cedido" },
  { id: "80", describe: "Ocupação" },
  { id: "81", describe: "Situação de rua" },
  { id: "82", describe: "Outra" }
];

const brazil = { id: 31, describe: "BRASIL" }

export {
  genders,
  raceColors,
  maritalStatus,
  nationality,
  kinshipRelationship,
  childrenResponsible,
  sexualOrientations,
  genderIdentities,
  disabilityTypes,
  respiratoryDiseases,
  heartDiseases,
  kidneyDiseases,
  homelessnessTime,
  personalHygienes,
  howTimesFeedDay,
  originFoods,
  typeBlood,
  homeLocations,
  homeAccessTypes,
  homeTypes,
  waterSupplies,
  waterConsumptions,
  destinationGarbages,
  buildingMaterials,
  sanitaryDrainages,
  animals,
  housingSituations,
  brazil
}