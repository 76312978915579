import { Box, Typography } from "@mui/material";
import React from "react";

export default function DetailCard({
  title,
  content,
  subContent,
  handleClick,
  sx,
  footer = () => <></>,
}) {
  return (
    <Box
      className="card-item"
      onClick={handleClick}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 1,
        padding: 0.5,
        ...sx,
      }}
    >
      <Typography variant="subtitle1" textAlign="center" fontWeight={600}>
        {title}
      </Typography>
      <Typography variant="h5" textAlign="center" fontWeight={700}>
        {content}
      </Typography>
      <Typography variant="p" color={"gray"} textAlign="center" fontWeight={600} fontSize={12}>
        {subContent}
      </Typography>
      {footer()}
    </Box>
  );
}
