import { Box, Grid, Grow, Typography, styled } from "@mui/material";
import React from "react";
import { DeleteIconButton, EditIconButton, PrintIconButton } from "../../../components/Buttons";

const CardPrescription = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: 4,
  backgroundColor: theme.palette.primary.medium,
  borderRadius: 8,
  padding: "0.8rem",
  color: theme.palette.primary.light,
  position: "relative",
}));

const IconContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: 4,
  position: "absolute",
  top: 6,
  right: 15,
});

const PrescriptionHeader = styled(Typography, {
  shouldForwardProp: (prop) => {
    return prop !== "color" && prop !== "sx";
  },
})(({ theme }) => ({
  fontSize: 20,
  fontWeight: 600,
  color: theme.palette.primary.light,
}));

const PrescriptionDesc = styled(Typography)(({ theme }) => ({
  fontSize: 13,
  marginRight: 20,
  color: theme.palette.primary.light,
}));

export default function ExamPrescriptionCard({ data, handleRemove, handleEdit, handlePrint }) {
  const isGroup = Array.isArray(data);
  let isRegistered = false;

  if (isGroup) {
    isRegistered = !!data[0].id_prescription;
  }

  function mountNotes() {
    if (!data.segment_area && !data.diagnostic_hypothesis && !data.notes) {
      return "";
    }
  
    let notes = `
      ${data.segment_area ? data.segment_area + " - " : ""} 
      ${data.diagnostic_hypothesis ? data.diagnostic_hypothesis + " - " : ""} 
      ${data.notes ? data.notes : ""}`;
    
    return `( ${notes.trim().replace(/-\s*$/, "")} )`;
  }
  return (
    <Grid xs={12} md={9} item>
      <Grow in timeout={300}>
        <CardPrescription>
          <PrescriptionHeader component="h6">
            {isGroup ? `Agrupamento ${data[0].tab_id} (${data[0].type})` : data.type}
          </PrescriptionHeader>
          <PrescriptionDesc component="ul">
            {isGroup ? (
              data.map((exam, index) => (
                <Typography key={`${exam.describe}-${index}`} component="li">
                  {exam.describe}
                </Typography>
              ))
            ) : (
              <Typography component="li">{data.describe}</Typography>
            )}
          </PrescriptionDesc>
          {!isGroup && (
            <PrescriptionDesc variant="p" component="p">
              {mountNotes()}
            </PrescriptionDesc>
          )}
          <IconContainer>
            {!isRegistered && isGroup && <EditIconButton onClick={handleEdit} />}
            {!isRegistered && <DeleteIconButton onClick={handleRemove} />}
            {isRegistered && <PrintIconButton onClick={handlePrint} />}
          </IconContainer>
        </CardPrescription>
      </Grow>
    </Grid>
  );
}
