import React, { useState } from "react";
import { Tabs } from "../../../../components";
import FormFilters from "../FormFilters";
import Housing from "./Housing";
import Population from "./Population";
import HealthConditions from "./HealthConditions";
import { useCockpit } from "../../../../service";
import { useQuery } from "react-query";
import { useNotifier } from "../../../../hooks";
import { Loading } from "../../../../helper";

export default function CockPitACSOperational({ filters, onSubmitFilters, initialValues }) {
  const notify = useNotifier();
  const { getACSHealthInformations, getACSHousingConditions, getACSPopulations } = useCockpit();
  const [currentTab, setCurrentTab] = useState("housing");

  const healthInformations = useQuery(
    ["health-information", filters],
    () => getACSHealthInformations(filters),
    {
      enabled: currentTab === "healthCondition" && !!filters,
      onError(error) {
        notify(error.message, "error");
      },
    }
  );
  const housingConditions = useQuery(
    ["housing-conditions", filters],
    () => getACSHousingConditions(filters),
    {
      enabled: currentTab === "housing" && !!filters,
      onError(error) {
        notify(error.message, "error");
      },
    }
  );

  const populations = useQuery(["populations", filters], () => getACSPopulations(filters), {
    enabled: currentTab === "population" && !!filters,
    onError(error) {
      notify(error.message, "error");
    },
  });

  const analyticalReport = filters?.report_type === "Analítico";

  const tabs = [
    {
      label: "Moradia",
      type: "housing",
      content: (
        <>
          <FormFilters onSubmit={(values) => onSubmitFilters(values)} values={initialValues} />
          {housingConditions.isLoading ? <Loading /> : <Housing data={housingConditions.data} analyticalReport={analyticalReport} filters={filters} />}
        </>
      ),
    },
    {
      label: "População",
      type: "population",
      content: (
        <>
          <FormFilters onSubmit={(values) => onSubmitFilters(values)} values={initialValues} />
          {populations.isLoading ? <Loading /> : <Population data={populations.data} analyticalReport={analyticalReport} filters={filters} />}
        </>
      ),
    },
    {
      label: "Condições de saúde",
      type: "healthCondition",
      content: (
        <>
          <FormFilters onSubmit={(values) => onSubmitFilters(values)} values={initialValues} />
          {healthInformations.isLoading ? (
            <Loading />
          ) : (
            <HealthConditions data={healthInformations.data} analyticalReport={analyticalReport} filters={filters} />
          )}
        </>
      ),
    },
  ];

  return (
    <Tabs
      tabs={tabs}
      handleClick={(tab) => {
        setCurrentTab(tab.type);
      }}
    />
  );
}
