import { styled } from "@mui/material/styles";
import PrintRounded from "@mui/icons-material/PrintRounded";
import { IconButton } from "@mui/material";

const StyledIconButton = styled(IconButton)(({ disabled }) => ({
  opacity: disabled ? 0.5 : 1,
}));

export default function PrintIconButton(props) {
  return (
    <StyledIconButton {...props}>
      <PrintRounded color="primary" />
    </StyledIconButton>
  );
}
