function openURL(url, download_as) {
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";

  if (download_as) {
    link.download = download_as;
  }

  link.dispatchEvent(new MouseEvent("click"));
}

export default openURL;
