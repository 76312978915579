import { Grid, Typography, useTheme } from "@mui/material";
import { format, parseISO } from "date-fns";
import WarningSpan from "../../../helper/WarningSpan";
import diabeticFootWarning from "../../../utils/diabeticFootWarning";
import FootValues from "./FootInfo/FootValues";

export default function FootInfo({
  footData,
  dateAssessment,
  newAssessmentDate,
}) {
  const theme = useTheme();

  return (
    <>
      <Grid item sx={"auto"}>
        <Typography color={theme.palette.primary.light}>
          <Typography mb={1} fontWeight="500">
            Pé diabético{" "}
          </Typography>
          {footData?.result && (
            <WarningSpan
              fontSize={11}
              text={diabeticFootWarning(footData?.result)}
            />
          )}
          <Typography mt={1} color={theme.palette.primary.main}>
            <Typography
              variant="h7"
              color={theme.palette.primary.light}
              fontWeight="500"
              sx={{ marginTop: "20px" }}
            >
              Data da avaliação do pé diabético:{" "}
            </Typography>
            {dateAssessment
              ? format(parseISO(dateAssessment), "dd/MM/yyyy")
              : "Não informado"}
          </Typography>
          <Typography color={theme.palette.primary.main}>
            <Typography
              variant="h7"
              color={theme.palette.primary.light}
              fontWeight="500"
              sx={{ marginTop: "20px" }}
            >
              Data prevista para nova avaliação:{" "}
            </Typography>
            {newAssessmentDate
              ? format(parseISO(newAssessmentDate), "dd/MM/yyyy")
              : "Não informado"}
          </Typography>
        </Typography>
        <Typography color={theme.palette.primary.light} component={"span"}>
          <Typography color={theme.palette.primary.main}>
            <Typography
              variant="h7"
              color={theme.palette.primary.light}
              fontWeight="500"
              sx={{ marginTop: "20px" }}
            >
              Diabético:{" "}
            </Typography>
            {footData.diabetic ? "Sim" : "Não"}
          </Typography>

          <Typography color={theme.palette.primary.main}>
            <Typography
              variant="h7"
              color={theme.palette.primary.light}
              fontWeight="500"
              sx={{ marginTop: "20px" }}
            >
              Hipertenso :{" "}
            </Typography>
            {footData.hypertensive ? "Sim" : "Não"}
          </Typography>
          <Typography color={theme.palette.primary.main}>
            <Typography
              variant="h7"
              color={theme.palette.primary.light}
              fontWeight="500"
              sx={{ marginTop: "20px" }}
            >
              Insulino Dependente :{" "}
            </Typography>
            {footData.insulin_dependent ? "Sim" : "Não"}
          </Typography>
          <Typography color={theme.palette.primary.main}>
            <Typography
              variant="h7"
              color={theme.palette.primary.light}
              fontWeight="500"
              sx={{ marginTop: "20px" }}
            >
              Tipo Diabetes:{" "}
            </Typography>
            {footData.diabetic_type}
          </Typography>
          <Typography color={theme.palette.primary.main}>
            <Typography
              variant="h7"
              color={theme.palette.primary.light}
              fontWeight="500"
              sx={{ marginTop: "20px" }}
            >
              AMG:{" "}
            </Typography>
            {footData.amg ? "Sim" : "Não"}
          </Typography>
          <Typography color={theme.palette.primary.main}>
            <Typography
              variant="h7"
              color={theme.palette.primary.light}
              fontWeight="500"
              sx={{ marginTop: "20px" }}
            >
              Data do ultimo laudo AMG:{" "}
            </Typography>
            {footData.last_amg_report_date
              ? format(parseISO(footData.last_amg_report_date), "dd/MM/yyyy")
              : "Não informado"}
          </Typography>
          <Typography color={theme.palette.primary.main}>
            <Typography
              variant="h7"
              color={theme.palette.primary.light}
              fontWeight="500"
              sx={{ marginTop: "20px" }}
            >
              Resultado hemoglobina glicada :{" "}
            </Typography>
            {footData.glycated_hemoglobin_result}
          </Typography>
          <Typography color={theme.palette.primary.main}>
            <Typography
              variant="h7"
              color={theme.palette.primary.light}
              fontWeight="500"
              sx={{ marginTop: "20px" }}
            >
              Data do resultado hemoglobina glicada:{" "}
            </Typography>
            {footData.glycated_hemoglobin_date
              ? format(
                  parseISO(footData.glycated_hemoglobin_date),
                  "dd/MM/yyyy"
                )
              : "Não informado"}
          </Typography>
        </Typography>
        <FootValues values={footData.left_foot} title={"Pé Esquerdo"} />
        <FootValues values={footData.right_foot} title={"Pé Direito"} />
      </Grid>
    </>
  );
}
