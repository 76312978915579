import React, { useState } from "react";
import { BoxContainer, Tabs } from "../../../components";
import CockPitACSOperational from "./Operational";
import CockPitACSManagement from "./Management";
import { format } from "date-fns";

function CockPitACS() {
  const [filters, setFilters] = useState(null);
  const [initialValues, setInitialValues] = useState(null);

  const onSubmitFilters = (values) => {
    setFilters({
      company_id: values.company?.id,
      initial_date: format(values.initial_date, "yyyy-MM-dd"),
      final_date: format(values.final_date, "yyyy-MM-dd"),
      microarea_id: values.micro_area?.id,
      sts: values.region?.describe,
      report_type: values.report_types?.describe,
      team_id: values.team?.id,
    });
    setInitialValues(values);
  };

  const tabs = [
    {
      label: "Operacional",
      content: (
        <CockPitACSOperational
          filters={filters}
          onSubmitFilters={onSubmitFilters}
          initialValues={initialValues}
        />
      ),
    },
    {
      label: "Gerencial",
      content: (
        <CockPitACSManagement
          filters={filters}
          onSubmitFilters={onSubmitFilters}
          initialValues={initialValues}
        />
      ),
    },
  ];

  return (
    <BoxContainer title="Painel - ACS">
      <Tabs tabs={tabs} />
    </BoxContainer>
  );
}

export default CockPitACS;
