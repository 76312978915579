export default function groupPrescriptionCollection(array, item) {
  const groupIndex = array.findIndex((group) => {
    if (Array.isArray(group)) {
      return item.tab_id === group[0].tab_id;
    }

    return false;
  });

  if (groupIndex !== -1) {
    array[groupIndex].push(item);
  } else {
    array.push([item]);
  }
}
