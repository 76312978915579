import { Grid, useTheme } from "@mui/material";
import React from "react";
import SectionHeader from "../Panels/SectionHeader";
import DetailCard from "../Panels/DetailCard";

export default function DynamicSections({ shape, header = true, gridItemSize = 2, headerSx, cardSx }) {
  const theme = useTheme();

  return shape?.map((item, index) => (
    <React.Fragment key={index}>
      {header && (
        <Grid item xs={12}>
          <SectionHeader
            icon={item.icon}
            title={item.title}
            sx={{
              backgroundColor: theme.palette.secondary.main,
              color: "white",
              ...headerSx,
            }}
          />
        </Grid>
      )}
      {item?.cards?.map((card, i) => (
        <Grid item xs={gridItemSize} key={i}>
          <DetailCard
            title={card.title}
            content={card.content}
            handleClick={() => card.handleClick(card, item)}
            sx={{
              backgroundColor: theme.palette.primary.medium,
              color: theme.palette.primary.light,
              borderRadius: 1.5,
              ...cardSx,
            }}
          />
        </Grid>
      ))}
    </React.Fragment>
  ));
}
