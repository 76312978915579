import HighlightOffOutlined from "@mui/icons-material/HighlightOffOutlined";
import { IconButton, styled } from "@mui/material";

const StyledIconButton = styled(IconButton)(({ disabled }) => ({
  opacity: disabled ? 0.5 : 1,
}));

export default function DeleteIconButton(props) {
  return (
    <StyledIconButton {...props}>
      <HighlightOffOutlined color="primary" />
    </StyledIconButton>
  );
}
