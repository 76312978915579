import React from "react";
import { createPortal } from "react-dom";
import { useConfirm } from "./BlockNavigation";
import ConfirmDialog from "./ConfirmDialog";

const GlobalConfirmDialog = () => {
  const { onConfirm, onCancel, confirmState } = useConfirm();

  const portalElement = document.getElementById("portal");
  const component = confirmState.show ? (
    <ConfirmDialog
      fullWidth
      handleClose={onCancel}
      handleConfirm={onConfirm}
      message={confirmState.text}
      open={confirmState.show}
      cancelLabel={confirmState.labels?.cancel}
      confirmLabel={confirmState.labels?.confirm}
      handleCancel={onCancel}
      actions={confirmState.actions}
    />
  ) : null;

  return createPortal(component, portalElement);
};
export default GlobalConfirmDialog;
