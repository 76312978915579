import React from "react";
import {
  Dialog as MaterialDialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { SubTitle } from "../components";
import { CloseRounded, EmojiObjects } from "@mui/icons-material";
import { Box } from "@mui/system";
import { grey } from "@mui/material/colors";
import { useTheme } from "@emotion/react";
function DialogMedium({
  title,
  open,
  handleClose,
  children,
  icon,
  removeTips,
  maxWidth,
  fullWidth,
  actions = [],
  ...props
}) {
  const theme = useTheme();
  return (
    <MaterialDialog
      open={open}
      onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      sx={{
        padding: 1,
        "& .MuiDialogTitle-root": {
          p: 2,
        },
      }}
      {...props}
    >
      <DialogTitle>
        <Box
          sx={{
            width: "100%",
            paddingY: removeTips ? 0 : 0.3,
            margin:0,
            paddingX: 0.3,
            borderRadius: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {!removeTips && (
            <Box display={"flex"} sx={{ backgroundColor: grey[50] }} alignItems={"center"}>
              <EmojiObjects transition sx={{ color: (theme) => theme.palette.primary.main }} />
              <Typography fontSize={12} color={theme.palette.secondary.main}>
                Atalho: Utilize a tecla{" "}
                <Typography fontWeight="bold" component="span" fontSize={12}>
                  Esc
                </Typography>{" "}
                para fechar.
              </Typography>
            </Box>
          )}
          {handleClose && (
            <IconButton onClick={handleClose} sx={{ marginLeft: "auto" }}>
              <CloseRounded />
            </IconButton>
          )}
        </Box>
        <SubTitle
        marginTop={!removeTips ? 2 : 0}
          text={
            <>
              <Box width={"100%"} sx={{ display: "flex", alignItems: "center" }} gap={2}>
                <Box>{title}</Box>
                <Box>{icon}</Box>
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {actions.map((action) => (
                    <IconButton
                      title={action.title}
                      color="primary"
                      onClick={() => action.handle()}
                    >
                      {action.icon}
                    </IconButton>
                  ))}
                </Box>
              </Box>
            </>
          }
        />
      </DialogTitle>
      <DialogContent
        sx={{
          mt: -3,
          "&.MuiDialogContent-root": {
            padding: 2,
          },
          position: "relative",
        }}
      >
        {children}
      </DialogContent>
    </MaterialDialog>
  );
}

export default DialogMedium;
