import { Box, Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  basicAttentionValidation,
  cidValidation,
  mainFormValidations,
  procedureValidation,
} from "./validations";
import {
  Button,
  DateField,
  PaginatedAutocompleteField,
  SelectField,
  SwitchField,
  TextField,
} from "../../../../components/FormFields";
import {
  useCids,
  usePatient,
  useProcedures,
  useProvider,
  useSOAP,
} from "../../../../service";
import TableFilter from "../../../../components/Table/TableFilter";
import useBilling from "../../../../service/useBilling";
import { useMutation, useQuery } from "react-query";
import { useNotifier } from "../../../../hooks";
import useAttendanceGroup from "../../../../service/useAttendanceGroup";
import useMAB from "../../../../service/useMAB";
import { format, parseISO } from "date-fns";
import { useState } from "react";
import { Loading } from "../../../../helper";
import { verifyAttendanceFlow } from "../../../../utils";
import { MEDICAL_EMPLOYEE_TYPE_ID } from "../../../../config/constants";

export default function BillingForm({
  closeForm,
  rpaId,
  isClassification,
  processed,
}) {
  const notify = useNotifier();
  const [specialties, setSpecialties] = useState([]);
  const [billingToUpdate, setBillingToUpdate] = useState(rpaId);

  const { getCids } = useCids();
  const { getProcedures } = useProcedures();
  const { getSpecialties, getProviders } = useProvider();
  const { getPatients } = usePatient();
  const { postBilling, updateBilling, getBillingByRPAId } = useBilling();
  const { getAttendanceGroups } = useAttendanceGroup();
  const { getAttendanceTypes } = useSOAP();
  const { getMABs } = useMAB();

  const postMutation = useMutation({
    mutationKey: "post-billing",
    mutationFn: postBilling,
  });

  const putMutation = useMutation({
    mutationKey: "patch-billing",
    mutationFn: updateBilling,
  });

  const cidForm = useForm({
    defaultValues: {
      cid: null,
      main: false,
    },
    resolver: yupResolver(cidValidation),
  });

  const procedureForm = useForm({
    defaultValues: {
      procedure: null,
      specialty: null,
      qtd: 1,
    },
    resolver: yupResolver(procedureValidation),
  });

  const procedureSpecialty = procedureForm.watch("specialty");

  const basicAttentionForm = useForm({
    defaultValues: {
      mab: null,
      complement: null,
    },
    resolver: yupResolver(basicAttentionValidation),
  });

  const [mabField] = basicAttentionForm.watch(["mab"]);

  const mainForm = useForm({
    defaultValues: {
      attendance_consolidated: false,
      employee: null,
      specialty: "",
      team_code: "",
      cids: [],
      procedures: [],
      date: null,
      hour: null,
      additional_employees: [],
      patient: null,
      attendance_group: null,
      attendance_type: null,
      basic_attention: [],
    },
    resolver: yupResolver(mainFormValidations),
  });

  const [
    cidsData,
    proceduresData,
    basicAttentionData,
    employeeField,
    patientField,
  ] = mainForm.watch([
    "cids",
    "procedures",
    "basic_attention",
    "employee",
    "patient",
  ]);

  function setDefaultValuesToEdit(data) {
    if (!data) return;
    if (typeof data.hour === "string") {
      const hour = data.hour.replace(/^(\d+)\:(\d+)\:(\d+)/, "$1");
      const minute = data.hour.replace(/^(\d+)\:(\d+)\:(\d+)/, "$2");
      const seconds = data.hour.replace(/^(\d+)\:(\d+)\:(\d+)/, "$3");
      const toSetTime = new Date().setHours(hour, minute, seconds);

      data.hour = new Date(toSetTime);
    }

    setSpecialties(data.employee_specialty.employee.specialties);

    mainForm.reset({
      attendance_consolidated: data.attendance_consolidated,
      employee: data.employee_specialty.employee,
      specialty: data.employee_specialty.specialty,
      team_code: data.team_code,
      cids: data.cids ?? [],
      procedures: data.procedures,
      date: parseISO(data.date),
      hour: data.hour,
      additional_employees: data.additional_employees || [],
      patient: data.patient,
      attendance_group: data.attendance_group,
      attendance_type: data.attendanceType,
      basic_attention: data.basic_attention,
    });

    procedureForm.reset({
      specialty: data.employee_specialty.specialty,
    });
  }

  const { isFetching } = useQuery(
    ["get-billing-by-rpa-Id"],
    () => getBillingByRPAId(rpaId),
    {
      enabled: !!rpaId,
      onSuccess(data) {
        setDefaultValuesToEdit(data);
        setBillingToUpdate(data.id);
      },
      onError(err) {
        notify(err.message, "error");
      },
    }
  );

  const cidTableColumns = [
    {
      name: "Código",
      field: "id",
    },
    {
      name: "Diagnostico",
      field: "description",
    },
    {
      name: "Principal",
      field: "main",
      use: (row) => (row ? "Sim" : "Não"),
    },
    cidsData,
  ];

  const procedureTableColumn = [
    {
      name: "Código",
      field: "tuss_code",
    },
    {
      name: "Procedimento",
      field: "name",
    },
    {
      name: "Especialidade",
      field: "specialty?.describe",
    },
    {
      name: "Quantidade",
      field: "quantity",
    },
  ];
  const attentionTableColumn = [
    {
      name: "Código",
      field: "mab?.id",
    },
    {
      name: "Domínio",
      field: "mab?.domain",
    },
    {
      name: "Subdomínio",
      field: "mab?.subdomain",
    },
    {
      name: "Descrição",
      field: "mab?.describe",
    },
    {
      name: "Complemento",
      field: "complement",
    },
  ];

  const complementLabel = {
    P23: "CIAP2",
    P24: "CID",
    AA1: "Peso",
    AA2: "Altura",
    AA3: "Perímetro cefálico",
  };

  const addCid = cidForm.handleSubmit(({ cid, main }) => {
    const { description, id } = cid;
    mainForm.setValue("cids", [...cidsData, { description, id, main }]);
    cidForm.reset();
  });

  function removeCid(index) {
    mainForm.setValue(
      "cids",
      cidsData.filter((_, i) => i !== index)
    );
  }

  const addProcedures = procedureForm.handleSubmit((values) => {
    const maxProcedures = values.procedure.maximum_amount;
    const excedMaxProcdures = maxProcedures && maxProcedures < values.qtd;

    if (excedMaxProcdures) {
      return notify(
        `A quantidade máxima para esse procedimento é ${maxProcedures}`,
        "info"
      );
    }

    const toAdd = {
      name: values.procedure.describe,
      tuss_code: values.procedure.tuss_code,
      specialty: {
        describe: values.specialty.describe,
        siga_code: values.specialty.siga_code,
        id: values.specialty.id,
      },
      quantity: values.qtd,
    };

    mainForm.setValue("procedures", [...proceduresData, toAdd]);

    procedureForm.reset(
      {
        specialty: values.specialty,
      },
      {
        keepDefaultValues: true,
      }
    );
  });

  const addBasicAttention = basicAttentionForm.handleSubmit((value) => {
    if (!basicAttentionData?.length && !["FA", "FO"].includes(value.mab.id)) {
      notify("Informe a ficha de atendimento.", "info");
      return;
    }

    if (["P24"].includes(value.mab.id)) {
      value.complement = value.complement.id;
    }

    mainForm.setValue("basic_attention", [
      ...(basicAttentionData ? basicAttentionData : []),
      value,
    ]);

    basicAttentionForm.reset();
  });

  function removeProcedure(index) {
    mainForm.setValue(
      "procedures",
      proceduresData.filter((_, i) => i !== index)
    );
  }

  function removeBasicAttention(index) {
    if (["FA", "FO"].includes(basicAttentionData[index].mab?.id)) {
      mainForm.setValue("basic_attention", []);
      return;
    }

    mainForm.setValue(
      "basic_attention",
      basicAttentionData.filter((_, i) => i !== index)
    );
  }

  function insertedMABs() {
    if (!basicAttentionData?.length) return;
    return basicAttentionData?.map((item) => item.mab?.id).join(",");
  }

  const handleSubmit = mainForm.handleSubmit((values) => {
    if (
      !values?.cids?.length &&
      values.employee.employee_type.id === MEDICAL_EMPLOYEE_TYPE_ID
    ) {
      return notify("Ao menos um CID deve ser informado.", "error");
    }

    const hasMainCid = values.cids?.some((cid) => cid.main);

    if (!hasMainCid && values.cids?.length) {
      return notify("Um CID principal deve ser informado.", "info");
    }

    const valuesToSend = {
      date: format(values.date, "yyyy-MM-dd"),
      hour: format(values.hour, "HH:mm"),
      employee_id: values.employee.id,
      attendance_type_id: values.attendance_type.id,
      attendance_group_id: values.attendance_group.id,
      attendance_consolidated: values.attendance_consolidated,
      specialty_id: values.specialty.id,
      patient_id: values.patient.id,
      team_code: values.team_code,
      cids: values.cids,
      procedures: values.procedures,
      additional_employees: values.additional_employees.map(({ id }) => id),
      basic_attention: values.basic_attention ?? [],
    };

    if (billingToUpdate) {
      putMutation.mutate(
        { id: billingToUpdate, data: valuesToSend },
        {
          onSuccess(data) {
            notify(data.message, "success");
            closeForm();
          },
          onError(data) {
            notify(data.message, "error");
          },
        }
      );
    } else {
      postMutation.mutate(valuesToSend, {
        onSuccess(data) {
          notify(data.message, "success");
          closeForm();
        },
        onError(data) {
          notify(data.message, "error");
        },
      });
    }
  });

  return (
    <Grid container item xs={12} spacing={3}>
      {isFetching ? (
        <Loading maxWidth={"100%"} backgroundColor={"rgba(255,255,255,0.7)"} />
      ) : null}
      <Grid xs={12} item>
        <Typography color={"primary"} fontWeight={"bolder"} fontSize={18}>
          Dados
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <PaginatedAutocompleteField
          name={"employee"}
          label={"Profissional"}
          control={mainForm.control}
          service={getProviders}
          queryKey={"get-providers"}
          optionCompareKey={"id"}
          filterKey={"filter"}
          autoCompleteProps={{
            getOptionLabel: (option) =>
              `${option.sus_card ? option.sus_card : "#"} - ${option.name}`,
            disablePortal: true,
          }}
          customOnChange={(value) => {
            mainForm.setValue("specialty", null);
            procedureForm.setValue("specialty", null);
            procedureForm.setValue("procedure", null);
            mainForm.setValue("procedures", []);
            setSpecialties(value?.specialties ?? []);
          }}
          disabled={processed}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectField
          control={mainForm.control}
          name={"specialty"}
          label={"Especialidade"}
          options={specialties}
          optionLabelKey="describe"
          disabled={!employeeField || processed}
          customOnChange={(value) => {
            procedureForm.setValue("specialty", value);
            procedureForm.setValue("procedure", null);
            mainForm.setValue("procedures", []);
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <DateField
          control={mainForm.control}
          fullWidth
          name={"date"}
          label={"Data"}
          disabled={processed}
        />
      </Grid>
      <Grid item xs={3}>
        <DateField
          control={mainForm.control}
          type="time"
          name={"hour"}
          fullWidth
          label={"Hora"}
          disabled={processed}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          control={mainForm.control}
          name={"team_code"}
          type="number"
          label={"Código de equipe"}
          disabled={processed}
        />
      </Grid>
      <Grid item xs={3}>
        <SwitchField
          control={mainForm.control}
          label={"Atendimento consolidado?"}
          name={"attendance_consolidated"}
          disabled
        />
      </Grid>
      <Grid xs={12} item>
        <Typography color={"primary"} fontWeight={"bolder"} fontSize={18}>
          Paciente
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <PaginatedAutocompleteField
          control={mainForm.control}
          name={"patient"}
          label={"Paciente"}
          service={getPatients}
          queryKey={"getPatients"}
          optionCompareKey={"id"}
          customOnChange={() => {
            mainForm.setValue("cids", []);
            procedureForm.setValue("procedure", null);
            mainForm.setValue("procedures", []);
          }}
          autoCompleteProps={{
            getOptionLabel: (option) =>
              `${option.sus_card ? option.sus_card : "#"} - ${option.name}`,
            disablePortal: true,
          }}
          filterKey={"filter"}
          disabled={processed}
        />
      </Grid>
      <Grid item xs={6}>
        <PaginatedAutocompleteField
          name={"attendance_group"}
          label={"Grupo de atendimento"}
          control={mainForm.control}
          service={getAttendanceGroups}
          queryKey={"getAttendanceGroups"}
          optionLabelKey="describe"
          autoCompleteProps={{
            disablePortal: true,
          }}
          disabled={processed}
        />
      </Grid>
      <Grid item xs={6}>
        <PaginatedAutocompleteField
          name={"attendance_type"}
          label={"Tipo de atendimento"}
          control={mainForm.control}
          service={(params) => getAttendanceTypes({ ...params, type: "SIGA" })}
          queryKey={"getAttendanceTypes"}
          optionLabelKey="describe"
          autoCompleteProps={{
            disablePortal: true,
          }}
          disabled={processed}
        />
      </Grid>
      <Grid xs={12} item>
        <Typography color={"primary"} fontWeight={"bolder"} fontSize={18}>
          CIDs
        </Typography>
      </Grid>
      <Grid item xs={12} display={"flex"} columnGap={3}>
        <Box width={"30%"}>
          <PaginatedAutocompleteField
            control={cidForm.control}
            label={"CID"}
            name={"cid"}
            service={(params) =>
              getCids({ ...params, patient_id: patientField?.id })
            }
            queryKey={"get-cids"}
            filterKey={"fieldValue"}
            disabled={!patientField || processed}
            refetchService={[patientField]}
            autoCompleteProps={{
              getOptionLabel: (option) =>
                `${option.id} - ${option.description}`,
              disablePortal: true,
            }}
            optionCompareKey={"id"}
          />
        </Box>
        <Box>
          <SwitchField
            control={cidForm.control}
            label={"Principal"}
            name={"main"}
            disabled={processed || !!cidsData?.find((value) => value.main)}
          />
        </Box>
        <Box>
          <Button variant="contained" onClick={addCid} disabled={processed}>
            Adicionar
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TableFilter
          data={cidsData}
          columns={cidTableColumns}
          noHeaderOptions
          actions
          actionsTypes={["delete"]}
          disableActions={() => processed}
          actionHandleDelete={removeCid}
        />
      </Grid>
      <Grid xs={12} item>
        <Typography color={"primary"} fontWeight={"bolder"} fontSize={18}>
          Procedimentos
        </Typography>
      </Grid>
      <Grid item xs={4.5}>
        <PaginatedAutocompleteField
          name={"specialty"}
          label={"Especialidade"}
          service={getSpecialties}
          queryKey={"get-specialties"}
          optionLabelKey="describe"
          filterKey={"describe"}
          control={procedureForm.control}
          disabled
          autoCompleteProps={{
            disablePortal: true,
          }}
        />
      </Grid>
      <Grid item xs={4.5}>
        <PaginatedAutocompleteField
          name={"procedure"}
          label={"Procedimento"}
          service={(params) =>
            getProcedures({
              ...params,
              type: "billing",
              exclude_type: ["medications", "lab", "external_lab"],
              patient_id: patientField?.id,
              attendance_flow: verifyAttendanceFlow(isClassification),
              cid_id: cidsData?.map(({ id }) => id),
              specialty_id: procedureSpecialty?.id,
            })
          }
          refetchService={[patientField, cidsData, procedureSpecialty]}
          queryKey={"get-procedures"}
          optionLabelKey="describe"
          filterKey={"filters"}
          control={procedureForm.control}
          disabled={!patientField || !procedureSpecialty || processed}
          autoCompleteProps={{
            getOptionLabel: (data) => {
              let label = data?.name;

              if (data?.tuss_code) {
                label = `${data?.tuss_code} - ${label}`;
              }

              return label;
            },
            filterOptions: (options) => {
              const optionsWithoutSelectedProcedures = options.filter(
                (option) => {
                  return proceduresData.every(
                    (procedure) => option.describe !== procedure.name
                  );
                }
              );

              return optionsWithoutSelectedProcedures;
            },
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          control={procedureForm.control}
          type={"number"}
          name={"qtd"}
          label={"Quantidade"}
          disabled={processed}
        />
      </Grid>
      <Grid item xs={1}>
        <Button
          variant="contained"
          onClick={addProcedures}
          disabled={processed}
        >
          Adicionar
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TableFilter
          data={proceduresData}
          columns={procedureTableColumn}
          noHeaderOptions
          actions
          actionsTypes={["delete"]}
          disableActions={() => processed}
          actionHandleDelete={removeProcedure}
          disabled={processed}
        />
      </Grid>
      <Grid xs={12} item>
        <Typography color={"primary"} fontWeight={"bolder"} fontSize={18}>
          Profissionais adicionais
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <PaginatedAutocompleteField
          control={mainForm.control}
          name={"additional_employees"}
          label={"Profissionais adicionais"}
          service={async (params) => {
            try {
              const response = await getProviders(params);

              response.items = response.items.filter(
                ({ id }) => id !== employeeField?.id
              );

              return response;
            } catch {
              return [];
            }
          }}
          refetchService={[employeeField]}
          queryKey={"get-providers"}
          optionCompareKey={"id"}
          autoCompleteProps={{
            getOptionLabel: (option) =>
              `${option.sus_card ? option.sus_card : "#"} - ${option.name}`,
            disablePortal: true,
          }}
          filterKey={"filter"}
          multiple
          disabled={processed}
        />
      </Grid>
      <Grid xs={12} item>
        <Typography color={"primary"} fontWeight={"bolder"} fontSize={18}>
          Atenção Primária
        </Typography>
      </Grid>
      <Grid xs={4} item>
        <PaginatedAutocompleteField
          control={basicAttentionForm.control}
          name={"mab"}
          label={"Código"}
          queryKey={"get-mabs"}
          filterKey={"id"}
          customOnChange={(value) => {
            basicAttentionForm.resetField("complement");
            if (value?.id !== "P24") {
              basicAttentionForm.setValue("complement", "");
            } else {
              basicAttentionForm.setValue("complement", null);
            }
          }}
          service={(params) => {
            let regex = /^(?!FA$|FO$).*\d.*$/;
            if (!regex.test(params.id)) {
              params.describe = params.id;
              delete params.id;
            }
            return getMABs({ ...params, mabs_inserted: insertedMABs() });
          }}
          autoCompleteProps={{
            getOptionLabel: (option) => `${option.id} - ${option.describe}`,
            disablePortal: true,
          }}
          optionCompareKey="id"
          refetchService={basicAttentionData}
          disabled={processed}
        />
      </Grid>
      {mabField?.needs_complement ? (
        <Grid xs={2} item>
          {mabField.id !== "P24" ? (
            <TextField
              control={basicAttentionForm.control}
              name={"complement"}
              label={complementLabel[mabField.id]}
            />
          ) : (
            <PaginatedAutocompleteField
              control={basicAttentionForm.control}
              label={complementLabel[mabField.id]}
              name={"complement"}
              service={(params) =>
                getCids({ ...params, patient_id: patientField?.id })
              }
              queryKey={"get-cids"}
              filterKey={"fieldValue"}
              disabled={!patientField}
              refetchService={[patientField]}
              autoCompleteProps={{
                getOptionLabel: (option) => {
                  return option.id ? `${option.id}  ${option.description}` : "";
                },
                disablePortal: true,
              }}
              optionCompareKey={"id"}
            />
          )}
        </Grid>
      ) : null}
      <Grid xs={1} item>
        <Button
          variant="contained"
          onClick={addBasicAttention}
          disabled={processed}
        >
          Adicionar
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TableFilter
          data={basicAttentionData}
          columns={attentionTableColumn}
          noHeaderOptions
          actions
          actionsTypes={["delete"]}
          disableActions={() => processed}
          actionHandleDelete={removeBasicAttention}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display={"flex"} justifyContent={"end"} columnGap={3}>
          <Button
            onClick={() => handleSubmit()}
            loading={putMutation.isLoading || postMutation.isLoading}
            disabled={processed}
          >
            Gravar e Atender
          </Button>
          <Button
            variant="text"
            onClick={() => closeForm()}
            disabled={processed}
          >
            Cancelar
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}
