import { Box, Grid, Typography } from "@mui/material";
import {
  AutocompleteField,
  Button,
  CheckboxField,
  DateField,
  PaginatedAutocompleteField,
  RadioField,
  TextField,
} from "../../FormFields";
import { useGeographicData, useMicroArea, useSusCode } from "../../../service";
import {
  genders,
  kinshipRelationship,
  maritalStatus,
  raceColors,
} from "../../../config/susCodeStandardSelect";
import { format, parseISO } from "date-fns";
import { Link } from "react-router-dom";
import { ListAlt, ModeEdit } from "@mui/icons-material";
import RegexOf from "../../../config/regex";
import { useQuery } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";

function IndividualUserForm({
  id,
  control,
  watch,
  setValue,
  patientData,
  isEditingIndividual,
  setIsEditingIndividual,
  isHistoric,
  handleChangeField,
  nationality,
}) {
  const { getCounties, getCountries } = useGeographicData();
  const { susCodeIndividualPaths, getSusCodeByProperty } = useSusCode();
  const { userData } = useContext(AuthContext);
  const { getMicroArea } = useMicroArea();
  const today = new Date();

  const microareasParams = patientData?.microarea
    ? { name: patientData?.microarea, company_id: userData?.company?.id }
    : {
        filter: "simple_list",
        name: !userData?.company?.type?.ESF ? "FA" : "",
        company_id: userData?.company?.id,
      };

  useQuery(["microareas", microareasParams], () => getMicroArea(microareasParams), {
    enabled: !!microareasParams,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (response) => {
      if (patientData?.microarea) setValue("microarea", response.items[0]);
    },
  });

  useQuery("counties", () => getCounties({ ibge_code: patientData?.naturality }), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!patientData?.naturality,
    onSuccess: (response) => {
      if (response.items.length) {
        setValue("naturality", response.items[0]);
      }
    },
  });

  useQuery("countries", () => getCountries({ id: patientData?.naturalization_country }), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!patientData?.naturalization_country,
    onSuccess: (response) => {
      if (response.items.length) {
        setValue("naturalization_country", response.items[0]);
      }
    },
  });

  const isRejectedTerms = watch("individual_registration_rejection") === true;

  const handleFieldsRequire = (field) => {
    if (isRejectedTerms) return false;

    if (field === "sus_card") {
      return !watch("physic_national");
    }
    if (field === "physic_national") {
      return !watch("sus_card");
    }
  };

  return (
    <>
      <Grid item xs={6}>
        <Typography
          variant="h5"
          sx={{ marginBottom: "2rem", fontWeight: "bold" }}
          color="secondary"
        >
          IDENTIFICAÇÃO DO USUÁRIO/CIDADÃO
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            pt: 2,
            gap: "1rem",
          }}
        >
          {patientData?.is_fully_registered && !isHistoric && (
            <Typography variant="p" color="secondary">
              Última edição: <br />
              {format(parseISO(patientData?.updatedAt), "dd/MM/yyyy")}
            </Typography>
          )}
          {!!patientData?.id && !isHistoric && (
            <Link to={`/cds/individual/historic/${patientData?.id}`}>
              <Button sx={{ textDecoration: "none" }}>
                <ListAlt /> Histórico
              </Button>
            </Link>
          )}
          {((id && !isEditingIndividual && !isHistoric) ||
            (patientData && !isEditingIndividual && !isHistoric)) && (
            <Button onClick={() => setIsEditingIndividual(true)}>
              <ModeEdit /> Editar
            </Button>
          )}
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6" color="secondary">
          Dados Pessoais do Usuário/Cidadão
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="physic_national"
          label="CPF DO CIDADÃO"
          control={control}
          mask={RegexOf.cpf}
          required={handleFieldsRequire("physic_national")}
          disabled={!isEditingIndividual}
        />
        {!watch("individual_registration_rejection") &&
          !watch("physic_national") &&
          !watch("sus_card") && (
            <Typography
              variant="p"
              color={"error"}
              sx={{ marginTop: "0.5rem", fontSize: "0.8rem" }}
            >
              CPF/CNS são obrigatórios, preencha um dos dois.
            </Typography>
          )}
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="sus_card"
          label="CNS DO CIDADÃO"
          control={control}
          mask={RegexOf.cns}
          required={handleFieldsRequire("sus_card")}
          disabled={!isEditingIndividual}
        />
        {!watch("individual_registration_rejection") &&
          !watch("physic_national") &&
          !watch("sus_card") && (
            <Typography
              variant="p"
              color={"error"}
              sx={{ marginTop: "0.5rem", fontSize: "0.8rem" }}
            >
              CPF/CNS são obrigatórios, preencha um dos dois.
            </Typography>
          )}
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="name"
          label="NOME COMPLETO"
          control={control}
          required={!isRejectedTerms}
          disabled={!isEditingIndividual}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="social_name"
          label="NOME SOCIAL"
          control={control}
          disabled={!isEditingIndividual}
        />
      </Grid>

      <Grid item xs={3}>
        <DateField
          name="birth_date"
          label="DATA DE NASCIMENTO"
          control={control}
          placeholder="DD/MM/AAAA"
          required={!isRejectedTerms}
          disabled={!isEditingIndividual}
          customOnChange={(value) => handleChangeField("birth_date", value)}
          maxDate={today}
        />
      </Grid>

      <Grid item xs={3}>
        <AutocompleteField
          name="gender"
          label="SEXO"
          control={control}
          options={genders}
          optionLabelKey="label"
          optionValueKey="sus_code_id"
          required={!isRejectedTerms}
          customOnChange={(value) => handleChangeField("gender", value)}
          disabled={!isEditingIndividual}
        />
      </Grid>

      <Grid item xs={3}>
        <AutocompleteField
          name="race"
          label="RAÇA/COR"
          control={control}
          options={raceColors}
          optionLabelKey="label"
          optionValueKey="sus_code_id"
          required={!isRejectedTerms}
          customOnChange={(value) => handleChangeField("race", value)}
          disabled={!isEditingIndividual}
        />
      </Grid>

      <Grid item xs={3}>
        <PaginatedAutocompleteField
          name="ethnicity"
          label="ETNIA"
          control={control}
          service={(params) =>
            getSusCodeByProperty(susCodeIndividualPaths.ethnicities, { ...params })
          }
          filterKey="describe"
          queryKey="ethnicities"
          optionLabelKey="describe"
          disabled={!isEditingIndividual || watch("race")?.sus_code_id !== 5 || !watch("race")}
          required={!isRejectedTerms ?? watch("race")?.sus_code_id === 5}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          name="nis_pis_pasep"
          label="Nº NIS (PIS/PASEP)"
          control={control}
          mask={RegexOf.pis_pasep}
          disabled={!isEditingIndividual}
        />
      </Grid>

      <Grid item xs={3}>
        <AutocompleteField
          label="ESTADO CIVIL"
          name="marital_status"
          control={control}
          options={maritalStatus}
          optionLabelKey="label"
          optionCompareKey="label"
          optionValueKey="label"
          disabled={!isEditingIndividual}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField name="email" label="EMAIL" control={control} disabled={!isEditingIndividual} />
      </Grid>

      <Grid item xs={3}>
        <TextField
          name="phone"
          label="TELEFONE CELULAR"
          control={control}
          mask={RegexOf.phone}
          required={!isRejectedTerms ?? watch("nationality")?.sus_code_id === 1}
          disabled={!isEditingIndividual}
          inputProps={{ maxLength: 15 }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="dad_name"
          label="NOME COMPLETO DO PAI"
          control={control}
          disabled={!isEditingIndividual || !!watch("does_not_know_dad")[0]}
          required={!isRejectedTerms ?? !watch("does_not_know_dad")[0]}
        />
      </Grid>

      <Grid item xs={3}>
        <CheckboxField
          name="does_not_know_dad"
          control={control}
          options={[{ label: "DESCONHECE" }]}
          optionLabelKey="label"
          direction="row"
          customOnChange={(value) => handleChangeField("does_not_know_dad", value)}
          disabled={!isEditingIndividual}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="mother_name"
          label="NOME COMPLETO DA MÃE"
          control={control}
          disabled={!isEditingIndividual || !!watch("does_not_know_mother")[0]}
          required={!isRejectedTerms ?? !watch("does_not_know_mother")[0]}
        />
      </Grid>

      <Grid item xs={3}>
        <CheckboxField
          name="does_not_know_mother"
          control={control}
          options={[{ label: "DESCONHECE" }]}
          optionLabelKey="label"
          direction="row"
          customOnChange={(value) => handleChangeField("does_not_know_mother", value)}
          disabled={!isEditingIndividual}
        />
      </Grid>

      <Grid item xs={3}></Grid>

      <Grid item xs={3}>
        <AutocompleteField
          name="nationality"
          label="NACIONALIDADE*"
          control={control}
          options={nationality}
          optionLabelKey="label"
          optionCompareKey="sus_code_id"
          optionValueKey="sus_code_id"
          required={!isRejectedTerms}
          customOnChange={(value) => handleChangeField("nationality", value)}
          disabled={!isEditingIndividual}
        />
      </Grid>

      <Grid item xs={3}>
        <PaginatedAutocompleteField
          name="naturalization_country"
          label="PAÍS DE NASCIMENTO"
          control={control}
          service={(params) => getCountries({ ...params })}
          filterKey="describe"
          queryKey="countries"
          optionLabelKey="describe"
          required={!isRejectedTerms ?? watch("nationality")?.sus_code_id === 3}
          disabled={!isEditingIndividual || watch("nationality")?.sus_code_id !== 3}
        />
      </Grid>

      <Grid item xs={3}>
        <PaginatedAutocompleteField
          name="naturality"
          label="MUNICÍPIO E UF DE NASCIMENTO"
          control={control}
          service={(params) => getCounties({ ...params }, true)}
          filterKey="describe"
          queryKey="counties"
          optionLabelKey="describe"
          disabled={!isEditingIndividual || watch("nationality")?.sus_code_id !== 1}
          required={!isRejectedTerms ?? watch("nationality")?.sus_code_id === 1}
        />
      </Grid>

      <Grid item xs={3}>
        <DateField
          name="date_naturalization"
          label="DATA DE NATURALIZAÇÃO"
          control={control}
          placeholder="DD/MM/AAAA"
          required={!isRejectedTerms ?? watch("nationality")?.sus_code_id === 2}
          disabled={!isEditingIndividual || watch("nationality")?.sus_code_id !== 2}
          maxDate={today}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          name="naturalization_ordinance"
          label="PORTARIA DE NATURALIZAÇÃO"
          control={control}
          required={!isRejectedTerms ?? watch("nationality")?.sus_code_id === 2}
          maxLength={16}
          disabled={!isEditingIndividual || watch("nationality")?.sus_code_id !== 2}
        />
      </Grid>

      <Grid item xs={3}>
        <DateField
          name="date_entry_country"
          label="DATA DE ENTRADA NO BRASIL"
          control={control}
          placeholder="DD/MM/AAAA"
          maximumDate={new Date()}
          required={!isRejectedTerms ?? watch("nationality")?.sus_code_id === 3}
          disabled={!isEditingIndividual || watch("nationality")?.sus_code_id !== 3}
          maxDate={today}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6" color="secondary">
          Informações de Responsável Familiar
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <RadioField
          name="is_family_responsible"
          label="É RESPONSÁVEL FAMILIAR"
          required={!isRejectedTerms}
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          customOnChange={(value) => handleChangeField("is_family_responsible", value)}
          disabled={!isEditingIndividual}
        />
      </Grid>

      {/* <Grid item xs={6}>
        <TextField
          name="responsible_name"
          label="NOME DO RESPONSÁVEL FAMILIAR"
          control={control}
          disabled={
            !isEditingIndividual ||
            (watch("is_family_responsible") === null ||
              watch("is_family_responsible"))}
        />
      </Grid> */}

      <Grid item xs={3}>
        <TextField
          name="responsible_physic_national"
          label="CPF DO RESPONSÁVEL FAMILIAR"
          control={control}
          mask={RegexOf.cpf}
          disabled={
            !isEditingIndividual ||
            watch("responsible_sus_card") ||
            watch("is_family_responsible") === null ||
            watch("is_family_responsible")
          }
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          name="responsible_sus_card"
          label="CNS DO RESPONSÁVEL FAMILIAR"
          control={control}
          mask={RegexOf.cns}
          disabled={
            !isEditingIndividual ||
            watch("responsible_physic_national") ||
            watch("is_family_responsible") === null ||
            watch("is_family_responsible")
          }
        />
      </Grid>

      <Grid item xs={3}>
        <AutocompleteField
          name="kinship"
          label="RELAÇÃO DE PARENTESCO COM O RESPONSÁVEL FAMILIAR"
          control={control}
          options={kinshipRelationship}
          optionLabelKey="describe"
          optionCompareKey="sus_code_id"
          optionKeyExtractor="sus_code_id"
          optionValueKey="sus_code_id"
          required={!isRejectedTerms || !watch("is_family_responsible")}
          disabled={
            !isEditingIndividual ||
            watch("individual_registration_rejection") ||
            watch("is_family_responsible") === null ||
            watch("is_family_responsible")
          }
        />
      </Grid>

      {/* <Grid item xs={3}>
        <TextField
          name="responsible_email"
          label="EMAIL DO RESPONSÁVEL"
          control={control}
          disabled={!isEditingIndividual || (watch("is_family_responsible") === null || watch("is_family_responsible"))}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          name="responsible_phone"
          label="TELEFONE CELULAR DO RESPONSÁVEL"
          control={control}
          mask={RegexOf.phone}
          disabled={!isEditingIndividual || (watch("is_family_responsible") === null || watch("is_family_responsible"))}
          inputProps={{ maxLength: 15 }}
        />
      </Grid> */}

      <Grid item xs={3}>
        <PaginatedAutocompleteField
          label="Micro Área"
          disabled={!userData?.company?.type?.ESF || !isEditingIndividual}
          optionLabelKey="name"
          control={control}
          service={(params) => getMicroArea({ ...params, company_id: userData?.company?.id })}
          queryKey={"microareas"}
          filterKey={"name"}
          name="microarea"
          containerProps={{
            style: {
              flex: 1,
            },
          }}
        />
      </Grid>
    </>
  );
}

export default IndividualUserForm;
