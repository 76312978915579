import React from "react";
import { DialogMedium } from "../../../helper";
import AgainstReferenceForm from "../../Attendance/AgainstReferences/AgainstReferenceForm";
import TableFilter from "../../../components/Table/TableFilter";
import { format, parseISO } from "date-fns";

export default function AgainstReferencesTable({ againstReferences, check }) {
  const columns = [
    { name: "Nº Atendimento", field: "attendance.number" },
    {
      name: "Data Solicitada",
      field: "createdAt",
      use: (date) => format(parseISO(date), "dd/MM/yyyy"),
    },
    { name: "Profissional Solicitante", field: "employee_specialty.employee.name" },
    { name: "Especialidade Requisitada", field: "requested_specialty.describe" },
    { name: "Prioridade", field: "priority" },
  ];

  const [unicReference, setUnicReference] = React.useState();
  const [openView, serOpenView] = React.useState(false);

  function handleDialogView(data) {
    setUnicReference(data);
    serOpenView(true);
  }

  function handleClose() {
    serOpenView(false);
  }

  return (
    <>
      <DialogMedium
        title="Visualizar Contrarreferência"
        open={openView}
        type={"view"}
        handleClose={handleClose}
      >
        <AgainstReferenceForm type="view" reference={unicReference} />
      </DialogMedium>
      <TableFilter
        data={againstReferences}
        columns={columns}
        actions
        actionsTypes={["finish", "view"]}
        rowStatus={(item) => (item.referred ? "disabled" : "")}
        disableActions={(item, action) => (action === "finish" ? item.referred : false)}
        shouldDisableFields={["id", "referred", "signed_document"]}
        actionHandleFinish={check}
        actionHandleView={(_, data) => handleDialogView(data)}
      />
    </>
  );
}
