import { Grid } from "@mui/material";
import React from "react";
import {
  Button,
  PaginatedAutocompleteField,
  TextField,
} from "../../FormFields";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Yup from "../../../config/yup";
import { useCollectiveActivity, useProcedures } from "../../../service";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNotifier } from "../../../hooks";

export default function ComplementCollectiveActivity({
  collectiveActivity,
  attendance,
  handleClose,
}) {
  const notify = useNotifier();
  const queryClient = useQueryClient();
  const { getProcedures } = useProcedures();
  const { getAdmeasurementsByPatientId, updateCollectiveActivity } =
    useCollectiveActivity();
  const updateCollectiveActivityMutation = useMutation(
    updateCollectiveActivity
  );

  const healthPracticeIsAnthropometry = collectiveActivity?.health_practice?.some(
    (hp) => hp?.id === "20"
  );

  const initialValues = {
    weight: null,
    height: null,
    procedure: null,
    notes: "",
  };

  const validations = Yup.object().shape({
    weight: Yup.string().nullable(),
    height: Yup.string().nullable(),
    procedure: Yup.object().nullable(),
    notes: Yup.string(),
  });

  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(validations),
    defaultValues: initialValues,
  });

  useQuery(
    "collective-attendance-admeasurements",
    () => getAdmeasurementsByPatientId(attendance?.id_patient),
    {
      enabled: !!attendance?.id_patient,
      onSuccess: (response) => {
        setValue("weight", response?.weight);
        setValue("height", response?.height);
      },
    }
  );

  function onSubmit(values) {
    updateCollectiveActivityMutation.mutate(
      {
        collectiveAttendanceId: collectiveActivity.id,
        patientId: attendance.id_patient,
        data: {
          ...values,
          weight: values.weight ? Number(values.weight) : null,
          height: values.height ? Number(values.height) : null,
          procedure_id: values.procedure.id,
        },
      },
      {
        onSuccess: (response) => {
          notify(response.message, "success");
          queryClient.invalidateQueries("collective-activities");
          queryClient.invalidateQueries("complement-collective-activities");
          reset();
          handleClose();
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      }
    );
  }

  return (
    <Grid
      container
      spacing={2}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item xs={3}>
        <TextField
          name="weight"
          required={healthPracticeIsAnthropometry}
          type="number"
          control={control}
          label="Peso:"
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          name="height"
          required={healthPracticeIsAnthropometry}
          type="number"
          control={control}
          label="Altura:"
        />
      </Grid>
      <Grid item xs={6}>
        <PaginatedAutocompleteField
          control={control}
          name="procedure"
          label="Procedimento:"
          queryKey={"proceduresCodes"}
          optionCompareKey="id"
          service={(params) =>
            getProcedures({
              ...params,
              type: "individual",
            })
          }
          filterKey="filters"
          autoCompleteProps={{
            getOptionLabel: (data) => {
              let label = data?.name;

              if (data?.tuss_code) {
                label = `${data?.tuss_code} - ${label}`;
              }

              return label;
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="notes"
          multiline
          minRows={3}
          maxRows={3}
          control={control}
          label="Anotações:"
        />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="end">
        <Button variant="contained" loading={false} type="submit">
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
}
