import { Typography } from "@mui/material";
import { footQuestions, footRegions } from "../../../../config/standardSelects";
import { accessObjectByString } from "../../../../utils";
import { theme } from "../../../../config/theme";
import FootRegion from "./FootRegion";

export default function  FootValues({ values, title }) {
    const isBoolean = (value) => typeof value === "boolean";
    return (
      <>
        <Typography
          color={theme.palette.primary.light}
          mt={1}
          display={"flex"}
          columnGap={1}
        >
          <Typography fontWeight="500" fontSize={15}>
            {title}
          </Typography>
        </Typography>
        <Typography color={theme.palette.primary.light} component={"span"}>
          {Object.entries(footQuestions).map(([key, value]) => {
            if (isBoolean(values[key]))
              return (
                <Typography color={theme.palette.primary.main}>
                  <Typography
                    variant="h7"
                    color={theme.palette.primary.light}
                    fontWeight="500"
                    sx={{ marginTop: "20px" }}
                  >
                    {`> ${value}: `}
                  </Typography>
                  {values[key] ? "Sim" : "Não"}
                </Typography>
              );
          })}
        </Typography>
        {footRegions.map(({ name, path,subPath }) => {
          return (
            <Typography color={theme.palette.primary.light} component={"span"}>
              <FootRegion
                title={name}
                values={accessObjectByString(values, subPath ? `${path}.${subPath}`: path)}
              />
            </Typography>
          );
        })}
      </>
    );
  };