import React, { useMemo } from "react";
import {
  Box,
  Typography,
  CardHeader,
  CardContent,
  Card,
  Avatar,
  Chip,
  Tooltip,
  Grid,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { CopyToClipboard } from "react-copy-to-clipboard";
import convertDateTobirthday from "../utils/convertDateToBirthday";
import { salary_income } from "../config/standardSelects";

function FamilyFeedback({ data }) {
  const [copiedText, setCopiedText] = React.useState("");

  const familyData = useMemo(() => {
    const family_income_label = salary_income.find(
      (salary_income) => salary_income.sus_code_id === +data?.family_income_id
    )?.describe;

    return {
      ...data,
      responsible: data.members.find((member) => member.responsible).patient,
      family_income: family_income_label || "Não Informado",
    };
  }, [data]);

  return (
    <>
      <Card
        sx={{
          border: "3px solid transparent",
          borderColor: "white",
          borderWidth: "3px",
          boxShadow: "0 0 0 1.5px #FFFFFF",
        }}
      >
        <CardHeader
          title={
            <CopyToClipboard
              text={familyData.number}
              onCopy={() => setCopiedText(familyData.number)}
            >
              <Tooltip
                title={
                  copiedText === familyData.number ? "Copiado!!" : "Copiar"
                }
                color="primary"
                placement="top"
              >
                <Chip
                  avatar={
                    <Avatar
                      sx={{
                        bgcolor: (theme) => theme.palette.primary.main,
                      }}
                    >
                      <Typography color="White" fontSize={12}>
                        {familyData &&
                          familyData.name.slice(0, 2).toUpperCase()}
                      </Typography>
                    </Avatar>
                  }
                  label={`ID - # ${familyData.number}`}
                  variant="outlined"
                  color="primary"
                  clickable
                />
              </Tooltip>
            </CopyToClipboard>
          }
        ></CardHeader>
        <Typography
          variant="p"
          color="secondary"
          fontWeight="600"
          fontSize={16}
          sx={{ ml: 2 }}
        >
          {familyData.name}
        </Typography>
        <Typography
          variant="h5"
          color="primary"
          fontWeight="500"
          fontSize={12}
          sx={{ ml: 2 }}
        >
          <Typography
            fontSize={12}
            color="secondary"
            fontWeight="500"
            component="span"
          >
            Reside Desde
          </Typography>{" "}
          :{" "}
          {familyData.resides_since
            ? format(parseISO(familyData.resides_since), "dd/MM/yyyy")
            : "Não Informado"}
        </Typography>
        <Typography
          variant="h5"
          color="primary"
          fontWeight="500"
          fontSize={12}
          sx={{ ml: 2 }}
        >
          <Typography
            fontSize={12}
            color="secondary"
            fontWeight="500"
            component="span"
          >
            Renda Familiar
          </Typography>{" "}
          : {familyData.family_income}
        </Typography>

        <Typography
          variant="h5"
          color="primary"
          fontWeight="500"
          fontSize={12}
          sx={{ ml: 2 }}
        >
          <Typography
            fontSize={12}
            color="secondary"
            fontWeight="500"
            component="span"
          >
            Status
          </Typography>{" "}
          : {familyData.active ? "ATIVA" : "INATIVA"}
        </Typography>
        {!familyData.active ? (
          <Typography
            variant="h5"
            color="primary"
            fontWeight="500"
            fontSize={15}
            sx={{ ml: 2 }}
          >
            <Typography
              fontSize={15}
              color="secondary"
              fontWeight="500"
              component="span"
            >
              Motivo
            </Typography>{" "}
            : {familyData.inactive_note}
          </Typography>
        ) : null}

        <Typography
          variant="h1"
          color="gray"
          fontWeight="700"
          fontSize={15}
          sx={{ ml: 2, mb: 1, mt: 1 }}
        >
          #1 DADOS DO RESPONSÁVEL
        </Typography>
        <CardContent
          sx={{ display: "flex", flexDirection: "column", mt: "-10px" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Cód. Paciente
                    </Typography>{" "}
                    : {familyData?.responsible?.number}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Nome do Responsável
                    </Typography>{" "}
                    : {familyData.responsible?.name}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Gênero
                    </Typography>{" "}
                    : {familyData.responsible?.gender}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Idade
                    </Typography>{" "}
                    :{" "}
                    {convertDateTobirthday(
                      familyData.responsible?.birth_date
                    ) || "Não informado"}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Data de Nascimento
                    </Typography>{" "}
                    :{" "}
                    {familyData.responsible?.birth_date
                      ? format(
                          parseISO(familyData.responsible?.birth_date),
                          "dd/MM/yyyy"
                        )
                      : "Não informado"}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h1"
                color="gray"
                fontWeight="700"
                fontSize={12}
                marginBottom={1.5}
              >
                #2 MEMBROS DA FAMÍLIA
              </Typography>
              {familyData?.members.map(
                (
                  {
                    patient: { number, name, gender, birth_date },
                    responsible,
                    kinship_id,
                  },
                  i
                ) => {
                  if (responsible) return null;
                  const birthMembers = convertDateTobirthday(birth_date);

                  return (
                    <>
                      <Box
                        key={number + "-" + name + "-" + i}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          flexWrap: "wrap",
                          mb: 1.5,
                        }}
                      >
                        <Typography
                          variant="h5"
                          color="primary"
                          fontWeight="500"
                          fontSize={13}
                        >
                          <Typography
                            fontSize={13}
                            color="secondary"
                            fontWeight="500"
                            component="span"
                          >
                            Cód. Paciente
                          </Typography>{" "}
                          : {number}
                        </Typography>
                        <Typography
                          variant="h5"
                          color="primary"
                          fontWeight="500"
                          fontSize={13}
                        >
                          <Typography
                            fontSize={13}
                            color="secondary"
                            fontWeight="500"
                            component="span"
                          >
                            Nome
                          </Typography>{" "}
                          : {name}
                        </Typography>
                        <Typography
                          variant="h5"
                          color="primary"
                          fontWeight="500"
                          fontSize={13}
                        >
                          <Typography
                            fontSize={13}
                            color="secondary"
                            fontWeight="500"
                            component="span"
                          >
                            Gênero
                          </Typography>{" "}
                          : {gender}
                        </Typography>
                        <Typography
                          variant="h5"
                          color="primary"
                          fontWeight="500"
                          fontSize={13}
                        >
                          <Typography
                            fontSize={13}
                            color="secondary"
                            fontWeight="500"
                            component="span"
                          >
                            Parentesco
                          </Typography>{" "}
                          : {kinship_id || "Não informado"}
                        </Typography>
                        <Typography
                          variant="h5"
                          color="primary"
                          fontWeight="500"
                          fontSize={13}
                        >
                          <Typography
                            fontSize={13}
                            color="secondary"
                            fontWeight="500"
                            component="span"
                          >
                            Idade
                          </Typography>{" "}
                          : {birthMembers}
                        </Typography>
                        <Typography
                          variant="h5"
                          color="primary"
                          fontWeight="500"
                          fontSize={13}
                        >
                          <Typography
                            fontSize={13}
                            color="secondary"
                            fontWeight="500"
                            component="span"
                          >
                            Data de Nascimento
                          </Typography>{" "}
                          :{" "}
                          {birth_date
                            ? format(parseISO(birth_date), "dd/MM/yyyy")
                            : "Não irformado"}
                        </Typography>
                      </Box>
                    </>
                  );
                }
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default FamilyFeedback;
