import { Divider, Grid, Typography, useTheme } from "@mui/material";
import OutlinedContainer from "../../../components/OutlinedContainer/OutlinedContainer";
import { format, parseISO } from "date-fns";
import {
  convertDateToBirthday,
  mapObject,
  calcByDUM,
  calcByUSG,
} from "../../../utils";
import {
  ampiOptions,
  cross_bite,
  deep_bite_class_1,
  deep_bite_class_2,
  deep_bite_class_3,
  dental_supplies,
  open_bite,
  risk_stratification_labels,
} from "../../../config/standardSelects";
import FootInfo from "./FootInfo";

export default function SoapRegularData({
  data,
  patientData,
  prontuaryOdonto,
  dataOdontoProcedures,
  dataRps,
}) {
  const theme = useTheme();
  const convertedBirthDate = convertDateToBirthday(
    patientData.birth_date,
    ["years", "months"],
    false
  );

  const RiskStratification = ({ data }) => {
    if (data?.objective?.risk_stratification) {
      const { id, cardiovascular_risk, therapeutic_goal, ...responses } =
        data?.objective?.risk_stratification;

      return (
        <>
          <Typography fontWeight="500">Estratificação RCV</Typography>
          <Typography fontWeight="500" marginLeft={2} mt={1} color={"red"}>
            {`RISCO CARDIOVASCULAR: ${cardiovascular_risk}`}
          </Typography>
          <Typography
            fontWeight="500"
            marginLeft={2}
            mt={1}
            color="secondary.main"
          >
            {therapeutic_goal}
          </Typography>
          <Typography fontWeight="500" marginLeft={2} mt={1}>
            Fatores de Risco
          </Typography>
          {Object.entries(risk_stratification_labels.risk_factors).map(
            ([key, value]) => (
              <Typography marginLeft={2} color={theme.palette.primary.main}>
                <Typography
                  variant="h7"
                  color={theme.palette.primary.light}
                  fontWeight="500"
                  sx={{ marginTop: "20px" }}
                >
                  {" > " + value}
                  {" - "}
                </Typography>
                {responses[key] ? "Sim" : "Não"}
              </Typography>
            )
          )}
          <Typography fontWeight="500" marginLeft={2} mt={1}>
            Lesão de Órgão Alvo (LOA)
          </Typography>
          {Object.entries(risk_stratification_labels.loa).map(
            ([key, value]) => (
              <Typography marginLeft={2} color={theme.palette.primary.main}>
                <Typography
                  variant="h7"
                  color={theme.palette.primary.light}
                  fontWeight="500"
                  sx={{ marginTop: "20px" }}
                >
                  {" > " + value}
                  {" - "}
                </Typography>
                {responses[key] ? "Sim" : "Não"}
              </Typography>
            )
          )}
          <Typography fontWeight="500" marginLeft={2} mt={1}>
            Doença Cardiovascular Avançada
          </Typography>
          {Object.entries(risk_stratification_labels.cd_advanced).map(
            ([key, value]) => (
              <Typography marginLeft={2} color={theme.palette.primary.main}>
                <Typography
                  variant="h7"
                  color={theme.palette.primary.light}
                  fontWeight="500"
                  sx={{ marginTop: "20px" }}
                >
                  {" > " + value}
                  {" - "}
                </Typography>
                {responses[key] ? "Sim" : "Não"}
              </Typography>
            )
          )}
        </>
      );
    }

    return null;
  };

  return (
    <OutlinedContainer label="SOAP">
      <Grid item xs={12}>
        <Divider sx={{ mt: 1 }} textAlign="left">
          <Typography
            variant="h7"
            color={theme.palette.primary.light}
            fontWeight="500"
            sx={{ marginTop: "20px" }}
          >
            S
          </Typography>
          <Typography
            variant="h7"
            color={theme.palette.primary.font}
            fontWeight="500"
            sx={{ marginTop: "20px" }}
          >
            ubjetivo
          </Typography>
        </Divider>
      </Grid>
      <Grid item xs={12} my={1}>
        {data?.subjective ? (
          <>
            <Grid item xs={12} my={1}>
              {data.subjective?.reason ? (
                <Typography color={theme.palette.primary.main}>
                  <Typography
                    variant="h7"
                    color={theme.palette.primary.light}
                    fontWeight="500"
                    sx={{ marginTop: "20px" }}
                  >
                    Razão:{" "}
                  </Typography>
                  {data.subjective?.reason}
                </Typography>
              ) : (
                ""
              )}
              {data.subjective?.ciaps ? (
                <>
                  <Grid container spacing={0}>
                    <Grid item>
                      <Typography color={theme.palette.primary.main}>
                        {data.subjective?.ciaps?.length ? (
                          <>
                            <Typography
                              variant="h7"
                              color={theme.palette.primary.light}
                              fontWeight="500"
                              sx={{ marginTop: "20px" }}
                            >
                              Alega:{" "}
                            </Typography>

                            {data.subjective?.ciaps?.map((item) => (
                              <Typography
                                component="span"
                                color={theme.palette.primary.main}
                              >
                                {" "}
                                {item?.description.firstCharToUp()}
                              </Typography>
                            ))}
                          </>
                        ) : (
                          ""
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              ) : (
                ""
              )}
            </Grid>
          </>
        ) : (
          <Typography color={theme.palette.primary.main}>
            Não há subjetivo registrado
          </Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ mt: 1 }} textAlign="left">
          <Typography
            variant="h7"
            color={theme.palette.primary.light}
            fontWeight="500"
            sx={{ marginTop: "20px" }}
          >
            O
          </Typography>
          <Typography
            variant="h7"
            color={theme.palette.primary.font}
            fontWeight="500"
            sx={{ marginTop: "20px" }}
          >
            bjetivo
          </Typography>
        </Divider>
      </Grid>
      {convertedBirthDate?.years >= 60 &&
        !!data?.objective?.ampi_classification &&
        !!data?.objective?.ampi_last_classification && (
          <>
            <Grid item xs={12} my={1}>
              <Typography color={theme.palette.primary.main}>
                <Typography
                  variant="h7"
                  color={theme.palette.primary.light}
                  fontWeight="500"
                  sx={{ marginTop: "20px" }}
                >
                  Classificação AMPI:{" "}
                </Typography>
                {ampiOptions[data?.objective?.ampi_classification]}
              </Typography>
            </Grid>
            <Grid item xs={12} my={1}>
              <Typography color={theme.palette.primary.main}>
                <Typography
                  variant="h7"
                  color={theme.palette.primary.light}
                  fontWeight="500"
                  sx={{ marginTop: "20px" }}
                >
                  Data da última classificação:{" "}
                </Typography>
                {format(
                  parseISO(data?.objective?.ampi_last_classification),
                  "dd/MM/yyyy"
                )}
              </Typography>
            </Grid>
          </>
        )}

      {data?.objective?.diabetic_foot_assessment && (
        <FootInfo
          footData={data?.objective?.diabetic_foot_assessment}
          dateAssessment={data?.objective?.date_diabetic_foot_assessment}
          newAssessmentDate={data?.objective?.new_evaluation_scheduled_date}
        />
      )}
      {!!data?.objective?.pregnancy_record && (
        <>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Gestante:{" "}
              </Typography>
              {patientData?.pregnant ? "Sim" : "Não"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Gravidez Planejada ?{" "}
              </Typography>
              {data?.objective?.pregnancy_record?.planned_pregnancy
                ? "Sim"
                : "Não"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Número de Gestações (G):{" "}
              </Typography>
              {data?.objective?.pregnancy_record?.gravidity || "Não Informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Número de Partos (P):{" "}
              </Typography>
              {data?.objective?.pregnancy_record?.parity || "Não Informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Número de Partos Normais (N):{" "}
              </Typography>
              {data?.objective?.pregnancy_record?.vaginal_deliveries_number ||
                "Não Informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Número de Partos Cesários (C):{" "}
              </Typography>
              {data?.objective?.pregnancy_record?.cesarean_deliveries_number ||
                "Não Informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Número de Abortamentos Cesários (C):{" "}
              </Typography>
              {data?.objective?.pregnancy_record?.abortions_number ||
                "Não Informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Data da última menstruação:{" "}
              </Typography>
              {data.objective?.pregnancy_record.last_menstrual_period
                ? format(
                    parseISO(
                      data.objective.pregnancy_record.last_menstrual_period
                    ),
                    "dd/MM/yyyy"
                  )
                : "Não informado"}
            </Typography>
          </Grid>
          {data?.objective?.pregnancy_record.last_menstrual_period && (
            <>
              <Grid item xs={12} my={1}>
                <Typography color={theme.palette.primary.main}>
                  <Typography
                    variant="h7"
                    color={theme.palette.primary.light}
                    fontWeight="500"
                    sx={{ marginTop: "20px" }}
                  >
                    Idade gestacional (DUM):{" "}
                  </Typography>
                  {
                    calcByDUM(
                      data.attendance.createdAt,
                      data.objective.pregnancy_record.last_menstrual_period
                    ).gestationalAge
                  }
                </Typography>
              </Grid>
              <Grid item xs={12} my={1}>
                <Typography color={theme.palette.primary.main}>
                  <Typography
                    variant="h7"
                    color={theme.palette.primary.light}
                    fontWeight="500"
                    sx={{ marginTop: "20px" }}
                  >
                    Data prevista de parto (DUM):{" "}
                  </Typography>
                  {
                    calcByDUM(
                      data.attendance.createdAt,
                      data.objective.pregnancy_record.last_menstrual_period
                    ).probableDateOfBirth
                  }
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Data do USG obstétrico:{" "}
              </Typography>
              {data?.objective?.pregnancy_record.obstetric_usg_date
                ? format(
                    parseISO(
                      data.objective.pregnancy_record.obstetric_usg_date
                    ),
                    "dd/MM/yyyy"
                  )
                : "Não informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Semanas no USG:{" "}
              </Typography>
              {data?.objective?.pregnancy_record.obstetric_usg_weeks ||
                "Não informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Dias no USG:{" "}
              </Typography>
              {data?.objective?.pregnancy_record.obstetric_usg_days ||
                "Não informado"}
            </Typography>
          </Grid>
          {data?.objective?.pregnancy_record?.obstetric_usg_date &&
            data?.objective?.pregnancy_record.obstetric_usg_weeks &&
            data?.objective?.pregnancy_record.obstetric_usg_days >= 0 && (
              <>
                <Grid item xs={12} my={1}>
                  <Typography color={theme.palette.primary.main}>
                    <Typography
                      variant="h7"
                      color={theme.palette.primary.light}
                      fontWeight="500"
                      sx={{ marginTop: "20px" }}
                    >
                      Idade gestacional (USG):{" "}
                    </Typography>
                    {
                      calcByUSG(
                        data.attendance.createdAt,
                        data.objective.pregnancy_record.obstetric_usg_date,
                        data.objective.pregnancy_record.obstetric_usg_weeks,
                        data.objective.pregnancy_record.obstetric_usg_days
                      ).gestationalAge
                    }
                  </Typography>
                </Grid>
                <Grid item xs={12} my={1}>
                  <Typography color={theme.palette.primary.main}>
                    <Typography
                      variant="h7"
                      color={theme.palette.primary.light}
                      fontWeight="500"
                      sx={{ marginTop: "20px" }}
                    >
                      Data prevista de parto (USG):{" "}
                    </Typography>
                    {
                      calcByUSG(
                        data.attendance.createdAt,
                        data.objective.pregnancy_record.obstetric_usg_date,
                        data.objective.pregnancy_record.obstetric_usg_weeks,
                        data.objective.pregnancy_record.obstetric_usg_days
                      ).probableDateOfBirth
                    }
                  </Typography>
                </Grid>
              </>
            )}
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Movimentos fetais:{" "}
              </Typography>
              {data?.objective?.pregnancy_record.fetal_movements
                ? "Sim"
                : "Não"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Suplementação com Ácido Fólico:{" "}
              </Typography>
              {data?.objective?.pregnancy_record.folic_acid_supplementation
                ? "Sim"
                : "Não"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Suplementação de Carbonato de Cálcio:{" "}
              </Typography>
              {data?.objective?.pregnancy_record
                .calcium_carbonate_supplementation
                ? "Sim"
                : "Não"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Edema:{" "}
              </Typography>
              {data?.objective?.pregnancy_record.edema === null
                ? "Sem edema"
                : data?.objective?.pregnancy_record?.edema || "Não informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Risco gestacional:{" "}
              </Typography>
              {data?.objective?.pregnancy_record.gestational_risk ||
                "Não informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Apresentação fetal:{" "}
              </Typography>
              {data?.objective?.pregnancy_record.fetal_presentation ||
                "Não informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Exame clínico normal:{" "}
              </Typography>
              {data?.objective?.pregnancy_record.normal_clinical_exam ||
                "Não informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Exame ginecológico normal:{" "}
              </Typography>
              {data?.objective?.pregnancy_record.normal_gynecological_exam ||
                "Não informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Exame de mamas normal?:{" "}
              </Typography>
              {data?.objective?.pregnancy_record.normal_breast_exam ||
                "Não informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Últimas gestações:{" "}
              </Typography>
              {data?.objective?.pregnancy_record.previous_pregnancies?.length >
              0
                ? data.objective.pregnancy_record.previous_pregnancies.reduce(
                    (current, value, index, array) => {
                      if (array.length - 1 === index) {
                        return current + format(parseISO(value), "dd/MM/yyyy");
                      } else {
                        return (
                          current + format(parseISO(value), "dd/MM/yyyy") + ", "
                        );
                      }
                    },
                    ""
                  )
                : "Não informado"}
            </Typography>
          </Grid>
        </>
      )}
      {convertedBirthDate?.years <= 6 && (
        <>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Aleitamento materno:{" "}
              </Typography>
              {data?.objective?.child_record?.breastfeeding}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Ortolani:{" "}
              </Typography>
              {data?.objective?.child_record?.ortolani || "Não informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Desenvolvimento Neuropsicomotor(DNPM):{" "}
              </Typography>
              {data?.objective?.child_record?.neuropsychomotor_development ||
                "Não informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Reflexo vermelho:{" "}
              </Typography>
              {data?.objective?.child_record?.red_reflex_exam ||
                "Não informado"}
            </Typography>
          </Grid>
        </>
      )}
      <Grid item xs={12} my={1}>
        <Typography color={theme.palette.primary.main}>
          <Typography
            variant="h7"
            color={theme.palette.primary.light}
            fontWeight="500"
            sx={{ marginTop: "20px" }}
          >
            Exame físico/psíquico:{" "}
          </Typography>
          {data?.objective?.physical_exams || "Não informado"}
        </Typography>
      </Grid>
      <Grid item xs={12} my={1}>
        <Typography color={theme.palette.primary.main}>
          <Typography
            variant="h7"
            color={theme.palette.primary.light}
            fontWeight="500"
            sx={{ marginTop: "20px" }}
          >
            Possui alergia:{" "}
          </Typography>
          {data?.objective?.allergy_status || "Não informado"}
        </Typography>
      </Grid>
      <Grid item xs={12} my={1}>
        <Typography color={theme.palette.primary.main}>
          <Typography
            variant="h7"
            color={theme.palette.primary.light}
            fontWeight="500"
            sx={{ marginTop: "20px" }}
          >
            Exames complementares | Medicamentos em uso:{" "}
          </Typography>
          {data?.objective?.complementary_exams || "Não informado"}
        </Typography>
      </Grid>

      <Grid item xs={12} my={1}>
        <Typography color={theme.palette.primary.main}>
          <Typography
            variant="h7"
            color={theme.palette.primary.light}
            fontWeight="500"
            sx={{ marginTop: "20px" }}
          >
            Descrição:{" "}
          </Typography>
          {data?.objective?.allergy_description || "Não informado"}
        </Typography>
      </Grid>

      <Grid item xs={12} my={1}>
        <Typography color={theme.palette.primary.main}>
          {data?.objective?.preExistIllnesses?.length ? (
            <>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Comorbidades:{" "}
              </Typography>

              {data.objective.preExistIllnesses.map((item) => (
                <Typography color={theme.palette.primary.main}>
                  {" "}
                  {item?.describe}
                </Typography>
              ))}
            </>
          ) : (
            ""
          )}
        </Typography>
      </Grid>
      <Grid item xs={12} my={1}>
        <Typography color={theme.palette.primary.main}>
          <Typography
            variant="h7"
            color={theme.palette.primary.light}
            fontWeight="500"
            sx={{ marginTop: "20px" }}
          >
            Vacina em dia:{" "}
          </Typography>

          {typeof data?.objective?.vaccine_up_to_date === "boolean"
            ? data?.objective?.vaccine_up_to_date
              ? " Sim"
              : " Não"
            : "Não informado"}
        </Typography>
      </Grid>
      <RiskStratification data={data} />
      <Grid item xs={12}>
        <Divider sx={{ mt: 1 }} textAlign="left">
          <Typography
            variant="h7"
            color={theme.palette.primary.light}
            fontWeight="500"
            sx={{ marginTop: "20px" }}
          >
            A
          </Typography>
          <Typography
            variant="h7"
            color={theme.palette.primary.font}
            fontWeight="500"
            sx={{ marginTop: "20px" }}
          >
            valiação
          </Typography>
        </Divider>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          mt: 1,
          mb: 1,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        {
          <Typography color={theme.palette.primary.main}>
            <Typography
              variant="h7"
              color={theme.palette.primary.light}
              fontWeight="500"
              sx={{ marginTop: "20px" }}
            >
              Avaliação da consulta:{" "}
            </Typography>
            {data?.assessment?.query_assessment || "Não informado"}
          </Typography>
        }
        <Typography color={theme.palette.primary.light}>
          <Typography fontWeight="500">CIAP</Typography>
          {data?.assessment?.ciaps?.length ? (
            <>
              {data.assessment.ciaps.map((item) => (
                <Typography color={theme.palette.primary.main}>
                  <Typography
                    variant="h7"
                    color={theme.palette.primary.light}
                    fontWeight="500"
                    sx={{ marginTop: "20px" }}
                  >
                    {" > " + item?.description.firstCharToUp()}
                    {" - "}
                  </Typography>
                  {item?.acronym}
                </Typography>
              ))}
            </>
          ) : (
            "Nenhum ciap informado"
          )}
          {prontuaryOdonto && (
            <>
              <Typography
                fontWeight="500"
                sx={{
                  marginTop: "10px",
                }}
              >
                Vigilância em saúde bucal
              </Typography>
              {data?.assessment?.oral_health_surveillances?.length > 0 ? (
                <>
                  {data.assessment?.oral_health_surveillances?.map((item) => (
                    <Typography color={theme.palette.primary.main}>
                      <Typography
                        variant="h7"
                        color={theme.palette.primary.main}
                        fontWeight="500"
                        sx={{ marginTop: "20px" }}
                      >
                        {" > " + item?.description.firstCharToUp()}
                      </Typography>
                      {item?.acronym}
                    </Typography>
                  ))}
                </>
              ) : (
                "Nenhuma vigilância informada"
              )}
            </>
          )}

          <Typography fontWeight="500" mt={1}>
            Cid:
          </Typography>
          {data?.assessment?.cids?.length ? (
            <>
              {data.assessment.cids.map((item) => (
                <Typography color={theme.palette.primary.main}>
                  <Typography
                    variant="h7"
                    color={theme.palette.primary.light}
                    fontWeight="500"
                    sx={{ marginTop: "20px" }}
                  >
                    {" > " + item?.description.firstCharToUp()}
                    {" - "}
                  </Typography>
                  {item?.id}
                </Typography>
              ))}
            </>
          ) : (
            "Nenhum cid informado"
          )}
          {!!data?.assessment?.oral_health_surveillance?.length ? (
            <Typography
              color={theme.palette.primary.light}
              fontWeight="500"
              sx={{ marginTop: "20px" }}
            >
              Vigilância em saúde bucal:{" "}
              <Typography
                variant="h7"
                color={theme.palette.primary.font}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                {data.assessment.oral_health_surveillance
                  .map(
                    (oral_health_surveillance) =>
                      oral_health_surveillance.description
                  )
                  .join(", ")}
              </Typography>
            </Typography>
          ) : null}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ mt: 1 }} textAlign="left">
          <Typography
            variant="h7"
            color={theme.palette.primary.light}
            fontWeight="500"
            sx={{ marginTop: "20px" }}
          >
            P
          </Typography>
          <Typography
            variant="h7"
            color={theme.palette.primary.font}
            fontWeight="500"
            sx={{ marginTop: "20px" }}
          >
            lanos
          </Typography>
        </Divider>
      </Grid>

      <Grid item xs={12} my={1}>
        <Typography color={theme.palette.primary.main}>
          <Typography
            variant="h7"
            color={theme.palette.primary.light}
            fontWeight="500"
            sx={{ marginTop: "20px" }}
          >
            Plano de consulta:{" "}
          </Typography>
          {data?.plan?.query_plan || "Não informado"}
        </Typography>
      </Grid>
      {prontuaryOdonto ? (
        <>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Possui aparelho:{" "}
              </Typography>
              {data?.plan?.braces ? "Sim" : "Não"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Tipo de atendimento:{" "}
              </Typography>
              {data?.attendance_type?.describe || "Não Informada"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Tipo de consulta:{" "}
              </Typography>
              {data?.dental_appointment_type?.description || "Não Informada"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Tipo de tratamento:{" "}
              </Typography>
              {data?.plan?.treatment_type?.name
                ? `> ${data?.plan?.treatment_type?.name} - ${data?.plan?.treatment_type_conclusion?.name}`
                : data?.plan?.treatment_type_conclusion?.name
                ? data?.plan?.treatment_type_conclusion?.name
                : "Não Informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Possui contenção:{" "}
              </Typography>
              {data?.plan?.retainer ? "Sim" : "Não"}
            </Typography>
          </Grid>

          {data?.plan?.upper_denture || data?.plan?.upper_denture ? (
            <Grid item xs={12} my={1}>
              <Typography color={theme.palette.primary.main}>
                <Typography
                  variant="h7"
                  color={theme.palette.primary.light}
                  fontWeight="500"
                  sx={{ marginTop: "20px" }}
                >
                  Prótese:{" "}
                </Typography>
                {data.plan.lower_denture ? "Total inferior" : ""}
                {data.plan.lower_denture && data.plan.upper_denture
                  ? " | "
                  : ""}
                {data.plan.upper_denture ? "Total inferior" : ""}
              </Typography>
            </Grid>
          ) : null}
          {!!data?.plan?.prosthesisCommand && (
            <Grid item xs={12} my={1}>
              <Typography color={theme.palette.primary.main}>
                <Typography
                  variant="h7"
                  color={theme.palette.primary.light}
                  fontWeight="500"
                  sx={{ marginTop: "20px" }}
                >
                  Comanda Prótese:{" "}
                </Typography>
                <Typography color={theme.palette.primary.main}>
                  <Typography
                    variant="h7"
                    color={theme.palette.primary.light}
                    fontWeight="500"
                    sx={{ marginTop: "20px" }}
                  >
                    - N° Caixa:{" "}
                    <Typography
                      component={"span"}
                      color={theme.palette.primary.main}
                    >
                      {data?.plan?.prosthesisCommand.box_number ||
                        "Não informado"}
                    </Typography>
                  </Typography>
                </Typography>
                <Typography color={theme.palette.primary.main}>
                  <Typography
                    variant="h7"
                    color={theme.palette.primary.light}
                    fontWeight="500"
                    sx={{ marginTop: "20px" }}
                  >
                    - Tipo de Trabalho:{" "}
                    <Typography
                      component={"span"}
                      color={theme.palette.primary.main}
                    >
                      {data?.plan?.prosthesisCommand.work_type ||
                        "Não informado"}
                    </Typography>
                  </Typography>
                </Typography>
                <Typography color={theme.palette.primary.main}>
                  <Typography
                    variant="h7"
                    color={theme.palette.primary.light}
                    fontWeight="500"
                    sx={{ marginTop: "20px" }}
                  >
                    - Cor do dente:{" "}
                    <Typography
                      component={"span"}
                      color={theme.palette.primary.main}
                    >
                      {data?.plan?.prosthesisCommand.tooth_color ||
                        "Não informado"}
                    </Typography>
                  </Typography>
                </Typography>
                <Typography color={theme.palette.primary.main}>
                  <Typography
                    variant="h7"
                    color={theme.palette.primary.light}
                    fontWeight="500"
                    sx={{ marginTop: "20px" }}
                  >
                    - Observações:{" "}
                    <Typography
                      component={"span"}
                      color={theme.palette.primary.main}
                    >
                      {data?.plan?.prosthesisCommand.notes || "Não informado"}
                    </Typography>
                  </Typography>
                </Typography>
              </Typography>
            </Grid>
          )}
          {dataOdontoProcedures.length ? (
            <Grid item xs={12} my={1}>
              <Typography color={theme.palette.primary.light}>
                <Typography fontWeight="500">
                  Procedimentos: (Local | Problema | Procedimento | Observação).
                </Typography>
                {dataOdontoProcedures?.map((item, index) => (
                  <Typography color={theme.palette.primary.main}>
                    {index + 1}
                    {" - "}
                    {item?.local}
                    {" | "}
                    {item?.problems}
                    {" | "}
                    {item?.procedures}
                    {" | "}
                    {item?.notes}
                  </Typography>
                ))}
              </Typography>
            </Grid>
          ) : null}
          {dataRps.length ? (
            <Grid item xs={12} my={1}>
              <Typography
                component={"span"}
                color={theme.palette.primary.light}
              >
                <Typography fontWeight="500">
                  Registro periodontal simplificado:{" "}
                  <Typography
                    component={"span"}
                    color={theme.palette.primary.main}
                  >
                    {dataRps?.map((item, index, array) => {
                      if (array.length - 1 === index) {
                        return item;
                      }
                      return item + " | ";
                    })}
                  </Typography>
                </Typography>
                <Typography
                  color={theme.palette.primary.main}
                >{`Observações: ${data.plan.sextant.notes}`}</Typography>
              </Typography>
            </Grid>
          ) : null}
        </>
      ) : null}
      {prontuaryOdonto ? (
        <Grid
          item
          xs={12}
          sx={{
            mt: 1,
            mb: 1,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          {data?.plan?.orthodontic?.open_bite ? (
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Mordida aberta (Ortodontia):{" "}
              </Typography>
              {mapObject(data.plan?.orthodontic.open_bite, (value, label) => {
                if (value) {
                  return open_bite[label];
                }
              })
                .filter((i) => i)
                .join(", ")}
            </Typography>
          ) : null}
          {data?.plan?.orthodontic?.crossbite ? (
            <Typography color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Mordida cruzada (Ortodontia):{" "}
              </Typography>
              {mapObject(data.plan.orthodontic.crossbite, (value, label) => {
                if (value) {
                  return cross_bite[label];
                }
              })
                .filter((i) => i)
                .join(", ")}
            </Typography>
          ) : null}

          {data?.plan?.orthodontic?.deep_bite ? (
            <Typography component={"span"} color={theme.palette.primary.main}>
              <Typography
                variant="h7"
                color={theme.palette.primary.light}
                fontWeight="500"
                sx={{ marginTop: "20px" }}
              >
                Mordida profunda (Ortodontia):{" "}
              </Typography>
              <Typography color={theme.palette.primary.main}>
                Classe I com apinhamento:
                <Typography color={theme.palette.primary.main}>
                  {`${mapObject(
                    data.plan.orthodontic.deep_bite.class_1,
                    (value, label) => {
                      if (value && label !== "note") {
                        return deep_bite_class_1[label];
                      }
                    }
                  )
                    .filter((i) => i)
                    .join(", ")} - ${
                    data.plan?.orthodontic.deep_bite.class_1.note
                  }`}
                </Typography>
              </Typography>
              <Typography color={theme.palette.primary.main}>
                Classe II:
                <Typography color={theme.palette.primary.main}>
                  {`Divisão 1ª - ${mapObject(
                    data.plan.orthodontic.deep_bite.class_2?.division_1,
                    (value, label) => {
                      if (value) {
                        return deep_bite_class_2.division_1[label];
                      }
                    }
                  ).join(", ")}`}
                </Typography>
                <Typography color={theme.palette.primary.main}>
                  {`Divisão 2ª - ${mapObject(
                    data.plan?.orthodontic.deep_bite.class_2?.division_2,
                    (value, label) => {
                      if (value) {
                        return deep_bite_class_2.division_2[label];
                      }
                    }
                  ).join(", ")}`}
                </Typography>
              </Typography>
              <Typography color={theme.palette.primary.main}>
                Classe III:
                <Typography color={theme.palette.primary.main}>
                  {`${mapObject(
                    data.plan.orthodontic.deep_bite.class_3,
                    (value, label) => {
                      if (value) {
                        return deep_bite_class_3[label];
                      }
                    }
                  ).join(", ")}`}
                </Typography>
              </Typography>
            </Typography>
          ) : null}
          <Typography color={theme.palette.primary.light}>
            <Typography fontWeight="500">
              Técnica anestésica | Tipo de anestésico | Quantidade | Lote /
              Validade:
            </Typography>
            {data?.plan?.anesthesia?.[0]?.types?.length ? (
              <>
                {data.plan.anesthesia[0].types.map((item, index) => (
                  <Typography color={theme.palette.primary.main}>
                    {index + 1}
                    {" - "}
                    {item.anesthetic_technique?.describe ?? "-"}
                    {" | "}
                    {item.typesDescribe?.describe ?? "-"}
                    {" | "}
                    {item.typesQuantity ?? "-"}
                    {" | "}
                    {item.typesBatch ?? "-"}
                    {" | "}
                    {item.typesExpirationDate
                      ? format(parseISO(item.typesExpirationDate), "dd/MM/yyyy")
                      : "-"}
                  </Typography>
                ))}
              </>
            ) : (
              "Nenhum tipo informado"
            )}
          </Typography>
          <Typography color={theme.palette.primary.light}>
            <Typography fontWeight="500">
              Instrumentais | Lote | Validade:
            </Typography>
            {data?.plan?.anesthesia?.[0]?.sterilization?.length ? (
              <>
                {data.plan.anesthesia[0].sterilization.map((item, index) => (
                  <Typography color={theme.palette.primary.main}>
                    {index + 1}
                    {" - "}
                    {item?.sterilizationDescribe?.firstCharToUp()}
                    {" | "}
                    {item?.sterilizationBatch}
                    {" | "}
                    {item?.sterilizationExpirationDate
                      ? format(
                          parseISO(item.sterilizationExpirationDate),
                          "dd/MM/yyyy"
                        )
                      : ""}
                  </Typography>
                ))}
              </>
            ) : (
              "Nenhum tipo instrumental informado"
            )}
          </Typography>
        </Grid>
      ) : null}
      {data?.plan?.reavaluation_comments && (
        <Typography color={theme.palette.primary.main}>
          <Typography
            variant="h7"
            color={theme.palette.primary.light}
            fontWeight="500"
            sx={{ marginTop: "20px" }}
          >
            Reavaliação:{" "}
          </Typography>
          {data?.plan?.reavaluation_comments || "Não informado"}
        </Typography>
      )}
      {data?.plan?.ciaps && (
        <Grid item xs={12} my={1}>
          <Typography
            variant="h7"
            fontWeight="500"
            sx={{ marginTop: "20px" }}
            color={theme.palette.primary.light}
          >
            Ciaps:
            {data.plan.ciaps.map((item) => (
              <Typography color={theme.palette.primary.main}>
                {item?.description.firstCharToUp()}
              </Typography>
            ))}
          </Typography>
        </Grid>
      )}
      {data?.plan?.dental_supplies && prontuaryOdonto ? (
        <Grid item xs={12} my={1}>
          <Typography
            variant="h7"
            fontWeight="500"
            sx={{ marginTop: "20px" }}
            color={theme.palette.primary.light}
          >
            Fornecimento:{" "}
            <Typography component="span" color={theme.palette.primary.main}>
              {data.plan.dental_supplies.map((item, index, dentalSupplies) => {
                if (dentalSupplies.length - 1 === index) {
                  return dental_supplies[item - 1];
                }
                return dental_supplies[item - 1] + ", ";
              })}
            </Typography>
          </Typography>
        </Grid>
      ) : null}
      {data?.conducts?.length ? (
        <Grid item xs={12} my={1}>
          <Typography
            variant="h7"
            fontWeight="500"
            sx={{ marginTop: "20px" }}
            color={theme.palette.primary.light}
          >
            Condutas:
            {data?.conducts?.map((item) => (
              <Typography color={theme.palette.primary.main}>
                {item?.description?.firstCharToUp()}
              </Typography>
            ))}
          </Typography>
        </Grid>
      ) : null}
      {data?.attendance?.procedure && (
        <Grid item xs={12} my={1}>
          <Typography
            variant="h7"
            fontWeight="500"
            sx={{ marginTop: "20px" }}
            color={theme.palette.primary.light}
          >
            Procedimento Administrativo :
            <Typography color={theme.palette.primary.main}>
              {data.attendance.procedure.name.firstCharToUp()}
            </Typography>
          </Typography>
        </Grid>
      )}
      {data?.plan?.prescription?.exams.length ? (
        <Grid item xs={12} my={1}>
          <Typography
            variant="h7"
            fontWeight="500"
            sx={{ marginTop: "20px" }}
            color={theme.palette.primary.light}
          >
            Exames:
            {data.plan.prescription.exams.map((item) => (
              <Typography color={theme.palette.primary.main}>
                {item?.describe.firstCharToUp()} - {item?.type}
                <Typography component={"p"} sx={{ ml: 3 }}>
                  {item.notes}
                </Typography>
              </Typography>
            ))}
          </Typography>
        </Grid>
      ) : null}
      {data?.plan?.prescription?.medications.length ? (
        <Grid item xs={12} my={1}>
          <Typography
            variant="h7"
            fontWeight="500"
            sx={{ marginTop: "20px" }}
            color={theme.palette.primary.light}
          >
            Receituários :
            {data.plan.prescription.medications.map((item) => (
              <Typography color={theme.palette.primary.main}>
                {item?.name.firstCharToUp()}
                {` ( ${item.posology} ) `}
                {item?.quantity ? `- ${item?.quantity}` : ""}
                {item?.continuous_use
                  ? " - Uso contínuo"
                  : ` - ${item?.presentation}`}
                {item.special ? " - Medicamento Especial" : ""}
                <Typography component={"p"} sx={{ ml: 3 }}>
                  {item.notes}
                </Typography>
              </Typography>
            ))}
          </Typography>
        </Grid>
      ) : null}
      {data?.plan?.prescription?.medications_on_site.length ? (
        <Grid item xs={12} my={1}>
          <Typography
            variant="h7"
            fontWeight="500"
            sx={{ marginTop: "20px" }}
            color={theme.palette.primary.light}
          >
            Medicação :
            {data.plan.prescription.medications_on_site.map((item) => (
              <Typography color={theme.palette.primary.main}>
                {item?.name.firstCharToUp()}
                {` ( ${item.posology} ) `}
                {item?.quantity ? `- ${item?.quantity}` : ""}
                {item?.continuous_use
                  ? " - Uso contínuo"
                  : ` - ${item?.presentation}`}
                <Typography component={"p"} sx={{ ml: 3 }}>
                  Nota: {item.notes}
                </Typography>
              </Typography>
            ))}
          </Typography>
        </Grid>
      ) : null}
      {!!data?.plan?.interventions.length ? (
        <Grid item xs={12} my={1}>
          <Typography
            variant="h7"
            fontWeight="500"
            sx={{ marginTop: "20px" }}
            color={theme.palette.primary.light}
          >
            Intervenções e/ou procedimentos clínicos :
            {data.plan.interventions.map((item) => (
              <Typography color={theme.palette.primary.main}>
                {item?.procedure.name.firstCharToUp()}
                {item.done ? "- Realizado" : "- Não Realizado"}
                <Typography component={"p"} sx={{ ml: 3 }}>
                  {item.notes}
                </Typography>
              </Typography>
            ))}
          </Typography>
        </Grid>
      ) : null}
    </OutlinedContainer>
  );
}
