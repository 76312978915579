import React, { useContext, useEffect, useState } from "react";
import { theme } from "../../../config/theme";
import { Box, Typography, Grid, TablePagination } from "@mui/material";
import Yup from "../../../config/yup";
import { format, isValid, parseISO } from "date-fns";
import usePrescriptions from "../../../service/usePrescriptions";
import { AppContext } from "../../../contexts/AppContext";
import { useQuery, useMutation, useQueryClient } from "react-query";
import useNotifier from "../../../hooks/useNotifier";
import { useParams } from "react-router-dom";
import { useClinicalRecords } from "../../../service";
import { AuthContext } from "../../../contexts/AuthContext";
import { ColorInfoLabels } from "../../../components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  AutocompleteField,
  Button,
  TextField,
} from "../../../components/FormFields";
import { Dialog } from "../../../helper";
import { DialogMedium } from "../../../helper";
import DoubleCheckEvolution from "./DoubleCheckEvolution";
import TableFilter from "../../../components/Table/TableFilter";
import { DoneAll, Medication } from "@mui/icons-material";
import DynamicFeedback from "../../../components/DynamicFeedback";

const DrugList = ({ patient }) => {
  const notify = useNotifier();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [columnsName, setColumnsName] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("in_use");
  const [displayDrug, setDisplayDrug] = useState(false);
  const [drugIdToSuspend, setDrugIdToSuspend] = useState(null);
  const [patientDischarge, setPatientDischarge] = useState();
  const [toOpenDoubleCheck, setToOpenDoubleCheck] = React.useState();

  const {
    patientData,
    setPlanAssistedMedicationAdministration,
    planAssistedMedicationAdministration,
  } = useContext(AppContext);
  const params = useParams();
  const queryClient = useQueryClient();
  const { userData } = useContext(AuthContext);

  const {
    getMedications,
    getMedicationsOnSite,
    putMedications,
    suspendMedicationOnSite,
  } = usePrescriptions();
  const { postClinicalRecords } = useClinicalRecords();
  const medicationQuery = useQuery(
    [
      "medications",
      [patient?.id || patientData.id, selectedFilter, page, limit],
    ],
    () => {
      if (
        selectedFilter === "medications-on-site" ||
        selectedFilter === "assisted"
      ) {
        return getMedicationsOnSite(patient?.id || patientData.id, {
          filter: selectedFilter === "assisted" ? selectedFilter : "",
          page,
          limit,
        });
      } else {
        return getMedications(patient?.id || patientData.id, {
          filter: selectedFilter,
          page,
          limit,
        });
      }
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      initialData: { items: [] },
      onError: (error) => {
        queryClient.setQueriesData(["medications", page, limit], []);
        notify(error.message, "error");
      },
    }
  );

  const medicationsMutation = useMutation(putMedications);
  const clinicalRecordsMutation = useMutation(postClinicalRecords);
  const suspendMedicationOnSitMutation = useMutation(suspendMedicationOnSite);

  function handleClose() {
    setDisplayDrug(false);
  }

  const handleSuspend = (values) => {
    if (["medications-on-site", "assisted"].includes(selectedFilter)) {
      let data;

      if (drugIdToSuspend) {
        data = {
          id: drugIdToSuspend,
          data: values,
        };
      } else {
        data = {
          id: values,
        };
      }
      suspendMedicationOnSitMutation.mutate(data, {
        onSuccess: (response) => {
          notify(response.message, "success");
          formFilters.reset();
          formSuspension.reset();
          medicationQuery.refetch();
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      });
    } else {
      medicationsMutation.mutate(
        {
          id: values,
          data: {
            suspended: true,
          },
        },
        {
          onSuccess: (success) => {
            queryClient.invalidateQueries("medications");
            notify(success.message, "success");
          },
          onError: (error) => {
            notify(error.message, "error");
          },
        }
      );
    }
  };

  useEffect(() => {
    switch (selectedFilter) {
      case "suspended":
        setColumnsName([
          { name: "Medicamento", field: "name" },
          {
            name: "Suspenso Em",
            field: "suspended_at",
            use: (suspended_at) =>
              suspended_at &&
              format(parseISO(suspended_at), "dd/MM/yyyy 'às' HH:mm "),
          },
          { name: "Posologia", field: "posology" },
          { name: "Observação", field: "notes" },
        ]);
        break;
      case "assisted":
        setColumnsName([
          { name: "Medicamento", field: "name" },
          {
            name: "Início",
            field: "initial_date",
            use: (initial_date) =>
              initial_date && format(parseISO(initial_date), "dd/MM/yyyy"),
          },
          { name: "Posologia", field: "posology" },
          { name: "Observação", field: "notes" },
        ]);
        break;
      default:
        setColumnsName([
          { name: "Medicamento", field: "name" },
          { name: "Posologia", field: "posology" },
          { name: "Observação", field: "notes" },
        ]);
    }
  }, [selectedFilter]);

  const filters = [
    { status: "medications-on-site", describe: "Prescrito" },
    { status: "in_use", describe: "Em uso" },
    { status: "continuous_use", describe: "Uso Contínuo" },
    { status: "assisted", describe: "Medicamento Assistido" },
    { status: "finished", describe: "Tratamento Concluído" },
    { status: "suspended", describe: "Suspenso" },
  ];

  const initialValues = {
    filter: { status: "in_use", describe: "Em uso" },
  };

  const validations = Yup.object().shape({
    filter: Yup.object().required("É requerido").nullable(),
  });

  const formFilters = useForm({
    resolver: yupResolver(validations),
    defaultValues: initialValues,
  });

  const initialValuesSuspension = {
    suspension_reason: "",
  };

  const validationsSuspension = Yup.object().shape({
    suspension_reason: Yup.string()
      .nullable()
      .required("A justificativa é obrigatória"),
  });

  const formSuspension = useForm({
    resolver: yupResolver(validationsSuspension),
    defaultValues: initialValuesSuspension,
  });

  const shapeFeedback = [
    {
      type: "header",
      label: "DADOS DA SUSPENSÃO",
    },
    {
      type: "description",
      label: " Medicamento",
      valueKey: "name",
    },
    {
      type: "description",
      label: "Posologia",
      valueKey: "posology",
    },
    {
      type: "description",
      label: "Observação",
      valueKey: "notes",
      formatter: (notes) => {
        return notes || "Não informado";
      },
    },
    {
      type: "description",
      label: "Suspenso Em",
      valueKey: "suspended_at",
      formatter: (suspended_at) => {
        const formattedDate = parseISO(suspended_at);
        return isValid(formattedDate)
          ? format(formattedDate, "dd/MM/yyyy 'às' HH:mm")
          : "Não informado";
      },
    },
    {
      type: "description",
      label: "Justificativa de Suspensão",
      valueKey: "suspension_reason",
    },
  ];

  function handleShow(index) {
    const medicationSelected = medicationQuery?.data?.items.find(
      ({ id }) => id === index
    );
    setPatientDischarge(medicationSelected);
    setDisplayDrug(true);
  }

  return (
    <>
      <DialogMedium
        fullWidth
        maxWidth={"md"}
        open={!!toOpenDoubleCheck}
        handleClose={() => setToOpenDoubleCheck()}
      >
        <DoubleCheckEvolution medicationOnSiteID={toOpenDoubleCheck} />
      </DialogMedium>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        marginY="1rem"
        gap={2}
      >
        <Grid component="form" container minWidth={300} spacing={2}>
          <Grid item xs={6.8}>
            <Typography
              component="h2"
              sx={{
                color: theme.palette.primary.light,
                fontWeight: 600,
              }}
            >
              Lista de Medicamentos
            </Typography>
          </Grid>
          <Grid xs={4} item>
            <AutocompleteField
              name="filter"
              label="Status do medicamento"
              required
              options={filters}
              control={formFilters.control}
              optionLabelKey="describe"
            />
          </Grid>
          <Grid xs={1} item>
            <Button
              onClick={formFilters.handleSubmit(({ filter }) =>
                setSelectedFilter(filter.status)
              )}
            >
              Filtrar
            </Button>
          </Grid>
        </Grid>
      </Box>
      <ColorInfoLabels
        labels={[
          {
            text: "Suspenso",
            color: "rgba(216, 0, 0, 0.1)",
          },
          {
            text: "Ação realizada neste atendimento.",
            color: "rgba(255, 255, 212)",
          },
        ]}
      />
      <Dialog
        open={!!displayDrug}
        title={"Visualização de Prescrições"}
        handleClose={handleClose}
      >
        <DynamicFeedback data={patientDischarge} shape={shapeFeedback} />
      </Dialog>
      <Dialog
        open={!!drugIdToSuspend}
        title={`Suspensão de Prescrições`}
        maxWidth="sm"
        fullWidth={true}
        fullScreen={false}
        handleClose={() => setDrugIdToSuspend(null)}
      >
        <Grid component="form" container spacing={4}>
          <Grid item xs={12}>
            <TextField
              control={formSuspension.control}
              name="suspension_reason"
              label="Justificativa de Suspensão"
              required
              multiline
              minRows={8}
            />
          </Grid>
          <Grid item xs={2}>
            <Button onClick={formSuspension.handleSubmit(handleSuspend)}>
              Suspender
            </Button>
          </Grid>
        </Grid>
      </Dialog>
      <TableFilter
        columns={columnsName}
        data={medicationQuery.data?.items}
        actions
        loading={medicationQuery.isFetching}
        customizeActions={{
          finish: {
            icon: <Medication />,
            verb: "Administrar",
            title: "Administrar",
          },
          delete: {
            icon: <Medication />,
            verb: "Não Administrar",
            title: "Não Administrar",
          },
          response: {
            icon: <DoneAll />,
            title: "Dupla checagem",
          },
        }}
        actionsTypes={
          selectedFilter === "suspended"
            ? ["suspend", "view"]
            : selectedFilter !== "assisted"
            ? ["suspend"]
            : ["suspend", "delete", "finish", "response"]
        }
        actionUniqueIdentifier="id"
        actionHandleFinish={(id) => {
          clinicalRecordsMutation.mutate(
            {
              medication_on_site_id: id,
              administration: true,
            },
            {
              onSuccess: () => {
                setPlanAssistedMedicationAdministration((current) => {
                  return Array.from(new Set([...current, id]));
                });
                notify("Administração realizada com sucesso", "success");
              },
              onError: (err) => {
                notify(err.message || "Error ao administrar", "error");
              },
            }
          );
        }}
        actionHandleDelete={(id) => {
          clinicalRecordsMutation.mutate(
            {
              medication_on_site_id: id,
              administration: false,
            },
            {
              onSuccess: () => {
                setPlanAssistedMedicationAdministration((current) => {
                  return Array.from(new Set([...current, id]));
                });
                notify("Não administração realizada com sucesso", "success");
              },
              onError: (err) => {
                notify(err.message || "Error ao administrar", "error");
              },
            }
          );
        }}
        actionHandleResponse={(id) => {
          setToOpenDoubleCheck(id);
        }}
        actionHandleSuspend={(id) => {
          if (["medications-on-site", "assisted"].includes(selectedFilter)) {
            setDrugIdToSuspend(id);
          } else {
            handleSuspend(id);
          }
        }}
        actionHandleView={handleShow}
        disableRows={(row) =>
          row.suspended || planAssistedMedicationAdministration.includes(row.id)
        }
        disabledColor={(row) =>
          planAssistedMedicationAdministration.includes(row.id) &&
          "rgba(255, 255, 212)"
        }
        disableActions={(row, action) => {
          return (
            (action === "suspend" &&
              !userData.employee_type.higher_education) ||
            planAssistedMedicationAdministration.includes(row.id) ||
            (row.suspended && action === "suspend") ||
            (["finish", "delete"].includes(action) && !params?.attendance) ||
            (["finish", "delete"].includes(action) &&
              !row.is_assisted_medication_valid)
          );
        }}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          count={medicationQuery.data?.totalItems || 0}
          component="div"
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={({ target }) => {
            setLimit(parseInt(target.value), 10);
            setPage(0);
          }}
        />
      </Box>
    </>
  );
};

export default DrugList;
