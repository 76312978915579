import React from "react";
import { useQuery, useQueryClient } from "react-query";
import { DialogMedium, Loading } from "../../../helper";
import { useAgainstReferences } from "../../../service";
import { format, parseISO } from "date-fns";
import { Box, TablePagination } from "@mui/material";
import AgainstReferenceForm from "./AgainstReferenceForm";
import TableFilter from "../../../components/Table/TableFilter";

export default function AgainstReference() {
  const queryClient = useQueryClient();
  const [openView, setOpenView] = React.useState(false);
  const [openResponse, setOpenResponse] = React.useState(false);
  const [action, setAction] = React.useState(1);
  const [limit, setLimit] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [unicReference, setUnicReference] = React.useState();

  const { getAgainstReferences } = useAgainstReferences();

  const againstReferenceQuery = useQuery(
    ["againstReferences", [page, limit]],
    () => getAgainstReferences({ page, limit, created_for_me: true }),
    {
      initialData: { totalItems: 0, items: [] },
    }
  );

  function handleView() {
    setOpenView(true);
    setAction("view");
  }
  function handleResponse() {
    setOpenResponse(true);
    setAction("edit");
  }
  function handleClose() {
    setOpenResponse(false);
    setOpenView(false);
    queryClient.invalidateQueries("againstReferences");
  }

  const tableColumns = [
    {
      name: "Data Solicitada",
      field: "createdAt",
      use: (date) => format(parseISO(date), "dd/MM/yyyy"),
    },
    { name: "Paciente", field: "attendance?.patient?.name" },
    { name: "Especialidade Requisitada", field: "requested_specialty.describe" },
    {
      name: "Situação",
      field: "suspendedAt",
      use: (suspendedAt, data) => {
        if (suspendedAt === null && data.response === null) {
          return "Aberto";
        } else if (suspendedAt) {
          return "Suspenso";
        } else if (data.response) {
          return "Fechado";
        }
      },
    },
  ];

  if (againstReferenceQuery.isLoading) return <Loading />;

  return (
    <>
      <DialogMedium
        title="Visualizar Referência / Contrarreferência"
        open={openView}
        type={action}
        handleClose={handleClose}
      >
        <AgainstReferenceForm type="view" reference={unicReference} />
      </DialogMedium>
      <DialogMedium
        title="Responder Referência / Contrarreferência"
        open={openResponse}
        type={action}
        handleClose={handleClose}
      >
        <AgainstReferenceForm type="edit" handleClose={handleClose} reference={unicReference} />
      </DialogMedium>
      <TableFilter
        data={againstReferenceQuery.data.items}
        columns={tableColumns}
        loading={againstReferenceQuery.isFetching && againstReferenceQuery.isPreviousData}
        actions
        actionsTypes={["view", "response"]}
        rowStatus={(row) => (row.suspended || row.response ? "disabled" : "")}
        disableActions={(row, action) => (row.suspended || row.response) && action === "response"}
        actionHandleResponse={(_, data) => {
          setUnicReference(data);
          handleResponse();
        }}
        actionHandleView={(_, data) => {
          setUnicReference(data);
          handleView();
        }}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          count={againstReferenceQuery.data.totalItems}
          component="div"
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={(event) => {
            setLimit(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </Box>
    </>
  );
}
