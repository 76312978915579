import { ExpandMoreOutlined } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import React, { useState } from "react";

const TAccordion = ({ configs, defaultOpen, kerning = true }) => {
  const theme = useTheme();

  const [expanded, setExpanded] = useState(defaultOpen);
  const handleChangePanel = (panel, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Box>
      {configs?.map((config, index) => (
        <Accordion
          expanded={defaultOpen || expanded === index}
          key={index}
          onChange={(_, nextExpanded) => {
            if (config.customOnChange instanceof Function) {
              config.customOnChange();
            }
            handleChangePanel(index, nextExpanded);
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined style={{ color: config.expandColor || "#fff"  }} />}
            aria-controls={`TAccordion-${index}`}
            id={`TAccordion-${index}`}
            sx={{
              background: config?.bgColor || theme.palette.secondary.main,
              borderRadius: "5px",
              color: "#FFF",
              letterSpacing: kerning ? "2px" : "",
              borderBottom: "1px solid #fff",
            }}
          >
            {config.title || ""}
          </AccordionSummary>
          <AccordionDetails sx={{ paddingTop: "20px" }}>
            {config.body || ""}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default React.memo(TAccordion);
