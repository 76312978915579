import { RemoveCircle } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { isValid } from "date-fns";
import dayjs from "dayjs";
import React from "react";
import InputMask from "react-input-mask";

export default function FilterInput({
  options,
  handleSearch,
  fieldKey,
  value = "",
  column = "",
  removeInput = () => null,
}) {
  const [selectedField, setSelectedField] = React.useState(column);
  const [selectedColumn, setSelectedColumn] = React.useState(options[column]);
  const [inputValue, setInputValue] = React.useState(value);
  function handleSelectedField(index) {
    setSelectedField(index);
    setSelectedColumn(options[index]);
    setInputValue("");
  }
  function callSearch(value) {
    setInputValue(value);
    handleSearch(selectedColumn, value, fieldKey);
  }
  return (
    <Box
      sx={{
        width: "100%",
        padding: "0 10px 0 20px",
        display: "flex",
      }}
    >
      <Grid container xs={12} spacing={1}>
        <Grid item xs={5.5}>
          <TextField
            fullWidth
            size="small"
            label="Campo:"
            value={selectedField}
            select
            onChange={({ target }) => handleSelectedField(target.value)}
            sx={{
              minWidth: "150px",
            }}
          >
            {options?.map((column, index) => {
              return (
                <MenuItem key={index} value={index}>
                  {column?.name}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <Grid item xs={6.5}>
          <Box display={"flex"}>
            {(() => {
              switch (selectedColumn?.type) {
                case "date":
                  return (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Escolha a data:"
                        inputFormat="DD/MM/YYYY"
                        value={inputValue}
                        disablePast={selectedColumn?.disablePast || false}
                        disableFuture={selectedColumn.disableFuture || false}
                        onChange={(value) => {
                          const dateValid = isValid(new Date(value));
                          if (!dateValid) return;
                          callSearch(dayjs(value).format("YYYY-MM-DD"));
                        }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            sx={{
                              minWidth: "150px",
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  );
                case "number":
                  return (
                    <TextField
                      fullWidth
                      size="small"
                      label="Valor: "
                      value={inputValue}
                      type={"number"}
                      defaultValue={inputValue}
                      disabled={!selectedColumn}
                      sx={{
                        minWidth: "150px",
                      }}
                      onChange={({ target }) => callSearch(target.value)}
                    />
                  );
                case "select":
                  return (
                    <Autocomplete
                      size="small"
                      variant="outlined"
                      fullWidth
                      options={selectedColumn.options}
                      getOptionLabel={(option) => option.label ? option.label : option}
                      value={selectedColumn.options.find(option => option.value === inputValue)}
                      onChange={(_,option) => callSearch(option?.value)}
                      clearText="Limpar Campo"
                      renderInput={ (params) =>
                        <TextField
                        {...params}
                          size="small"
                          label="Campo:"
                          sx={{
                            minWidth: "180px",
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      }
                    />
                  );
                case "boolean":
                  return (
                    <TextField
                      fullWidth
                      size="small"
                      label="Valor:"
                      value={inputValue}
                      select
                      defaultValue={""}
                      onChange={({ target }) => callSearch(target.value)}
                      sx={{
                        minWidth: "150px",
                      }}
                    >
                      <MenuItem key={"true-option"} value={true}>
                        Sim
                      </MenuItem>
                      <MenuItem key={"false-option"} value={false}>
                        Não
                      </MenuItem>
                    </TextField>
                  );
                case "text":
                  return selectedColumn?.mask ? (
                    <InputMask
                      fullWidth
                      size="small"
                      label="Valor: "
                      value={inputValue}
                      mask={selectedColumn?.mask}     
                      disabled={!selectedColumn}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        minWidth: "150px",
                      }}
                      onChange={({ target }) => callSearch(target.value)}
                    >
                      {(settings) => <TextField {...settings} />}
                    </InputMask>
                  ) : (
                    <TextField
                      fullWidth
                      size="small"
                      label="Valor: "
                      value={inputValue}
                      disabled={!selectedColumn}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        minWidth: "150px",
                      }}
                      onChange={({ target }) => callSearch(target.value)}
                    />
                  );
                default:
                  return null;
              }
            })()}
            {selectedColumn?.type ? (
              <IconButton
                variant="text"
                fullWidth
                color="error"
                onClick={() => {
                  handleSelectedField("");
                  removeInput(fieldKey);
                }}
              >
                <RemoveCircle />
              </IconButton>
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
