import { Typography } from "@mui/material";
import { limitName } from "../../utils";
import CopyToClipboardField from "../CardPatient/CopyToClipboardField";
import RegexOf from "../../config/regex";
import { formatWithMask } from "../../config/mask";

export default function PatientRenderCell({ row }) {
  return (
    <>
      <Typography fontWeight={"bold"}>
        {limitName(
          row.social_prioritize ? row.social_name : row.name,
          5,
          "Não informado"
        )}
      </Typography>
      <CopyToClipboardField
        textColor="primary.light"
        label="CPF"
        fontSize={14}
        text={
          row?.physic_national
            ? formatWithMask({
                mask: RegexOf.cpf,
                text: row?.physic_national,
              }).masked
            : "Não Informado"
        }
      />
      <CopyToClipboardField
        textColor="primary.light"
        label="CNS"
        fontSize={14}
        text={
          row?.sus_card
            ? formatWithMask({
                mask: RegexOf.cns,
                text: row?.sus_card,
              }).masked
            : "Não Informado"
        }
      />
    </>
  );
}
