export default function verifyAttendanceFlow(isClassification) {
  let userData = localStorage.getItem("user-data");

  if (userData) {
    userData = JSON.parse(userData);
    const allowedCompany = userData.company.type.AMA || userData.company.type.UBS;
    const specialCompany = userData.company.type.CEM;

    if (specialCompany) return "specialized";

    if (allowedCompany) {
      if (isClassification) {
        return "ama";
      }

      return "ubs";
    }
  }

  return null;
}
