import React from "react";
import HistoryDeclaration from "./HistoryDeclaration";
import { Tabs } from "../../../components";
import usePermissions from "../../../config/usePermissions";
import DeclarationForm from "./DeclarationForm";

function Declaration({ attendance, isExternal, defaultCids, isPregnancy, odonto, ceo }) {
  const permission = usePermissions("/soap/statement");

  let tabs = [];

  if (permission.create) {
    tabs = [
      {
        label: "Declarações",
        content: (
          <DeclarationForm
            attendance={attendance}
            defaultCids={defaultCids}
            isPregnancy={isPregnancy}
            odonto={odonto}
            ceo={ceo}
            isExternal={isExternal}
          />
        ),
      },
      {
        label: "Histórico de Declarações",
        content: <HistoryDeclaration attendance={attendance} />,
      },
    ];
  }

  return <Tabs tabs={tabs} />;
}

export default Declaration;
