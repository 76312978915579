
import { Grid, Typography } from "@mui/material";
import { AutocompleteField, RadioField, TextField } from "../../FormFields";
import { homelessnessTime, howTimesFeedDay, originFoods, personalHygienes } from "../../../config/susCodeStandardSelect";

function IndividualHomelessnessForm({
  control,
  watch,
  isEditingIndividual,
  handleChangeField,
}) {

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" color="secondary">CIDADÃO EM SITUAÇÃO DE RUA</Typography>
      </Grid>

      <Grid item xs={3}>
        <RadioField
          name="is_homeless"
          label="ESTÁ EM SITUAÇÃO DE RUA ?"
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          customOnChange={(value) => handleChangeField("is_homeless", value)}
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>

      <Grid item xs={9}>
        <AutocompleteField
          name="homelessness_time"
          label="TEMPO EM SITUAÇÃO DE RUA"
          control={control}
          options={homelessnessTime}
          optionLabelKey="describe"
          optionValueKey="sus_code_id"
          disabled={
            !isEditingIndividual ||
            watch("individual_registration_rejection") ||
            !watch("is_homeless")
          }
        />
      </Grid>

      <Grid item xs={12}>
        <RadioField
          name="receive_some_benefit"
          label="RECEBE ALGUM BENEFÍCIO ?"
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          disabled={
            !isEditingIndividual ||
            watch("individual_registration_rejection") ||
            !watch("is_homeless")
          }
        />
      </Grid>

      <Grid item xs={3}>
        <RadioField
          name="have_access_to_personal_hygiene"
          label="TEM ACESSO À HIGIENE PESSOAL ?"
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          disabled={
            !isEditingIndividual ||
            watch("individual_registration_rejection") ||
            !watch("is_homeless")
          }
          customOnChange={(value) => handleChangeField("have_access_to_personal_hygiene", value)}
        />
      </Grid>

      <Grid item xs={9}>
        <AutocompleteField
          name="personal_hygiene"
          label="SE SIM, INDIQUE QUAIS?"
          control={control}
          options={personalHygienes}
          optionLabelKey="describe"
          optionValueKey="sus_code_id"
          direction="row"
          disabled={
            !isEditingIndividual ||
            watch("individual_registration_rejection") ||
            (!watch("is_homeless") ||
              !watch("have_access_to_personal_hygiene"))
          }
          multiple
        />
      </Grid>

      <Grid item xs={3}>
        <RadioField
          name="is_accompanied_by_another_institution"
          label="É ACOMPANHADO POR OUTRA INSTITUIÇÃO ?"
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          disabled={
            !isEditingIndividual ||
            watch("individual_registration_rejection") ||
            !watch("is_homeless")
          }
          customOnChange={(value) => handleChangeField("is_accompanied_by_another_institution", value)}
        />
      </Grid>

      <Grid item xs={9}>
        <TextField
          name="instituition_name"
          label="SE SIM, INDIQUE QUAL(IS) ?"
          control={control}
          disabled={
            !isEditingIndividual ||
            watch("individual_registration_rejection") ||
            (!watch("is_homeless") || !watch("is_accompanied_by_another_institution"))
          }
          required={watch("is_accompanied_by_another_institution")}
        />
      </Grid>

      <Grid item xs={12}>
        <AutocompleteField
          name="how_many_times_do_you_feed_a_day"
          label="QUANTAS VEZES SE ALIMENTA AO DIA"
          control={control}
          options={howTimesFeedDay}
          optionLabelKey="describe"
          optionValueKey="id"
          disabled={
            !isEditingIndividual ||
            watch("individual_registration_rejection") ||
            !watch("is_homeless")
          }
        />
      </Grid>

      <Grid item xs={12}>
        <AutocompleteField
          name="what_is_the_origin_of_the_food"
          label="QUAL A ORIGEM DA ALIMENTAÇÃO?"
          control={control}
          options={originFoods}
          optionLabelKey="describe"
          optionValueKey="id"
          disabled={
            !isEditingIndividual ||
            watch("individual_registration_rejection") ||
            !watch("is_homeless")
          }
          multiple
        />
      </Grid>

      <Grid item xs={12}>
        <RadioField
          name="visit_family_members_frequently"
          label="VISITA ALGUM FAMILIAR COM FREQUÊNCIA ?"
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          disabled={
            !isEditingIndividual ||
            watch("individual_registration_rejection") ||
            !watch("is_homeless")
          }
        />
      </Grid>

      <Grid item xs={3}>
        <RadioField
          name="does_it_have_any_family_references"
          label="POSSUI ALGUMA REFERÊNCIA FAMILIAR ?"
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          disabled={
            !isEditingIndividual ||
            watch("individual_registration_rejection") ||
            !watch("is_homeless")
          }
          customOnChange={(value) => handleChangeField("does_it_have_any_family_references", value)}
        />
      </Grid>

      <Grid item xs={9}>
        <TextField
          name="family_members_references"
          label="SE SIM, QUAL?"
          control={control}
          disabled={
            !isEditingIndividual ||
            watch("individual_registration_rejection") ||
            !watch("does_it_have_any_family_references")
          }
          required={watch("does_it_have_any_family_references")}
        />
      </Grid>
    </>
  )
}

export default IndividualHomelessnessForm;