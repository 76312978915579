import React from "react";
import TableFilter from "../../../../../components/Table/TableFilter";
import { Button, Grid, Tooltip, Typography, useTheme } from "@mui/material";
import { DialogMedium } from "../../../../../helper";
import DetailCard from "../../../../../components/Panels/DetailCard";
import { PaginatedScrollList } from "../../../../../components/PaginatedScrollList";
import SimpleCard from "../../../../../components/SimpleCard";
import TypographyList from "../../../../../components/TypographyList";
import { useCockpit } from "../../../../../service";
import { useQuery } from "react-query";
import { useNotifier } from "../../../../../hooks";
import { Assignment, PeopleRounded } from "@mui/icons-material";
import { openURL } from "../../../../../pdfModels";
import { v4 as uuidv4 } from "uuid";

export default function Population({ data, analyticalReport, filters }) {
  const [detailing, setDetailing] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [filterId, setFilterId] = React.useState(null);
  const [familyList, setFamilyList] = React.useState([]);
  const notify = useNotifier();
  const theme = useTheme();
  const { getACSIndividualRegistersAnalytics } = useCockpit();

  const exportData = (data) => {
    const fileUrl = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
    const Content_Dispostition = data.headers?.["content-disposition"];
    const match = Content_Dispostition?.match(/filename\s*?="(.+)"/);

    let filename = `painel_acs_${uuidv4()}.xlsx`;

    if (match && match[1]) {
      filename = match[1];
    }

    openURL(fileUrl, filename);
  };

  const populationAnalytics = useQuery(
    ["population-analytics", filters, filterId, page],
    () => getACSIndividualRegistersAnalytics({ ...filters, ...filterId, page, limit: 5 }),
    {
      enabled: !!filterId && !!filters,
      onSuccess(data) {
        if (Object.keys(data).length === 5) {
          setFamilyList((current) => (page === 0 ? data.items : [...current, ...data.items]));
        } else {
          exportData(data);
        }
      },
      onError(error) {
        notify(error.message, "error");
      },
    }
  );

  const columns = [
    {
      name: "Faixa Etária",
      field: "age_group",
    },
    {
      name: "Feminino",
      field: "female",
    },
    {
      name: "Masculino",
      field: "male",
    },
    {
      name: "Total",
      field: "total",
    },
  ];

  const shapeList = [
    {
      label: "Código",
      valueKey: "patient.patient_family.family.number",
      formatter: (value) => value || "Não informado",
    },
    {
      label: "Familia",
      valueKey: "patient.patient_family.family.name",
      formatter: (value) => value || "Não informado",
    },
    {
      label: "Equipe",
      valueKey: "team_name",
      formatter: (value) => value || "Não informado",
    },
    {
      label: "Membros",
      valueKey: "quantity_members",
      formatter: (value) => value || "Não informado",
    },
  ];

  return (
    <>
      <DialogMedium
        title={`${detailing?.title} - Relatório Analítico`}
        fullWidth
        open={detailing?.open}
        handleClose={() => setDetailing((curr) => ({ ...curr, open: false }))}
        maxWidth="md"
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <DetailCard
              title={detailing?.title}
              content={detailing?.data}
              sx={{
                backgroundColor: theme.palette.primary.medium,
                color: theme.palette.primary.light,
                borderRadius: 1.5,
              }}
            />
          </Grid>
          {/* {detailing?.cards?.map((card, i) => (
            <Grid item xs={3} key={i}>
              <DetailCard
                title={card.age_group}
                content={card.total}
                sx={{
                  backgroundColor: theme.palette.primary.medium,
                  color: theme.palette.primary.light,
                  borderRadius: 1.5,
                }}
              />
            </Grid>
          ))} */}
          <Grid container item xs={12} pl={2} mt={2}>
            <PaginatedScrollList
              component={(item, index) => (
                <SimpleCard
                  key={index}
                  icon={<PeopleRounded sx={{ fontSize: 22, color: "white", borderRadius: 10 }} />}
                  title={
                    <Typography
                      color={item?.patient?.has_any_condition && theme.palette.error.main}
                      fontWeight={600}
                    >
                      {item?.patient?.name || "Não Informado"}
                    </Typography>
                  }
                  containerStyles={{ paddingY: 1.5 }}
                >
                  <TypographyList numberColumns={2} compact data={item} shape={shapeList} />
                </SimpleCard>
              )}
              hasDivider={false}
              maxHeight="250px"
              minHeight="auto"
              handlePage={setPage}
              loading={populationAnalytics.isLoading || populationAnalytics.isRefetching}
              page={page}
              totalPage={populationAnalytics.data?.total}
              data={familyList || []}
            />
            <Grid item xs={2}>
              <Tooltip title="Exportar para Excel" placement="right">
                <Button
                  disabled={!familyList?.length}
                  variant="contained"
                  onClick={() => setFilterId((curr) => ({ ...curr, to_excel: true }))}
                >
                  <Assignment color="white" />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </DialogMedium>
      {!!data && (
        <TableFilter
          columns={columns}
          data={data}
          noFilter
          actions={analyticalReport}
          actionsTypes={["view"]}
          actionHandleView={(_, row) => {
            setPage(0);
            setFamilyList([]);
            const ageGroupToFilter = row?.age_group
              ?.replace(/\s*à\s*/g, "/")
              .replace(/\s*anos\s*/g, "");
            const otherCards = data?.filter((item) => item?.age_group !== row?.age_group);

            setDetailing({
              open: true,
              title: row?.age_group,
              data: row?.total,
              cards: otherCards,
            });
            setFilterId({
              age_group: ageGroupToFilter,
            });
          }}
        />
      )}
    </>
  );
}
