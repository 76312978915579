import { Grid, Typography } from "@mui/material";
import { AutocompleteField, PaginatedAutocompleteField, RadioField } from "../../FormFields";
import { useSusCode } from "../../../service";
import {
  childrenResponsible,
  disabilityTypes,
  genderIdentities,
  sexualOrientations,
  typeBlood,
} from "../../../config/susCodeStandardSelect";

function IndividualSociodemograpicForm({
  control,
  watch,
  isEditingIndividual,
  handleChangeField,
  diffInYears,
}) {
  const { susCodeIndividualPaths, getSusCodeByProperty } = useSusCode();

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" color="secondary">
          INFORMAÇÕES SOCIODEMOGRÁFICAS
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <RadioField
          name="attends_scholl_or_daycare"
          label="FREQUENTA ESCOLA OU CRECHE"
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteField
          name="children_responsible"
          control={control}
          label="COM QUEM FICA A CRIANÇA?"
          filterKey="describe"
          options={childrenResponsible}
          optionLabelKey="describe"
          disabled={
            !isEditingIndividual ||
            watch("individual_registration_rejection") ||
            (!diffInYears && diffInYears !== 0) ||
            diffInYears > 9
          }
          multiple
        />
      </Grid>

      <Grid item xs={4}>
        <PaginatedAutocompleteField
          name="educational_attainment"
          required
          label="QUAL É O CURSO MAIS ELEVADO QUE FREQUENTA OU FREQUENTOU?"
          control={control}
          service={(params) =>
            getSusCodeByProperty(susCodeIndividualPaths.educationalAttainments, { ...params })
          }
          queryKey="education-attainments"
          optionLabelKey="describe"
          optionValueKey="id"
          filterKey="describe"
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>

      <Grid item xs={4}>
        <PaginatedAutocompleteField
          name="work_situation"
          required
          label="SITUAÇÃO NO MERCADO DE TRABALHO"
          control={control}
          service={(params) =>
            getSusCodeByProperty(susCodeIndividualPaths.workSituations, { ...params })
          }
          queryKey="work-situations"
          optionLabelKey="describe"
          optionValueKey="sus_code_id"
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>

      <Grid item xs={4}>
        <PaginatedAutocompleteField
          name="profession"
          label="OCUPAÇÃO"
          control={control}
          optionLabelKey="describe"
          service={(params) =>
            getSusCodeByProperty(susCodeIndividualPaths.profession, { ...params })
          }
          filterKey="describe"
          queryKey="professions"
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>

      <Grid item xs={4}>
        <RadioField
          name="attends_traditional_caregiver"
          label="FREQUENTA CUIDADOR TRADICIONAL?"
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>

      <Grid item xs={4}>
        <RadioField
          name="participate_in_community_group"
          label="PARTICIPA DE ALGUM GRUPO COMUNITÁRIO?"
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>

      <Grid item xs={4}>
        <RadioField
          name="have_health_insurance"
          required
          label="POSSUI PLANO DE SAÚDE PRIVADO?"
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>

      <Grid item xs={4}>
        <RadioField
          name="is_member_of_traditional_community"
          label="É MEMBRO DE POVO OU COMUNIDADE TRADICIONAL?"
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          customOnChange={(value) => handleChangeField("is_member_of_traditional_community", value)}
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>

      <Grid item xs={8}>
        <PaginatedAutocompleteField
          name="traditional_community_name"
          required={watch("is_member_of_traditional_community")}
          label="SE SIM, QUAL?"
          control={control}
          service={(params) =>
            getSusCodeByProperty(susCodeIndividualPaths.traditionalCommunityNames, { ...params })
          }
          optionLabelKey="describe"
          filterKey="describe"
          queryKey="traditionalCommunities"
          disabled={
            !isEditingIndividual ||
            watch("individual_registration_rejection") ||
            !watch("is_member_of_traditional_community")
          }
        />
      </Grid>

      <Grid item xs={4}>
        <RadioField
          name="is_sexual_orientation"
          required
          label="DESEJA INFORMAR ORIENTAÇÃO SEXUAL"
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          customOnChange={(value) => handleChangeField("is_sexual_orientation", value)}
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>

      <Grid item xs={8}>
        <AutocompleteField
          name="sexual_orientation"
          label="SE SIM, QUAL?"
          control={control}
          options={sexualOrientations}
          optionLabelKey="describe"
          optionValueKey="sus_code_id"
          disabled={
            !isEditingIndividual ||
            watch("individual_registration_rejection") ||
            !watch("is_sexual_orientation")
          }
          required={watch("is_sexual_orientation")}
        />
      </Grid>

      <Grid item xs={4}>
        <RadioField
          name="is_gender_identity"
          label="DESEJA INFORMAR IDENTIDADE DE GÊNERO"
          required
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          customOnChange={(value) => handleChangeField("is_gender_identity", value)}
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>

      <Grid item xs={8}>
        <AutocompleteField
          name="gender_identity"
          label="SE SIM, QUAL?"
          control={control}
          options={genderIdentities}
          optionLabelKey="describe"
          optionValueKey="sus_code_id"
          disabled={
            !isEditingIndividual ||
            watch("individual_registration_rejection") ||
            !watch("is_gender_identity")
          }
          required={!!watch("is_gender_identity")}
        />
      </Grid>

      <Grid item xs={4}>
        <RadioField
          name="disabled"
          required
          label="POSSUI ALGUMA DEFICIÊNCIA?"
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          customOnChange={(value) => handleChangeField("disabled", value)}
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>

      <Grid item xs={8}>
        <AutocompleteField
          name="disability_type"
          label="SE SIM, QUAL?"
          control={control}
          options={disabilityTypes}
          optionLabelKey="describe"
          optionValueKey="sus_code_id"
          disabled={
            !isEditingIndividual || watch("individual_registration_rejection") || !watch("disabled")
          }
          multiple
        />
      </Grid>

      <Grid item xs={12}>
        <RadioField
          name="organ_donor"
          label="DOADOR DE ORGÃOS?"
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>

      <Grid item xs={12}>
        <AutocompleteField
          name="type_blood"
          label="TIPO SANGUÍNEO"
          control={control}
          options={typeBlood}
          optionLabelKey="label"
          optionValueKey="label"
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>
    </>
  );
}

export default IndividualSociodemograpicForm;
