import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const path = "/collective-attendances";
const pathComplement = "/collective-attendances-complements";

function useCollectiveActivity() {
  async function getCollectiveAttendances(params) {
    try {
      const response = await instance.get(path, { params: urlQuery(params) });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getCollectiveAttendanceById(id, params) {
    try {
      const response = await instance.get(`${path}/${id}`, { params: urlQuery(params) });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function postCollectiveAttendances(data) {
    try {
      const response = await instance.post(path, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function suspendCollectiveAttendance({id, data}) {
    try {
      const response = await instance.delete(`${path}/${id}`, {data});
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getAdmeasurementsByPatientId(id) {
    try {
      const response = await instance.get(`${path}/patient/${id}/admeasurements`);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function updateCollectiveActivity({collectiveAttendanceId, patientId, data}) {
    try {
      const response = await instance.patch(`${path}/${collectiveAttendanceId}/patient/${patientId}`, {...data});
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function suspendComplementOfCollectiveActivity({collectiveAttendanceId, patientId, data}) {
    try {
      const response = await instance.delete(`${path}/${collectiveAttendanceId}/patient/${patientId}`, {data});
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getComplementsByCollectiveActivityId(params) {
    try {
      const response = await instance.get(pathComplement, { params: urlQuery(params) });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  return {
    getCollectiveAttendances,
    getCollectiveAttendanceById,
    postCollectiveAttendances,
    suspendCollectiveAttendance,
    getAdmeasurementsByPatientId,
    updateCollectiveActivity,
    suspendComplementOfCollectiveActivity,
    getComplementsByCollectiveActivityId
  };
}
export default useCollectiveActivity;
