import { Badge, Box, Button, Divider, Grid, Typography } from "@mui/material";
import {
  FormCheckbox,
  FormRadio,
  FormSelectWithPaginate,
  FormSwitch,
  FormTextField,
} from "../../../components/Form";
import { SubTitle, Tabs } from "../../../components";
import TeethTab from "./TeethTab";
import ArchTab from "./ArchTab";
import Sextant from "./Sextant";
import OthersTab from "./OthersTab";
import { Dialog, DialogMedium } from "../../../helper";
import Reference from "../Reference/Reference";
import Prescription from "../Prescription/Prescription";
import Declaration from "../Declaration/Declaration";
import Intervention from "../Interventions";
import { useCallback, useContext, useMemo, useState } from "react";
import { AppContext } from "../../../contexts/AppContext";
import { useFormikContext } from "formik";
import EditorForms from "../../Attendance/EditorForms/EditorForms";
import useOdonto from "../../../service/useOdonto";
import { useQuery } from "react-query";
import { useProcedures, useSOAP } from "../../../service";
import OrthodonticTab from "./OrthodonticTab";
import EndodonticsTab from "./EndodonticsTab";
import usePermissions from "../../../config/usePermissions";
import { addMonths, format, isAfter, isValid, parseISO } from "date-fns";
import { verifyAttendanceFlow } from "../../../utils";

const types_attendance_urgency = [
  "Trauma",
  "Alveolite",
  "Abscesso",
  "Acesso a polpa + medicação intracanal",
  "Hemorragia",
  "Dor aguda",
];

export default function PlanOdonto({ odonto, ceo, conducts, attendance, allergies }) {
  const [referencesDialog, setReferencesDialog] = useState(false);
  const [prescriptionsDialog, setPrescriptionsDialog] = useState(false);
  const [declarationDialog, setDeclarationDialog] = useState(false);
  const [interventionDialog, setInterventionDialog] = useState(false);
  const [orientationDialog, setOrientationDialog] = useState(false);
  const drugPermissions = usePermissions("/soap/drugPrescription");
  const examsPermissions = usePermissions("/soap/examsPrescription");

  const {
    disableFields,
    againstReference,
    declarations,
    interventions,
    anesthesia,
    prosthesisCommand,
    patientData,
  } = useContext(AppContext);
  const { setFieldValue, values: formvalues } = useFormikContext();
  const [archType, setArchType] = useState("default");
  const { getDentalAppointmentTypes } = useOdonto();
  const { getProcedures } = useProcedures();
  const { getAttendanceTypes } = useSOAP();

  const emergencyCareTussCode = "0301060037";
  const firstConsultationTussCode = "0301010153";
  const consultationOfHigherLevelTussCode = "0301010030";

  const attendanceType = formvalues.attendance_type;
  const dentalAppoinment = formvalues.dental_appointment_type;

  const dentalAppointmentTypes = useQuery(
    "getDentalAppointmentTypes",
    () => getDentalAppointmentTypes(),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const attendanceTypes = useQuery(
    "getAttendanceTypes",
    () =>
      getAttendanceTypes({
        active: true,
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const { newConducts, referrals } = useMemo(() => {
    const newConducts = [];
    const referrals = [];
    const conductsSUSCode = [15, 16, 14, 12, 13, 17];
    const referralsSUSCodeOrder = [3, 1, 2, 4, 5, 6, 7, 8, 9, 10, 11];

    conducts.forEach((conduct) => {
      if (conductsSUSCode.includes(conduct.sus_code)) {
        const index = conductsSUSCode.indexOf(conduct.sus_code);
        newConducts[index] = conduct;
      } else {
        const index = referralsSUSCodeOrder.indexOf(conduct.sus_code);
        referrals[index] = conduct;
      }
    });

    return { newConducts, referrals };
  }, [conducts]);

  useQuery(
    ["getProcedureByDentalAppoinment", dentalAppoinment],
    () => {
      let tussCode;

      if (dentalAppoinment === "1") {
        setFieldValue(
          "conducts",
          formvalues.conducts.filter((e) => e !== 18)
        );
        if (["6", "5", "2"].includes(attendanceType)) {
          tussCode = firstConsultationTussCode;
        }
      } else if (dentalAppoinment === "2") {
        setFieldValue(
          "conducts",
          formvalues.conducts.filter((e) => e !== 18)
        );
        if (["5", "2"].includes(attendanceType)) {
          tussCode = consultationOfHigherLevelTussCode;
        }
      } else {
        setFieldValue(
          "conducts",
          formvalues.conducts.filter((e) => e !== 17)
        );
        if (attendanceType === "6") {
          tussCode = emergencyCareTussCode;
        }
        if (["5", "2"].includes(attendanceType)) {
          tussCode = consultationOfHigherLevelTussCode;
        }
      }

      return getProcedures({
        tuss_code: tussCode,
        type: "administrative",
        filter: "active",
        patient_id: patientData?.id,
        attendance_flow: verifyAttendanceFlow(attendance.risk_classification_id),
        cid_id: formvalues?.cid?.map(({ id }) => id),
      });
    },
    {
      enabled: !!dentalAppoinment,
      onSuccess: (procedure) => {
        if (procedure) setFieldValue("plan_procedure", procedure.items[0]);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const dentalAppoinmentValidation = (radio) => {
    if (!attendanceType || disableFields) return true;
    if (attendanceType === "4") return true;
    if (radio.id === 1 && verify12MonthsCycle()) return true;
    if (attendanceType === "6" && radio.id === 2) return true;
  };

  const conductsValidation = (conductSUSCode) => {
    if (["1", "2"].includes(dentalAppoinment)) {
      return conductSUSCode === 17;
    } else if (dentalAppoinment === "3") {
      return conductSUSCode === 15;
    } else return false;
  };

  const handleDentalAppoinmentByAttendance = (attendanceType) => {
    if (attendanceType === "4" || attendanceType === "6") {
      setFieldValue("treatment_type_conclusion_procedure_id", null);
      setFieldValue("dental_appointment_type", null);
      if (attendanceType === "6") {
        setFieldValue("dental_appointment_type", "3");
        setFieldValue(
          "conducts",
          formvalues.conducts.filter((e) => e !== 17)
        );
      }
    } else {
      setFieldValue("types_attendance_urgency", []);
      setFieldValue("dental_appointment_type", null);
    }
  };

  const tabs = [
    {
      label: "Dentes",
      content: (
        <TeethTab attendance={attendance} archType={archType} handleArchType={setArchType} />
      ),
    },
    {
      label: "Arcada",
      content: <ArchTab attendance={attendance} archType={archType} handleArchType={setArchType} />,
    },
    { label: "Sextante", content: <Sextant attendance={attendance} /> },
    { label: "Outros", content: <OthersTab attendance={attendance} /> },
  ];

  if (ceo)
    tabs.push(
      { label: "Ortodontia", content: <OrthodonticTab /> },
      { label: "Endodontia", content: <EndodonticsTab /> }
    );

  function handleClose() {
    setReferencesDialog(false);
    setPrescriptionsDialog(false);
    setDeclarationDialog(false);
  }

  function handleCloseInterventionDialog() {
    setInterventionDialog(false);
  }

  const lengthDeclarations = declarations.length;
  const lengthAgainstReference = againstReference.length;
  const lengthInterventions =
    interventions.length +
    (anesthesia.sterilization?.length || 0) +
    (anesthesia.types?.length || 0) +
    prosthesisCommand;

  const verify12MonthsCycle = useCallback(() => {
    let shouldDisable = false;
    if (
      attendance?.newest_odonto_initial_treatment_procedure_date &&
      isValid(new Date(attendance?.newest_odonto_initial_treatment_procedure_date))
    ) {
      const today = format(new Date(), "yyyy-MM-dd");
      const twelveMonthsDate = format(
        addMonths(parseISO(attendance?.newest_odonto_initial_treatment_procedure_date), 12),
        "yyyy-MM-dd"
      );
      if (isAfter(parseISO(twelveMonthsDate), parseISO(today))) {
        shouldDisable = true;
      }
    } else {
      shouldDisable = false;
    }

    return shouldDisable;
  }, [attendance?.newest_odonto_initial_treatment_procedure_date]);

  return (
    <>
      <Dialog
        open={orientationDialog}
        title={"Orientações"}
        maxWidth="lg"
        fullWidth={true}
        handleClose={() => {
          setOrientationDialog(false);
        }}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.light,
            fontWeight: 500,
            fontSize: "1.5em",
            marginBottom: 5,
          }}
        >
          {patientData.name}
        </Typography>
        <EditorForms
          staticPatientId={patientData.id}
          attendanceId={attendance?.id}
          showOnlyTable={true}
          staticType={"orientations"}
        />
      </Dialog>
      <DialogMedium
        open={referencesDialog}
        title={"Referência / Contrarreferência"}
        maxWidth="md"
        fullWidth={true}
        handleClose={handleClose}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.light,
            fontWeight: 500,
            fontSize: "1.5em",
            marginBottom: 1,
          }}
        >
          {patientData.name}
        </Typography>
        <Reference attendance={attendance} />
      </DialogMedium>
      <DialogMedium
        open={prescriptionsDialog}
        title={"Prescrição do Paciente"}
        maxWidth="md"
        fullWidth={true}
        handleClose={handleClose}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.light,
            fontWeight: 500,
            fontSize: "1.5em",
            marginBottom: 1,
          }}
        >
          {patientData.name}
        </Typography>
        <Prescription attendance={attendance} allergies={allergies} handleClose={handleClose} />
      </DialogMedium>
      <DialogMedium
        open={declarationDialog}
        title={"Gerar Declaração"}
        maxWidth="md"
        fullWidth={true}
        handleClose={handleClose}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.light,
            fontWeight: 500,
            fontSize: "1.5em",
            marginBottom: 1,
          }}
        >
          {patientData.name}
        </Typography>
        <Declaration
          defaultCids={formvalues.cid}
          attendance={attendance}
          odonto={odonto}
          ceo={ceo}
        />
      </DialogMedium>
      <DialogMedium
        open={interventionDialog}
        title={"Intervenções e/ou procedimentos clínicos"}
        maxWidth="md"
        fullWidth={true}
        handleClose={handleCloseInterventionDialog}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.light,
            fontWeight: 500,
            fontSize: "1.5em",
            marginBottom: 1,
          }}
        >
          {patientData.name}
        </Typography>
        <Intervention
          attendance={attendance}
          onClose={handleCloseInterventionDialog}
          odonto
          ceo={!!ceo}
        />
      </DialogMedium>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <FormTextField
            name="plan"
            multiline
            minRows={4}
            maxRows={4}
            label="Plano de Consulta : "
            sx={{ mb: 2 }}
            disabled={disableFields}
          />
        </Grid>
        <Grid xs={12} item>
          <Typography fontWeight={"bold"} component={"span"} color={"primary"}>
            Odontograma
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} display={"flex"} columnGap={2}>
          <Box display={"flex"} flexDirection={"column"}>
            <Typography fontWeight={"bold"} component={"span"} color={"primary"}>
              Possui aparelho?
            </Typography>
            <FormSwitch disabled={disableFields} name={"braces"} label={""} />
          </Box>
          <Box display={"flex"} flexDirection={"column"}>
            <Typography fontWeight={"bold"} component={"span"} color={"primary"}>
              Possui contenção?
            </Typography>
            <FormSwitch disabled={disableFields} name={"retainer"} label={""} />
          </Box>
          <Box display={"flex"} flexDirection={"column"} rowGap={2}>
            <Typography fontWeight={"bold"} component={"span"} color={"primary"}>
              Prótese
            </Typography>
            <Box>
              <FormCheckbox
                disabled={disableFields}
                name={"upper_denture"}
                label={"Total Superior"}
              />
              <FormCheckbox
                disabled={disableFields}
                name={"lower_denture"}
                label={"Total Inferior"}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Tabs tabs={tabs} />
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" gap={2}>
            <Button
              onClick={() => setPrescriptionsDialog(true)}
              variant="outlined"
              disabled={
                disableFields ||
                !(
                  drugPermissions.create ||
                  drugPermissions.read ||
                  examsPermissions.create ||
                  examsPermissions.read
                )
              }
            >
              Prescrições
            </Button>
            <Button
              onClick={() => setReferencesDialog(true)}
              variant="outlined"
              disabled={disableFields}
            >
              Referência / Contrarreferência
              <Badge
                color="secondary"
                overlap="circular"
                badgeContent={lengthAgainstReference}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
              />
            </Button>
            <Button
              onClick={() => setDeclarationDialog(true)}
              variant="outlined"
              disabled={disableFields}
            >
              Declarações
              <Badge
                color="secondary"
                overlap="circular"
                badgeContent={lengthDeclarations}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
              />
            </Button>
            <Button
              onClick={() => setInterventionDialog(true)}
              variant="outlined"
              disabled={disableFields}
            >
              Intervenções e/ou procedimentos clínicos
              <Badge
                color="secondary"
                overlap="circular"
                badgeContent={lengthInterventions}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
              />
            </Button>
            <Button
              onClick={() => setOrientationDialog(true)}
              variant="outlined"
              disabled={disableFields}
            >
              Orientações
            </Button>
          </Box>
        </Grid>
        <Grid item xs={3} mt={2.5}>
          <FormCheckbox
            name="confirm_procedure"
            label="Confirmar procedimento"
            disabled={disableFields || !!attendance?.finished}
          />
        </Grid>
        <Grid item xs={9}>
          <FormSelectWithPaginate
            service={(params) =>
              getProcedures({
                ...params,
                type: "administrative",
                filter: "active",
                patient_id: patientData?.id,
                attendance_flow: verifyAttendanceFlow(attendance.risk_classification_id),
                cid_id: formvalues?.cid?.map(({ id }) => id),
              })
            }
            getOptionLabel={(data) => {
              let label = data?.name;

              if (data?.tuss_code) {
                label = `${data?.tuss_code} - ${label}`;
              }

              return label;
            }}
            searchBy="filters"
            fields="describe"
            customHandleChange={(value) => {
              setFieldValue("plan_procedure", value);
            }}
            name="plan_procedure"
            label="Procedimento administrativo:"
            disabled={disableFields || formvalues.confirm_procedure}
            required
          />
        </Grid>
        <Grid xs={12} item>
          <Typography fontWeight={"bold"} component={"span"} color={"primary"}>
            Finalização do Atendimento
          </Typography>
          <Divider />
        </Grid>
        {!ceo && (
          <Grid item xs={12} display={"flex"} columnGap={2}>
            <FormRadio
              disabled={disableFields}
              name="attendance_type"
              customChange={handleDentalAppoinmentByAttendance}
              legend="Tipo de atendimento"
              radios={attendanceTypes.data}
              keys={["describe", "id"]}
              row
            />
          </Grid>
        )}
        {formvalues.attendance_type == 6 && (
          <Grid item xs={12}>
            <Box display={"flex"} flexDirection={"column"} rowGap={2}>
              <Box>
                {types_attendance_urgency.map((item) => (
                  <FormCheckbox
                    disabled={disableFields}
                    multiple
                    name={"types_attendance_urgency"}
                    label={item}
                    value={item}
                  />
                ))}
              </Box>
            </Box>
          </Grid>
        )}
        {!ceo && (
          <Grid item xs={12} display={"flex"} columnGap={2}>
            <FormRadio
              name="dental_appointment_type"
              legend="Tipo de consulta"
              radios={dentalAppointmentTypes.data}
              keys={["description", "id"]}
              disabledFn={(radio) => dentalAppoinmentValidation(radio)}
              row
            />
          </Grid>
        )}
        <Grid item xs={12} columnGap={2}>
          <Typography fontSize={12} component={"span"} color={"primary"}>
            Tipo de Tratamento
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <Box marginTop={"10px"}>
              <FormCheckbox
                disabled={
                  disableFields ||
                  formvalues.attendance_type == 4 ||
                  formvalues.attendance_type == 6 ||
                  (formvalues.treatment_type_procedure_id === 6840 &&
                    verify12MonthsCycle())
                }
                name={"treatment_type_procedure_id"}
                label={"Tratamento Inicial"}
                value={6840}
              />
            </Box> */}
            <FormRadio
              name="treatment_type_conclusion_procedure_id"
              radios={[
                {
                  describe: "Tratamento Concluído",
                  id: 6853,
                },
                {
                  describe: "Tratamento Completado",
                  id: 6838,
                },
              ]}
              disabledFn={(check) => {
                if (disableFields) {
                  return true;
                }

                if (formvalues.attendance_type == 4 || formvalues.attendance_type == 6) {
                  return true;
                }

                if (check.id === 6840 && verify12MonthsCycle()) {
                  return true;
                }
              }}
              keys={["describe", "id"]}
              row
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display={"flex"} flexDirection={"column"} rowGap={2}>
            <Typography fontSize={12} component={"span"} color={"primary"}>
              Fornecimento
            </Typography>
            <Box>
              <FormCheckbox
                disabled={disableFields}
                multiple
                name={"dental_supplies"}
                label={"Escova dental"}
                value={1}
              />
              <FormCheckbox
                disabled={disableFields}
                multiple
                name={"dental_supplies"}
                label={"Creme dental"}
                value={2}
              />
              <FormCheckbox
                disabled={disableFields}
                multiple
                name={"dental_supplies"}
                label={"Fio dental"}
                value={3}
              />
            </Box>
          </Box>
        </Grid>

        {((!!conducts.length && !disableFields) ||
          (disableFields && !!formvalues.conducts?.length)) && (
          <Grid container item xs={12} spacing={1}>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12}>
                <SubTitle text="Condutas" sx={{ mb: 2 }} />
              </Grid>
              {newConducts?.map((data) => {
                return (
                  <Grid key={data.id} item xs={6}>
                    <FormCheckbox
                      name="conducts"
                      label={data.description.firstCharToUp()}
                      value={data.id}
                      multiple
                      disabled={conductsValidation(data.sus_code)}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12}>
                <SubTitle text="Encaminhamentos" sx={{ mb: 2 }} />
              </Grid>
              {referrals?.map((data) => {
                return (
                  <Grid key={data.id} item xs={6}>
                    <FormCheckbox
                      name="conducts"
                      label={data.description.firstCharToUp()}
                      value={data.id}
                      multiple
                      disabled={conductsValidation(data.sus_code)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}
