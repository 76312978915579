import React, { useState } from "react";
import { BoxContainer, FormTextField, SubmitButton } from "../../../components";
import TableFilter from "../../../components/Table/TableFilter";
import { Dialog, Loading } from "../../../helper";
import { useAttendaces, usePatientDischarge } from "../../../service";
import PatientDischargeForm from "./PatientDischargeForm";
import PatientDischargeFeedback from "./PatientDischargeFeedback";
import { useMutation, useQuery } from "react-query";
import { Box } from "@mui/system";
import { Button, Grid, TablePagination } from "@mui/material";
import usePermissions from "../../../config/usePermissions";
import { useLocation, useParams } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { patientDischargePDF, openURL } from "../../../pdfModels";
import { PatientInfo } from "../../Monitoring/PatientInfo";
import { Form, Formik } from "formik";
import Yup from "../../../config/yup";
import useNotifier from "../../../hooks/useNotifier";

function PatientDischargeList() {
  const permission = usePermissions("/attendance/patientDischarge");
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [registerHigh, setRegisterHigh] = React.useState(false);
  const [displayHigh, setDisplayHigh] = React.useState(false);
  const [suspendEvolution, setSuspendEvolution] = useState(false);
  const [patientDischarge, setPatientDischarge] = useState();
  const [attendance, setAttendance] = useState(null);
  const { id } = useParams();
  const location = useLocation();
  const notify = useNotifier();

  const { getAttendanceById } = useAttendaces();
  const { getPatientDischarges, suspendPatientDischarges } =
    usePatientDischarge();
  const suspendMutation = useMutation(suspendPatientDischarges);

  useQuery("attendance-by-id", () => getAttendanceById(id), {
    enabled: !attendance,
    retry: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    onSuccess(response) {
      setAttendance(response);
    },
  });

  const patientDischargeQuery = useQuery(
    ["patient-discharge", attendance?.id, page, limit],
    () => getPatientDischarges(attendance?.patient?.id, { page, limit }),
    {
      enabled: !!attendance,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      initialData: { total: 0, items: [] },
    }
  );

  const tableColumns = [
    {
      name: "Atendimento",
      field: "attendance?.number",
    },
    {
      name: "Data do atendimento",
      field: "attendance?.createdAt",
      use: (date) => (date ? format(parseISO(date), "dd/MM/yyyy - HH:mm") : ""),
    },
    {
      name: "Profissional",
      field: "employee_specialty?.employee?.name",
    },
    {
      name: "Data da alta",
      field: "createdAt",
      use: (date) => (date ? format(parseISO(date), "dd/MM/yyyy - HH:mm") : ""),
    },
  ];

  function handleClose() {
    setRegisterHigh(false);
    setDisplayHigh(false);
  }

  const validations = Yup.object().shape({
    cancelation_notes: Yup.string()
      .nullable()
      .required("A justificativa é obrigatória"),
  });

  function suspendDischarge(cancelation_notes) {
    suspendMutation.mutate(
      { id: suspendEvolution, cancelation_notes },
      {
        onSuccess: (data) => {
          notify(data.message, "success");
          setSuspendEvolution(false);
          patientDischargeQuery.refetch();
        },
        onError: (err) => {
          notify(err.message, "error");
        },
      }
    );
  }

  if (patientDischargeQuery.isLoading) return <Loading />;

  return (
    <>
      <BoxContainer
        title="Lista de Altas"
        backTo={location.state?.backTo}
        backState={{
          menuServiceOpen: true,
          use: id,
        }}
      >
        <Dialog
          open={registerHigh}
          title={"Cadastro de Alta"}
          handleClose={handleClose}
        >
          <PatientDischargeForm
            patient={attendance?.patient}
            handleClose={handleClose}
          />
        </Dialog>
        <Dialog
          open={displayHigh}
          title={"Visualização de Alta"}
          handleClose={handleClose}
        >
          <PatientDischargeFeedback data={patientDischarge} />
        </Dialog>
        <Dialog
          open={suspendEvolution}
          title={`Suspensão de Alta`}
          maxWidth="md"
          fullWidth={true}
          fullScreen={false}
          handleClose={() => setSuspendEvolution(false)}
        >
          <Formik
            onSubmit={suspendDischarge}
            initialValues={{ cancelation_notes: "" }}
            validationSchema={validations}
          >
            {() => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormTextField
                      name="cancelation_notes"
                      label="Justificativa de suspensão"
                      required
                      multiline
                      minRows={8}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <SubmitButton>Suspender</SubmitButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog>
        <PatientInfo attendance={attendance} />
        <TableFilter
          data={patientDischargeQuery.data.items}
          columns={tableColumns}
          loading={patientDischargeQuery.isLoading}
          emptyMessage={patientDischargeQuery.error?.message}
          confirmMessage="Você realmente deseja *action* esta alta?"
          actions
          disableActions={(row, action) =>
            row.canceledAt && ["print", "suspend"].includes(action)
          }
          actionsTypes={["view", "print", "suspend"]}
          actionHandleView={(_, patientDischarge) => {
            setPatientDischarge(patientDischarge);
            setDisplayHigh(true);
          }}
          actionHandleSuspend={(_, data) => {
            setSuspendEvolution(data.id);
          }}
          actionHandlePrint={(_, patientDischarge) => {
            if (patientDischarge.signed_document.result) {
              openURL(patientDischarge.signed_document.result);
            } else {
              const pdf = patientDischargePDF(
                patientDischarge,
                attendance?.patient,
                {
                  createdAt: patientDischarge.createdAt,
                  employee: {
                    ...patientDischarge.employee_specialty.employee,
                    specialty: patientDischarge.employee_specialty.specialty,
                  },
                  company: patientDischarge?.attendance?.company,
                }
              );
              pdf.open();
            }
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={patientDischargeQuery.data.total}
            component="div"
            page={page}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={({ target }) => {
              setLimit(parseInt(target.value, 10));
              setPage(0);
            }}
          />
        </Box>
        <Button
          variant="contained"
          color="secondary"
          disabled={!permission.create}
          onClick={() => setRegisterHigh(true)}
        >
          Novo Cadastro
        </Button>
      </BoxContainer>
    </>
  );
}

export default PatientDischargeList;
