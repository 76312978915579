import { createNumberMask, createPhoneNumberMask } from "./mask";

const RegexOf = {
  cpf: [/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/],
  rg: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  cep: [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/],
  familyProntuary: [/\d/, /\d/, /\d/],
  phone: createPhoneNumberMask,
  telephone: ["(", /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
  cns: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  cnpj: [
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    "/",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
  ],
  pis_pasep: [/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/, /\d/, ".", /\d/, /\d/, "-", /\d/],
  price: createNumberMask({
    prefix: ["R", "$", " "],
    separator: ",",
    delimiter: ".",
    precision: 2,
  }),
  familyName: /^[A-Z]{2}\.\d{2}\.\d{3}$|^\d{2}\.\d{2}\.\d{3}$/,
};

export default RegexOf;
