import axios from "axios";
import cookie from "js-cookie";
import { unprotectedRoutes } from "./config";

function getSignaturetoken() {
  const singnatureToken = cookie.get("signer-auth-token");
  return singnatureToken || null;
}

function getAuthToken() {
  const authToken = cookie.get("x-access-token");
  return authToken || null;
}

const instance = axios.create({
  baseURL: window.API_URL,
});

export const lunaInstance = axios.create({
  baseURL: window.LUNA_AI_URL,
});

function verifyIsNotExcluded(url) {
  const urlsToExclude = [/^\/external-services\/tsigner\/validate\/signer-token/];

  const isNotExcludedUrl = !urlsToExclude.some((url) => url.test(url));
  const isNotExcludedPath = !unprotectedRoutes.some((path) => path.test(window.location.pathname));

  return isNotExcludedUrl && isNotExcludedPath;
}

instance.interceptors.request.use((config) => {
  config.headers = {
    platform: "web",
    "internal-version": window.INTERNAL_VERSION,
    "x-access-token": getAuthToken(),
    "signer-auth-token": getSignaturetoken(),
    ...config.headers,
  };

  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.data?.valid === false &&
      verifyIsNotExcluded(error.response.config.url)
    ) {
      cookie.remove("x-access-token");
      cookie.remove("signer-auth-token");
      localStorage.clear();
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

export default instance;
